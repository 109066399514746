<template>
  <o-modal :active="isExcelModalActive" :canCancel="false" class="modal-sm">
    <div class="modal-card">
      <header>
       <div class="modal-card-head is-borderless">
          <div class="card-header-title is-justify-content-left has-text-black-bis pl-0">
            <span class="is-capitalized">{{ modalHeaderTitle }}</span>
          </div>
          <div class="is-divider-vertical"></div>
          <i class="icon-close is-clickable" @click="close()"></i>
        </div>
        <div class="is-primary" v-if="tab !== 'tasks' && (maxLimit || importLimit)">
          <div class="header-note is-size-5 is-primary" v-if="!isSubstitute && !(importLimit &&
              activeTab === 0 &&
              ((tab !== 'templateManagerRuns' && tab !== 'managerRuns') ||
                !ptEnabled))">
            <span class="has-text-white">Note: </span>
            <span class="has-text-white-ter">
              File must contain no more than {{ maxLimit }} entries
            </span>
          </div>
          <div v-else class="header-note">
            <span
          v-if="
            importLimit &&
              activeTab === 0 &&
              ((tab !== 'templateManagerRuns' && tab !== 'managerRuns') ||
                !ptEnabled)
          "
          class=""
          >File must contain no more than {{ importLimit }} entries
        </span>
          </div>
        </div>
      </header>
      <section class="modal-card-body is-visible has-text-black-bis">
        <p class="is-size-5 has-text-weight-normal is-italic has-text-right">*required</p>
        <div class="column p-0">
          <div class="columns">
            <div class="column" v-if="bulkExcelImport">
              <h3 class="title">
                <span class="has-text-black-bis has-text-weight-semibold is-italic is-size-3">Target Project *</span>
              </h3>
              <mf-multi-select v-model="selectedTargetProject"
                  :options="options"
                  :multiple="false"
                  :searchable="true"
                  openDirection="below"
                  label="name"
                  track-by="_id"
                  :allow-empty="false">
              </mf-multi-select>
            </div>
            <div class="column">
               <h3 class="title">
                <span class="has-text-black-bis has-text-weight-semibold is-italic is-size-3">Excel File *</span>
              </h3>
              <excel-reader ref="excelReaderRef"
                @on-select-file="onReadFile"
                @clear-file-selection="resetFields"
                @upload-raw-file="getRawFile"
                 />
            </div>
          </div>
        </div>
        <div class="field" v-if="bulkExcelImport && !importOrders">
            <div class="field-label">
              <label class="has-text-black-bis is-italic is-size-3 label title">
                During Import
              </label>
            </div>
            <div class="field-body is-flex">
              <div>
                <o-checkbox class="pl-0"
                  v-model="createBom">
                  <span class="has-text-black-bis">
                    Create Available BOMs
                  </span>
                </o-checkbox>
              </div>
              <o-checkbox
                v-model="submitBom"
                :disabled="isSubmitDisabled()"
              >
                <span class="has-text-black-bis">
                  Submit Available BOMs
                </span>
              </o-checkbox>
            </div>
        </div>
         <h4 class="title has-text-black-bis has-text-weight-bold is-size-3 mb-0">
          Map Excel Columns to Manufacton
         </h4>
        <slot name="PTRuns"> </slot>
        <div class="excel-import-body">
          <table class="table is-fullwidth has-background-grey-lighter">
            <tr>
              <th width="40%" class="pr-0" >
                Manufacton Property <span class="is-pulled-right line-right"></span>
              </th>
              <th width="60%">Excel Column</th>
            </tr>
            <slot name="location"></slot>
            <tr v-for="(col, index) in excelKeys" :key="index">
              <template v-if="isFieldSlot(excelCols[col].field)">
                <slot :name="excelCols[col].field"></slot>
              </template>
              <template v-else>
                <td width="40%"  class="pr-0">
                  <span>{{ headerTitle(col) === 'QA' ? 'QA/QC' : headerTitle(col) }}</span>
                  <span v-if="requiredFields.includes(excelCols[col].field.toUpperCase())"> *</span>
                  <span class="is-pulled-right line-right"></span>
                </td>
                <td width="60%">
                  <mf-multi-select
                    v-if="readFile"
                    :options="headers"
                    :multiple="excelCols[col].multiple"
                    :displayType="$_.get(excelCols, `${col}.displayType`, '')"
                    v-model="model[excelCols[col].field]"
                    @select="updateSelection(excelCols[col].field)"
                    @remove="updateSelection(excelCols[col].field)"
                  ></mf-multi-select>
                  <span v-else>No File Chosen</span>
                </td>
              </template>
            </tr>
          </table>
        </div>
        <o-checkbox
          v-model="addDefaultItem"
          v-if="
            (store.state.activeScreen === 'planning' ||
              store.state.activeScreen === 'mm-preparation') &&
              !kitsImport &&
              !multiAssemblyImport
          "
        >
          {{ getTag() }}
        </o-checkbox>
        <o-checkbox
          v-model="createProdOrders"
          v-if="showAdditionalPrefabOpts && !kitsImport && !multiAssemblyImport"
          :disabled="createPm"
        >
          Create a Production Order for each Package
        </o-checkbox>
        <o-checkbox
          v-model="createPm"
          v-if="showAdditionalPrefabOpts && !kitsImport && !multiAssemblyImport"
        >
          Move Created order to Detailing
        </o-checkbox>
        <span
          v-if="
            activeTab === 1 &&
              excelCols['NOTES'] &&
              excelCols['NOTES'].field === 'inventoryNotes'
          "
          class="has-text-danger"
        >
          Notes more than 32 characters would be trimmed to 32!
        </span>
      </section>
      <footer class="modal-card-foot is-justify-content-flex-end">
        <button class="button is-outlined" type="button" @click="close()">
          Close
        </button>
        <button
          class="button has-background-black-bis"
          :disabled="disableNextButton"
          @click="next"
        >
          Import
        </button>
      </footer>
    </div>
    <o-modal :active="isLoading"
    v-if="isLoading && isProgressBar"  :canCancel="false" class="modal-xs">
      <progress-bar
        :totalCount="totalProgressLength"
        :progressCount="progressCount"
        :headerLabel= "modalHeaderTitle"
        progressType="percent"
        progressLabel=" Imported"
      />
    </o-modal>
    <o-loading  v-if="isLoading && !isProgressBar"
      :full-page="true"
      :active="isLoading"
      :can-cancel="false">
    </o-loading>
  </o-modal>
  <excel-invalid-data
    :isActive="isInvalidModalActive"
    :loadData="loadInvalidData"
    :tableCols="getInvalidExcelCols"
    :excelFileName="excelFileName"
    @close="closeInvalidExcelModal"
    :key="invalidRefreshKey"
  />
</template>
<script>
import {
  reactive, toRefs, computed, nextTick, ref, watch, onUpdated,
} from 'vue';
import { useStore } from 'vuex';
import _ from 'lodash';
import ExcelReader from '@/components/ExcelReader.vue';
import Filter from '@/directives/filters';
import SupplyChain from '@/models/SupplyChain';
import QtyOptions from '@/components/utils/QtyOptions';
import { useToast } from 'vue-toastification';
import moment from 'moment';
import Validation from '@/components/utils/Validations';
import ExcelMixin from '@/components/mixins/ExcelMixin';
import PrefabExcelCols from '@/components/excel-cols/prefabExcelCols';
import KitsExcelCols from '@/components/excel-cols/kitsExcelCols';
import MultiAssmbCols from '@/components/excel-cols/multiAssemblyExcelCols';
import ChecklistExcelCols from '@/components/excel-cols/checklistExcelCols';
import AssemblyExcelCols from '@/components/excel-cols/assemblyExcelCols';
import PartsExcelCols from '@/components/excel-cols/partsExcelCols';
import RunsExcelCols from '@/components/excel-cols/runsExcelCols';
import ScheduleExcelCols from '@/components/excel-cols/ScheduleExcelCols';
import ExcelInvalidData from '@/components/modals/ExcelInvalidData.vue';
import BulkExcelItemsCols from '@/components/excel-cols/bulkExcelItemsCols';
import BulkExcelOrderCols from '@/components/excel-cols/bulkExcelOrderCols';
import ProductionManagerMixin from '@/components/mixins/ProductionManagerMixin';
import Locations from '@/models/Locations';
import Validations from '@/models/Validations';
import Catalogs from '@/models/Catalogs';
import ProgressBar from '@/components/modals/ProgressBar';
import Upload from '@/models/Upload';
import ProductionManager from '../models/ProductionManager';

export default {
  emits:['closeModal','refreshTable', 'targetProject'],
  components: {
    ExcelReader,
    ExcelInvalidData,
    'progress-bar': ProgressBar,
  },
  props: {
    card: Object,
    isExcelModalActive: Boolean,
    importLimit: Number,
    type: String,
    tab: String,
    ptEnabled: Boolean,
    importModule: String,
    kitsImport: Boolean,
    multiAssemblyImport: Boolean,
    ptLimit: Number,
    maxLimit: Number,
    catalogImport: Boolean,
    uniqueFields: {
      type: Array,
      default: () => [],
    },
    batchSize: Number,
    mapData: {
      type: Function,
      default: () => {},
    },
    saveExcelItem: {
      type: Function,
      default: null,
    },
    parallelCount: {
      type: Number,
      default: 5,
    },
    dates: {
      type: Array,
      default: () => [],
    },
    validateExcelItem: {
      type: Function,
      default: null,
    },
    forShipping: { type: Boolean, default: false },
    isNestedLocImport: {
      type: Boolean,
      default: false,
    },
    modalHeaderTitle: {
      type: String,
      default: 'import data from excel',
    },
    selectedPrefab: String,
    selectedProjectId: Object,
    importOrders: {
      type: Boolean,
      default: true,
    },
    bulkExcelImport: {
      type: Boolean,
      default: false,
    },
    allTemplateOrders: {
      type: [Array, Object],
      default: () => [],
    },
  },
  setup(props, { slots, emit }) {
    const store = useStore();
    const toast = useToast();
    const { validateExcelItem, saveExcelItem, isValidInteger } = ExcelMixin();
    const excelReaderRef = ref(null);
    const data = reactive({
      selectedTargetProject: '',
      createBom: false,
      submitBom: false,
      activeTab: 0,
      excelFileName: '',
      headers: [],
      disableNextButton: true,
      excelHeaders: {},
      model: {},
      createProdOrders: false,
      moveToDetailing: false,
      readFile: false,
      existingKits: [],
      excelData: {},
      uniqueVal: {},
      isLoading: false,
      saveArray: [],
      itemsToCreate: [],
      itemsImport: false,
      invalidData: [],
      success: false,
      isProgressModal: false,
      importPercentage: 0,
      downloadErrorLog: false,
      showErrorLog: false,
      limit: props.importLimit,
      qtyToastGenerated: false,
      showAdditionalPrefabOpts: false,
      datesMap: [
        {
          kind: 'coord',
          label: 'Coordination',
          lockedStages: ['detailing', 'manufacturing', 'qa', 'delivery'],
        },
        {
          kind: 'poDetailBy',
          label: 'Detail By',
          lockedStages: ['manufacturing', 'qa', 'delivery'],
        },
        {
          kind: 'partsManufactureBy',
          label: 'Assembly Manuf',
          lockedStages: ['qa', 'delivery'],
        },
        {
          kind: 'poManufactureBy',
          label: 'Manufacture',
          lockedStages: ['qa', 'delivery'],
        },
        {
          kind: 'poQaBy',
          label: 'QA',
          lockedStages: ['delivery'],
        },
        {
          kind: 'deliver',
          label: 'Onsite By',
          lockedStages: [],
        },
      ],
      toManagerDates: {
        poDetailBy: 'detailBy',
        poManufactureBy: 'manufactureBy',
        poQaBy: 'qaBy',
      },
      selectedProjectId: {},
      isInvalidModalActive: false,
      invalidExcelColsType: '',
      invalidRefreshKey: 0,
      progressCount: 0,
      totalProgressLength: 0,
      isProgressBar: false,
      rawFile: '',
      warning: null,
    });
    data.isProgressBar = (store.state.activeScreen === 'planning' && !props.multiAssemblyImport && !props.batchSize)
      || store.state.activeScreen === 'mm-preparation';
    const { saveCard, createBOMForOrders } = ProductionManagerMixin();

    const options = computed(() => store.state.queryParams.projects);

    const turnOffProgress = () => {
      setTimeout(() => {
        data.isProgressBar = false;
        data.progressCount = 0;
      }, 2000);
    };

    const isSubmitDisabled = () => {
      if (!data.createBom) {
        data.submitBom = false;
        return true;
      }
      return false;
    };
    const getRawFile = async (file) => {
      data.rawFile = file;
    };

    const setInvalidExcelCols = (invalidColsType) => {
      _.set(data, 'invalidExcelColsType', invalidColsType);
    };

    const getGroupedItemsData = async (orderIds) => {
      const params = {
        uniqueOrderId: orderIds,
        projectId: data.selectedTargetProject._id,
        company: store.getters.selectedIdsForKey('companies', false),
        isManager: true,
        screen: 'manager',
        module: 'ProductionOrder',
        fromPM: true,
        limit: 500
      };
      const response = await SupplyChain.supplyChain(params);
      return response;
    };

    const getInvalidExcelCols = computed(() => store.getters.invalidExcelColumns(_.get(data, 'invalidExcelColsType', '')));

    const excelCols = computed(() => {
      const { activeScreen } = store.state;
      if (activeScreen === 'detailing') {
        if (props.importOrders) {
          setInvalidExcelCols('prodorders-excel-import');
          return BulkExcelOrderCols;
        }
        setInvalidExcelCols('prodOrderItems-excel-import');
        return BulkExcelItemsCols;
      }
      if (activeScreen === 'schedule') {
        setInvalidExcelCols('schedule-excel-import');
        return ScheduleExcelCols;
      }
      if (activeScreen === 'planning' && props.kitsImport) {
        setInvalidExcelCols('kits-excel-import');
        return KitsExcelCols;
      }
      if (activeScreen === 'planning' && props.multiAssemblyImport) {
        setInvalidExcelCols('multi-assembly-import');
        return MultiAssmbCols;
      }
      if (activeScreen === 'planning') {
        setInvalidExcelCols('prefabs-import');
        data.showAdditionalPrefabOpts = true;
        return PrefabExcelCols;
      }
      if (activeScreen === 'po-edit' && props.tab !== 'tasks') {
        setInvalidExcelCols('prefab-items-import');
        return store.getters.excelColumns();
      }
      if (
        ['material-edit-sourcing', 'material-edit-ordering'].includes(
          activeScreen,
        )
        && props.importModule === 'mm-items-import'
      ) {
        const mmExcelCols = _.cloneDeep(store.getters.excelColumns(props.tab));
        delete mmExcelCols.NAME;
        delete mmExcelCols.ID;
        delete mmExcelCols['CATALOG ID'];
        setInvalidExcelCols('mm-items-import');
        return mmExcelCols;
      }
      if (['manager-edit-detailing', 'manager-edit-manufacturing'].includes(activeScreen) && props.tab === 'workSteps') {
        setInvalidExcelCols('pm-runs-import');
        return RunsExcelCols;
      }
      if (['manager-edit-detailing', 'manager-edit-manufacturing'].includes(activeScreen) && props.tab === 'managerItem') {
        setInvalidExcelCols('prefab-items-import');
        return store.getters.excelColumns('');
      }
      if (props.tab === 'tasks') {
        setInvalidExcelCols('order-cl-import');
        return ChecklistExcelCols;
      }
      if (activeScreen === 'inventory' && props.isNestedLocImport) {
        setInvalidExcelCols('inventory-import');
        const { PROJECT, ...cols } = store.getters.excelColumns(props.tab);
        return cols;
      }

      if (activeScreen === 'assemblies' && props.importModule === 'assemblyParts-import') {
        setInvalidExcelCols('assemblyParts-import');
        return AssemblyExcelCols;
      }

      if (activeScreen === 'parts' && props.importModule === 'assemblyParts-import') {
        setInvalidExcelCols('assemblyParts-import');
        return PartsExcelCols;
      }

      setInvalidExcelCols(activeScreen);
      return store.getters.excelColumns(props.tab);
    });

    const excelKeys = computed(() => _.keys(excelCols.value));

    const requiredFields = computed(() => _(excelCols.value)
      .map((val) => (val.required ? val.field.toUpperCase() : null))
      .compact()
      .value());
    const datesArray = computed(() => props.dates || data.datesMap);

    const isFieldSlot = (fieldName) => typeof slots[fieldName] !== 'undefined';

    const addDefaultItem = computed({
      get: () => store.state.defaultItem,
      set: (val) => store.commit('setDefaultItem', val),
    });
    const createPm = computed({
      get: () => data.moveToDetailing,
      set: (val) => {
        if (!data.createProdOrders) data.createProdOrders = val;
        data.moveToDetailing = val;
      },
    });

    const waitOnReadFile = computed(() => data.readFile);

    const methods = {
      headerTitle(title) {
        return Filter.headerTitle(title);
      },

      async uploadRawFile() {
        const uploadId = store.state.companyData._id;
        const result = await Upload.FileUpload(data.rawFile, `company=${uploadId}&type=companies`, 'commons/uploadFile');
        const files = result.data?.files[0];
        _.set(files, 'sourceId', uploadId);
        return files;
      },

      checkToDisableNext(selectedProject = data.selectedProjectId) {
        const mappedModelKeys = [];
        for (const [key, val] of Object.entries(data.model)) {
          if (
            (!_.isArray(val) && val !== null)
            || (_.isArray(val) && val.length > 0)
          ) {
            mappedModelKeys.push(key.toUpperCase());
          }
        }
        if (!props.bulkExcelImport) {
          data.selectedProjectId = !_.isEmpty(selectedProject)
            ? selectedProject : props.selectedProjectId;
          data.disableNextButton = (!_.every(requiredFields.value,
            (val) => mappedModelKeys.includes(val)) || (_.isEmpty(data.selectedProjectId) && isFieldSlot('project')));
        } else {
          data.disableNextButton = (!_.every(requiredFields.value, (val) => mappedModelKeys.includes(val)) || _.isNull(selectedProject));
        }
      },

      getParams() {
        return _.pick(props, [
          'importLimit',
          'ptEnabled',
          'importModule',
          'kitsImport',
          'multiAssemblyImport',
          'ptLimit',
          'maxLimit',
          'catalogImport',
          'batchSize',
          'forShipping',
          'dates',
          'selectedPrefab',
        ]);
      },

      onReadFile(excelDataObject) {
        const { tableData, workbook, fileName } = excelDataObject;
        if (!workbook) return;
        data.disableNextButton = false;
        data.headers = tableData.header;
        data.excelData = tableData.body;
        data.excelFileName = fileName;

        if (
          (props.ptEnabled
            && (props.tab.includes('templateManagerRuns')
              || props.tab.includes('managerRuns'))
            && data.excelData.length > props.ptLimit)
          || data.excelData.length > props.importLimit
          || data.excelData.length > props.maxLimit
        ) {
          data.disableNextButton = true;
          return;
        }
        _.map(excelCols.value, (val) => {
          if (val.multiple) {
            _.set(data, `model[${val.field}]`, []);
          }
        });
        let key = '';
        data.headers.forEach((header) => {
          if (excelKeys.value.includes(header.toUpperCase().trim())) {
            key = excelCols.value[header.toUpperCase().trim()].field;
            if (data.model[key] instanceof Array) {
              data.model[key].push(header);
              data.excelHeaders[key] = [];
              data.excelHeaders[key].push(header);
            } else if (header !== 'Project') {
              data.model[key] = header;
              data.excelHeaders[key] = header;
            }
          }
        });
        methods.checkToDisableNext();
        if(props.bulkExcelImport && !data.selectedTargetProject){
          data.disableNextButton = true;
        }
        data.readFile = true;
      },

      async next() {
        if (props.bulkExcelImport) {
          data.disableNextButton = true;
          await methods.detailImport();
          return;
        }
        let response;
        if (props.kitsImport || props.multiAssemblyImport) {
          data.existingKits = await methods.getExistingKits(data.excelData);
        }
        _.forEach(props.uniqueFields, (f) => {
          data.uniqueVal[f] = [];
        });

        data.isLoading = true;
        data.totalProgressLength = data.excelData.length;
        data.dataKey = undefined;
        data.disableNextButton = true;
        data.isProgressModal = true;
        let importExcelData = '';
        importExcelData = await methods.uploadRawFile();
        data.saveArray = _.map(excelCols.value, (col) => col.field);
        data.itemChunks = _.chunk(data.excelData, props.batchSize);
        if (props.multiAssemblyImport) {
          const kitGroups = _.groupBy(
            data.excelData,
            data.excelHeaders.customId,
          );
          data.itemChunks = Object.values(kitGroups);
        }
        if (props.batchSize > 1 || props.multiAssemblyImport) {
          data.itemsToCreate = data.itemChunks;
          data.dataKey = 'itemsToCreate';
          data.itemsImport = true;
        } else {
          data.dataKey = 'excelData';
        }
        data.i = 0;
        if (['planning'].includes(store.state.activeScreen) && !props.multiAssemblyImport && !props.batchSize) {
          const chunkData = _.chunk(data[data.dataKey], 50);
          for (const chunk of chunkData) {
            const requests = [];
            for (const dataObj of chunk) {
              requests.push(this.saveItem(dataObj, importExcelData));
            }
            await Promise.all(requests);
          }
        } else {
          for await (const dataObj of data[data.dataKey]) {
            await this.saveItem(dataObj, importExcelData);
          }
        }
        if (data.success) {
          data.isExcelModalActive = false;
          if (data.invalidData.length) {
            toast.warning(`${data.invalidData.length} item(s) could not be imported`);
            data.isInvalidModalActive = true;
            data.showErrorLog = true;
            methods.close();
          } else {
            toast.success('Excel Imported Successfully');
            methods.close();
          }
        }
        data.itemsImport = false;
        data.isLoading = false;
        data.progressCount = 0;
      },
      async saveItem(dataObj, importExcelData) {
        try {
          if (data.dataKey === 'excelData') {
            dataObj = props.kitsImport
              ? await methods.createKitMap(dataObj)
              : methods.createDataMap([dataObj]);
          } else if (data.dataKey === 'itemsToCreate') {
            dataObj = methods.createDataMap(dataObj);
          }
          let currBatchNo = data.i;
          currBatchNo++;
          const isFinalBatch = data[data.dataKey].length === currBatchNo;
          const validItems = [];
          if (
            ['assemblyParts-import', 'vendor-import'].includes(
              props.importModule,
            )
          ) dataObj = await props.mapData(dataObj);
          for await (const obj of dataObj) {
            if (props.kitsImport && _.some(data.existingKits, { customId: obj.customId })) {
              obj._isValid = false;
              obj._errorMsg = 'Kit Id already exists.';
              data.invalidData.push(obj);
              continue;
            }
            const validData = await methods.validate(obj, dataObj, currBatchNo);
            if (validData) {
              if (!_.isEmpty(props.uniqueFields)) {
                _.forEach(props.uniqueFields, (f) => {
                  if (validData[f]) {
                    data.uniqueVal[f].push(validData[f].toUpperCase());
                  }
                });
              }
              if (props.batchSize > 1 || props.multiAssemblyImport) {
                validItems.push(validData);
              } else {
                // eslint-disable-next-line no-lonely-if
                if (['assemblies', 'parts'].includes(store.state.activeScreen)) {
                  data.response = await props.saveExcelItem(
                    validData,
                    data.excelFileName, isFinalBatch, currBatchNo,
                  );
                  if (data.response?.failedData.length > 0) {
                    data.invalidData.push(...data.response.failedData);
                  }
                } else {
                  if (importExcelData) _.set(validData, 'excelFile', importExcelData);
                  data.response = await saveExcelItem(
                    props.card,
                    validData,
                    methods.getParams(),
                    props.saveExcelItem,
                    data.excelFileName,
                    isFinalBatch,
                    currBatchNo,
                  );
                  if (data.response?.failedData.length > 0) {
                    data.invalidData.push(...data.response.failedData);
                  }
                }
              }
            }
          }
          if (validItems.length) {
            if (['assemblies', 'parts'].includes(store.state.activeScreen)) {
              if (importExcelData) {
                validItems.forEach((item) => _.set(item, 'excelFile', importExcelData));
              }
              data.response = await props.saveExcelItem(
                _.compact(validItems),
                data.excelFileName, isFinalBatch, currBatchNo,
                props.multiAssemblyImport ? _.find(
                  data.existingKits,
                  (k) => k.customId.toLowerCase() === validItems[0].customId.toLowerCase(),
                ) : '',
              );
              if (data.response?.failedData.length > 0) {
                data.invalidData.push(...data.response.failedData);
              }
            } else {
              if (importExcelData) {
                validItems.forEach((item) => _.set(item, 'excelFile', importExcelData));
              }
              data.response = await saveExcelItem(
                props.card,
                _.compact(validItems),
                methods.getParams(),
                props.saveExcelItem,
                data.excelFileName,
                isFinalBatch,
                currBatchNo,
                props.multiAssemblyImport
                  ? _.find(
                    data.existingKits,
                    (k) => k.customId.toLowerCase()
                      === validItems[0].customId.toLowerCase(),
                  )
                  : '',
              );
              if (data.response?.failedData.length > 0) {
                data.invalidData.push(...data.response.failedData);
              }
            }
          }
          data.success = true;
          data.i++;
        } catch (e) {
          data.disableNextButton = false;
          data.isProgressModal = false;
          let errorMsg = e?.config?.data || '{}';
          errorMsg = JSON.parse(errorMsg);
          _.set(errorMsg, '_errorMsg', e?.data?.msg);
          data.invalidData.push(errorMsg);
          return;
          // continue;
        }
        if (dataObj) {
          if (!_.isUndefined(dataObj.length)) data.progressCount += dataObj.length;
          else data.progressCount += 1;
          data.importPercentage = _.round((data.i / data.itemChunks.length) * 100);
        }
      },

      async detailingOrdersImport(dataObj, allUniqOrderIds, existingUniqIds, importExcelData, allProjectLocations, response, validData, itemChunks, retrievedUniqueIds) {
        try {
          dataObj = methods.createDataMap([dataObj]);
          if (Validation.validateUniqueOrderId(_.get(dataObj, '[0].uniqueOrderId', ''))) {
            const uniqueOrderId = Validation.getValidUniqueorderId(_.get(dataObj, '[0].uniqueOrderId', ''));
            _.set(dataObj, '[0].uniqueOrderId', uniqueOrderId);
          }
          for await (const obj of dataObj) {
            // validate required fields
            validData = methods.validateRequiredFields(obj, allUniqOrderIds, existingUniqIds, allProjectLocations, retrievedUniqueIds);
            if (importExcelData) _.set(validData, 'excelFile', importExcelData);
            if (validData) {
              response = await saveExcelItem(
                props.card,
                validData,
                methods.getParams(),
                props.saveExcelItem,
                data.excelFileName,
                null,
                null,
                null,
                allProjectLocations,
              );
            }
            if (response?.failedData.length > 0) {
              data.invalidData.push(...response.failedData);
            }
          }
          data.success = true;
        } catch (e) {
          console.log(e);
          toast.error('Import Failed : ', e.message);
          data.disableNextButton = false;
          let errorMsg = e?.config?.data || '{}';
          errorMsg = JSON.parse(errorMsg);
          _.set(errorMsg, '_errorMsg', e?.data?.msg);
          data.invalidData.push(errorMsg);
        }
      },

      async detailImport() {
        data.isLoading = true;
        if (props.importOrders) {
          let allProjectLocations = [];
          if (data.selectedTargetProject !== null) {
            allProjectLocations = await Locations.allLocationForUser({
              projectId: data.selectedTargetProject._id,
            });
          }
          emit('target-project', data.selectedTargetProject, data.createBom, data.submitBom);
          let response;
          data.disableNextButton = true;
          data.saveArray = _.map(excelCols.value, (col) => col.field);
          const itemChunks = _.chunk(data.excelData, 1);
          const validUniqOrderIds = [];
          let allUniqOrderIds = [];
          let validData;
          data.totalProgressLength = itemChunks.length;
          for (let dataObj of data.excelData) {
            dataObj = methods.createDataMap([dataObj]);
            const uniqueOrderId = Validation.getValidUniqueorderId(_.get(dataObj, '[0].uniqueOrderId', ''));
            _.set(dataObj, '[0].uniqueOrderId', uniqueOrderId);
            if (!_.isEmpty(uniqueOrderId)) {
              allUniqOrderIds.push(uniqueOrderId);
              if (!_.includes(validUniqOrderIds, uniqueOrderId)) {
                validUniqOrderIds.push(uniqueOrderId);
              }
            }
          }
          const detailingValidOrders = await getGroupedItemsData(validUniqOrderIds);
          let retrievedUniqueIds = await ProductionManager.getUniqueOrderIds({ uniqueOrderIds: allUniqOrderIds, projectId: data.selectedTargetProject._id });
          retrievedUniqueIds = _.map(retrievedUniqueIds, 'uniqueOrderId.value');
          allUniqOrderIds = _.groupBy(allUniqOrderIds, (uniqOrd) => uniqOrd);
          const existingOrdersWithUniqId = _.groupBy(detailingValidOrders.data, 'uniqueOrderId.value');
          const existingUniqIds = _.keys(existingOrdersWithUniqId);
          const importExcelData = await methods.uploadRawFile();
          data.isProgressBar = true;
          for (const chunk of itemChunks) {
            const promises = [];
            for (const dataObj of chunk) {
              promises.push(methods.detailingOrdersImport(dataObj, allUniqOrderIds, existingUniqIds, importExcelData, allProjectLocations, response, validData, itemChunks, retrievedUniqueIds));
            }
            await Promise.all(promises);
            if (data.warning) toast.warning(data.warning);
            data.warning = null;
            data.progressCount += 1;
          }
        } else {
          emit('target-project', data.selectedTargetProject, data.createBom, data.submitBom);
          let response;
          data.isLoading = true;
          data.disableNextButton = true;
          data.saveArray = _.map(excelCols.value, (col) => col.field);
          const allCatIds = await Catalogs.getAllCatIds();
          const catIds = [];
          for (const catItem of allCatIds.data) {
            if (catItem.__t === 'AssemblyItems') {
              catIds.push(catItem.catId);
            }
          }
          const detailingValidItems = {};
          const duplicateEntries = {};
          const uniqOrderIds = [];
          let validData;
          try {
            for (let dataObj of data.excelData) {
              dataObj = methods.createDataMap([dataObj]);
              const uniqueOrderId = Validation.getValidUniqueorderId(_.get(dataObj, '[0].uniqueOrderId', ''));
              const catalogId = _.get(dataObj[0], 'catId', '');
              if (uniqueOrderId && !_.includes(uniqOrderIds, uniqueOrderId)) {
                uniqOrderIds.push(uniqueOrderId);
              }
              if (!_.isEmpty(uniqueOrderId) && !_.isEmpty(catalogId)) {
                if (!_.includes(_.keys(duplicateEntries), uniqueOrderId)) {
                  _.set(duplicateEntries, uniqueOrderId, [catalogId]);
                } else {
                  duplicateEntries[uniqueOrderId].push(catalogId);
                }
              }
            }
            let detailingValidOrders = await getGroupedItemsData(uniqOrderIds);
            detailingValidOrders = _.filter(detailingValidOrders.data, (vo) => ['coordination','detailing', 'mixed'].includes(vo?.stage));
            detailingValidOrders = _.groupBy(detailingValidOrders, 'uniqueOrderId.value');
            const validOrdersKeys = _.keys(detailingValidOrders);
            for (let dataObj of data.excelData) {
              dataObj = methods.createDataMap([dataObj]);
              for (const obj of dataObj) {
                validData = methods.validateRequiredItemFields(obj, detailingValidOrders, validOrdersKeys, catIds, duplicateEntries, data.excelData);
                if (validData) {
                  if (!_.includes(_.keys(detailingValidItems), obj.uniqueOrderId)) {
                    detailingValidItems[obj.uniqueOrderId] = [obj];
                  } else {
                    detailingValidItems[obj.uniqueOrderId].push(obj);
                  }
                }
              }
            }
            data.totalProgressLength = validOrdersKeys.length;
            // itereate through detailingValidItems and detailingValidOrders add Items
            const params = methods.getParams();
            data.isProgressBar = true;
            for await (const unqOrder of validOrdersKeys) {
              if (detailingValidItems[unqOrder] && detailingValidItems[unqOrder].length) {
                let card = _.first(detailingValidOrders[unqOrder]);
                const cardItems = _.first(card.items);
                const cardBaseOrder = _.first(cardItems.cards);
                const defaultPrefabId = cardBaseOrder;
                const items = detailingValidItems[unqOrder];
                const importExcelData = await methods.uploadRawFile();
                if (importExcelData) _.set(card, 'excelFile', importExcelData);
                _.set(params, 'selectedPrefab', { _id: defaultPrefabId, name: card.name });
                // setting defaultItemArchive value for items import
                if (card.name === card.items[0].name && card.items.length === 1) {
                  _.set(card.items[0], 'defaultItemArchive', true);
                }
                response = await saveExcelItem(
                  card,
                  items,
                  params,
                  null,
                  data.excelFileName,
                );
                if (response?.failedData.length > 0) {
                  data.invalidData.push(...response.failedData);
                }
                await saveCard(card._id, card.stage, card, props.importOrders).then((resp) => {
                  card = resp;
                }).catch((err) => {
                  const errObj = {
                    _isValid: false,
                    _errorMsg: null,
                    name: null,
                    uniqueOrderId: null,
                    quantity: null,
                    catId: null,
                  };
                  if (_.isArray(err) && err.length) {
                    for (const errItem of err) {
                      errObj._errorMsg = `Item with ${errItem.catId} already exist in prefab`;
                      errObj.name = errItem.itemId;
                      errObj.uniqueOrderId = errItem.uniqueOrderId;
                      errObj.catId = errItem.catId;
                      errObj.quantity = errItem.quantity;
                      data.invalidData.push(errObj);
                    }
                  } else {
                    data.invalidData.push(errObj);
                  }
                });

                // eslint-disable-next-line max-len
                const isCatIdItemExist = _.some(card.items, (cardItem) => !_.isEmpty(cardItem.catId));
                if (isCatIdItemExist && data.createBom) {
                  card = await createBOMForOrders(card);
                  if (data.submitBom) {
                    const materialsToSubmit = await card.getSubmitMaterials();
                    // eslint-disable-next-line max-len
                    const disableSubmit = _.some(materialsToSubmit, (ord) => ord.hasNotPurchasedItems);
                    if (_.some(materialsToSubmit, (material) => ['preparation', 'mixed'].includes(material.stage)) && !disableSubmit) {
                      await card.submitMaterials(materialsToSubmit, card);
                    }
                  }
                }
              }
              data.progressCount += 1;
            }
            data.success = true;
          } catch (e) {
            console.log(e);
            toast.error('Import Failed : ', e.message);
            data.disableNextButton = false;
            let errorMsg = e?.config?.data || '{}';
            errorMsg = JSON.parse(errorMsg);
            _.set(errorMsg, '_errorMsg', e?.data?.msg);
            data.invalidData.push(errorMsg);
          }
        }
        turnOffProgress();
        if (data.success) {
          if (data.invalidData.length ) {
            toast.warning(`${data.invalidData.length} item(s) could not be imported`);
            data.isInvalidModalActive = true;
            data.showErrorLog = true;
            methods.close();
          } else {
            toast.success('Excel Imported Successfully');
            methods.close();
          }
        }
        data.itemsImport = false;
        data.isLoading = false;
      },

      async getExistingKits(dataObj) {
        let kitIds = _.compact(_.map(dataObj, data.excelHeaders.customId));
        if (props.multiAssemblyImport) {
          kitIds = _.uniq(kitIds);
        }
        const idChunks = _.chunk(kitIds, 100);
        let kits = [];
        try {
          for (const ids of idChunks) {
            kits = [
              ...kits,
              ...(
                await SupplyChain.supplyChain({
                  customId: ids,
                  projectId: data.selectedProjectId._id,
                  module: ['Prefabs', 'ProductionOrder'],
                  // added the limit 99999 as we cannot be sure that only one order
                  // present for each kit.
                  // When the order is moved to PO there will be multiple orders with same kit ID.
                  limit: 99999,
                })
              ).data,
            ];
          }
          return kits;
        } catch (e) {
          console.log(e);
          return e;
        }
      },

      createDataMap(dataObj) {
        const arrayToSend = [];
        data.limit = props.importLimit; // require for when import limit provided
        dataObj.forEach((item) => {
          if (Object.values(item).every((col) => !col)) return;
          const createData = {};
          data.limit--;
          if (data.limit < 0) return;
          data.saveArray.forEach((key) => {
            if (
              !_.isEmpty(data.excelHeaders[key])
              && _.isArray(data.excelHeaders[key])
            ) {
              data.excelHeaders[key].forEach((ec) => {
                createData[key] = createData[key]
                  ? `${createData[key]} ${item[ec]}`
                  : item[ec];
              });
            } else if (!_.isUndefined(item[data.excelHeaders[key]])) {
              if (
                key.toString() === 'quantity'
                && parseInt(item[data.excelHeaders[key]], 10) === 0
                && ![
                  'mm-items-import',
                  'tmm-items-import',
                  'inventory-import',
                ].includes(props.importModule)
              ) {
                item[data.excelHeaders[key]] = 1;
              }
              if (
                key.toString() === 'quantity'
                && parseInt(item[data.excelHeaders[key]], 10)
                  > QtyOptions.inputProps.max
              ) {
                item[data.excelHeaders[key]] = QtyOptions.inputProps.max;
                if (!data.qtyToastGenerated) {
                  toast.warning(
                    `Item quantities above ${QtyOptions.inputProps.max} were reset to ${QtyOptions.inputProps.max}`,
                  );
                  data.qtyToastGenerated = true;
                }
              }
              if (key.toString() === 'itemId') {
                createData.customId = item[data.excelHeaders[key]];
              }
              createData[key] = item[data.excelHeaders[key]];
            }
          });

          if (!_.isEmpty(createData)) {
            createData.fromExcel = true;
            createData.updatedBy = 'excel';
            arrayToSend.push(createData);
          }
        });
        return arrayToSend;
      },

      async createKitMap(item) {
        const arrayToSend = [];
        const kits = _.filter(
          data.existingKits,
          (kit) => kit.customId.toUpperCase()
            === item[data.excelHeaders.customId].toUpperCase(),
        );
        if (_.isEmpty(kits)) {
          kits.push({
            customId: item[data.excelHeaders.customId],
            purpose: 'kit',
            _isDirty: true,
          });
        }
        for (const kit of kits) {
          const mfRow = kit;
          mfRow._isValid = true;
          mfRow._errorMsg = 'OK';
          mfRow.updatedBy = 'excel';
          mfRow._beforeUpdate = _.cloneDeep(mfRow);
          const datesVal = [];
          for (const dateObj of data.datesMap) {
            const { kind, label } = dateObj;
            const date = _.cloneDeep(dateObj);
            let place = `simpleDates.${kind}`;
            if (mfRow.isManager) {
              date.kind = data.toManagerDates[kind]
                ? data.toManagerDates[kind]
                : kind;
              if (['detailBy', 'manufactureBy', 'qaBy'].includes(date.kind)) {
                place = `manager.simpleDates.${[date.kind]}`;
              }
            }
            date.place = place;
            for (const key of data.saveArray) {
              if (key === 'name' && mfRow.purpose === 'assembly') break;
              if (!item[data.excelHeaders[key]]) continue;
              mfRow[key] = item[data.excelHeaders[key]];
              if (
                ['prefabOwner', 'docNames', 'documents', 'note'].includes(key)
              ) {
                if (mfRow[key]) mfRow._isDirty = true;
              }
            }
            if (!data.excelHeaders[kind]) continue;
            let dateVal = moment(_.get(item, data.excelHeaders[kind], '')).add(0, 'd').format('l');
            if (!methods.validateFormat(mfRow, dateVal, label)) break;
            if (mfRow._id) {
              if (
                ![
                  'planning',
                  'coordination',
                  'detailing',
                  'manufacturing',
                  'qa',
                  'mixed',
                ].includes(mfRow.stage)
              ) continue;
              if (kind === 'poManufactureBy' && mfRow.purpose === 'assembly') {
                // set assembly's mfby date to row's partsMfBy date
                [dateVal] = moment(_.get(item, data.excelHeaders.partsManufactureBy, '')).add(0, 'd').format('l');
              }
              if (dateVal) methods.kitDateUpdate(mfRow, date, dateVal);
            } else {
              mfRow[kind] = dateVal;
            }
            if (mfRow[kind] || _.get(mfRow, `${place}.value`, '')) {
              const newDate = mfRow._id
                ? {
                  kind: date.kind,
                  label,
                  val: _.get(mfRow, `${place}.value`, null),
                }
                : { kind: date.kind, label, val: new Date(mfRow[date.kind]) };
              datesVal.push(newDate);
            } else {
              datesVal.push({ kind: date.kind, label, val: '' });
            }
          }
          if (!mfRow.name) mfRow.name = mfRow.customId;
          methods.basicDateValidation(mfRow, datesVal);
          if (mfRow._isDirty) {
            arrayToSend.push(mfRow);
          } else {
            console.log(
              `kit order with custom id '${mfRow.customId}' to be skipped not dirty`,
            );
          }
        }
        // eslint-disable-next-line consistent-return
        return arrayToSend;
      },

      duplicateExists(obj) {
        if (_.isEmpty(props.uniqueFields)) return false;
        for (const field of props.uniqueFields) {
          if (
            obj[field]
            && data.uniqueVal[field].includes(obj[field].toString().toUpperCase())
          ) {
            return true;
          }
        }
        return false;
      },

      isValidDate(dateObj) {
        if (dateObj instanceof Date) {
          return true;
        }
        return false;
      },

      validateRequiredFields(obj, allUniqOrderIds, existingUniqIds, allProjectLocations, retrievedUniqueOrderIds) {
        if (!obj) return null;
        const newObj = {
          _isValid: false,
          _errorMsg: null,
          uniqueOrderId: obj.uniqueOrderId,
          templateId: obj.templateId,
          name: obj.name,
        };
        let templateOrder;
        if (_.isEmpty(obj?.templateId?.toString())) {
          newObj._errorMsg = 'Template ID is required';
          data.invalidData.push(newObj);
          return null;
        } if (obj.templateId) {
          // check if the templateId is valid
          if (!_.isEmpty(props.allTemplateOrders)) {
            templateOrder = _.find(props.allTemplateOrders.data, (temp) => {
              const tempId = obj?.templateId ? obj.templateId.toString() : obj.templateId;
              return temp.templateId === tempId;
            });
          }
          if (_.isEmpty(templateOrder)) {
            newObj._errorMsg = `Template Id ${obj.templateId} does not exist`;
            data.invalidData.push(newObj);
            return null;
          } if (!['all', 'detailing'].includes(templateOrder.stageUsed)) {
            newObj._errorMsg = `Template is being used in stage ${templateOrder.stageUsed} only`;
            data.invalidData.push(newObj);
            return null;
          } if (!_.isEmpty(templateOrder)) {
              const totalItems = templateOrder.items.length;
              const itemLimit = 20;
              let itemParRun = 21;
              if (!_.isEmpty(templateOrder.manager.runs)) {
                const runsNumber = templateOrder.manager.runs.length;
                itemParRun = totalItems / runsNumber;
                if (itemParRun > itemLimit) {
                  newObj._errorMsg = 'Please add more runs to template order';
                  data.invalidData.push(newObj);
                  return null;
                } else if (totalItems > itemLimit) {
                  newObj._errorMsg = 'Please add runs to template';
                  data.invalidData.push(newObj);
                  return null;
                }
              }
          }
        }
        obj.name = _.toString(obj.name);
        obj.location = _.isNumber(obj.location) ? obj.location.toString() : obj.location;
        if (!newObj._errorMsg && (_.isEmpty(obj.name) || (!_.isEmpty(obj.name) && obj.name.length < 3))) {
          newObj._errorMsg = 'Order name is mandatory and should be atleast 3 characters long';
          data.invalidData.push(newObj);
          return null;
        } if (_.isEmpty(obj.location)) {
          newObj._errorMsg = 'Location is required';
          data.invalidData.push(newObj);
          return null;
        } if (!_.isEmpty(obj.location)) {
          const isValidLoc = _.find(allProjectLocations, (loc) => loc.name === obj.location);
          if (_.isEmpty(isValidLoc)) {
            if (_.isEmpty(templateOrder?.manager?.location?.name)) {
              newObj._errorMsg = "Invalid Location entry and also Template Doesn't have Location";
              data.invalidData.push(newObj);
              return null;
            }
            data.warning = 'Invalid Location entry will be replaced by Template Location';
          }
        }
        if (!methods.isValidDate(obj.poDetailBy)) {
          newObj._errorMsg = 'Detailby Date is required';
          data.invalidData.push(newObj);
          return null;
        } if (!methods.isValidDate(obj.deliver)) {
          newObj._errorMsg = 'Onsite Date is required';
          data.invalidData.push(newObj);
          return null;
        } if (obj.poManufactureBy && !methods.isValidDate(obj.poManufactureBy)) {
          newObj._errorMsg = 'Manufacture By Date is in Invalid Format';
          data.invalidData.push(newObj);
          return null;
        }
        const deliverDate = moment(obj.deliver);
        const manufactureByDate = obj.poManufactureBy && methods.isValidDate(obj.poManufactureBy) ? moment(obj.poManufactureBy) : '';
        const detailByDate = moment(obj.poDetailBy);
        if (_.isEmpty(manufactureByDate) && !deliverDate.isSameOrAfter(detailByDate)) {
          newObj._errorMsg = 'Invalid Dates';
          data.invalidData.push(newObj);
          return null;
        } if (!_.isEmpty(manufactureByDate) && !(deliverDate.isSameOrAfter(detailByDate) && detailByDate.isSameOrBefore(manufactureByDate) && deliverDate.isSameOrAfter(manufactureByDate))) {
          newObj._errorMsg = 'Invalid Dates';
          data.invalidData.push(newObj);
          return null;
        }
        obj.uniqueOrderId = _.toString(obj.uniqueOrderId);
        if (!_.isEmpty(obj.uniqueOrderId)) {
          if (obj.uniqueOrderId.length < 4 || obj.uniqueOrderId.length > 24) {
            newObj._errorMsg = 'Order Id must be in range of 4-24 characters';
            data.invalidData.push(newObj);
            return null;
          } if (!Validation.validateUniqueOrderId(obj.uniqueOrderId)) {
            newObj._errorMsg = 'Unique Order Contains Invalid Characters';
            data.invalidData.push(newObj);
            return null;
          } if (_.includes(existingUniqIds, obj.uniqueOrderId) || _.includes(retrievedUniqueOrderIds, obj.uniqueOrderId)) {
            newObj._errorMsg = 'Order already exist with this Order Id';
            data.invalidData.push(newObj);
            return null;
          } if (allUniqOrderIds[obj.uniqueOrderId] && allUniqOrderIds[obj.uniqueOrderId].length !== 1) {
            newObj._errorMsg = 'Duplicate Order Id Entry';
            data.invalidData.push(newObj);
            return null;
          }
        }
        if (obj.financialId && obj.financialId !== '') {
          obj.financialId = obj.financialId.toString().trim().replace(/ /g, '.').toUpperCase();
          if (!Validations.validateOrderId(obj.financialId)) {
            newObj._errorMsg = 'Description length must be 4 to 24 characters long and cannot start or end with special characters';
            data.invalidData.push(newObj);
            return null;
          }
        }
        return obj;
      },

      validateRequiredItemFields(obj, detailingValidOrders, validOrders, allCatIds, duplicateEntries, excelData) {
        if (!obj) return null;
        const newObj = {
          _isValid: false,
          _errorMsg: null,
          name: obj.name,
          uniqueOrderId: obj.uniqueOrderId,
          quantity: obj.quantity,
          catId: obj.catId,
        };
        obj.uniqueOrderId = _.toString(obj.uniqueOrderId).toUpperCase();
        let lengthOfRuns = 0;
        let objKey;
        for (const key in detailingValidOrders) {
          objKey = key;
          if (detailingValidOrders.hasOwnProperty(key) && Array.isArray(detailingValidOrders[key])) {
            lengthOfRuns = detailingValidOrders[key][0].manager.runs.length;
            break;
          }
        }
        if ((excelData.length + detailingValidOrders[objKey][0].items.length) > 20*lengthOfRuns) {
          newObj._errorMsg = '1 workstep can only have 20 items.';
          data.invalidData.push(newObj);
          return null;
        }
        if (_.isEmpty(obj.uniqueOrderId)) {
          newObj._errorMsg = 'Order Id Is Empty';
          data.invalidData.push(newObj);
          return null;
        } if (!_.isEmpty(obj.uniqueOrderId) && !_.includes(validOrders, obj.uniqueOrderId)) {
          newObj._errorMsg = 'No Order Exist With This Order Id';
          data.invalidData.push(newObj);
          return null;
        } if (_.isEmpty(obj.name.toString().trim()) || (obj.name && obj.name.toString().trim().length < 3)) {
          newObj._errorMsg = 'Name should be atleast 3 characters long';
          data.invalidData.push(newObj);
          return null;
        } if (obj.quantity && !isValidInteger(obj, 'quantity')) {
          newObj._errorMsg = 'Quantity should be an integer in the range 0 - 9999';
          data.invalidData.push(newObj);
          return null;
        } if (!_.isEmpty(obj.catId)) {
          obj.catId = Validation.getValidCatId(obj.catId);
          if (!Validation.validateCatalogId(obj.catId)) {
            newObj._errorMsg = 'Catalog ID should be 4 to 32 character long';
            data.invalidData.push(newObj);
            return null;
          } if (!_.includes(allCatIds, obj.catId)) {
            newObj._errorMsg = "Item with this Catalog Id doesn't Exist";
            data.invalidData.push(newObj);
            return null;
          } if (_.find(detailingValidOrders[obj.uniqueOrderId][0].items, (item) => !_.isEmpty(item.catId) && item.catId === obj.catId)) {
            newObj._errorMsg = 'Item With this Catalog ID is Already Exist In Order';
            data.invalidData.push(newObj);
            return null;
          }
          const dupCatId = _.countBy(duplicateEntries[obj.uniqueOrderId], (CatItemId) => CatItemId);
          const CatItemId = obj.catId;
          if (dupCatId[CatItemId] && dupCatId[CatItemId] !== 1) {
            newObj._errorMsg = 'Duplicate CatId entry for same Order Id';
            data.invalidData.push(newObj);
            return null;
          }
        }
        return obj;
      },

      async validate(obj, dataObj, currBatchNo) {
        let err = null;
        if (!obj) return null;
        for (const dateObj of datesArray.value) {
          if (_.has(obj, dateObj.kind)) {
            const isValid = methods.validateFormat(
              obj,
              obj[dateObj.kind],
              dateObj.label,
            );
            if (!isValid) break;
            // obj[dateObj.kind] = moment(obj[dateObj.kind]).toJSON();
          }
        }
        if (methods.duplicateExists(obj) && !obj?.appendToAssembly) {
          err = 'Duplicate Entry';
        }
        if (['assemblies', 'parts'].includes(store.state.activeScreen)) {
          err = obj && !err ? props.validateExcelItem(obj, true) : err;
        } else {
          err = obj && !err
            ? await validateExcelItem(
              props.card,
              obj,
              dataObj,
              props.validateExcelItem,
              methods.getParams(),
              props.multiAssemblyImport
                ? _.find(
                  data.existingKits,
                  (k) => k.customId.toLowerCase() === obj.customId.toLowerCase(),
                )
                : '',
              currBatchNo,
            )
            : err;
        }
        if (err) {
          obj._isValid = false;
          obj._errorMsg = err;
        } else {
          if (_.isUndefined(obj._isValid)) obj._isValid = true;
          methods.basicDateValidation(obj, datesArray.value);
        }
        if (_.has(obj, '_errorMsg') && obj._errorMsg !== 'OK') {
          data.invalidData.push(obj);
          if (obj._isValid) return obj;
          return null;
        }
        return obj;
      },

      close() {
        methods.resetFields();
        data.moveToDetailing = false;
        data.selectedTargetProject = null;
        store.commit('setDefaultItem', false);
        emit('refresh-table');
        emit('close-modal');
        emit('close');
      },

      kitDateUpdate(kit, dateObj, val) {
        if (!kit._isValid) return;

        // check if date needs to be updated
        const oldDate = _.get(kit, `${dateObj.place}.value`, null);
        const newDate = moment(val).hours(12);
        if (oldDate && newDate.diff(moment(oldDate), 'days') === 0) {
          if (!_.has(kit, '_isDirty')) {
            kit._isDirty = false;
          }
          return;
        }
        kit._isDirty = true;
        // don't update locked dates
        if (dateObj.lockedStages.includes(kit.stage)) {
          kit._errorMsg = `Cannot update dates till ${dateObj.label} as the kit is in ${kit.stage} stage`;
          return;
        }
        // don't update if user modified date in mf platform
        if (_.get(kit, `${dateObj.place}.isDirty`, false)) {
          kit._errorMsg = `Cannot update ${dateObj.label} as it was modified.`;
          return;
        }
        kit.addOrUpdateDate(
          dateObj.kind,
          moment(val)
            .hours(12)
            .format(),
        );
      },

      basicDateValidation(row, datesOrder) {
        if (!row._isValid) return;

        // basic sequence validation
        const datesVal = datesOrder.map(
          (date) => _.get(date, 'val', -100) || -100,
        );
        let index = datesVal.length - 1;
        let lowIndex = index;
        while (index > 0 && lowIndex > 0) {
          lowIndex -= 1;
          if (datesVal[index] === -100) continue;
          if (datesVal[lowIndex] === -100) continue;
          if (moment(datesVal[lowIndex]).isAfter(datesVal[index], 'd')) {
            row._isValid = false;
            row._errorMsg = `'${datesOrder[lowIndex].label}' date should not `
              + `be greater than '${datesOrder[index].label}' date!`;
            return;
          }
          index = lowIndex;
        }
      },
      validateFormat(row, val, label) {
        if ((!val || val === 'Invalid date') && props.kitsImport && label !== 'QA') {
          row._isValid = false;
          row._errorMsg = `'${row.customId}' kit has empty ${label} date`;
          row._isDirty = true;
          return false;
        }
        if (val && !Validation.isDateValid(val) && props.kitsImport && label !== 'QA') {
          row._isValid = false;
          row._errorMsg = `${label} date format is invalid. We support mm/dd/yy mm/dd/yyyy m/d/yy m/dd/yy.`;
          row._isDirty = true;
          return false;
        }
        if (val && !Validation.isDateValid(val)) {
          row._isValid = false;
          row._errorMsg = `${label} date format is invalid. We support mm/dd/yy mm/dd/yyyy m/d/yy m/dd/yy.`;
          row._isDirty = true;
          return false;
        }
        if (!moment(val).isValid() && !props.kitsImport) {
          row._isValid = false;
          row._errorMsg = `${label} date is empty`;
          row._isDirty = true;
        }
        return true;
      },

      resetFields() {
        _.assign(data, {
          headers: [],
          disableNextButton: true,
          excelHeaders: {},
          model: {},
          readFile: false,
          existingKits: [],
          excelData: {},
          uniqueVal: {},
          createProdOrders: false,
          createBom: false,
          submitBom: false,
        });
        // eslint-disable-next-line no-unused-expressions
        excelReaderRef.value?.clearFile(false);
      },

      getTag() {
        if (store.state.activeScreen === 'planning' && !props.multiAssemblyImport) {
          return 'Add a default item to each new Prefab Package';
        }
        if (store.state.activeScreen === 'mm-preparation') {
          return 'Add a default item to each new Material Order';
        }
        return '';
      },
    };

    watch(
      () => data.selectedTargetProject,
      (newVal) => {
        methods.checkToDisableNext(newVal);
      },
    );

    const updateSelection = async (selectedOption) => {
      await nextTick();
      data.excelHeaders[selectedOption] = data.model[selectedOption];
      if (props.bulkExcelImport) methods.checkToDisableNext(data.selectedTargetProject);
      else methods.checkToDisableNext();
    };

    const loadInvalidData = () => data.invalidData;

    const closeInvalidExcelModal = () => {
      data.invalidData = [];
      data.isInvalidModalActive = false;
      data.invalidRefreshKey++;
    };

    onUpdated(() => {
      if (options.value.length === 1) {
        _.set(data, 'selectedTargetProject', options.value[0]);
      }
    });

    return {
      store,
      ...toRefs(data),
      ...methods,
      excelCols,
      datesArray,
      excelKeys,
      requiredFields,
      updateSelection,
      isFieldSlot,
      addDefaultItem,
      excelReaderRef,
      getInvalidExcelCols,
      loadInvalidData,
      closeInvalidExcelModal,
      createPm,
      options,
      isSubmitDisabled,
      getGroupedItemsData,
      turnOffProgress,
      getRawFile,
    };
  },
};
</script>
