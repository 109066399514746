import _ from 'lodash';
import validateLmv from '@/components/utils/lmv3dotValidation';

export default {
  defaultSortField: 'name',
  defaultSortOrder: 'asc',
  isDetailed: true,
  stickeyHeaders: true,
  rearrangeCols: false,
  detailRowComponent: 'mf-detail-row',
  gutterOpts: {
    buttons: [{
      event: 'columnChooser',
      icon: 'icon-tablecolumns',
      tooltip: 'Customize Columns',
      isLine: true,
    }],
  },
  permissionModule: (screen) => (screen === 'planning' ? 'prefabs' : 'production-order'),
  toolBar: {
    // name: for drop down label, label: for action button label
    bulkActions: (stage) => {
      const opts = [];
      if (stage === 'planning') {
        opts.push(
          {
            name: 'Create Production Orders', event: 'moveOrdersForward', label: 'Create Orders',
          },
          {
            name: 'Delete Orders', event: 'removeOrders', label: 'Delete Orders',
          },
        );
      }
      if (stage === 'coordination') {
        opts.push(
          // {
          //   name: 'Move Orders To Detailing', event: 'moveForward', label: 'Move Forward',
          // },
          {
            name: 'Move Orders Forward', event: 'moveOrdersForward', label: 'Move Orders',
          },
          {
            name: 'Move Orders Back To Planning', event: 'moveBackward', label: 'Move Back',
          },
          {
            name: 'Combine Orders (limit 2)', event: 'combine', label: 'Combine Orders', refreshOnCancel: true,
          },
          {
            name: 'Edit Description', event: 'editOrderIds', label: 'Edit Order IDs',
          },
          {
            name: 'Delete Orders', event: 'removeOrders', label: 'Delete',
          },
        );
      }
      if (stage === 'schedule') {
        opts.push(
          {
            name: 'Hide Activities', event: 'hideSchedule', label: 'Hide Activities',
          }, {
            name: 'Accept/Reject Schedule Updates', event: 'resolveConflict', label: 'Accept/Reject Schedule Updates',
          }, {
            name: 'Update Submittals', event: 'updateSubmittal', label: 'Update Submittals',
          }, {
            name: 'Update On-Site Dates', event: 'updateOnSiteDateSchedule', label: 'Update On-Site Dates',
          }, {
            name: 'Move To Ordering', event: 'moveSchedules', label: 'Move To Ordering', refreshOnCancel: false,
          },
        );
      }
      return opts;
    },
    hideSearchBar: false, // by default let the search bar be available for the table
    buttons: (stage) => {
      const buttons = [];
      if (stage === 'schedule') {
        buttons.push({
          event: 'excelImport',
          showOptionsForStage: 'planning',
          icon: 'icon-importexcel',
          tooltip: 'Import From Excel',
        },
        {
          event: 'excelExport',
          icon: 'icon-exportexcel',
          tooltip: 'Export to Excel',
          showOptionsForStage: 'planning',
        });
      } else {
        buttons.push({
          event: 'addNew',
          icon: 'icon-addnew',
          isLine: true,
          tooltip: 'Add',
          showOptionsForStage: 'planning',
          options: [{
            label: 'Create Prefab Package',
            type: 'prefab',
          }, {
            label: 'Create Kit',
            type: 'kit',
          }],
        });
      }
      if (stage === 'planning') {
        buttons.push(
          {
            event: 'excelImport',
            showOptionsForStage: 'planning',
            options: [{
              label: 'Import Prefab Package',
              type: 'importPrefab',
            }, {
              label: 'Import Kit',
              type: 'importKits',
            }, {
              label: 'Import Assembly',
              type: 'importAssemblies',
            }],
            icon: 'icon-importexcel',
            tooltip: 'Import From Excel',
          },
        );
      }
      return buttons;
    },
  },
  fields: [
    {
      planning: [
        {
          title: 'Kits/Prefab Packages',
          name: 'field-generic-input',
          sortField: 'name',
          prop: 'name',
          width: '400px',
          id: 'name',
          sticky: true,
          isClickable: true,
          eventType: 'openCard',
          defaultVisible: true,
          alwaysVisible: true,
          searchField: false, // to give a search bar for the field
          searchable: true, // to place any custom field in the header of the column like for below
          bulkActionButtonField: true,
          inputProps: {
            type: 'text',
          },
        },
        {
          title: 'Description',
          name: 'field-generic-input',
          prop: 'financialId',
          width: '152px',
          id: 'financialId',
          sortField: 'financialId',
          isDisabled: false,
          defaultVisible: false,
        },
        {
          title: 'Kit ID',
          name: 'field-kit-id',
          prop: 'customId',
          width: '152px',
          id: 'customId',
          sortField: 'customId',
          isDisabled: true,
          defaultVisible: true,
        },
        {
          name: 'lmvIcon',
          width: '64px',
          title: ' ',
          id: 'lmvIcon',
          customHeader: true,
          customSlotHeader: true,
          defaultVisible: true,
          inputProps: {
            icon: 'icon-linked-model',
          },
        },
        {
          title: 'Project',
          name: 'field-generic-input',
          prop: 'project.name',
          sortField: 'project',
          searchable: true,
          filterText: 'Projects',
          width: '296px',
          id: 'project',
          isDisabled: true,
          defaultVisible: true,
        },
        {
          title: 'Stage',
          name: 'field-stage',
          prop: 'stage',
          searchable: true,
          filterText: 'Stages',
          id: 'orderStage',
          width: '192px',
          defaultVisible: true,
          alwaysVisible: false,
          sortField: 'stage',
          cardProps: {
            cardType: '__t',
          },
        },
        {
          title: 'Owner',
          name: 'field-user-select',
          prop: 'owner.user',
          sortField: 'owner.user',
          width: '296px',
          id: 'owners',
          searchable: true,
          filterText: 'Owners',
          defaultVisible: true,
          alwaysVisible: false,
          cardProps: {
            companyId: 'created.by.company._id',
            projectId: 'project._id',
            isEditing: 'isEditing',
          },
        },
        {
          title: 'Company',
          name: 'field-generic-input',
          id: 'company',
          prop: 'owner.company.name',
          // searchable: true,
          // filterField: true,
          width: '296px',
          sortField: 'owner.company',
          defaultVisible: false,
          isDisabled: true,
          iPadVisible: true,
        },
        {
          title: 'Coordinate By',
          name: 'field-date',
          defaultVisible: true,
          alwaysVisible: false,
          prop: 'coord',
          kind: 'dates',
          id: 'coord',
          width: '152px',
          searchable: true,
          dateFilter: 'coord',
          sortField: 'dates.coord',
          inputProps: {
            kind: 'coord',
            max: ['deliver'],
            disableEdit: (row) => !['planning'].includes(row.stage),
          },
        },
        {
          title: 'Onsite',
          name: 'field-date',
          id: 'deliver',
          defaultVisible: true,
          alwaysVisible: false,
          prop: 'deliver',
          kind: 'dates',
          width: '152px',
          searchable: true,
          dateFilter: 'deliver',
          sortField: 'dates.deliver',
          inputProps: {
            kind: 'deliver',
            min: ['coord'],
            disableEdit: (row) => !['planning'].includes(row.stage),
          },
        },
        {
          title: 'Modified',
          name: 'lastModified',
          id: 'modified',
          defaultVisible: true,
          sortField: 'lastModified.at',
          prop: 'lastModified.at',
          searchable: true,
          dateFilter: 'modified',
          isDisabled: true,
          width: '152px',
        },
        {
          name: 'field-notes-icon',
          title: 'Notes',
          prop: 'notes',
          defaultVisible: true,
          width: '64px',
          id: 'notes',
          inputProps: {
            isItem: false,
          },
        },
        {
          title: 'Actions',
          name: 'table-field-actions',
          id: 'action',
          hideFromChooser: true,
          alwaysVisible: true,
          width: '112px',
          searchable: true,
          clearBtn: true,
          permissionModule: 'prefabs',
          sticky: true,
          thAttrs: () => ({ class: 'action-sticky' }),
          tdClass: 'action-sticky',
          inputProps: {
            showEdit: true,
            options(card) {
              let opts = [
                {
                  label: 'Delete',
                  event: 'archive',
                  icon: 'icon-removedelete ',
                }, {
                  label: 'History',
                  event: 'activity',
                  icon: 'icon-activityhistory',
                },
              ];
              if (card.isPrefab()) {
                opts.unshift({
                  label: 'Clone',
                  event: 'clone',
                  icon: 'icon-clone',
                });
              } else if (card.isPO()) {
                opts.unshift({
                  label: 'Split',
                  event: 'split',
                  icon: 'icon-split',
                });
              }
              if (card.purpose && card.purpose === 'kit') {
                opts = _.filter(opts, (opt) => ['activity', 'archive'].includes(opt.event));
              }
              if (['planning', 'mixed'].includes(card.stage) && _.some(card.items, (item) => ['planning'].includes(item.stage))) {
                opts.unshift({
                  event: 'moveForward',
                  label: 'Create Production Orders',
                });
              }
              if (validateLmv.validate3dots(card.items)) {
                opts.push({
                  icon: 'icon-linked-model',
                  label: 'View Model',
                  event: 'lmv',
                });
              }
              return opts;
            },
          },
        },
      ],
    }, {
      coordination: [
        {
          title: 'Order Name',
          name: 'field-generic-input',
          sortField: 'name',
          prop: 'name',
          width: '400px',
          sticky: true,
          id: 'name',
          isClickable: true,
          eventType: 'openCard',
          defaultVisible: true,
          alwaysVisible: true,
          searchField: false, // to give a search bar for the field
          searchable: true, // to place any custom field in the header of the column like for below
          bulkActionButtonField: true,
          inputProps: {
            type: 'text',
          },
        },
        {
          title: 'Description',
          name: 'field-generic-input',
          prop: 'financialId',
          width: '152px',
          id: 'financialId',
          sortField: 'financialId',
          isDisabled: false,
          defaultVisible: false,
        },
        {
          title: 'Order ID',
          name: 'field-generic-input',
          prop: 'uniqueOrderId.value',
          width: '152px',
          id: 'uniqueOrderId',
          isDisabled: false,
          defaultVisible: false,
        },
        {
          title: 'Kit ID',
          name: 'field-kit-id',
          prop: 'customId',
          width: '152px',
          id: 'customId',
          sortField: 'customId',
          isDisabled: true,
          defaultVisible: true,
        },
        {
          name: 'lmvIcon',
          width: '64px',
          title: ' ',
          id: 'lmvIcon',
          customHeader: true,
          customSlotHeader: true,
          defaultVisible: true,
          inputProps: {
            icon: 'icon-linked-model',
          },
        },
        {
          title: 'Project',
          name: 'field-generic-input',
          prop: 'project.name',
          sortField: 'project',
          searchable: true,
          filterText: 'Projects',
          width: '296px',
          id: 'project',
          isDisabled: true,
          defaultVisible: true,
        },
        {
          title: 'Stage',
          name: 'field-stage',
          prop: 'stage',
          searchable: true,
          filterText: 'Stages',
          sortField: 'stage',
          id: 'orderStage',
          width: '192px',
          defaultVisible: false,
          alwaysVisible: false,
          cardProps: {
            cardType: '__t',
          },
        },
        {
          title: 'Owner',
          name: 'field-user-select',
          prop: 'owner.user',
          sortField: 'owner.user',
          width: '296px',
          id: 'owners',
          defaultVisible: true,
          alwaysVisible: false,
          searchable: true,
          filterText: 'Owners',
          cardProps: {
            companyId: 'created.by.company._id',
            projectId: 'project._id',
            isEditing: 'isEditing',
          },
        },
        {
          title: 'Company',
          name: 'field-generic-input',
          id: 'companies',
          prop: 'owner.company.name',
          // searchable: true,
          // filterField: true,
          width: '296px',
          sortField: 'owner.company',
          defaultVisible: false,
          alwaysVisible: false,
          isDisabled: true,
          iPadVisible: true,
          searchField: 'owner.company.name',
          searchable: true,
          searchPlaceHolder: 'Company',
          filterText: 'Company',
        },
        {
          name: 'field-lvl-zone',
          isDisabled: false,
          sortField: 'level',
          width: '296px',
          title: 'Level',
          id: 'level',
          prop: 'level',
          searchable: true,
          searchField: 'level',
          searchPlaceHolder: 'Level',
          filterText: 'Level',
          inputProps: {
            options: 'levels',
          },
        },
        {
          name: 'field-lvl-zone',
          isDisabled: false,
          sortField: 'zone',
          width: '296px',
          title: 'Zone',
          id: 'zone',
          prop: 'zone',
          searchField: 'zone',
          searchable: true,
          searchPlaceHolder: 'Zone',
          filterText: 'Zone',
          inputProps: {
            options: 'zones',
          },
        },
        {
          title: 'Coordinate By',
          name: 'field-date',
          defaultVisible: true,
          alwaysVisible: false,
          prop: 'coord',
          kind: 'dates',
          id: 'coord',
          width: '152px',
          searchable: true,
          dateFilter: 'coord',
          sortField: 'dates.coord',
          inputProps: {
            kind: 'coord',
            max: ['deliver'],
            disableEdit: false,
          },
        },
        {
          title: 'Onsite',
          name: 'field-date',
          id: 'deliver',
          defaultVisible: true,
          alwaysVisible: false,
          prop: 'deliver',
          kind: 'dates',
          width: '152px',
          inputProps: {
            kind: 'deliver',
            min: ['coord'],
            disableEdit: false,
          },
          searchable: true,
          dateFilter: 'deliver',
          sortField: 'dates.deliver',
        },
        {
          title: 'Modified',
          name: 'lastModified',
          id: 'modified',
          defaultVisible: true,
          sortField: 'lastModified.at',
          prop: 'lastModified.at',
          classRight: 'is-pulled-right',
          searchable: true,
          dateFilter: 'modified',
          width: '152px',
          isDisabled: true,
        },
        {
          name: 'field-notes-icon',
          title: 'Notes',
          prop: 'notes',
          defaultVisible: true,
          alwaysVisible: false,
          width: '64px',
          id: 'notes',
          inputProps: {
            isItem: false,
          },
        },
        {
          title: 'Actions',
          name: 'table-field-actions',
          id: 'action',
          hideFromChooser: true,
          alwaysVisible: true,
          sticky: true,
          thAttrs: () => ({ class: 'action-sticky' }),
          tdClass: 'action-sticky',
          width: '112px',
          searchable: true,
          clearBtn: true,
          permissionModule: 'production-order',
          inputProps: {
            showEdit: true,
            options(card) {
              let opts = [
                {
                  label: 'Delete',
                  event: 'archive',
                  icon: 'icon-removedelete ',
                }, {
                  label: 'History',
                  event: 'activity',
                  icon: 'icon-activityhistory',
                },
              ];
              if (card.isPrefab()) {
                opts.unshift({
                  label: 'Clone',
                  event: 'clone',
                  icon: 'icon-clone',
                });
              } else if (card.isPO()) {
                opts.unshift({
                  label: 'Split',
                  event: 'split',
                  icon: 'icon-split',
                });
              }
              if (card.purpose && card.purpose === 'kit') {
                opts = _.filter(opts, (opt) => ['activity', 'archive'].includes(opt.event));
              }
              if (validateLmv.validate3dots(card.items)) {
                opts.push({
                  icon: 'icon-linked-model',
                  label: 'View Model',
                  event: 'lmv',
                });
              }
              return opts;
            },
          },
        },
      ],
    }, {
      schedule: [
        {
          title: 'Activity Name',
          name: 'field-generic-input',
          sortField: 'activityName',
          prop: 'activityName',
          width: '400px',
          id: 'activityName',
          isClickable: false,
          // eventType: 'openCard',
          searchField: false, // to give a search bar for the field
          searchable: true, // to place any custom field in the header of the column like for below
          bulkActionButtonField: true,
          iconName: 'fa-calendar-times',
          alwaysVisible: true,
          defaultVisible: true,
          sticky: true,
          inputProps: {
            type: 'text',
            openCard: false,
            stageIcons: true,
          },
        }, {
          title: 'Activity ID',
          name: 'field-generic-input',
          prop: 'activityId',
          width: '152px',
          id: 'activityId',
          sortField: 'activityId',
          isDisabled: false,
          defaultVisible: true,
          inputProps: {
            type: 'text',
            openCard: false,
            stageIcons: true,
            disableEdit() {
              return true;
            },
          },
        }, {
          title: 'Submit By',
          name: 'field-date',
          defaultVisible: true,
          alwaysVisible: false,
          prop: 'submitBy',
          kind: 'dates',
          id: 'submitBy',
          width: '152px',
          searchable: true,
          dateFilter: 'submitBy',
          sortField: 'submitBy.value',
          inputProps: {
            kind: 'submitBy.value',
            disableEdit: true,
          },
        }, {
          title: 'Approve By',
          name: 'field-date',
          defaultVisible: true,
          alwaysVisible: false,
          prop: 'approveBy',
          kind: 'dates',
          id: 'approveBy',
          width: '152px',
          searchable: true,
          dateFilter: 'approveBy',
          sortField: 'approveBy.value',
          inputProps: {
            kind: 'approveBy.value',
            disableEdit: true,
          },
        }, {
          title: 'Release',
          name: 'field-date',
          defaultVisible: true,
          alwaysVisible: false,
          prop: 'release',
          kind: 'dates',
          id: 'release',
          width: '152px',
          searchable: true,
          dateFilter: 'release',
          sortField: 'release.value',
          inputProps: {
            kind: 'release.value',
            disableEdit: false,
          },
        }, {
          title: 'Order By',
          name: 'field-date',
          defaultVisible: true,
          alwaysVisible: false,
          prop: 'orderBy',
          kind: 'dates',
          id: 'orderBy',
          width: '152px',
          searchable: true,
          dateFilter: 'orderBy',
          sortField: 'orderBy.value',
          inputProps: {
            kind: 'orderBy.value',
            disableEdit: true,
          },
        }, {
          title: 'Ship By',
          name: 'field-date',
          defaultVisible: true,
          alwaysVisible: false,
          prop: 'shipBy',
          kind: 'dates',
          id: 'shipBy',
          width: '152px',
          searchable: true,
          dateFilter: 'shipBy',
          sortField: 'shipBy.value',
          inputProps: {
            kind: 'shipBy.value',
            max: 'deliverBy.value',
            disableEdit(data) {
              if (data.shippingStage === 'scheduled') {
                return false;
              }
              return true;
            },
          },
        }, {
          title: 'Deliver By',
          name: 'field-date',
          defaultVisible: true,
          alwaysVisible: false,
          prop: 'deliverBy',
          kind: 'dates',
          id: 'deliverBy',
          width: '152px',
          searchable: true,
          dateFilter: 'deliverBy',
          sortField: 'deliverBy.value',
          inputProps: {
            min: 'shipBy.value',
            kind: 'deliverBy.value',
            disableEdit(data) {
              if (['scheduled', 'in-transit'].includes(data.shippingStage)) {
                return false;
              }
              return true;
            },
          },
        }, {
          title: 'Onsite',
          name: 'field-date',
          defaultVisible: true,
          alwaysVisible: false,
          prop: 'deliver',
          kind: 'dates',
          id: 'deliver',
          width: '152px',
          searchable: true,
          dateFilter: 'deliver',
          sortField: 'deliver.value',
          inputProps: {
            kind: 'deliver.value',
            disableEdit: false,
          },
        }, {
          title: 'Submittal',
          name: 'submittal',
          prop: 'submittal.name',
          width: '196px',
          id: 'submittal',
          sortField: 'submittal.name',
          isDisabled: false,
          defaultVisible: true,
        }, {
          title: 'Submittal Response',
          name: 'field-stage',
          prop: 'submittalResponse',
          searchable: true,
          filterText: 'Responses',
          id: 'submittalResponse',
          sortField: 'submittalResponse',
          width: '196px',
          isStatus: true,
          defaultVisible: true,
          alwaysVisible: false,
          cardProps: {
            cardType: '__t',
          },
        }, {
          title: 'Vendor',
          name: 'vendor',
          // prop: 'vendor.name',
          width: '296px',
          id: 'vendor',
          sortField: 'vendor.name',
          isDisabled: false,
          defaultVisible: true,
          searchable: true,
          filterText: 'Vendors',
        }, {
          title: 'Material Order',
          name: 'field-generic-input',
          prop: 'order.name',
          width: '296px',
          id: 'order.name',
          sortField: 'order.name',
          isDisabled: false,
          eventType: 'openCard',
          defaultVisible: false,
          isClickable: true,
          inputProps: {
            type: 'text',
            openCard: true,
            stageIcons: true,
            disableEdit() {
              return true;
            },
          },
        }, {
          title: 'Material Order Stage',
          name: 'field-stage',
          prop: 'orderStage',
          searchable: true,
          filterText: 'Stages',
          width: '296px',
          id: 'orderStage',
          defaultVisible: false,
          alwaysVisible: false,
          cardProps: {
            cardType: '__t',
          },
          sortField: 'orderStage',
        }, {
          title: 'Shipping Order',
          name: 'field-generic-input',
          prop: 'shipping.name',
          width: '296px',
          id: 'shipping.name',
          sortField: 'shipping.name',
          isDisabled: false,
          eventType: 'openShipping',
          isClickable: true,
          defaultVisible: false,
          openCard: true,
          openRow: false,
          inputProps: {
            type: 'url',
            stageIcons: true,
            disableEdit() {
              return true;
            },
          },
        }, {
          title: 'Shipping Order Status',
          name: 'field-stage',
          prop: 'shippingStage',
          searchable: true,
          filterText: 'Statuses',
          id: 'shippingStatus',
          width: '196px',
          defaultVisible: false,
          alwaysVisible: false,
          isStatus: true,
          cardProps: {
            cardType: '__t',
          },
          sortField: 'shippingStage',
        }, {
          title: 'Actions',
          name: 'table-field-actions',
          id: 'action',
          hideFromChooser: true,
          alwaysVisible: true,
          sticky: true,
          thAttrs: () => ({ class: 'action-sticky' }),
          tdClass: 'action-sticky',
          width: '112px',
          searchable: true,
          showAllFilters: false,
          clearBtn: true,
          inputProps: {
            showEdit: true,
            options(obj) {
              const opts = [
                {
                  label: 'Receive Shipping Order',
                  event: 'receiveShippingOrder',
                  icon: 'fa fa-truck',
                  isDisabled(item) {
                    if (!_.isEmpty(item.shipping) && item.shippingStage === 'in-transit') {
                      return false;
                    }
                    return true;
                  },
                }, {
                  label: 'Accept Schedule Update',
                  event: 'acceptUpdate',
                  icon: 'fa-thumbs-up',
                  isDisabled(item) {
                    return _.get(item, 'deliver.isValid', true);
                  },
                }, {
                  label: 'Reject Schedule Update',
                  event: 'rejectUpdate',
                  icon: 'fa-thumbs-down',
                  isDisabled(item) {
                    return _.get(item, 'deliver.isValid', true);
                  },
                },
              ];
              if (obj.isHidden) {
                opts.push({
                  label: 'Unhide',
                  event: 'unHide',
                  icon: 'fa-eye',
                });
              } else {
                opts.push({
                  label: 'Hide',
                  event: 'hide',
                  icon: 'fa-eye-slash',
                });
              }
              opts.push(...[{
                label: 'Remove',
                event: 'archive',
                icon: 'fa fa-archive',
              }, {
                label: 'Clone',
                event: 'clone',
                icon: 'icon icon-clone',
                isDisabled(item) {
                  return !_.isEmpty(item.parent);
                },
              }, {
                label: 'History',
                event: 'activity',
                icon: 'fa fa-history',
              },
              ]);
              return opts;
            },
          },
        },
      ],
    },
  ],
};
