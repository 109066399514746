<template>
  <!-- <o-loading v-if="!isDataReady" full-page="false" :active="!isDataReady" :can-cancel="false">
  </o-loading> -->
  <div class="mf-table" ref="root">
    <div class="tab-table-gutter" v-if="!hideGutter"></div>
    <mf-toolbar
      ref="mfToolbar"
      v-if="showToolBar"
      :tools="tableProps.toolBar"
      :tableName="tableName"
      :poMaterials="poMaterials"
      :isKitOrder="isKitOrder"
      :orderStage="orderStage"
      :purpose="purpose"
      :showArchive="showArchive"
      :rowData="rowData"
      :key="reRenderToolbar"
      :isQtyUpdated="isQtyUpdated"
      @event="toolEvent($event)"
      :isPartQtyUpdated="isPartQtyUpdated"
      :selectedWorkstep="selectedWorkstep"
    >
    </mf-toolbar>
    <o-table
      clickable
      ref="oTable"
      :data="tableData"
      :customRowKey="getCustomRowKey"
      :loading="isLoading"
      :custom-detail-row="false"
      :detailed="isDetailed"
      :sticky-header="tableProps.stickeyHeaders"
      :checked-rows="selectedRows"
      :is-row-checkable="(row) => isRowCheckable(row)"
      :custom-is-checked="(selectedRow, row) => isCustomChecked(selectedRow, row)"
      :checkable="isCheckable"
      :showDetailIcon="true"
      :backendSorting="apiMode || isLocallyFiltered"
      :backendFiltering="apiMode"
      :mobile-cards="false"
      :debounce-search="200"
      :rowClass="getRowClass"
      :key="columnUpdate"
      :tableClass="getTableClass"
      :showHeader="$_.get(tableProps, 'showHeader', true)"
      :default-sort="getDefaultSort()"
      @update:checked-rows="selectedCheckBoxRows($event)"
      @sort="onSort"
      @details-close="DetailRow($event, false)"
      @details-open="DetailRow($event, true)"
      detailIcon=" icon-disclosearrow fa-rotate-270"
      :wrapperClass="filterDropdownActive ? 'is-visible' : ''"
      :draggable="activeScreen"
      @dragstart="dragstart($event)"
      @drop="drop($event)"
      @dragover="dragover($event)"
      :headerCheckable="showHeaderCheckBox()"
    >
      <o-table-column
        v-for="field in screenCols"
        :key="field.id"
        :field="field.id"
        :visible="field.visible"
        :label="field.title"
        :width="field.width"
        :sortable="Boolean(field.sortField)"
        :sticky="field.sticky"
        :headerSelectable="true"
        v-bind="field"

      >
        <template #searchable="props">
          <column-filter
            v-if="field.filterText && fetchedAllFilters"
            v-model="props.filters[props.column.field]"
            track-by="id"
            label="name"
            :filterText="field.filterText"
            :placeholder="field.filterPlaceHolder"
            :options="filterData[field.id]"
            :searchable="true"
            :allow-empty="true"
            :fieldId="field.id"
            @close="setFilter($event)"
            @update:modelValue="updateValue($event, props.column.field)"
            @isFilterDropdownActive="setFilterDropdownActive"
          ></column-filter>
          <div v-else-if="showButton(field)" class="bulk-action">
            <button
              class="button has-text-weight-bold has-background-black-bis mr-3 has-text-white"
              @click="initiateBulkAction()"
              :disabled="!selectedRows.length"
            >
              {{ selectedBulkAction.label }}
            </button>
            <button
              class="button is-outlined has-text-weight-bold"
              @click="resetBulkAction(true)"
            >
              Cancel
            </button>
          </div>
          <div v-else-if="field.clearBtn">
            <button
              class="button has-text-weight-bold filter mr-3"
              v-tooltip="{content: 'Clear Filters'}"
              @click="onFilterChange(true)"
              :disabled="isDisableClearBtn || fromDashBoard"
            >
              <i class="icon-filterremove "></i>
            </button>
            <button v-if="$_.get(field, 'hideAllFilters', true)"
              class="button  has-text-weight-bold filter"
              v-tooltip="{content: 'More Filters'}"
              @click="toggleAllFilters(true)"
              :disabled="fromDashBoard"
            >
              <i class="icon-more"></i>
            </button>
          </div>
          <component
            v-else-if="field.customSecondaryHeader"
            :is="field.name"
            :isHeader="true"
            :key="field.id"
            :row-index="props.index"
            :row-field="field"
            @cell-clicked="tableCellClicked"
          ></component>
          <input
            v-else-if="field.searchField"
            v-model="props.filters[props.column.field]"
            :placeholder="field.searchPlaceHolder"
            iconPack=" "
            icon="icon-search"
            type="search"
            size="small"
          />
          <date-range
            v-else-if="field.dateFilter"
            :title="`Filter: ${field.title}`"
            :key="refreshDate || field.dateFilter"
            @filter="applyDateFilter($_.get(field, 'dateFilter'), $event)"
            :dateValues="getFilteredDates($_.get(field, 'dateFilter'))"
          >
          </date-range>
        </template>
        <template v-if="field.customHeader" #header="props">
          <table-field-checkbox v-if="field.name === 'table-field-checkbox'"
            :isHeader="true"
            :key="field.id"
            :row-index="props.index"
            :row-field="field"
            @cell-clicked="tableCellClicked"
            :totalRows="getLength(tableData)"
            :totalRowsSelected="getLength(checkedRows)"
          >
          </table-field-checkbox>
          <!-- Below code is to set custom header for slot table components -->
          <template v-else-if="isFieldSlot(field.name) && field.customSlotHeader">
            <div :key="field.id">
              <slot
                :name="field.name"
                :isHeader="true"
                :row-data="props.row"
                :row-index="props.index"
                :row-field="field"
                @cell-clicked="tableCellClicked"
              ></slot>
            </div>
          </template>
          <component
            v-else
            :is="field.name"
            :isHeader="true"
            :key="field.id"
            :row-data="props.row"
            :row-index="props.index"
            :row-field="field"
            @cell-clicked="tableCellClicked"
          ></component>
        </template>
        <template v-if="isFieldComponent(field.name)" v-slot="props">
          <component
            :is="field.name"
            :key="field.id + (props.row.uuid || props.row._id)"
            :row-data="props.row"
            :row-index="props.index"
            :row-field="field"
            v-bind="propFormatter(field, props.row)"
            :value="$_.get(props.row, field.prop, [])"
            @update:value="(v) => updateFieldValues(props.row, field.prop, v)"
            :isEditing="$_.has(field, 'isForcedEditing')
              ? field.isForcedEditing : props.row.isEditing"
            :row-count="tableData.length"
            @cell-clicked="
              (payload) =>
                tableCellClicked({
                  ...payload,
                  data: props.row,
                })
            "
          ></component>
        </template>
        <template v-else-if="isFieldSlot(field.name)" v-slot="props">
          <div :key="field.id">
            <slot
              :name="field.name"
              :row-data="props.row"
              :row-index="props.index"
              :row-field="field"
            ></slot>
          </div>
        </template>
        <template v-else v-slot="props">
          <div v-if="field.name === `table-field-radio`" class="mf-radio">
            <input
              type="radio"
              :id="$_.get(props.row, getCustomRowKey, props.row.id)"
              @click="selectRow(props.row)"
              :checked="$_.get(selectedRow, getCustomRowKey, selectedRow.id) === $_.get(props.row, getCustomRowKey, props.row.id)"
              :value="$_.get(props.row, getCustomRowKey, props.row.id)"
            />
            <label :for="$_.get(props.row, getCustomRowKey, props.row.id)"></label>
          </div>
          <div
            v-else
            :key="field.id"
            class="text-overflow"
            :class="[field.classRight, (props.row?.isAlreadyPresent
              || props.row?.isAlreadySubAssembly) ? 'has-text-grey-light' : '']"
            v-html="renderNormalField(field, props.row)"
            @click="
              onCellClicked(props.row[field.prop], props.index, field, $event)
            "
          ></div>
        </template>
      </o-table-column>
      <template #detail="props">
        <component
          :is="tableProps.detailRowComponent"
          :key="props.id"
          :row-data="props.row"
          :row-index="props.index"
          :refreshTable="refreshTable"
          @cell-clicked="tableCellClicked"
        >
        </component>
      </template>
      <template #empty>
        <div class="table-footer" v-if="!isLoading">
          <span v-if="tableProps.noDataText">
            {{ tableProps.noDataText }}
          </span>
          <span v-else>{{ noDataMsg }}</span>
        </div>
      </template>
    </o-table>
    <column-chooser
      v-if="columnChooserModal"
      :isActive="columnChooserModal"
      @close="closeColumnChooserModal"
    >
    </column-chooser>
    <o-modal :active="allFiltersModal"  v-if="allFiltersModal" class="modal-md" :canCancel="false">
      <all-filters
        :generalFilters="filterFields"
        :dateFilters="dateFilterFields"
        :options="filterData"
        :selectedFilters="table.filters"
        @close="toggleAllFilters(false)"
        @applyAllFilters="refreshTable"
      >
      </all-filters>
    </o-modal>
    <o-modal :active="isProgressBar" v-if="isProgressBar" :canCancel="false" class="modal-xs">
      <progress-bar
	      :totalCount="progressBar.length"
	      :progressCount="progressCount"
	      :headerLabel="progressBar.header"
	      @turn-off-progress="turnOffProgress"
	      :progressLabel="progressBar.label"
	      :progressType="progressBar.type"
	      />
    </o-modal>
  </div>
</template>

<script>
import {
  resolveDynamicComponent,
  defineComponent,
  reactive,
  toRefs,
  onMounted,
  ref,
  watch,
  computed,
  nextTick,
  onBeforeUnmount,
  inject
} from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { useToast } from 'vue-toastification';
import _ from 'lodash';
import useBulkActions from '@/components/utils/BulkActions';
import MfDetailRow from '@/components/table-fields/MfDetailRow.vue';
import NotesIconField from '@/components/table-fields/NotesIconField.vue';
import ColumnFilter from '@/components/abstract/ColumnFilter.vue';
import IconField from '@/components/fields/IconField.vue';
import GlobalFilter from '@/components/GlobalFilter';
import CheckboxField from '@/components/table-fields/CheckboxField.vue';
import PermissionMixin from '@/components/mixins/PermissionMixin';
import CardEditMixin from '@/components/mixins/CardEditMixin';
import defaultStatus from '@/components/DefaultFilterOpts';
import ProgressBar from '@/components/modals/ProgressBar';
import { CardDirtyBus } from '@/utils/CardDirtyBus';
import moment from 'moment';
import MfToolbar from './MfToolbar.vue';
import ActionsField from './ActionsField.vue';
import InfiniteScrollUtil from './InfiniteScrollUtil';
import ColumnChooser from '../modals/ColumnChooser.vue';
import AllFilters from '../modals/AllFilters.vue';
import DateRange from '../DateRange.vue';

const zeroId = '000000000000000000000000';
export default defineComponent({
  name: 'mf-table',
  components: {
    ColumnFilter,
    'table-field-actions': ActionsField,
    ColumnChooser,
    MfToolbar,
    'mf-detail-row': MfDetailRow,
    'table-field-icon': IconField,
    DateRange,
    'field-notes-icon': NotesIconField,
    'table-field-checkbox': CheckboxField,
    AllFilters,
    ProgressBar,
  },
  props: {
    apiMode: {
      required: false,
      type: Boolean,
      default: true,
    },
    tableProps: Object,
    loadData: {
      required: true,
      type: Function,
    },
    checkedRows: {
      type: Array,
      default: () => [],
    },
    tableName: String,
    hideGutter: {
      type: Boolean,
      default: false,
    },
    screenTable: {
      type: Boolean,
      default: false,
    },
    selectedRow: {
      type: Object,
      default: () => {},
    },
    tableClass: {
      type: String,
      default: () => '',
    },
    refreshCard: {
      type: Function,
    },
    poMaterials: {
      type: Array,
      default: () => [],
    },
    tab: {
      type: Object,
      default: () => {},
    },
    purpose: {
      type: String,
      default: () => '',
    },
    isPmCardView: {
      type: Boolean,
      default: () => false,
    },
    orderStage: {
      type: String,
      default: () => '',
    },
    isLocallyFiltered: {
      type: Boolean,
      default: () => false,
    },
    rowData: {
      type: Object,
      default: () => {},
    },
    isAssembDet: {
      type: Boolean,
      default: false,
    },
    isPartQtyUpdated: {
      type: Boolean,
      default: () => false,
    },
    showArchive: {
      type: Boolean,
      default: () => false,
    },
    selectedOption: {
      type: String,
      default: '',
    },
    limitSelection: {
      type: Number,
      required: false,
    },
    isRowCheckable: {
      type: Function,
    },
    loadScreen: {
      type: Boolean,
      default: false,
    },
    noDefaultLoad: {
      type: Boolean,
      default: false,
    }
  },
  setup(props, { slots, emit }) {
    const emitter = inject('emitter');
    const store = useStore();
    const route = useRoute();
    const toast = useToast();
    const { getOptions, applyFilters, clearFilters } = GlobalFilter();
    const { getPermissionValues, getReqCheckForBulkActions } = PermissionMixin();
    const { archive } = CardEditMixin();

    // this function is used to get default sort fields
    const getDefaultSortField = () => {
      if (store.state.activeScreen === 'schedule') {
        return 'activityName';
      }
      return _.get(props, 'tableProps.defaultSortField', 'name');
    };

    const state = reactive({
      selectedRow: props.selectedRow || {},
      isCheckable: _.get(props, 'tableProps.isCheckable', false),
      selectedRows: [],
      tableData: [],
      total: 0,
      sort: [
        {
          sortField: getDefaultSortField(),
          direction: _.get(props, 'tableProps.defaultSortOrder', 'asc'),
        },
      ],
      isLoading: false,
      limit: 25,
      page: 1,
      showDetailIcon: true,
      showDefaultDetail: true,
      mfTableElem: {},
      filters: [],
      table: { filters: {} },
      clearFilters: {},
      filterData: {},
      selectedBulkAction: null,
      columnChooserModal: false,
      allFiltersModal: false,
      columnUpdate: 0,
      tableCols: [],
      filterDropdownActive: false,
      refreshDate: 0,
      fetchedAllFilters: false,
      reRenderToolbar: 0,
      isQtyUpdated: false,
      activeScreen: ['manager-edit-manufacturing', 'manager-edit-detailing', 'manager-edit-qa'].includes(store.state.activeScreen) && props?.tab?.text === 'WORK STEPS',
      isProgressBar: false,
      progressCount: 0,
      progressBar: {},
      selectedWorkstep: '',
    });
    const oTable = ref(null);
    // computed
    const noDataMsg = computed(() => {
      const { activeScreen } = store.state;
      if (activeScreen === 'schedule' && props.tableName === 'scheduleDetailItems') { return 'You must enter at least one item'; }
      return 'No data to display.';
    });
    const showToolBar = computed(()=>{
      return _.get(props, 'tableProps.toolBar', false)
    })
    const screenCols = computed(() => {
      if (props.screenTable || props.tableProps.cardColumnChooser) {
        return store.getters.activeScreenCols;
      }
      let cols = _.get(props.tableProps, 'fields', []);
      cols = _.map(cols, (f) => {
        f.tdAttrs = () => ({ style: `width: ${f.width}`, class: f.tdClass || '' });
        return f;
      });
      // cols.push({ width: '20px' });
      return cols;
    });
    const fromDashBoard = computed(() => route.query.fromDashBoard === 'true');

    const filterFields = computed(() => {
      const allFilterFields = _.filter(screenCols.value, (f) => !!f.filterText && f.visible);
      if (store.state.activeScreen === 'shipping-order-view') {
        allFilterFields.push({
          alwaysVisible: false,
          defaultVisible: true,
          filterText: 'Types',
          id: 'types',
          title: 'Types',
          visible: true,
          width: '296px',
        });
      }
      return allFilterFields;
    });

    const dateFilterFields = computed(() => _.filter(screenCols.value, (f) => !!f.dateFilter));

    const getCustomRowKey = computed(() => {
      if (_.isFunction(props.tableProps.customRowKey)) {
        // added selected option so that the re-organize tabe data have an way to choose
        // the customRowKey
        return props.tableProps.customRowKey(state.selectedBulkAction, props.selectedOption);
      }
      return _.get(props.tableProps, 'customRowKey', '_id');
    });

    const isCustomChecked = (selectedRow, row) => {
      const { activeScreen } = store.state;
      if (activeScreen === 'shipping-edit' && selectedRow._id !== zeroId) {
        return selectedRow._id.toString() === row._id.toString();
      }
      return selectedRow[getCustomRowKey.value].toString()
        === row[getCustomRowKey.value].toString();
    };

    const mfToolbar = ref(null);
    const showButton = (field) => {
      return (
        field.bulkActionButtonField &&
        state.selectedBulkAction &&
        !state.selectedBulkAction.hideBulkActionBtns
      ) ? true : false;
    }
    const tableCellClicked = async (params) => {
      if (params.type === 'three-dot') {
        const tableRow = _.find(state.tableData, { _id: params.data._id });
        _.set(tableRow, 'actionMenuOpen', params.active);
      } else if (params.type === 'selectAll') {
        _.forEach(state.tableData, (data) => {
          data.selected = params.value;
        });
        const rows = state.tableData.filter((val) => val.selected);
        emit('checkbox-toggled', rows);
      } else if (params.type === 'selectRow') {
        const rows = state.tableData.filter((val) => val.selected);
        emit('checkbox-toggled', rows);
      } else if (params.type === 'edit') {
        const editedRows = _.filter(state.tableData, (d) => d.isEditing);
        editedRows.forEach((row) => {
          Object.assign(row, row._beforeEdit);
          _.set(row, 'isEditing', !row.isEditing);
        });
      }
      if (params.type === 'update-schedule') {
        const scheduleData = _.find(state.tableData, (sh) => sh.order._id === params.data._id);
        if (scheduleData) {
          if (params.data.name !== scheduleData.order.name) {
            _.set(scheduleData.order, 'name', params.data.name);
          }
          if (params.data.simpleDates.orderBy.value !== scheduleData.orderBy.value) {
            _.set(scheduleData.orderBy, 'value', params.data.simpleDates.orderBy.value);
          }
        }
      }
      if (params.type === 'lmvCarousel') {
        // eslint-disable-next-line no-use-before-define
        await initFilterOpts();
      }
      emit('cell-clicked', params);
    };
    const selectRow = (rowData) => {
      state.selectedRow = rowData;
      emit('row-select', rowData);
    };
    const hasFormatter = (item) => typeof item.formatter === 'function';
    const getObjectValue = (object, path, defaultValue) => {
      defaultValue = typeof defaultValue === 'undefined' ? null : defaultValue;
      let obj = object;
      if (path.trim() !== '') {
        const keys = path.split('.');
        keys.forEach((key) => {
          if (
            obj !== null
            && typeof obj[key] !== 'undefined'
            && obj[key] !== null
          ) {
            obj = obj[key];
          } else {
            obj = defaultValue;
          }
        });
      }
      return obj;
    };
    const callFormatter = (field, item) => {
      if (!hasFormatter(field)) return null;
      if (typeof field.formatter === 'function') {
        return field.formatter(getObjectValue(item, field.name));
      }
    };
    const renderNormalField = (field, item) => (hasFormatter(field)
      ? callFormatter(field, item)
      : getObjectValue(item, field.name, ''));
    const isFieldComponent = (fieldName) => typeof resolveDynamicComponent(fieldName) !== 'string';

    const root = ref(null);

    const setHeaderHeight = () => {
      if (root.value) {
        // fixed table width
        if (props.tableProps.stickeyHeaders) {
          const oTableElem = root.value.querySelector('.o-table');
          if (oTableElem) {
            const oTablecols = _.cloneDeep(screenCols.value);
            let activeCols = _.filter(oTablecols, { visible: true });
            if (props.tableProps.isCardView) {
              activeCols = oTablecols;
            }
            const tableWidth = activeCols?.reduce((total, obj) => {
              const val = Number(obj.width.replace('px', ''));
              return Number(total) + val;
            }, 0);
            oTableElem.style = `width: ${tableWidth}px;`;
            // below code is to enable freezer shadow based on column chooser
            const tableWrapper = root.value.querySelector('.o-table__wrapper--sticky-header');
            if (activeCols.length < 8 && !tableWrapper.classList.contains('ending-sticky-shadow')) {
              tableWrapper.classList.add('ending-sticky-shadow');
            } else if (activeCols.length > 7) {
              tableWrapper.classList.remove('ending-sticky-shadow');
            }
          }
        }
        const tableElem = root.value.getElementsByClassName('o-table__wrapper--sticky-header');
        if (tableElem[0]) {
          const tableHead = tableElem[0].querySelectorAll('thead > tr');
          if (tableHead[1]) {
            tableHead[1].style = `top: ${tableHead[0].getBoundingClientRect().height}px;`;
          }
        }
      }
    };

    const closeColumnChooserModal = () => {
      // state.columnChooserModal = false;
      // column chooser modal take time to close and by that time DOM doesnt recognize
      // the mftable so added the settimeout
      setTimeout(() => {
        setHeaderHeight();
      });
    };

    const isDetailed = computed(() => {
      // hide detail row for limited view
      if (!store.state.detailedCols[store.state.activeScreen]?.hide) {
        return _.get(props, 'tableProps.isDetailed', false);
      }
      return false;
    });

    const dataManager = async (page = 1, infiniteScroll) => {
      state.isLoading = true;
      if (_.isUndefined(infiniteScroll)) setHeaderHeight();
      state.page = page;
      try {
        const params = {
          limit: state.limit,
          page: state.page,
          search: _.get(store, 'state.queryParams.searchText', ''),
          sort: state.sort,
        };
        if (props.apiMode && props.loadData) {
          const response = await props.loadData(params);
          if (page === 1) {
            state.tableData = response.data;
          } else {
            state.tableData.push(...response.data);
          }
          state.total = response.total;
          if (store.state.activeScreen === 'manufacturing' && state.selectedBulkAction?.event === 'moveOrdersForward') await bulkActions[state.selectedBulkAction.event].setMaterialsReserved(state.tableData);
          state.isLoading = false;
        } else if (props.isPmCardView) {
          setTimeout(() => {
            state.tableData = props.loadData();
            state.isLoading = false;
          }, 0);
        } else {
          state.tableData = props.loadData(state.sort);
        }
      } catch (e) {
        console.error('error', e);
        state.tableData = [];
      } finally {
        if (!props.isPmCardView || !props.isAssembDet) state.isLoading = false;
        setHeaderHeight();
      }
    };
    const onSort = async (field, order) => {
      const { sortField } = _.find(screenCols.value, { id: field });
      if (sortField) field = sortField;
      state.sort = [{ sortField: field, direction: order }];
      await dataManager();
    };

    const refreshTable = async (e) => {
      // clearing the table data to overcome reactive issue
      //  faced for 3-dot UI
      state.selectedWorkstep = e?.selectedWorkstep;
      if (store.state.activeScreen === 'kit-view-edit') {
        emit('cell-clicked', { type: 'refresh-table' });
      } else {
        state.isLoading = true;
        state.tableData = [];
        await dataManager();
        state.refreshDate++;
      }
    };

    const isFieldSlot = (fieldName) => typeof slots[fieldName] !== 'undefined';
    const bodyClass = (base, field) => [base, field.dataClass];

    const initFilterOpts = async (refreshFields = []) => {
      if (!_.isEmpty(refreshFields)) {
        state.isLoading = true;
        const updatedOptions = await getOptions(refreshFields);
        state.filterData = _.assign(state.filterData, updatedOptions);
        if (!_.isEmpty(state.filterData)) state.fetchedAllFilters = true;
        Object.entries(state.filterData).forEach(([key, value]) => {
          state.table.filters[key] = value;
        });
        state.isLoading = false;
        return;
      }
      state.isLoading = true;
      await getOptions(filterFields.value).then((data) => {
        state.fetchedAllFilters = true;
        state.filterData = data;
      });
      for (const key of Object.keys(state.filterData)) {
        state.clearFilters[key] = [];
      }
      state.clearFilters.allDates = {};
      state.isLoading = false;
    };

    const refreshFilterOpts = async () => {
      await initFilterOpts();
    };

    const onFilterChange = async (clearAll = false, params = {}) => {
      const keepVal = params.keepVal || [];
      if (clearAll) {
        state.table.filters = {};
        clearFilters(state.clearFilters, keepVal);
        state.clearFilters = {};
        await initFilterOpts();
        state.refreshDate++;
      }
      if (['assemblies', 'parts'].includes(store.state.activeScreen) && clearAll) emit('refresh-table');
      else {
        applyFilters(state.table.filters, state.filterData, clearAll);
        await refreshTable();
      }
    };

    const setFilter = async (e) => {
      // return if dropdown is closed without any change
      if (e.length === 0) return;
      await nextTick();
      await onFilterChange();
    };

    const applyDateFilter = async (dateKind, filter) => {
      if (!state.table.filters.allDates) state.table.filters.allDates = {};
      state.table.filters.allDates[`${dateKind}StartDate`] = filter.fromDate;
      state.table.filters.allDates[`${dateKind}EndDate`] = filter.toDate;
      await onFilterChange();
    };

    const getFilteredDates = (dateKind) => {
      const val = {};
      val.startDate = store.state.queryParams.allDates[`${dateKind}StartDate`];
      val.endDate = store.state.queryParams.allDates[`${dateKind}EndDate`];
      return val;
    };

    const scrollParams = computed(() => _.pick(state, [
      'total',
      'tableData',
      'page',
      'isLoading',
    ]));

    onMounted(async () => {
      if (!props.noDefaultLoad) await dataManager();
      state.selectedRows = props.checkedRows.length > 0 ? props.checkedRows : [];
      const { setRowElemHeight, handleInfiniteScroll } = InfiniteScrollUtil(
        _.assign(scrollParams.value, { root, dataManager }),
      );
      setRowElemHeight();
      if (root.value) {
        [state.mfTableElem] = root.value.getElementsByTagName('tbody');
        if (props.tableProps.stickeyHeaders) {
          [state.mfTableElem] = root.value.getElementsByClassName('o-table__wrapper');
        }
      }
      state.mfTableElem.addEventListener('scroll', (event) => {
        const {
          scrollLeft,
          scrollWidth,
          offsetWidth,
        } = event.target;
        // below code is to provide shadow when scrolling in x-axis.
        if (scrollWidth) {
          if (scrollLeft === 0) {
            event.target.classList.remove('starting-sticky-shadow');
            event.target.classList.remove('ending-sticky-shadow');
          } else {
            event.target.classList.add('starting-sticky-shadow');
            event.target.classList.remove('ending-sticky-shadow');
            // below code to hide the active column filter dropdown on horizontal scroll
            const dropdown = document.querySelector('.o-table__th .o-drop__menu--active');
            if (!_.isEmpty(dropdown)) store.dispatch('setHideFilterDropdown', true);
          }
          if ((Math.ceil(scrollLeft) + offsetWidth - 20) >= scrollWidth) {
            event.target.classList.add('ending-sticky-shadow');
          }
        }
        if (props.apiMode && !['materials', 'parts'].includes(props?.tab?.id)) handleInfiniteScroll(event, scrollParams.value);
      });
      window.addEventListener('resize', () => {
        setHeaderHeight();
      });
      await initFilterOpts();
      if (_.isEmpty(store.state.userData?.company)) {
        await store.dispatch('getUserData');
      }
    });

    // refresh table on search
    watch(
      () => store.state.queryParams.searchText,
      _.debounce(async (newVal, oldVal) => {
        const { activeScreen } = store.state;
        if (activeScreen.includes('edit')) {
          return;
        }
        if (oldVal !== newVal) {
          await refreshTable();
        }
      }, 1000, {
        leading: false,
        trailing: true,
      }),
    );
    watch(
      () => store.state.refreshFilters,
      async (newVal) => {
        if (newVal) {
          state.isLoading = true;
        }
      },
      { immediate: true },
    );

    watch(
      () => store.state.queryParams,
      // _.debounce(
      async (newVal, oldVal) => {
        if (oldVal.filteredProjects.length !== newVal.filteredProjects.length) {
          state.isLoading = true;
          // await onFilterChange(true, { keepVal: ['project'] });
        } else if (oldVal?.category.length !== newVal?.category.length
          || (oldVal?.category.length === 1 && newVal?.category.length === 1
          && oldVal?.category[0]._id !== newVal?.category[0]._id)) {
          state.isLoading = true;
          await onFilterChange(true, { keepVal: ['category'] });
        } else if (store.state.refreshFilters) {
          await onFilterChange(true);
        } else if (newVal.projectsUpdate !== oldVal.projectsUpdate) {
          const refreshField = () => {
            if (['planning', 'coordination'].includes(store.state.activeScreen)) {
              return [_.find(store.state.activeScreen === 'planning' ? props.tableProps.fields[0].planning : props.tableProps.fields[1].coordination, (f) => f.id === 'project')];
            }
            return _.filter(screenCols.value, (f) => ['project'].includes(f.id));
          };
          const cols = refreshField() || [];
          if (cols.length) {
            state.table.filters.project = null;
            await initFilterOpts(cols);
            applyFilters({ project: _.get(state.filterData, 'project', []) }, state.filterData);
            await refreshTable();
          }
        }
        if (props.tableName == "projectTable") {
          state.isLoading = false;
        }
        store.commit('setRefreshFilters', false);
      },
      //  200, {
      //   leading: true,
      //   trailing: false,
      // }),
      { deep: true },
    );

    const getRowClass = (row) => {
      if (row) {
        if (row.actionMenuOpen) return 'row-action-open';
        if (row.dropdownActive) return 'run-mf-select';
        if (row.unscheduledRowActive) return 'has-text-weight-bold';
        if (row.underDeliverRowActive) return 'has-text-weight-bold';
      }
      return '';
    };
    const selectedCheckBoxRows = (rows) => {
      state.selectedRows = rows;
      emit('checkbox-toggled', rows);
    };

    const dateMinMax = (f) => {
      const date = f.prop;
      const datesOrder = props.card.datesOrder();
      const idx = _.indexOf(datesOrder, date);
      const obj = { kind: date, ...f.inputProps };
      if (idx > 0) obj.min = datesOrder.slice(0, idx);
      if (idx < datesOrder.length - 1) obj.max = datesOrder.slice(idx + 1);
      return {
        ...f,
        inputProps: obj,
        value: _.find(props.card.dates, (d) => d.kind === date).value,
        item: props.card,
        isEdit: true,
      };
    };

    const propFormatter = (f, card) => {
      if (props.kind === 'dates') {
        return dateMinMax(f);
      }
      if (props.kind === 'constant') return f;
      const cardProps = _.mapValues(f.cardProps || {}, (v) => _.get(card, v));
      // ignoring the title prop as it gets added to component parent tag which gives
      // default tooltip
      const { title, ...fieldProps } = f;
      const inputProps = { ...f.inputProps } || {};
      return {
        ...fieldProps, ...cardProps, ...inputProps, value: _.get(card, f.value, []),
      };
    };
    const loadSpinner = (val) => {
      if (_.isEmpty(val)) {
        state.isLoading = !state.isLoading;
      } else {
        state.isLoading = val;
      }
    };

    const loadProgressBar = (params) => {
      state.progressBar.type = params.type;
      state.progressBar.length = params.length;
      state.progressBar.header = params.header;
      state.progressBar.label = params.label;
      state.isProgressBar = true;
    };
    const turnOffProgress = () => {
      setTimeout(() => {
        state.isProgressBar = false;
        state.progressCount = 0;
      }, 2000);
    };

    const resetSelectedMultiOption = (resetProject = false) => {
      if (resetProject) if (!_.isEmpty(store.state.queryParams.project)) store.commit('setQueryParams', { project: {} });
      if (!_.isEmpty(store.state.queryParams.multiMoveShipLocId)) store.commit('setQueryParams', { multiMoveShipLocId: '' });
      if (!_.isEmpty(store.state.queryParams.multiMoveProjectId)) store.commit('setQueryParams', { multiMoveProjectId: '' });
    };
    const resetBulkAction = async (refreshTableOnCancel = false) => {
      store.commit('setSelectedWorkStep', '');
      state.selectedRows = [];
      state.isCheckable = props.tableProps.isCheckable;
      const refreshOnCancel = state.selectedBulkAction?.refreshOnCancel;
      state.selectedBulkAction = null;
      const prevAction = mfToolbar?.value?.selectedAction;
      if (mfToolbar.value.selectedAction && ['moveToInventory', 'shipMulti'].includes(mfToolbar.value.selectedAction.event)) {
        emit('resetAction', true)
      }
      mfToolbar.value.clearAction();
      store.commit('setMultiCreateMatActive', false);
      store.commit('setMultiRemoveActive', false);
      store.commit('setMultiShipInventoryActive', false);
      const { selectedOldCompanyLocations } = store.state.queryParams;
      if (!_.isEmpty(selectedOldCompanyLocations)) {
        store.commit('setQueryParams', { selectedCompanyLocations: _.castArray(selectedOldCompanyLocations) });
      }
      resetSelectedMultiOption();
      if (!_.isEmpty(store.state.queryParams.multiMoveInvLoc)) store.commit('setQueryParams', { multiMoveInvLoc: '' });
      if (!_.isEmpty(store.state.queryParams.filteredProjects) && (prevAction?.event !== 'exportforms')) store.commit('setQueryParams', { filteredProjects: [] });
      if (store.state.queryParams.getOnlyRoot) {
        store.commit('setQueryParams', { getOnlyRoot: false });
      }
      if (store.state.queryParams.bulkQrPdf === true) {
        store.dispatch('qrScan/qrsBasedOnUserPref', false);
        store.commit('setQueryParams', { bulkQrPdf: false });
        state.reRenderToolbar++;
      }
      if (refreshOnCancel || refreshTableOnCancel) {
        await refreshTable();
      }
    };
    const getRefreshCard = computed(() => props.refreshCard);
    const archiveCard = async (card, cbVal) => {
      await archive('remove', card, cbVal, refreshTable);
      state.progressCount += 1;
    };
    const archiveOrders = async (selectedCards, cbVal) => {
      loadProgressBar({
        length: selectedCards.length,
        header: selectedCards.length === 1 ? 'Deleting Order' : 'Deleting Orders',
        label: 'Delete Orders',
        type: 'Delete Orders',
      });
      state.progressCount = 0;
      const chunckSelectedOrders = _.chunk(selectedCards, 50);
      for (const cards of chunckSelectedOrders) {
        const promises = [];
        for (const card of cards) {
          promises.push(archiveCard(card, cbVal));
        }
        await Promise.all(promises);
      }
      toast.success(selectedCards.length === 1 ? 'Order deleted' : 'Orders deleted');
    };
    // eslint-disable-next-line max-len
    const bulkActions = useBulkActions(refreshTable, resetBulkAction, getRefreshCard.value, loadSpinner, archiveOrders);

    const getPermissionModule = ((event) => {
      if (bulkActions[event]?.permissionModule) return bulkActions[event].permissionModule;
      if (_.isFunction(props.tableProps.permissionModule)) {
        return props.tableProps.permissionModule(store.state.activeScreen);
      }
      return props.tableProps.permissionModule;
    });

    const isRowCheckable = (row) => {
      // check if isCheckable key exists
      if (_.isFunction(props.isRowCheckable)) {
        return props.isRowCheckable(row);
      }
      const hasCheckableProp = Object.prototype.hasOwnProperty.call(row, 'isCheckable');
      if (hasCheckableProp) {
        return row.isCheckable;
      }

      const event = state.selectedBulkAction?.event;
      if (!_.isUndefined(event)) {
        const permissionModule = getPermissionModule(event);
        const params = {
          permissionModule,
          type: bulkActions[event]?.permissionType,
          requiredCheck: getReqCheckForBulkActions(permissionModule, event),
        };
        const disAbledByPermission = getPermissionValues(params);
        if (disAbledByPermission) return false;
        if (_.find(state.selectedRows, (i) => i._id === row._id)) return true;
        if (_.isFunction(bulkActions[event]?.isRowCheckable)) {
          return bulkActions[event]?.isRowCheckable(row, state.selectedRows);
        }
      }

      if (['assemblies'].includes(store.state.activeScreen)) {
        return !(row.isAlreadyPresent || row.isAlreadySubAssembly);
      }

      if (props.limitSelection) {
        let checkedRows = {};
        if (oTable.value?.newCheckedRows?.length) {
          checkedRows = _.map(oTable.value?.newCheckedRows, '_id');
        }
        if (props.limitSelection > oTable.value?.newCheckedRows?.length
          || (props.limitSelection === oTable.value?.newCheckedRows?.length
            && checkedRows.includes(row._id))) {
          return true;
        }
        return false;
      }
      return true;
    };

    const toolEvent = async (params) => {
      const projectsFiltered = store.getters.selectedIdsForKey('filteredProjects');
      if (params.nonBulkAction) {
        if (params.event === 'columnChooser') {
          state.columnChooserModal = true;
        }
      } else {
        state.selectedBulkAction = params;
        if (bulkActions[params.event]) {
          if (bulkActions[params.event].selectProject && projectsFiltered?.length) {
            if (params.event !== 'moveForward' && store.state.activeScreen !== 'kit-view-edit') bulkActions[params.event].selectProject(params.event);
          } else if (bulkActions[params.event].selectLocation) {
            bulkActions[params.event].selectLocation(params.event);
          } else if (bulkActions[params.event].openModal) {
            bulkActions[params.event].openModal(params.event, props.rowData);
          } else if (bulkActions[params.event].refresh) {
            if (params.event !== 'moveForward'
              || (params.event === 'moveForward' && store.state.activeScreen === 'manufacturing')) {
              bulkActions[params.event].refresh();
            }
          }
          if (bulkActions[params.event].selectStage) {
            bulkActions[params.event].selectStage();
          }
          if (['combine', 'massUpdateDates'].includes(params.event)
           && projectsFiltered.length === 1 && store.state.activeScreen !== 'kit-view-edit') await refreshTable();
        }
        if (['reorganize', 'exportforms'].includes(params.event)) {
          state.isCheckable = false;
        } else {
          if (params.event === 'rejectItems') {
            if (_.some(props.rowData.items, (itemObj) => _.isUndefined(itemObj._id))) {
              mfToolbar.value.clearAction();
              toast.error('Unsaved Items cant be rejected. Please save them before proceeding');
              return;
            }
          }
          state.isCheckable = true;
        }
      }
      tableCellClicked(params);
    };
    const initiateBulkAction = async () => {
      state.isLoading = true;
      try {
        if (bulkActions[state.selectedBulkAction.event]) {
          await bulkActions[state.selectedBulkAction.event].initiate(state.selectedRows, props.rowData);
        }
      } catch (e) {
        console.error(e);
      } finally {
        state.isLoading = false;
        if (['removeOrders'].includes(state.selectedBulkAction.event)) {
          state.reRenderToolbar++;
        }
      }
      emit('bulk-action-event', { event: state.selectedBulkAction, selectedRows: state.selectedRows });
    };
    const updateValue = (event, field) => {
      _.set(state.table.filters, field, event);
    };

    const isDisableClearBtn = computed(() => {
      let checkFilterFields = false;
      let checkDateFields = false;
      if (filterFields.value && filterFields.value.length) {
        checkFilterFields = !(_.some(filterFields.value, (f) => {
          const key = f.id === 'project' ? 'filteredProjects' : f.id;
          if (Object.keys(defaultStatus).includes(key)) {
            return (store.getters.selectedValuesForKey(key, false) || []).length > 0;
          }
          return (store.getters.selectedIdsForKey(key) || []).length > 0;
        }));
      }
      if (dateFilterFields.value && dateFilterFields.value.length) {
        checkDateFields = !(_.some(dateFilterFields.value, (f) => moment.isDate(store.state.queryParams.allDates[`${f.id}StartDate`])));
      }
      return (checkFilterFields && checkDateFields);
    });

    const getTableClass = computed(() => {
      if (state.isCheckable) {
        return `${props.tableClass} mf-table-checkbox ${isDetailed.value === false ? 'hide-flyout' : ''}`;
      }
      return `${props.tableClass} ${isDetailed.value === false ? 'hide-flyout' : ''}`;
    });

    const DetailRow = (e, isDetailRowEnabled) => {
      if (isDetailRowEnabled) {
        emit('opened-row', e);
      }
      if (!isDetailRowEnabled) _.remove(e.parts, (part) => part?.newRow);
      _.set(e, 'isDetailRowEnabled', isDetailRowEnabled);
    };

    const getLength = (obj) => (obj ? obj.length : 0);

    const toggleAllFilters = (val) => {
      state.allFiltersModal = val;
    };

    const setFilterDropdownActive = _.debounce((val) => {
      state.filterDropdownActive = val;
    }, 100, { leading: true, trailing: false });

    onBeforeUnmount(() => {
      // clear the selected project and location options selected for multi shipment
      if (['shipMulti', 'shipInventory'].includes(state.selectedBulkAction?.event)) {
        resetSelectedMultiOption(true);
      }
    });

    const dragstart = (e) => {
      e.event.dataTransfer.dropEffect = 'move';
      e.event.dataTransfer.setData('text/plain', e.index);
    };
    const dragover = (e) => {
      e.event.dataTransfer.dropEffect = 'move';
      e.event.preventDefault();
    };
    const drop = (e) => {
      const oldIndex = parseInt(e.event.dataTransfer.getData('text/plain'), 10);
      const { index } = e;
      if (oldIndex === index) return;
      const targetRect = e.event.target.getBoundingClientRect();
      let isBelow = e.event.y < (targetRect.y + (targetRect.height / 2)) ? 1 : 0;
      if (isBelow === 0) {
        isBelow = 1;
      } else {
        isBelow = 0;
      }
      const data = _.filter(state.tableData, (r) => !r.archived.value);
      const runs = state.tableData;
      const newIndex = _.clamp(index + isBelow, 0, data.length);
      const managerIndex = _.findIndex(runs, (i) => i.uuid === data[oldIndex].uuid);
      const oldItem = runs.splice(managerIndex, 1)[0];
      runs.splice(newIndex, 0, oldItem);
      const runsName = _.map(runs, (run) => run.uuid);
      store.commit('setreOrderRunsName', runsName);
      CardDirtyBus.emit('setCustomDirty');
    };
    const isKitOrder = computed(() => {
      let isKit = false;
      if (['manager-edit-manufacturing', 'manager-edit-qa'].includes(store.state.activeScreen) && props?.tab?.text === 'ITEMS') {
        _.forEach(state.tableData, (dt) => {
          if (['kit'].includes(dt.purpose)) isKit = true;
        });
      }
      return isKit;
    });
    const getDefaultSort = () => {
      if (props.tableProps.hideDefaultSortIcon) return [];
      return [state.sort[0].sortField, state.sort[0].direction];
    };

    const showHeaderCheckBox = () => {
      if (state.selectedBulkAction?.event === 'combine') return false;
      return true;
    };

    const checkIfAssemblyQuantity = (row, field, v) => {
      if (store.state.activeScreen === 'assemblies') {
        if (_.toNumber(v) > 0) {
          state.selectedRows.push(row);
        } else {
          _.remove(state.selectedRows, (sRow) => sRow._id === row._id);
        }
        emit('checkbox-toggled', _.uniqBy(state.selectedRows, '_id'));
      }
    };

    const updateFieldValues = (row, field, v) => {
      if (field === 'quantity') {
        checkIfAssemblyQuantity(row, field, v);
        emit('cell-clicked', { type: 'quantityUpdate' });
      }
      return _.set(row, field, v);
    };

    emitter.on('show-inv-loading', (val) => {
      if (props.tableName === 'inventory') {
        state.isLoading = val;
      }
    });

    emitter.on('refresh-inv-table', async() => {
      if (props.tableName === 'inventory') {
        await refreshTable();
      }
    });

    return {
      ...toRefs(state),
      propFormatter,
      dateMinMax,
      screenCols,
      mfToolbar,
      dataManager,
      onSort,
      isRowCheckable,
      isFieldComponent,
      isFieldSlot,
      bodyClass,
      renderNormalField,
      root,
      tableCellClicked,
      refreshTable,
      selectRow,
      getRowClass,
      onFilterChange,
      setFilter,
      selectedCheckBoxRows,
      toolEvent,
      initiateBulkAction,
      resetBulkAction,
      applyDateFilter,
      isCustomChecked,
      getCustomRowKey,
      updateValue,
      isDisableClearBtn,
      closeColumnChooserModal,
      getTableClass,
      DetailRow,
      getLength,
      isDetailed,
      toggleAllFilters,
      filterFields,
      dateFilterFields,
      noDataMsg,
      loadSpinner,
      setFilterDropdownActive,
      dragstart,
      dragover,
      drop,
      isKitOrder,
      refreshFilterOpts,
      getDefaultSort,
      showHeaderCheckBox,
      getFilteredDates,
      updateFieldValues,
      fromDashBoard,
      oTable,
      turnOffProgress,
      loadProgressBar,
      archiveOrders,
      showToolBar,
      showButton,
    };
  },
});
</script>

<style>
.o-table__td-checkbox, .o-table__th--detailed:first-child {
  width: 40px;
}
</style>
