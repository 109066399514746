<template>
  <o-loading :full-page="true" :active="isLoading" :can-cancel="false"></o-loading>
  <div class="list-view">
      <TableGutter
        :tools="tableProps.gutterOpts"
        :selectedAction="isBulkActionEnabled"
         @cc-modal-closed="triggerSetHeaderHeight"
         @re-render-table="rerenderMfTable += 1"
      >
      </TableGutter>
      <mf-table
        ref="managerTable"
        :tableProps="tableProps"
        :loadData="loadData"
        :hideGutter="true"
        :tableName="stageFromUrl"
        :screenTable="true"
        @opened-row="getDetailRowOrder($event)"
        @cell-clicked="onCellClicked"
        @resetAction="resetBulkAction"
        :key="rerenderMfTable"
      >
    <template v-slot:plannedHrs="{ rowData }">
      <span class="is-size-4 is-pulled-right">
        {{$filters.clockDisplay($_.get(rowData, 'manager.stats.plannedHrs', 0))}}
       </span>
    </template>
    <template v-slot:actualHrs="{ rowData }">
      <span class="is-size-4 is-pulled-right">
        {{$filters.clockDisplay($_.get(rowData, 'manager.stats.actualHrs', 0))}}
      </span>
    </template>
    <template v-slot:lastModified="pmData">
      {{$filters.usaDate($_.get(pmData.rowData, 'lastModified.at', ''))}}
    </template>
      <template v-slot:selectStatus="{ rowData, rowField }">
        <div v-if="rowData.isEditing && getStatusOnPermission(rowData)">
          <mf-multi-select v-if="rowData.hasRestartPermission"
            :modelValue="getStatus(rowData)"
            :options="getFilteredStatuses(rowData)"
            track-by="value"
            label="name"
            :closeOnSelect="true"
            :show-labels="false"
            :multiple="false"
            @update:modelValue="(val) => updateStatus(val, rowData, rowField.prop)"
          />
          <field-stage v-else
          :value="$_.get(rowData, rowField.prop, '')"
          :isStaticColor="$_.get(rowField, 'isStaticColor', true)"
          :isStatus="$_.get(rowField, 'isStaticColor', true)"/>
        </div>
        <field-stage v-else
          :value="$_.get(rowData, rowField.prop, '')"
          :isStaticColor="$_.get(rowField, 'isStaticColor', true)"
          :isStatus="$_.get(rowField, 'isStaticColor', true)"
        />
      </template>
      <template #lmvIcon="{rowData, rowField, isHeader, rowIndex }">
        <IconField
          :rowData="rowData"
          :rowField="rowField"
          :isHeader="isHeader"
          :rowIndex="rowIndex"
          :projects="allProjects" />
      </template>
      </mf-table>
    <o-modal
      v-if="createOrderModal"
      :active="createOrderModal"
      :canCancel="false"
      class="modal-sm"
    >
      <create-order
        @onSave="onCreate"
        @close="createOrderModal = false"
        :stage="stageFromUrl"
        :isActive="createOrderModal"
        permissionModule="production-manager"
        :requiredCheck="requiredCheck"
      >
      </create-order>
    </o-modal>
    <transition name="slide">
      <notes-icon-slider
          v-if="isSlideNotesActive"
          :isActive="isSlideNotesActive"
          :rowData="selectedItem"
          @close="closeNotesSlider"
          @update:note="updateNotes($event)"
        >
      </notes-icon-slider>
    </transition>
    <transition name="slide">
      <item-document-slider
        v-if="isFileListVisible"
        :isActive="isFileListVisible"
        :rowData="selectedItem"
        :rowField="rowField"
        @close="closeDocumentSlider"
        :card="detailRowOrder"
        >
      </item-document-slider>
    </transition>
    <activity-log
      v-if="isActive"
      :isActive="isActive"
      :id="activityDetails.id"
      :projectId="activityDetails.projectId"
      :orderName="activityDetails.orderName"
      @update:isActive="isActive=false">
    </activity-log>
    <excel-import
      :bulkExcelImport="true"
      :importOrders="importOrders"
      :batchSize="50"
      :isExcelModalActive="excelImportModal"
      :saveExcelItem="importOrders ? saveExcelItemFromListView : null"
      :allTemplateOrders="templateOrders"
      importModule="detailing-import"
      :modalHeaderTitle="importOrders ? 'import Orders' : 'import Items'"
      @refresh-table="refreshTable"
      @target-project="setTargetProject"
      ref="excel"
      :card="loadData"
      :maxLimit="500"
      @close-modal="closeModal">
    </excel-import>
  </div>
</template>

<script>
import {
  reactive,
  toRefs,
  computed,
  ref,
  inject,
  onBeforeUnmount,
  onBeforeMount,
} from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { BaseOrder } from '@/models/BaseOrder';
import _ from 'lodash';
import moment from 'moment';
import { ModalProgrammatic } from '@oruga-ui/oruga-next';
import Projects from '@/models/Projects';
import { useToast } from 'vue-toastification';
import SupplyChain from '@/models/SupplyChain';
import tableDefinition from '@/components/table-cols/managerCols';
import CreateOrder from '@/components/CreateOrder.vue';
import CardEditMixin from '@/components/mixins/CardEditMixin';
import ProductionManagerMixin from '@/components/mixins/ProductionManagerMixin';
import UtilityMixin from '@/components/mixins/UtilityMixin';
import TableGutter from '@/components/TableGutter.vue';
import MfTable from '@/components/table-fields/MfTable.vue';
import ExcelImport from '@/components/ExcelImport.vue';
import NotesIconSlider from '@/components/modals/NotesIconSlider.vue';
import ItemDocumentSlider from '@/components/modals/ItemDocumentSlider.vue';
import ProductionManager from '@/models/ProductionManager';
import defaultStatus from '@/components/DefaultFilterOpts';
import Validation from '@/utils/Validations';
import SplitCard from '../components/modals/SplitCard.vue';
import ActivityLog from '../components/ActivityLog.vue';
import { DialogProgrammatic } from '@/components/Dialog';
import IconField from '../components/fields/IconField.vue';
import ExcelMixin from '@/components/mixins/ExcelMixin';


export default {
  name: 'Manager',
  components: {
    MfTable,
    CreateOrder,
    ActivityLog,
    ExcelImport,
    TableGutter,
    'notes-icon-slider': NotesIconSlider,
    'item-document-slider': ItemDocumentSlider,
    IconField,
  },
  setup() {
    const store = useStore();
    const emitter = inject('emitter');
    const router = useRouter();
    const route = useRoute();
    const toast = useToast();
    const managerTable = ref(null);
    const excel = ref(null);
    const isBulkActionEnabled = computed(() => managerTable?.value?.selectedBulkAction);
    const {
      printQr, openLmvModel, archiveCard, openWithUserPref, getCardStatus, getRestartPermission, disableStatus,
    } = CardEditMixin();
    const { getNegativeList, conflictHtml } = UtilityMixin();
    const { createOrderInDetailing } = ProductionManagerMixin();
    const { addFiles } = ExcelMixin();

    const state = reactive({
      isLoading: false,
      isActive: false,
      isTableReady: true,
      tableProps: tableDefinition,
      datesMap: ['coord', 'poDetailBy', 'partsManufactureBy', 'poManufactureBy', 'poQaBy', 'deliver', 'detailBy'],
      createOrderModal: false,
      excelImportModal: false,
      activityDetails: {
        id: '',
        projectId: '',
        orderName: '',
      },
      allUsers: [],
      isSlideNotesActive: false,
      isFileListVisible: false,
      selectedItem: {},
      isItem: false,
      requiredCheck: [
        { module: 'prefabs', key: 'data' },
        { module: 'prefabs', key: 'move' },
        { module: 'production-order', key: 'data' },
        { module: 'production-order', key: 'move' },
      ],
      rowField: {},
      detailRowOrder: {},
      rerenderMfTable: 0,
      targetProject: null,
      importOrders: false,
      templateProject: {},
      templateOrders: {},
      isMultiShip: false,
      allProjects: [],
    });

    const closeModal = () => {
      state.excelImportModal = false;
    };
    const setTargetProject = (e) => {
      state.targetProject = e;
    };
    const stageFromUrl = computed(() => route.path.split('/').pop());
    const fetchCompleteOrders = computed(() => !_.isEmpty(store.state.queryParams.multiMoveInvLoc)
      || !!store.state.queryParams.multiMoveShipLocId
      || ['shipOrders', 'moveToKitLocation'].includes(_.get(managerTable, 'value.selectedBulkAction.event', ''))
      || (_.get(managerTable, 'value.selectedBulkAction.event', '') === 'moveForward'
      && store.state.activeScreen === 'manufacturing'));

    const loadData = async (commonParam, e) => {
      if (_.isEmpty(store.state.userData?.company)) {
        await store.dispatch('getUserData');
      }
      const exclude = { 'manager.runs': 0 };
      let params = {
        showAllCompanyOrders: !store.state.queryParams.multiMoveShipLocId
          ? store.state.queryParams.showAllCompanyOrders : false,
        location: store.state.queryParams.multiMoveShipLocId || store.getters.selectedIdsForKey('locations', false),
        status: store.getters.selectedValuesForKey('status', false),
        tag: store.getters.selectedIdsForKey('tags'),
        owner: store.getters.selectedOwners,
        completedOrders: fetchCompleteOrders.value,
        isManager: true,
        screen: 'manager',
        company: store.state.userData.company,
        purpose: store.state.queryParams.multiMoveShipLocId ? ['general', 'kit'] : [],
        ...store.getters.selectedDatesForKeys([
          'deliverStartDate',
          'deliverEndDate',
          'coordStartDate',
          'coordEndDate',
          'detailByStartDate',
          'detailByEndDate',
          'manufactureByStartDate',
          'manufactureByEndDate',
          'qaByStartDate',
          'qaByEndDate',
          'modifiedStartDate',
          'modifiedEndDate',
        ]),
        module: 'ProductionOrder',
        stage: stageFromUrl.value,
        fromPM: true,
        excludeFields: store.state.queryParams.selectedWorkstep ? '' : exclude,
        levels: store.getters.selectedIdsForKey('level'),
        zones: store.getters.selectedIdsForKey('zone'),
        ...commonParam,
      };
      if (store.state.queryParams.selectedWorkstep) {
        params.bulkrun = store.state.queryParams.bulkWorkStep;
        const run = store.state.queryParams.selectedWorkstep;
        const { selectedProject } = store.state.queryParams;
        params.workStepProjectId = selectedProject._id;
        params.run = run.name;
      }
      const projectId = store.state.queryParams.multiMoveProjectId || store.getters.selectedIdsForKey('filteredProjects');
      params = await getNegativeList(params, {
        projectId,
        owner: store.getters.selectedOwners?.user?._id,
      });
      if (state.isMultiShip) {
        params.readyToShip = true;
      }
      const data = await SupplyChain.supplyChain(params);
      data.data.forEach((dt) => {
        dt._customStage = stageFromUrl.value;
        dt._excludedFields = ['runs'];
      });
      if (managerTable.value?.selectedBulkAction?.event === 'moveToKitLocation') {
        data.data = _.filter(data.data, { purpose: 'assembly' });
      }
      if (managerTable.value?.selectedBulkAction?.event === 'shipOrders') {
        data.data = _.filter(data.data, (order) => order.purpose !== 'assembly');
      }
      if (managerTable.value?.selectedBulkAction?.event === 'combine') {
        data.data = _.filter(data.data, (order) => order.purpose !== 'kit');
      }
      //   if (this.checkedList && page === 1) this.checkedList = [];
      if (['shipMulti', 'shipOrders'].includes(managerTable.value?.selectedBulkAction?.event)) {
        data.data = _.filter(data.data, (order) => order.status !== 'pause');
      }
      return data;
    };
    const isUniqueValueIsUpdated = (card) => {
      const beforeValue = _.get(card, '_beforeEdit.uniqueOrderId.value', '');
      const afterValue = _.get(card, 'uniqueOrderId.value', '');
      return (beforeValue !== afterValue);
    };
    const saveRow = async (rowData) => {
      let order = _.cloneDeep(rowData);
      try {
        store.commit('setLoading', true);
        if (isUniqueValueIsUpdated(order)) {
          _.set(order, 'uniqueOrderId.value', Validation.getValidUniqueorderId(_.get(order, 'uniqueOrderId.value', '')));
          const uniqueOrderId = _.get(order, 'uniqueOrderId.value', '');
          if (!_.isEmpty((uniqueOrderId)) && !Validation.validateUniqueOrderId(uniqueOrderId)) {
            throw new Error(`Invalid Order ID ${uniqueOrderId}`);
          }
        }
        order = await order.save();
        _.set(rowData, 'isEditing', !rowData.isEditing);
        _.set(rowData, 'hasRestartPermission', false);
        Object.assign(rowData, order);
      } catch (e) {
        const msg = _.get(e.data, 'message', '')
          || _.get(e.data, 'msg', '')
          || _.get(e, 'message', '')
          || 'Error saving: please contact ManufactOn support';
        const conflictMsg =  conflictHtml(msg);
        // const conflictMsg =  `<h3 class="is-size-3 is-italic has-text-weight-bold mb-3 has-text-danger" >Duplicate Order ID:</h3>
        //   <h3 class="is-size-3  mb-3 has-text-danger" > ${msg.OrderId}</h3>
        //   <h6 class="is-size-6 has-text-weight-bold has-text-black-bis" >(Order ID's must be unique and cannot be duplicated within a single project.) </h6>
        //   <div class="is-divider my-3"></div>
        //   <h3 class="is-size-3 mb-3 has-text-weight-bold is-italic has-text-black-bis" >Order Name:</h3>
        //   <h3 class="is-size-3 mb-3 has-text-black-bis" >${msg.OrderName} </h3>
        //    <h3 class="is-size-3 mb-3 has-text-weight-bold is-italic has-text-black-bis" >Project: </h3>
        //    <h3 class="is-size-3 has-text-black-bis" >${msg.Project} </h3>`;
        if (msg.OrderId || msg.OrderName || msg.Project) {
          const confirmParam = {
            title: 'CONFLICT: Order ID',
            okButton: 'Close',
            type: 'black-bis',
            conflictNote: conflictMsg,
            noteType: 'danger',
          };
          DialogProgrammatic.confirm(confirmParam);
        } else {
          toast.error(msg);
        }
      } finally {
        store.commit('setLoading', false);
      }
    };

    const onCreate = (card, toOpen) => {
      state.createOrderModal = false;
      if (toOpen) {
        router.push({
          name: 'manager-edit',
          params: {
            module: 'material',
            projectId: card.project._id,
            cardId: card._id,
            stage: card.stage,
            orderJSON: JSON.stringify(card),
          },
        });
      } else {
        // eslint-disable-next-line no-unused-expressions
        managerTable.value?.refreshTable();
      }
    };
    const refreshTable = async () => {
      await managerTable.value.refreshTable();
    };

    const setLoading = (value) => {
      state.isLoading = value;
    };

    const onCellClicked = async (event) => {
      if (event.type === 'excelImport') {
        if (event.optType === 'importPrefab') {
          state.importOrders = true;
        } else {
          state.importOrders = false;
        }
        state.excelImportModal = true;
      } else if (event.type === 'save') {
        const card = await getCardStatus(event.data);
        await saveRow(card);
        card.manager.runItemsIndexMap();
      } else if (event.type === 'remove') {
        state.isLoading = true;
        await archiveCard(event.data, refreshTable, setLoading);
        state.isLoading = false;
        await managerTable.value.refreshTable();
      } else if (event.type === 'split') {
        if (event.data.items.length < 2) {
          toast.warning(
            'Since the order has less than 2 items, so we cannot split this order',
          );
        } else {
          let order = event.data;
          state.isLoading = true;
          order = await order.attachVendors();
          state.isLoading = false;
          ModalProgrammatic.open({
            component: SplitCard,
            props: {
              card: order,
              isActive: true,
            },
            custom: false,
            trapFocus: true,
            canCancel: false,
            events: {
              'refresh-table': async () => {
                await managerTable.value?.refreshTable();
              },
            },
          });
        }
      } else if (event.type === 'openCard') {
        openWithUserPref(event.data);
      } else if (event.type === 'addNew') {
        state.createOrderModal = true;
      } else if (event.type === 'activity') {
        state.isActive = true;
        state.activityDetails.id = event.data._id;
        state.activityDetails.projectId = event.data.project._id;
        state.activityDetails.orderName = event.data.name;
      } else if (event.type === 'lmv') {
        openLmvModel(event.data);
      } else if (event.type === 'printQr') {
        printQr(event.data);
      } else if (['shipMulti', 'moveToInventory'].includes(event.event) && !event.resetAction) {
        state.isMultiShip = true;
      }
    };
    // triggering the function in mfTable after closing the column chooser modal.
    const triggerSetHeaderHeight = () => {
      // eslint-disable-next-line no-unused-expressions
      managerTable?.value?.closeColumnChooserModal();
      // eslint-disable-next-line no-unused-expressions
      managerTable?.value?.refreshFilterOpts();
    };

    const updateNotes = async (event) => {
      if (event.type === 'save') {
        let order = _.cloneDeep(event.data);
        _.set(event.data, 'isEditing', !event.data.isEditing);
        order = await saveRow(order);
        Object.assign(event.data, order);
      }
    };
    const closeNotesSlider = (() => {
      state.isSlideNotesActive = false;
      state.selectedItem = {};
    });

    const closeDocumentSlider = () => {
      state.isFileListVisible = false;
    };

    const getDetailRowOrder = async (e) => {
      state.detailRowOrder = e;
      const order = await ProductionManager.get({
        orderId: e._id,
        projectId: e.project._id,
      });
      order._customStage = order.stage;
      order.isDetailRowEnabled = true;
      const pmTableData = managerTable.value?.tableData;
      const idx = _.findIndex(pmTableData, { _id: e._id });
      order.manager.runItemsIndexMap();
      Object.assign(managerTable.value.tableData[idx], order);
    };
    const updateStatus = (val, rowData, field) => {
      _.set(rowData, field, val.value);
    };
    const getOrderStatuses = computed(() => defaultStatus.orderStatus);

    const getStatusOnPermission = async (order) => {
      const checkPermission = await getRestartPermission(order);
      if (_.get(order, 'resumeOrdersByAll', false)) {
        _.set(order, 'hasRestartPermission', true);
        return true;
      }
      if (disableStatus(order.status) && !checkPermission ) {
        // when status is pause and user isAdmin 
        _.set(order, 'hasRestartPermission', !checkPermission);
      } else if (!disableStatus(order.status)) {
        // when status is other than pause
        _.set(order, 'hasRestartPermission', true);
      }
      return true;
    }
    // trigger notes slider on emit
    emitter.on('toggle:notesSlider', (payload) => {
      state.selectedItem = payload.data;
      state.isItem = payload.isItem;
      state.isSlideNotesActive = payload.isActive;
    });

    emitter.on('toggle:itemDocsSlider', (payload) => {
      state.selectedItem = payload.data;
      state.rowField = payload.rowField;
      state.isFileListVisible = payload.isActive;
    });
    onBeforeUnmount(() => {
      // removing eventBus listener
      emitter.off('toggle:notesSlider');
      emitter.off('toggle:itemDocsSlider');
    });

    const getStatus = (rowData) => {
      const status = _.find(getOrderStatuses.value, { value: rowData.status });
      return status;
    };
    const saveExcelItemFromListView = async (obj, allProjectLocations) => {
      // refector this code to multimove
      if (!state.excelImportModal || !obj) {
        managerTable.value.refreshTable();
        return;
      }
      let templateOrder = {};
      if (obj.templateId && !_.isEmpty(state.templateOrders)) {
        templateOrder = _.find(state.templateOrders.data, (temp) => {
          const tempId = obj?.templateId ? obj.templateId.toString() : obj.templateId;
          return temp.templateId === tempId;
        });
      }
      try {
        let card= new BaseOrder({
          ...obj,
          name: '',
          _customStage: 'detailing',
          stage: 'planning',
          __t: 'Prefabs',
          project: state.targetProject,
          owner: { user: null },
          location: null,
          isTrackingEnabled: false,
          defaultRun: null,
          purpose: 'general',
          owner: store.state.userData,
        });
        if (obj.uniqueOrderId) {
          card.uniqueOrderId = {
            value: obj.uniqueOrderId,
            isSystemGenerated: false
          }
        }
        _.forEach(state.datesMap, (dateKind) => {
          if (_.has(card, dateKind) && card[dateKind]) {
            if (!card._id) {
              card.addOrUpdateDate(dateKind, moment(_.get(card, dateKind)).add(0, 'days').hours(12).format());
              _.set(card.simpleDates, `${dateKind}.isDirty`, card.purpose !== 'kit');
            }
          }
        });
        const fillTemplateData = async (templateOrder, defaultTemplate = false, card) => {
          card = card.fillBasicTemplateData(templateOrder);
          const validLoc =  _.find(allProjectLocations, (loca) => loca.name === obj.location);
          if (validLoc) card.location = validLoc;
          card.files = [];
          card.simpleFiles = [];
          card.archivedFiles = [];
          card.isTrackingEnabled = true;
          card.name = obj.name;
          card.manager.notes = obj.note;
        }
        await fillTemplateData(templateOrder, false, card);
        card.stage='detailing';
        card.keepDates=true;
        addFiles(card)
        await ProductionManager.createOrderFromTemplate(card);
      } catch (e) {
        console.log('Failed to create order', e);
      }
    };

    const getFilteredStatuses = (order) => {
      const statuses = getOrderStatuses.value;
      statuses.forEach((status) => {
        if (status.id === 'pause') {
          status.$isDisabled = (order.stage === 'qa');
        }
        return status;
      });
      return statuses;
    }

    onBeforeMount(async () => {
      state.allProjects = await Projects.allProjects();
      state.templateProject = (await Projects.haveTemplateProject())?.templateProject;
      state.templateOrders = await SupplyChain.supplyChain({
        projectId: state.templateProject._id,
        module: 'ProdTemplates',
        filterNoItemOrders: false,
        purpose: 'general',
        limit: 9999,
        page: 1,
      });
    });

    const resetBulkAction = () => {
      state.isMultiShip = false;
    }
    return {
      ...toRefs(state),
      loadData,
      onCellClicked,
      managerTable,
      onCreate,
      stageFromUrl,
      refreshTable,
      isBulkActionEnabled,
      triggerSetHeaderHeight,
      fetchCompleteOrders,
      updateNotes,
      closeNotesSlider,
      closeDocumentSlider,
      getDetailRowOrder,
      updateStatus,
      getOrderStatuses,
      getStatus,
      saveExcelItemFromListView,
      setTargetProject,
      closeModal,
      excel,
      resetBulkAction,
      getStatusOnPermission,
      disableStatus,
      getFilteredStatuses,
    };
  },
};
</script>

<style scoped lang="sass"></style>
