<template>
  <div>
   <o-loading :full-page="true" :active="isLoading" :can-cancel="false"></o-loading>
    <div class="list-view">
        <TableGutter
          :tools="tableProps.gutterOpts"
          :selectedAction="isBulkActionEnabled"
          @re-render-table="refreshTable"
        >
          <template v-slot:selectStock>
            <div class="column is-3 is-offset-3">
              <div class="columns  is-flex is-align-items-center pr-0 is-pulled-right">
                <div class="column is-narrow">
                  <button class="button pointer px-0 has-text-black-bis">
                    <i class="icon-activityhistory is-size-3" @click="showActivity"></i>
                  </button>
                </div>
                <div class="is-divider-vertical mx-0" v-if="!viewAs"></div>
                <div class="column is-narrow" v-if="!viewAs">
                  <label class="label has-text-black-bis is-size-3 is-italic has-text-weight-bold">
                    Display As:
                  </label>
                </div>
                <div class="column" v-if="!viewAs">
                  <mf-multi-select
                    :modelValue="viewAs"
                    track-by="value"
                    label="label"
                    :multiple="false"
                    :options="viewOptions"
                    :allow-empty="false"
                    :closeOnSelect="true"
                    @update:modelValue="setView($event)"
                  ></mf-multi-select>
                </div>
              </div>
            </div>
          </template>
        </TableGutter>
        <mf-table
          ref="assembliesTable"
          :tableProps="tableProps"
          :loadData="loadData"
          :hideGutter="true"
          :screenTable="true"
          :showArchive="showArchive"
          @opened-row="getDetailRowOrder($event)"
          :apiMode="true"
          :checkedRows="selectedItems"
          :selectedRow="selectedRow"
          :key="rerenderMfTable"
          @cell-clicked="onCellClicked"
          @refreshTable="rerenderMfTable++"
          >
          <template
            v-slot:categorySelect="{ rowData }"
            v-if="['assemblies', 'parts'].includes($store.state.activeScreen)">
            <h1 v-if="!rowData.isEditing"> {{rowData?.category?.name}} </h1>
             <mf-multi-select
                v-model="selectedCategories"
                v-if="isRowEdit && rowData.isEditing"
                label="name"
                placeholder="Select Category"
                :searchable="false"
                :allow-empty="false"
                :options="categories?.data"
                :multiple="false"
                track-by="_id"
                @update:modelValue="fetchSubCategories()"
              ></mf-multi-select>
          </template>
          <template
            v-slot:subCategorySelect="{ rowData }"
            v-if="['assemblies', 'parts'].includes($store.state.activeScreen)">
              <h1 v-if="!rowData.isEditing"> {{rowData?.subCategory?.name}} </h1>
              <mf-multi-select
                v-model="selectedSubcategory"
                v-if="isRowEdit && rowData.isEditing"
                label="name"
                placeholder="Select Subcategory"
                :searchable="false"
                :allow-empty="false"
                :options="subCategories"
                track-by="_id"
                @update:modelValue="setSubCategory($event)"
              ></mf-multi-select>
          </template>
          <template
            v-slot:measureUnits="{ rowData }"
            v-if="['assemblies', 'parts'].includes($store.state.activeScreen)">
            <h1 v-if="!rowData.isEditing"> {{ rowData.measureUnits }} </h1>
            <mf-multi-select
              v-if="isRowEdit && rowData.isEditing"
              v-model="rowData.measureUnits"
              placeholder="Select a measured unit"
              :options="measureUnits"
              openDirection="below"
              :clear-on-select="false">
              </mf-multi-select>
          </template>
        </mf-table>
      </div>
    </div>
    <create-assembly
      v-if="createAssemblyModal"
      :isActive="createAssemblyModal"
      @close="closeAssembly"
    ></create-assembly>
    <create-parts
      v-if="createPartsModal"
      :isActive="createPartsModal"
      @close="closePartsModal"
    ></create-parts>
    <excel-import
      :card="rowData"
      :isExcelModalActive="excelModal"
      :type="type"
      :importModule="importModule"
      :uniqueFields="['catId']"
      :batchSize="50"
      :validateExcelItem="(data) => validationChecks(data, true)"
      :mapData="(data) => mapAssemblyParts(data)"
      :saveExcelItem="async (obj, fileName, isFinalBatch) => saveExcelItem(obj, fileName, isFinalBatch)"
      modalHeaderTitle="import items from excel"
      @close-modal="closeModal"
    ></excel-import>
    <activity-log
      :isActive="isActive"
      :id="$store.state.userData.company"
      @update:isActive="isActive=false">
    </activity-log>
</template>
<script>

import {
  reactive, toRefs, ref, onMounted, computed,
} from 'vue';
import CreateAssembly from '@/components/modals/CreateAssembly';
import CreateParts from '@/components/modals/CreateParts';
import partsCols from '@/components/table-cols/partsCols';
import assemblyCols from '@/components/table-cols/assemblyCols';
import MfTable from '@/components/table-fields/MfTable.vue';
import TableGutter from '@/components/TableGutter.vue';
import { DialogProgrammatic } from '@/components/Dialog';
import ActivityLog from '@/components/ActivityLog.vue';
import ExcelImport from '@/components/ExcelImport.vue';
import Catalogs from '@/models/Catalogs';
import Vendors from '@/models/Vendors';
import Validation from '@/components/utils/Validations';
import { useStore } from 'vuex';
import {
  map, get, assign, pick, isEmpty, forEach, keyBy, uniqBy,
  inRange, trim, find, concat, defaults, lowerCase, keys,
  isUndefined, filter, cloneDeep, some, flatMap, compact,
  set, remove, countBy, toString, findIndex,
} from 'lodash';
import { useToast } from 'vue-toastification';

export default {
  name: 'AssembliesParts',
  props: [],
  components: {
    'mf-table': MfTable,
    TableGutter,
    ActivityLog,
    CreateAssembly,
    CreateParts,
    ExcelImport,
  },
  setup() {
    const store = useStore();
    const toast = useToast();
    const assembliesTable = ref(null);
    const tableDefinition = store.state.activeScreen === 'assemblies' ? assemblyCols : partsCols;
    const viewOptions = [
      { label: 'Show All Items', value: 'show-archived-catalogs' },
      { label: 'Show Active Items only', value: 'show-active-catalogs' },
    ];
    const isBulkActionEnabled = computed(() => assembliesTable?.value?.selectedBulkAction);
    const state = reactive({
      views: ['Assembly', 'Parts'],
      tableProps: tableDefinition,
      selectedItems: [],
      selectedRow: {},
      isLoading: false,
      rerenderMfTable: 0,
      categoryId: [],
      subCategoryId: null,
      viewAs: viewOptions[1],
      isActive: false,
      detailRowOrder: {},
      createAssemblyModal: false,
      createPartsModal: false,
      categories: [],
      subCategories: [],
      measureUnits: [],
      selectedCategories: [],
      selectedSubcategory: [],
      isRowEdit: false,
      excelModal: false,
      type: 'items',
      importModule: 'assemblyParts-import',
      retainedAssembly: {},
      existingSuperAssemblies: [],
      existingCatIds: [],
      showArchive: false,
    });

    const getCatalogType = () => {
      const { activeScreen } = store.state;
      if (activeScreen === 'assemblies') return 'Assembly';
      return 'Parts';
    };

    const validateCostCode = (assemblyParts) => {
      if (!isEmpty(assemblyParts.costCode)) {
        forEach(assemblyParts.costCode, (costCode) => {
          if (!isEmpty(costCode.code)) {
            costCode.code = costCode.code.trim();
            costCode.code = costCode.code.replace(/ /g, '.');
          }
        });
      }
    };

    const validateDefaultValues = (assemblyPart) => {
      let err = null;
      const keyMap = {
        isDefault: 'Default Vendor',
        isPreferred: 'Preferred Vendor',
      };
      if (!isEmpty(assemblyPart.vendors)) {
        for (const vendor of assemblyPart.vendors) {
          for (const key of ['isDefault', 'isPreferred']) {
            if (['true', true, 'yes', '1'].includes(lowerCase(vendor[key]))) {
              vendor[key] = true;
            } else if (['false', false, 'no', '0', ''].includes(lowerCase(vendor[key]))) {
              vendor[key] = false;
            } else {
              err = `Bad value for ${keyMap[key]}`;
            }
          }
        }
      }
      return err;
    };

    const validationChecks = (assemblyParts, fromExcel = false) => {
      if (isEmpty(assemblyParts)) {
        return false;
      }
      let errMsg = null;
      let views;
      const selectedView = getCatalogType();
      if (selectedView === 'Parts') {
        views = ['vendor', 'labour', 'costCode'];
      } else {
        views = ['customAttributes'];
      }
      if (fromExcel) {
        // validate cost code
        validateCostCode(assemblyParts);
        // check if existing catalog id
        // validate catId
        if (!isEmpty(toString(assemblyParts.catId))) {
          assemblyParts.catId = Validation.getValidCatId(assemblyParts.catId);
        }

        const error = validateDefaultValues(assemblyParts);
        if (!isEmpty(error)) {
          errMsg = error;
        }
      }

      const { companyData } = store.state;
      if (isEmpty(assemblyParts.name) || assemblyParts.name.trim().length < 3) {
        errMsg = `${selectedView} Name should be atleast 3 characters long!`;
      } else if (!isEmpty(assemblyParts.catId) && !inRange(assemblyParts.catId.length, 4, 33)) {
        errMsg = 'CatId should be 4 to 32 character long';
      } else if (!isEmpty(assemblyParts.catId) && /^[A-Za-z0-9._-]+$/.test(assemblyParts.catId) === false) {
        errMsg = 'CatId can only contain alpha-numeric characters, periods, dashes and underscores';
      } else if (!isEmpty(assemblyParts.measureUnits)
        && !Validation.measureUnitsCheck(assemblyParts, companyData)) {
        errMsg = 'Measure units must be a part of company measure units';
      } else if (assemblyParts.category && assemblyParts.category.toString().trim().length < 3) {
        errMsg = 'Category name should be atleast 3 characters long';
      } else if (!isUndefined(assemblyParts.assemblies)) {
        // if view is Assembly
        if (selectedView === 'Assembly' && !isEmpty(assemblyParts.assemblies)) {
          for (const doc of assemblyParts.assemblies) {
            if (errMsg) continue;

            // validate catId
            if (!isEmpty(doc.catId.toString())) {
              doc.catId = Validation.getValidCatId(doc.catId);
            }

            if (state.existingSuperAssemblies.includes(doc.catId)) {
              errMsg = `Part already contains a Sub-Assembly: ${doc.catId}`;
              continue;
            }

            if (isEmpty(doc.catId) || (!isEmpty(doc.catId)
              && !Validation.validateCatalogId(doc.catId))) {
              errMsg = `part's catId is mandatory and should be 4 to 32 character long`;
              continue;
            } else if (!Validation.isValidFloat(doc, 'quantity')) {
              if (!trim(doc.quantity)) {
                doc.quantity = 1;
              } else {
                errMsg = 'part Quantity must be integer and should be in range between 0.01 and 9999';
                continue;
              }
            }
          }
        }
      } else {
        for (const view of views) {
          // add validation for parts
          if (errMsg) continue;
          if (!isUndefined(assemblyParts[view])) {
            // view is vendor and not for excel
            if (view === 'vendor' && !fromExcel && !isEmpty(assemblyParts[view])) {
              const vendorCount = filter(assemblyParts[view], (p) => p.isDefault === true);
              const preVendorCount = filter(assemblyParts[view], (p) => p.isPreferred === true);
              if (vendorCount.length > 1) {
                errMsg = 'More than One Default Vendor selected Please select one as default';
                continue;
              } if (preVendorCount.length > 3) {
                errMsg = 'More than Three Prefered Vendor selected Please select maximum 3 as Prefered';
                continue;
              }
              if (assemblyParts[view].length > 25) {
                errMsg = 'Part can not have More than 25 Vendors';
                continue;
              }
            }
          }
        }
      }
      if (selectedView === 'Assembly' && !isEmpty(assemblyParts.parts)) {
        // eslint-disable-next-line guard-for-in
        for (const idx in assemblyParts.parts) {
          const part = assemblyParts.parts[idx];
          if (Object.values(part).every((i) => !i)) {
            continue;
          }
          if (errMsg) continue;
          part.catId = Validation.getValidCatId(part.catId);
          if (isEmpty(part.catId) || (!isEmpty(part.catId)
            && !Validation.validateCatalogId(part.catId))) {
            errMsg = `part's catId is mandatory and should be 4 to 32 character long`;
            continue;
          }
          if (state.existingSuperAssemblies.includes(part.catId)) {
            errMsg = `Part already contains a Sub-Assembly: ${part.catId}`;
            continue;
          }
        }
      }
      if (!fromExcel) {
        if (errMsg) {
          toast.error(errMsg);
          return false;
        }
        return true;
      }
      return errMsg;
    };

    const mapAssemblyParts = (items) => {
      const newItems = [];
      let count = 0;
      let newItem = {};
      // eslint-disable-next-line guard-for-in
      for (let idx in items) {
        idx = parseInt(idx, 10);
        const item = items[idx];
        item.name = toString(item.name);
        // current batch with previous assembly parts
        // we use == so the for in gives index in string format
        if (idx === 0 && isEmpty(item.name)) {
          assign(newItem, pick(state.retainedAssembly, ['customId', 'name', 'desc', 'type', 'category', 'subCategory', 'catId', 'measureUnits']));
          set(newItem, 'appendToAssembly', true);
        }
        if (!isEmpty(item.name)) {
          state.retainedAssembly = item;
          if (idx !== 0) {
            newItems.push(newItem);
            newItem = {};
          }
          count = 0;
          assign(newItem, pick(state.retainedAssembly, ['customId', 'name', 'desc', 'type', 'category', 'subCategory', 'catId', 'measureUnits']));
        }
        // eslint-disable-next-line guard-for-in
        for (const a in item) {
          if (!isUndefined(a)) {
            const [arrName, key] = a.split(':');
            const isPartOrAssembly = arrName === 'parts' ? 'assemblies' : 'parts';
            if (!isUndefined(key)) {
              if (!newItem[arrName]) {
                newItem[arrName] = [{}];
              }
              if (!item[`${isPartOrAssembly}:catId`]) {
                  if (isUndefined(newItem[arrName][count])) {
                    newItem[arrName][count] = {};
                  }
                  newItem[arrName][count][key] = item[a];
              }
              continue;
            }
          }
        }
        newItem.fromExcel = true;
        _.remove(newItem.parts, (part) => _.isEmpty(part));
        _.remove(newItem.assemblies, (assembly) => _.isEmpty(assembly));
        if (items.length === (Number(idx) + 1) && !isEmpty(newItem)) {
          newItems.push(newItem);
          newItem = {};
        }
        count++;
      }
      return newItems;
    };

    const categoryValidation = async (catalogs) => {
      /* This fn is to check Imported categories and subCategories present in company categories.
        If Present
          It will set category and subcategory to imported part
        else
          setting it to empty so that it will set with default category and subcategory
          */
      let categories = flatMap(map(catalogs, 'category'));
      categories = compact(categories);
      categories = categories.map((c) => (c ? c.toString().toUpperCase().trim() : null));
      const indexedCategories = (await Vendors.getCategories({
        category: categories,
      })).data;
      const indexedSubCategories = await Vendors.getSubCategories({
        categoryId: map(indexedCategories, '_id'),
      });
      let cat;
      let subcat;
      for (const assemblyPart of catalogs) {
        if (!isUndefined(assemblyPart.category)) {
          cat = find(indexedCategories, (usr) => usr.name.toUpperCase() === assemblyPart.category.toUpperCase());
          if (cat) {
            assemblyPart.category = cat;
            if (!isUndefined(assemblyPart.subCategory)) {
              subcat = find(
                // eslint-disable-next-line no-loop-func
                filter(indexedSubCategories, (subcategory) => subcategory.parent._id === cat._id),
                (usr) => usr.name.toUpperCase() === assemblyPart.subCategory.toUpperCase(),
              );
            }
            if (isEmpty(subcat)) {
              subcat = find(
                indexedSubCategories,
                // eslint-disable-next-line no-loop-func
                (sc) => sc.parent._id === cat._id && sc.isDefault,
              );
            }
            assemblyPart.subCategory = subcat;
          } else assemblyPart.category = '';
        } else {
          assemblyPart.category = '';
          assemblyPart.subCategory = '';
        }
      }
      return catalogs;
    };

    const vendorValidation = async (catalogs) => {
      /* This fn is to check Imported vendors present in company vendors.
        Also applicable when importing multiple vendors.
      */
      let dbVendors = [];
      const allVendors = compact(flatMap(catalogs, 'vendors'));
      const vendorNames = allVendors.map((v) => (v.name ? v.name.toUpperCase().trim() : null));
      const result = await Vendors.getVendors({
        name: compact(vendorNames),
      });
      if (!isEmpty(result) && result.data) dbVendors = result.data;
      const indexedVendors = keyBy(dbVendors, (v) => v.name.toUpperCase().trim());
      for (const part of catalogs) {
        part.vendor = [];
        if (!isEmpty(part.vendors)) {
          for (const vendor of part.vendors) {
            if (isEmpty(vendor.name) || vendor.name.length < 3) continue;
            const vendorName = vendor.name.toUpperCase().trim();
            if (keys(indexedVendors).includes(vendorName)) {
              part.vendor.push({
                ...vendor,
                isImported: true,
                _id: indexedVendors[vendorName]._id,
              });
            }
          }
          part.vendor = uniqBy(part.vendor, '_id');
        }
      }
      return catalogs;
    };

    const fetchSubCategories = async () => {
      if (!isEmpty(state.selectedCategories)) {
        const categoryId = get(state.selectedCategories, '_id', '');
        if (categoryId !== '') {
          state.subCategories = await Vendors.getSubCategories({ categoryId });
          return;
        }
      }
      state.subCategories = [];
    };

    const setSubCategory = (subCat) => {
      state.selectedSubcategory = subCat;
    };

    const refreshData = () => {
      state.rerenderMfTable++;
      // eslint-disable-next-line no-unused-expressions
      assembliesTable?.value.refreshTable();
    };

    const getPartsData = async (rowData, limit = 100) => {
      const partsData = [];
      const assembliesData = [];
      const vendorsData = [];
      const params = {
        limit,
        type: 'assemblyAndItems',
        catId: [],
        showAssmbsParts: true,
      };
      let ids = map(rowData.parts, 'catId');
      ids = concat(ids, map(rowData?.assemblies, 'catId'));
      params.catId = ids;
      const result = await Catalogs.getAssemblyParts(params);
      if (result.data && result.data.length > 0) {
        for (const d of result.data) {
          if (rowData.parts) {
            for (const part of rowData.parts) {
              if (part.catId === d.catId) {
                assign(part, pick(d, ['customId', 'name', 'catId', 'desc', 'type', 'category', 'subCategory', '__t', 'measureUnits']));
                partsData.push(part);
              }
            }
          }
          if (rowData.assemblies) {
            for (const assembly of rowData.assemblies) {
              if (assembly.catId === d.catId) {
                assign(assembly, pick(d, ['customId', 'name', 'catId', 'desc', 'type', 'category', 'subCategory', '__t']));
                assembliesData.push(assembly);
              }
            }
          }
        }
      }

      return { partsData, assembliesData, vendorsData };
    };

    const getDetailRowOrder = async (rowData) => {
      const data = await getPartsData(rowData);
      rowData.parts = data.partsData;
      rowData.assemblies = data.assembliesData;
      state.detailRowOrder = rowData;
    };

    const updateRow = async (assemblyParts) => {
      if (assemblyParts.__t === 'AssemblyItems') {
        for (const part of assemblyParts.parts) {
          if (!parseFloat(part.quantity)) part.quantity = 1;
        }
      }
      state.isLoading = true;
      try {
        // validate cost code
        validateCostCode(assemblyParts);

        if (!isEmpty(assemblyParts.catId)) {
          assemblyParts.catId = Validation.getValidCatId(assemblyParts.catId);
        }

        if (validationChecks(assemblyParts)) {
          assemblyParts = await Catalogs.updateAssemblyParts(assemblyParts);
          if (!isEmpty(assemblyParts.failedData)) {
            const errMessage = get(assemblyParts.failedData[0], '_errorMsg', 'Please Contact ManufactOn Support');
            toast.error(errMessage);
          }
          if (!isEmpty(assemblyParts.data)) {
            let toastMsg = 'Updated.';
            if (store.state.activeScreen === 'assemblies') {
              toastMsg = 'Updated Assembly.';
            } else if (store.state.activeScreen === 'parts') {
              toastMsg = 'Updated parts list.';
            }
            toast.success(toastMsg);
            const newDataIndex = findIndex(assembliesTable.value.tableData, { _id: assemblyParts.data._id });
            // below code is to update the object in-order to stop the table refresh
            // if any better solution please do change.
            set(assemblyParts.data, 'isEditing', false);
            Object.assign(assembliesTable.value.tableData[newDataIndex], assemblyParts.data);
            if (store.state.activeScreen === 'assemblies') {
              await getDetailRowOrder(assembliesTable.value.tableData[newDataIndex]);
            }
            // end of table data update without refresh
            // state.rerenderMfTable++;
          }
        }
      } catch (err) {
        console.log('err', err);
        toast.error(`${err.data.message || err.data.msg || 'There was some error creating assembly'}`);
      }
      state.isLoading = false;
    };

    const loadData = async (params) => {
      defaults(params, {
        categoryId: map(store.state.queryParams.category, '_id'),
        subCategoryId: map(store.state.queryParams.subCategory, '_id'),
        search: store.state.queryParams.searchText,
        type: getCatalogType(),
        limit: 25,
        page: 1,
        sort: [],
        showItems: state.viewAs.value,
        showArchive: state.showArchive,
      });
      const data = await Catalogs.getAssemblyParts(params);
      return data;
    };

    const refreshTable = (params) => {
      state.categoryId = map(params.categories, '_id');
      state.subCategoryId = get(params.subCategories, '_id', null);
      state.rerenderMfTable++;
    };

    const onCellClicked = async (params) => {
      if (params.type === 'catalogArchive') {
        let isPartOf = false;
        if (params.data.__t === 'AssemblyItems') {
          isPartOf = params?.data?.partOfAssemblies > 0 || params?.data?.partOf > 0;
        } else isPartOf = params?.data?.partOf > 0;
        const confirmParam = {
          title: 'ARCHIVE CATALOG ITEM',
          message: `Archive "${params?.data?.name}?"`,
          okButton: 'Archive',
          cancelButton: 'Cancel',
          type: isPartOf ? 'danger' : '',
          note: '</br>NOTE: Archived item can be viewed and recovered by enabling "Show Archived."',
          beforeMessage: isPartOf ? `"${params?.data?.name}" is included in (${params?.data?.partOf}) other assemblies.
            Archiving will remove it from those assemblies.` : '',
          onConfirm: async () => {
            state.isLoading = true;
            const type = getCatalogType() === 'Parts' ? 'items' : 'assemblies';
            try {
              await Catalogs.archive({
                catIds: [params?.data?.catId],
                isTemporaryDelete: true,
              }, type);
              toast.success('Archive Successful');
              state.rerenderMfTable++;
            } catch (error) {
              toast.error(`Error while archiving - ${error.data}`);
            } finally {
              state.isLoading = false;
            }
          },
        };
        DialogProgrammatic.confirm(confirmParam);
      } else if (params.type === 'catalogDelete') {
        const confirmParam = {
          title: 'DELETE CATALOG ITEM',
          message: `Delete "${params?.data?.name}\n?"`,
          okButton: 'Delete',
          cancelButton: 'Cancel',
          type: 'danger',
          note: 'NOTE: Item will be permanently removed from the Manufacton Catalog and cannot be recovered.',
          noteType: 'danger',
          onConfirm: async () => {
            state.isLoading = true;
            const type = getCatalogType() === 'Parts' ? 'items' : 'assemblies';
            const archivedCatalogIds = [];
            archivedCatalogIds.push(params?.data?.catId);
            try {
              await Catalogs.archive({
                catIds: archivedCatalogIds,
              }, type);
              toast.success('Delete Successful');
              state.rerenderMfTable++;
            } catch (error) {
              toast.error(`Error while archiving - ${error.data}`);
            } finally {
              state.isLoading = false;
            }
          },
        };
        DialogProgrammatic.confirm(confirmParam);
      } else if (params.type === 'catalogUnArchive') {
        const type = getCatalogType() === 'Parts' ? 'items' : 'assemblies';
        const confirmParam = {
          title: 'RESTORE CATALOG ITEM',
          message: `Restore "${params?.data?.name}?"`,
          okButton: 'Restore',
          cancelButton: 'Cancel',
          cancelBtnIsOutlined: true,
          type: 'success',
          confirmButtonStyle: 'isSolid',
          onConfirm: async () => {
            state.isLoading = true;
            try {
              await Catalogs.unarchive({
                catId: params?.data?.catId,
              }, 'catalogueitems');
              toast.success('Restore successful');
              state.rerenderMfTable++;
            } catch (error) {
              toast.error('Error while restoring item');
            } finally {
              state.isLoading = false;
            }
          },
        };
        DialogProgrammatic.confirm(confirmParam);
      } else if (params.type === 'addNew') {
        state.createAssemblyModal = true;
      } else if (params.type === 'excelImport') {
        state.type = getCatalogType() === 'Parts' ? 'items' : 'assemblies';
        // state.existingCatIds = map(get(await Catalogs.getAllCatIds(), 'data', []), 'catId');
        state.excelModal = true;
        state.existingSuperAssemblies = map(get(await Catalogs.getSuperAssemblies(), 'data', []), 'catId');
      } else if (params.type === 'addParts') {
        state.createPartsModal = true;
      } else if (params.type === 'edit') {
        state.selectedCategories = params.data.category;
        state.selectedSubcategory = params.data.subCategory;
        fetchSubCategories();
        state.isRowEdit = true;
      } else if (params.type === 'cancel') {
        state.isRowEdit = false;
      } else if (params.type === 'save') {
        // check if vendor is non empty before saving...
        const rowToSave = params.data;
        if (rowToSave.vendor?.length) {
          // total pref vendors should not be more than 3
          let totalPrefVendor = 0;
          for (const vendor of rowToSave.vendor) {
            totalPrefVendor += (vendor.isPreferred) ? 1 : 0;
            if (totalPrefVendor > 3) {
              toast.error('More than Three Preferred Vendor selected. Please select maximum 3 as Preferred');
              return false;
            }
          }

          const newAddedVendors = rowToSave.vendor.filter((vRow) => vRow.newRow);
          newAddedVendors.forEach((ven) => {
            ven.vendorName = ven.name;
          });
          // check if any of the vendor has empty name
          if (some(newAddedVendors, (vendor) => isEmpty(vendor.name))) {
            toast.error('Vendor Name can not be empty');
            return false;
          }
        }
        params.data.category = state.selectedCategories;
        params.data.subCategory = state.selectedSubcategory;
        const keyedVendors = countBy(params.data.vendor, 'name');
        let duplicateVendor;
        forEach(keyedVendors, (value, key) => { if (value >= 2) duplicateVendor = key; });
        if (!isUndefined(duplicateVendor)) {
          toast.error(`${duplicateVendor} Vendor is already present in part`);
          remove(params.data.vendor, (vRow) => vRow.newRow && vRow.name === duplicateVendor);
        }
        await updateRow(params.data);
      } else if (params.type === 'showArchive') {
        state.showArchive = params.optType;
        refreshData();
      }
    };

    const saveExcelItem = async (obj) => {
      let result;
      obj.forEach((assemblyPart) => {
        if (!isEmpty(assemblyPart.catId)) {
          assemblyPart.catId = Validation.getValidCatId(assemblyPart.catId);
        }
        validateCostCode(assemblyPart);
        validateDefaultValues(assemblyPart);
        const selectedView = getCatalogType();
        if (selectedView === 'Parts') {
          const measUnits = cloneDeep(assemblyPart.measureUnits) || '';
          if (!assemblyPart.measureUnits
            || !some(state.measureUnits, (unit) => unit === measUnits.toUpperCase())) {
            assemblyPart.measureUnits = '';
          } else {
            assemblyPart.measureUnits = measUnits.toUpperCase();
          }
        }
      });
      obj = await categoryValidation(obj);
      const type = getCatalogType() === 'Parts' ? 'parts' : 'assembly';
      if (type === 'parts') {
        obj = await vendorValidation(obj);
      }
      if (obj.length > 0) {
        const assembliesToBeAppended = await remove(obj,
          (assemblyPart) => assemblyPart?.appendToAssembly);
        if (type === 'parts') {
          result = await Catalogs.addAssemblyParts(obj, 'items');
        } else if (type === 'assembly') {
          result = await Catalogs.addAssemblyParts(obj, 'assemblies');
          for (const assembly of assembliesToBeAppended) {
            const appendedResult = await Catalogs.updateAssemblyParts(assembly);
            result.data.push({ data: appendedResult.data });
          }
        }
      }
      return result;
    };

    const setView = (event) => {
      state.viewAs = event;
      state.rerenderMfTable++;
    };

    const showActivity = () => {
      state.isActive = true;
    };

    const closeModal = async () => {
      state.excelModal = false;
      refreshData();
    };

    const closeAssembly = async (closeParams = { cancel: false }) => {
      state.createAssemblyModal = false;
      if (!closeParams?.cancel) refreshData();
    };
    const closePartsModal = async (closeParams = { cancel: false }) => {
      state.createPartsModal = false;
      if (!closeParams?.cancel) refreshData();
    };

    onMounted(async () => {
      state.categories = await Vendors.getCategories({ limit: 500 });
      const { companyData } = store.state;
      state.measureUnits = map(companyData.measureUnits, (unit) => unit.name);
    });

    return {
      loadData,
      getCatalogType,
      getDetailRowOrder,
      ...toRefs(state),
      refreshTable,
      refreshData,
      onCellClicked,
      viewOptions,
      setView,
      showActivity,
      fetchSubCategories,
      setSubCategory,
      closeAssembly,
      closePartsModal,
      closeModal,
      isBulkActionEnabled,
      validationChecks,
      mapAssemblyParts,
      saveExcelItem,
      assembliesTable,
    };
  },
};

</script>
