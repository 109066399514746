import MfDetailTab from '@/components/table-fields/MfDetailRowTab.vue';
import colsMap from '@/components/table-cols';
import OrderCheckList from '@/views/OrderChecklist.vue';
import materialItemsCols from '@/components/excel-cols/materialItemCols';
import prefabItemsCols from '@/components/excel-cols/prefabItemsCols';
import poItemsExcelCols from '@/components/excel-cols/poItemsExcelCols';
import MaterialTab from '@/components/table-fields/MaterialTabForPo.vue';
import ItemLocations from '@/components/table-fields/ItemLocations.vue';
import MfDetailManagerTab from '@/components/table-fields/MfDetailManagerRowTab.vue';
import {
  some, isEmpty, isUndefined, get, set, every, find, findIndex, cloneDeep, map, assign, pick, has,
} from 'lodash';
import translator from '@/languages/translator';
import materialExcelCols from '@/components/excel-cols/materialExcelCols';
import imExcelCols from '@/components/excel-cols/imExcelCols';
import shippingOrderViewCols from './components/table-cols/shippingOrderViewCols';
import shippingItemViewCols from './components/table-cols/shippingItemViewCols';
import formsListCols from './components/table-cols/formsListCols';
import StageChangeViewCols from './components/table-cols/stageChangeViewCols';
import OwnerChangeViewCols from './components/table-cols/ownerChangeViewCols';
import DateChangeViewCols from './components/table-cols/dateChangeViewCols';
import assemblyCols from './components/table-cols/assemblyCols';
import partsCols from './components/table-cols/partsCols';
import validateLmv from './components/utils/lmv3dotValidation';
import Catalogs from './models/Catalogs';

const { t } = translator;
function baseCardEditHeader() {
  return {
    activity: true,
    notifyUsers: true,
    subscribe: true,
    qrPrint: true,
  };
}

const disableLvlZone = (rowData) => {
  if (['not-started', 'in-transit', 'complete', 'delivery', 'released-to-inventory',
    'fulfilled', 'in-storage', 'mixed-shipping'].includes(rowData?.stage)) {
    return true;
  }
  return false;
};

const getManagerItemFields = (screen) => {
  const fields = [
    {
      name: 'field-generic-input',
      width: '400px',
      title: 'Items',
      prop: 'name',
      id: 'name',
      sticky: true,
      searchable: true,
      cardProps: {
        type: 'text',
        isEditing: 'isEditing',
      },
      isDisabled(item) {
        return (item.order._customStage === 'qa' || !isEmpty(item.catId));
      },
      sortField: 'name',
    },
    {
      name: 'field-generic-input',
      width: '156px',
      title: 'Item ID',
      prop: 'customId',
      id: 'customId',
      cardProps: {
        type: 'text',
      },
      isDisabled(item) {
        return item.order._customStage === 'qa' || (['not-started', 'in-transit', 'complete', 'delivery', 'released-to-inventory',
          'fulfilled', 'in-storage', 'mixed-shipping'].includes(item?.stage));
      },
      sortField: 'customId',
    }, {
      name: 'field-run-select',
      width: '64px',
      title: 'Work Steps',
      id: 'add-run-in-items',
      dataClass: 'overflow-visible',
    }, {
      name: 'item-check',
      width: '64px',
      title: 'Done',
      prop: 'name',
      id: 'name',
      inputProps: {
        type: 'text',
        id: 'itemName',
        disableEdit: true,
        textArea: true,
      },
    }, {
      name: 'item-qty',
      width: '112px',
      title: 'QTY',
      prop: 'autoHoursonQty',
      id: 'qty',
      isDisabled(item) {
        if (item.purpose === 'kit') return true;
        return item.stage === 'qa';
      },
      sortField: 'quantity',
    }, {
      name: 'field-generic-input',
      width: '112px',
      title: 'Cost Code',
      prop: 'costCode',
      id: 'costCode',
      inputProps: {
        type: 'text',
        disableEdit: false,
      },
      sortField: 'costCode',
      isDisabled(item) {
        return (['not-started', 'in-transit', 'complete', 'delivery', 'released-to-inventory',
          'fulfilled', 'in-storage', 'mixed-shipping'].includes(item?.stage));
      },
    },
    {
      name: 'field-qty-input',
      width: '112px',
      title: 'Measure',
      id: 'measure',
      prop: 'measure',
      sortField: 'measure',
      min: 0.01,
      roundTo: 2,
    },
    {
      name: 'field-measure-unit-select',
      width: '152px',
      title: 'Measure Units',
      id: 'measureUnits',
      prop: 'measureUnits',
      cardProps: {
        fromMasterCatalog: 'fromMasterCatalog',
        isEditing: 'isEditing',
        measure: 'measure',
      },
      sortField: 'measureUnits',
    },
    {
      name: 'field-install-locs-icon',
      title: 'Install Location',
      width: '64px',
      id: 'installLocation',
    },
    {
      name: 'field-lvl-zone',
      prop: 'level',
      width: '296px',
      title: 'Level',
      id: 'level',
      inputProps: {
        options: 'levels',
        isDisabled: disableLvlZone,
        isEditing: true,
      },
      sortField: 'level',
    }, {
      name: 'field-lvl-zone',
      prop: 'zone',
      width: '296px',
      title: 'Zone',
      id: 'zone',
      dataClass: 'overflow-visible',
      inputProps: {
        options: 'zones',
        isDisabled: disableLvlZone,
        isEditing: true,
      },
      sortField: 'zone',
    },
    {
      name: 'field-item-docs-icon',
      width: '64px',
      title: 'Docs',
      id: 'simpleFiles',
      allowAdd: true,
      showForm: true,
      for: 'Item',
      allowDelete(file, order) {
        if (some(file.sources, { stage: 'planning' })) {
          return order._customStage === 'planning';
        }
        return true;
      },
    },
    {
      name: 'field-notes-icon',
      title: 'Notes',
      prop: 'simpleMemos[0].text',
      defaultVisible: true,
      width: '64px',
      id: 'notes',
      inputProps: {
        isItem: true,
      },
    },
    {
      title: 'Actions',
      name: 'field-card-edit-action',
      permissionModule: 'production-manager',
      id: 'action',
      thAttrs: () => ({ class: 'action-sticky' }),
      tdClass: 'action-sticky',
      sticky: true,
      width: '112px',
      isDisabled(item, count) {
        if (['detailing', 'manufacturing', 'qa'].includes(item.stage) && count === 1) return true;
        return item.stage !== 'detailing' && ['kit', 'assembly'].includes(item.purpose);
      },
    },
  ];
  if (['manager-edit-detailing', 'detailing'].includes(screen)) {
    const indx = findIndex(fields, { name: 'item-check' });
    fields.splice(indx, 1);
  }
  return fields;
};

function extraTableParams() {
  return {
    defaultSortField: 'name',
    defaultSortOrder: 'asc',
    isDetailed: false,
    rearrangeCols: false,
  };
}

function simpleTab() {
  return {
    showComapnyChooser: true,
    customSubHeader: false,
    showColumns: true,
    showSearch: true,
    showFilter: true,
    showActivity: true,
    showListView: false,
    showProject: false,
    showExport: false,
    showImport: false,
    showAdjustInv: false,
    showAdd: false,
    showMultiSort: true,
    showShActivity: false,
    tabs: [],
    cols: [],
    activeTab: '',
    module: '',
    showDelete: false,
    showRegularView: false,
    queryParamsProjectField: '',
  };
}

function orderChecklistTabProps(permissionModule) {
  const toolBar = { ...colsMap.OrderChecklistCols.toolBar };
  toolBar.permissionModule = permissionModule;
  toolBar.buttons = [];
  if (colsMap.OrderChecklistCols.toolBar.buttons.length > 0) {
    for (const button of colsMap.OrderChecklistCols.toolBar.buttons) {
      toolBar.buttons.push({
        ...button,
        acl: {
          permissionModule,
          type: 'edit',
        },
      });
    }
  }
  let props = { ...colsMap.OrderChecklistCols };
  props = set(props, 'toolBar', toolBar);
  return props;
}
function orderChecklistTab(permissionModule) {
  return {
    text: 'TASKS',
    component: OrderCheckList,
    tableProps: orderChecklistTabProps(permissionModule),
    id: 'tasks',
    accessor: 'items',
  };
}

function materialTab() {
  return {
    ...simpleTab(),
    tabs: [
      {
        title: 'INCOMING ORDERS & REQUESTS',
        path: 'preparation',
        icon: 'fa fa-suitcase',
      },
      {
        title: 'SOURCING',
        path: 'sourcing',
        icon: 'fas fa-eye-dropper',
      },
      {
        title: 'PURCHASE ORDERS',
        path: 'ordering',
        icon: 'fa fa-phone fa-flip-horizontal',
      },
    ],
    activeTab: 'SOURCING',
  };
}

function prefabCardItemDeatils() {
  const tableOpts = {
    stickeyHeaders: true,
    isCardView: true,
    toolBar: {
      hideSearchBar: true, // by default let the search bar be available for the table
      buttons: [
        {
          event: 'addNew',
          icon: 'icon-addnew',
          isLine: true,
          tooltip: 'Add New',
          acl: {
            permissionModule: 'prefabs',
            type: 'edit',
          },
        },
        {
          event: 'addFromCatalog',
          icon: 'icon-addcatalog',
          isLine: true,
          tooltip: 'Add From Catalog',
          acl: {
            permissionModule: 'prefabs',
            type: 'edit',
          },
        },
        {
          event: 'excelImport',
          icon: 'icon-importexcel',
          isLine: true,
          tooltip: 'Import From Excel',
          acl: {
            permissionModule: 'prefabs',
            type: 'edit',
          },
        },
      ],
    },
  };
  return {
    'item-info': {
      ...extraTableParams(),
      ...tableOpts,
      fields: [
        {
          name: 'field-generic-input',
          width: '400px',
          title: 'Items',
          prop: 'name',
          id: 'name',
          sticky: true,
          searchable: true,
          sortField: 'name',
        }, {
          name: 'field-generic-input',
          width: '112px',
          title: 'Item ID',
          id: 'customId',
          prop: 'customId',
          isDisabled:(rowData) =>{
            if(rowData?.catId?.length){
              return true;
            }
            return false;
          },
          sortField: 'customId',
        },
        {
          name: 'catId',
          width: '152px',
          title: 'Catalog ID',
          id: 'catId',
          prop: 'catId',
          allowAutoComplete: true,
          verifyCatId: true,
          inputProps: {
            type: 'text',
            disableEdit: true,
          },
          sortField: 'catId',
        },
        {
          name: 'field-qty-input',
          width: '112px',
          title: 'QTY',
          id: 'quantity',
          prop: 'quantity',
          sortField: 'quantity',
          isDisabled: (rowData) => rowData.order?.__t === 'Prefabs' && rowData.stage !== 'planning',
        },
        {
          name: 'field-qty-input',
          width: '112px',
          title: 'Measure',
          id: 'measure',
          prop: 'measure',
          sortField: 'measure',
          min: 0.01,
          roundTo: 2,
          isDisabled: (rowData) => rowData.order?.__t === 'Prefabs' && rowData.stage !== 'planning',
        },
        {
          name: 'field-measure-unit-select',
          width: '152px',
          title: 'Measure Units',
          id: 'measureUnits',
          prop: 'measureUnits',
          cardProps: {
            fromMasterCatalog: 'fromMasterCatalog',
            measure: 'measure',
          },
          isDisabled: (rowData) => rowData.order?.__t === 'Prefabs' && rowData.stage !== 'planning',
          inputProps: {
            isEditing: (rowData) => rowData.order?.__t === 'Prefabs' && rowData.stage === 'planning',
          },
          sortField: 'measureUnits',
        },
        {
          name: 'field-stage',
          width: '152px',
          title: 'Stage',
          id: 'stage',
          prop: 'stage',
          inputProps: {
            stageColor: true,
          },
          sortField: 'stage',
        },
        {
          name: 'field-lvl-zone',
          width: '296px',
          title: 'Level',
          id: 'level',
          prop: 'level',
          inputProps: {
            options: 'levels',
            isDisabled: false,
            isEditing: true,
          },
          sortField: 'level',
        },
        {
          name: 'field-lvl-zone',
          width: '296px',
          title: 'Zone',
          id: 'zone',
          prop: 'zone',
          inputProps: {
            options: 'zones',
            isDisabled: false,
            isEditing: true,
          },
          sortField: 'zone',
        },
        {
          name: 'field-item-docs-icon',
          width: '64px',
          title: 'Docs',
          id: 'simpleFiles',
          for: 'Item',
        },
        {
          name: 'field-notes-icon',
          title: 'Notes',
          prop: 'simpleMemos[0].text',
          defaultVisible: true,
          width: '64px',
          id: 'notes',
          inputProps: {
            isItem: true,
          },
        },
        {
          title: '',
          name: 'field-card-edit-action',
          alwaysVisible: true,
          id: 'action',
          width: '112px',
          sticky: true,
          thAttrs: () => ({ class: 'action-sticky' }),
          tdClass: 'action-sticky',
        },
      ],
    },
    management: {
      ...extraTableParams(),
      ...tableOpts,
      fields: [
        {
          name: 'field-generic-input',
          width: '400px',
          sticky: true,
          title: 'Items',
          prop: 'name',
          id: 'name',
          searchable: true,
          sortField: 'name',
        },
        {
          name: 'field-generic-input',
          width: '152px',
          title: 'Item ID',
          id: 'customId',
          prop: 'customId',
          sortField: 'customId',
        },
        {
          name: 'field-generic-input',
          width: '152px',
          title: 'Cost Code',
          prop: 'costCode',
          id: 'costCode',
          sortField: 'costCode',
          inputProps: {
            type: 'text',
            disableEdit: true,
          },
        },
        {
          name: 'field-date',
          width: '152px',
          title: 'Coordinate',
          prop: 'coord',
          id: 'simpleDates.coord.value',
          inputProps: {
            kind: 'coord',
            max: 'deliver',
            isEdit: true,
            disableEdit: (rowData, params) => get(rowData, 'order.__t', '') === 'Prefabs'
                && get(rowData, 'order.purpose', '') === 'kit'
                && get(rowData, '_place', '') === 'item',
          },
          sortField: 'simpleDates.coord.value',
        },
        {
          name: 'field-date',
          width: '152px',
          title: 'Onsite',
          prop: 'date',
          id: 'simpleDates.deliver.value',
          inputProps: {
            type: 'mf-date',
            kind: 'deliver',
            min: 'coord',
            isEdit: true,
            disableEdit: (rowData, params) => get(rowData, 'order.__t', '') === 'Prefabs'
                && get(rowData, 'order.purpose', '') === 'kit'
                && get(rowData, '_place', '') === 'item',
          },
          sortField: 'simpleDates.deliver.value',
        },
        {
          name: 'field-install-locs-icon',
          title: 'Install Location',
          width: '64px',
          id: 'installLocation',
          sortField: 'installLocation',
        },
        {
          title: '',
          name: 'field-card-edit-action',
          alwaysVisible: true,
          id: 'action',
          width: '112px',
          sticky: true,
          thAttrs: () => ({ class: 'action-sticky' }),
          tdClass: 'action-sticky',
        },
      ],
    },
  };
}

function productionCardItemDetails() {
  const materialTabOpts = {
    stickeyHeaders: true,
    toolBar: {
      hideSearchBar: true, // by default let the search bar be available for the table
      buttons: [
        {
          event: 'addFromCatalog',
          icon: 'icon-addcatalog',
          tooltip: 'Add From Catalog',
          acl: {
            permissionModule: 'material-manager',
            type: 'edit',
          },
        },
        {
          event: 'linkMaterialRequest',
          icon: 'icon-linkexistingrequest',
          tooltip: 'Link Existing Material Request',
          acl: {
            permissionModule: 'material-manager',
            type: 'edit',
          },
        },
        {
          event: 'addFromInventory',
          icon: 'icon-addinventory',
          tooltip: 'Add From Inventory',
          acl: {
            permissionModule: 'material-manager',
            type: 'edit',
          },
        },
      ],
    },
  };
  const tableOpts = {
    toolBar: {
      stickeyHeaders: true,
      hideSearchBar: true, // by default let the search bar be available for the table
      buttons: [
        {
          event: 'addNew',
          icon: 'icon-addnew',
          isLine: true,
          tooltip: 'Add New',
          acl: {
            permissionModule: 'production-order',
            type: 'edit',
          },
          isDisabled: (rowData, props) => ['kit', 'assembly'].includes(props.purpose),
        },
        {
          event: 'addFromCatalog',
          icon: 'icon-addcatalog',
          isLine: true,
          tooltip: 'Add From Catalog',
          acl: {
            permissionModule: 'production-order',
            type: 'edit',
          },
          isDisabled: (rowData, props) => ['kit', 'assembly'].includes(props.purpose),
        },
        {
          event: 'excelImport',
          icon: 'icon-importexcel',
          isLine: true,
          tooltip: 'Import From Excel',
          acl: {
            permissionModule: 'production-order',
            type: 'edit',
          },
          isDisabled: (rowData, props) => ['kit', 'assembly'].includes(props.purpose),
        },
      ],
    },
  };
  return {
    'item-info': {
      ...extraTableParams(),
      ...tableOpts,
      isCardView: true,
      stickeyHeaders: true,
      fields: [{
        name: 'field-generic-input',
        width: '400px',
        sticky: true,
        title: 'Items',
        prop: 'name',
        id: 'name',
        searchable: true,
        sortField: 'name',
      },
      {
        name: 'field-generic-input',
        width: '152px',
        title: 'Item ID',
        id: 'customId',
        prop: 'customId',
        sortField: 'customId',
      },
      {
        name: 'catId',
        width: '152px',
        title: 'Catalog ID',
        id: 'catId',
        prop: 'catId',
        allowAutoComplete: true,
        verifyCatId: true,
        inputProps: {
          type: 'text',
          disableEdit: true,
        },
        sortField: 'catId',
      },
      {
        name: 'field-qty-input',
        width: '112px',
        title: 'QTY',
        id: 'quantity',
        prop: 'quantity',
        sortField: 'quantity',
      },
      {
        name: 'field-qty-input',
        width: '112px',
        title: 'Measure',
        id: 'measure',
        prop: 'measure',
        sortField: 'measure',
        min: 0.01,
        roundTo: 2,
      },
      {
        name: 'field-measure-unit-select',
        width: '152px',
        title: 'Measure Units',
        id: 'measureUnits',
        prop: 'measureUnits',
        cardProps: {
          fromMasterCatalog: 'fromMasterCatalog',
          isEditing: 'isEditing',
          measure: 'measure',
        },
        inputProps: {
          isEditing: () => true,
        },
        sortField: 'measureUnits',
      },
      {
        name: 'field-generic-input',
        width: '112px',
        title: 'Cost Code',
        id: 'costCode',
        prop: 'costCode',
        sortField: 'costCode',
        inputProps: {
          type: 'text',
          disableEdit: false,
        },
      },
      {
        name: 'field-lvl-zone',
        width: '296px',
        title: 'Level',
        id: 'level',
        prop: 'level',
        inputProps: {
          options: 'levels',
          isDisabled: false,
          isEditing: true,
        },
        sortField: 'level',
      },
      {
        name: 'field-lvl-zone',
        width: '296px',
        title: 'Zone',
        id: 'zone',
        prop: 'zone',
        inputProps: {
          options: 'zones',
          isDisabled: false,
          isEditing: true,
        },
        sortField: 'zone',
      },
      {
        name: 'field-install-locs-icon',
        title: 'Install Location',
        width: '64px',
        id: 'installLocation',
      },
      {
        name: 'field-item-docs-icon',
        width: '64px',
        title: 'Docs',
        id: 'simpleFiles',
        for: 'Item',
      },
      {
        name: 'field-notes-icon',
        title: 'Notes',
        prop: 'simpleMemos[0].text',
        defaultVisible: true,
        width: '64px',
        id: 'notes',
        inputProps: {
          isItem: true,
        },
      },
      {
        title: 'Actions',
        name: 'field-card-edit-action',
        id: 'action',
        thAttrs: () => ({ class: 'action-sticky' }),
        tdClass: 'action-sticky',
        sticky: true,
        width: '112px',
        isDisabled(item, count) {
          if (['coordination'].includes(item.stage) && count === 1) return true;
          return false;
        },
      },
      ],
    },
    notes: {
      ...extraTableParams(),
      ...tableOpts,
      fields: [
        {
          name: 'field-generic-input',
          width: '50%',
          title: 'Items',
          prop: 'name',
          id: 'name',
          searchable: true,
        },
        {
          name: 'field-generic-input',
          width: '10%',
          title: 'Item ID',
          id: 'customId',
          prop: 'customId',
        },
        {
          name: 'field-cat-id',
          width: '10%',
          title: 'Catalog ID',
          id: 'catId',
          prop: 'catId',
          allowAutoComplete: true,
          verifyCatId: true,
          inputProps: {
            type: 'text',
            disableEdit: false,
          },
        },
        {
          name: 'field-qty-input',
          width: '90px',
          title: 'QTY',
          id: 'quantity',
          prop: 'quantity',
        },
        {
          name: 'field-qty-input',
          width: '90px',
          title: 'Measure',
          id: 'measure',
          prop: 'measure',
          min: 0.01,
          roundTo: 2,
        },
        {
          name: 'field-measure-unit-select',
          width: '10%',
          title: 'Measure Units',
          id: 'measureUnits',
          prop: 'measureUnits',
          cardProps: {
            fromMasterCatalog: 'fromMasterCatalog',
            isEditing: 'isEditing',
            measure: 'measure',
          },
        },
        {
          name: 'field-generic-input',
          width: '10%',
          title: 'Cost Code',
          id: 'costCode',
          prop: 'costCode',
        },
        {
          name: 'field-lvl-zone',
          width: '5%',
          title: 'Level',
          id: 'level',
          prop: 'level',
          inputProps: {
            options: 'levels',
            isDisabled: false,
          },
        },
        {
          name: 'field-lvl-zone',
          width: '5%',
          title: 'Zone',
          id: 'zone',
          prop: 'zone',
          inputProps: {
            options: 'zones',
            isDisabled: false,
          },
        },
        {
          name: 'field-install-locs-icon',
          title: 'Install Location',
          width: '10%',
          id: 'installLocation',
          sortField: 'installLocation',
        },
        {
          name: 'field-item-docs-icon',
          width: '10%',
          title: 'Docs',
          id: 'simpleFiles',
          for: 'Item',
        },
        {
          name: 'table-field-notes',
          title: 'Notes',
          width: '10%',
          prop: 'simpleMemos[0].text',
          id: 'notes',
          inputProps: {
            isItem: true,
          },
        },
        {
          title: '',
          name: 'field-card-edit-action',
          alwaysVisible: true,
          id: 'action',
          width: '10%',
        },
      ],
    },
    'materials-tab': {
      ...extraTableParams(),
      ...materialTabOpts,
      isCardView: true,
      stickeyHeaders: true,
      fields: [
        {
          name: 'field-generic-input',
          width: '400px',
          title: 'Name',
          prop: 'name',
          id: 'name',
          sticky: true,
          searchable: true,
          // sortField: 'name',
        }, {
          name: 'field-generic-input',
          width: '152px',
          title: 'Item ID',
          prop: 'customId',
          id: 'customId',
          sortField: 'customId',
        }, {
          name: 'catId',
          width: '152px',
          title: 'Catalog ID',
          id: 'catId',
          prop: 'catId',
          allowAutoComplete: true,
          verifyCatId: true,
          sortField: 'catId',
        }, {
          title: 'Measure',
          name: 'field-generic-input',
          id: 'measure',
          prop: 'measure',
          width: '112px',
          sortField: 'measure',
        }, {
          title: 'Measure Units',
          name: 'field-measure-unit-select',
          id: 'measureUnits',
          prop: 'measureUnits',
          width: '152px',
          sortField: 'measureUnits',
        }, {
          name: 'qty-consumed',
          width: '112px',
          title: 'Qty Consumed',
          id: 'qtyConsumed',
          prop: 'qtyConsumed',
          sortField: 'qtyConsumed',
        }, {
          name: 'qty-ship',
          id: 'qtyToShip',
          title: 'Qty To Ship',
          width: '112px',
          sortField: 'qtyToShip',
        }, {
          name: 'field-generic-input',
          prop: 'quantity',
          sortField: 'quantity',
          width: '112px',
          title: 'Qty Needed',
          id: 'quantity',
        }, {
          name: '',
          sortField: 'available',
          width: '112px',
          title: 'Qty In Inventory',
          id: 'available',
          classRight: 'is-pulled-right',
          inputProps: {
            disableEdit: true,
          },
          formatter(item) {
            if (!isEmpty(item.available) || item.available) return item.available;
            return 0;
          },
        }, {
          title: '',
          name: 'field-card-edit-action',
          alwaysVisible: true,
          id: 'action',
          width: '112px',
          sticky: true,
          noButtons: true,
          thAttrs: () => ({ class: 'action-sticky' }),
          tdClass: 'action-sticky',
          isDisabled(rowData) {
            if (rowData.stage !== 'preparation' || rowData.combinedQuantity) {
              return true;
            }
            return false;
          },
        },
      ],
    },
  };
}
function materialCardItemDetails() {
  const tableOpts = {
    stickeyHeaders: true,
    isCardView: true,
    toolBar: {
      // name: for drop down label, label: for action button label
      hideSearchBar: true, // by default let the search bar be available for the table
      buttons: [
        {
          event: 'addNew',
          icon: 'icon-addnew',
          isLine: true,
          tooltip: 'Add New',
          isDisabled: ({ stage }) => !stage || stage === 'complete',
          acl: {
            permissionModule: 'material-manager',
            type: 'edit',
          },
        },
        {
          event: 'addFromCatalog',
          icon: 'icon-addcatalog',
          isLine: true,
          tooltip: 'Add From Catalog',
          isDisabled: ({ stage }) => !stage || stage === 'complete',
          acl: {
            permissionModule: 'material-manager',
            type: 'edit',
          },
        },
        {
          event: 'excelImport',
          icon: 'icon-importexcel',
          isLine: true,
          tooltip: 'Import From Excel',
          isDisabled: ({ stage }) => !stage || stage === 'rejected' || stage === 'complete',
          acl: {
            permissionModule: 'material-manager',
            type: 'edit',
          },
        },
      ],
    },
  };
  return {
    'item-info': {
      ...extraTableParams(),
      ...tableOpts,
      fields: [
        {
          name: 'field-generic-input',
          width: '400px',
          title: 'Items',
          prop: 'name',
          id: 'name',
          searchable: true,
          sticky: true,
          cardProps: {
            isEditing: false,
          },
          sortField: 'name',
        },
        {
          name: 'field-generic-input',
          width: '152px',
          title: 'Item ID',
          id: 'customId',
          prop: 'customId',
          cardProps: {
            isEditing: false,
          },
          sortField: 'customId',
        },
        {
          name: 'table-field-icon',
          width: '64px',
          title: '',
          id: 'lmvIcon',
          customHeader: true,
          inputProps: {
            icon: 'icon-linked-model',
          },
        },
        {
          name: 'catId',
          width: '112px',
          title: 'Catalog ID',
          id: 'catId',
          prop: 'catId',
          allowAutoComplete: true,
          verifyCatId: true,
          inputProps: {
            type: 'text',
            disableEdit: true,
          },
          sortField: 'catId',
        },
        {
          name: 'field-qty-input',
          width: '112px',
          title: 'QTY',
          id: 'quantity',
          prop: 'quantity',
          isDisabled: (rowData, routeParams) => ['delivery', 'complete'].includes(get(routeParams, 'stage', '')),
          sortField: 'quantity',
          roundTo: 2,
        },
        {
          name: 'table-field-icon',
          width: '64px',
          title: '',
          id: 'qtyCalculator',
          customHeader: true,
          inputProps: {
            event: 'QtyCalculator',
            icon: 'icon-inventory',
          },
        },
        {
          name: 'field-generic-input',
          width: '64px',
          title: 'Purchase',
          id: 'purchase',
          prop: 'purchase',
          has: true,
          type: 'checkbox',
          isDisabled: (rowData, routeParams) => !['preparation', 'rejected'].includes(get(rowData, 'stage', '')),
        },
        {
          name: 'field-stage',
          id: 'stage',
          prop: 'stage',
          title: 'Stage',
          width: '152px',
          sortField: 'stage',
          inputProps: {
            isPurchased: (item) => item?.purchase,
          },
        },
        {
          name: 'field-install-locs-icon',
          title: 'Install Location',
          width: '64px',
          id: 'installLocation',
          sortField: 'installLocation',
        },
        {
          name: 'field-item-docs-icon',
          width: '64px',
          title: 'Docs',
          id: 'simpleFiles',
          for: 'Item',
          cardProps: {
            isEditing: false,
          },
        },
        {
          name: 'field-notes-icon',
          title: 'Notes',
          prop: 'simpleMemos[0].text',
          defaultVisible: true,
          width: '64px',
          id: 'notes',
          inputProps: {
            isItem: true,
          },
        },
        {
          title: '',
          name: 'field-card-edit-action',
          alwaysVisible: true,
          id: 'action',
          width: '112px',
          sticky: true,
          thAttrs: () => ({ class: 'action-sticky' }),
          tdClass: 'action-sticky',
        },
      ],
    },
    'item-details': {
      ...extraTableParams(),
      ...tableOpts,
      fields: [
        {
          name: 'field-generic-input',
          width: '400px',
          title: 'Items',
          prop: 'name',
          id: 'name',
          sticky: true,
          searchable: true,
          cardProps: {
            isEditing: false,
          },
          // sortField: 'name',
        },
        {
          name: 'field-generic-input',
          width: '152px',
          title: 'Item ID',
          id: 'customId',
          prop: 'customId',
          cardProps: {
            isEditing: false,
          },
          sortField: 'customId',
        },
        {
          name: 'field-generic-input',
          prop: 'category.name',
          width: '112px',
          title: 'Category',
          id: 'category',
          sortField: 'category',
        },
        {
          name: 'table-field-icon',
          prop: 'defVendor.name',
          width: '296px',
          title: 'Default Vendor',
          id: 'defVendor',
          customHeader: true,
          inputProps: {
            event: 'defVendor',
            icon: 'icon-default',
          },
          sortField: 'defVendor',
        },
        {
          name: 'table-field-icon',
          width: '64px',
          title: '',
          id: 'vendors',
          customHeader: true,
          inputProps: {
            event: 'vendors',
            icon: 'icon-preferred',
          },
          sortField: 'vendors',
        },
        {
          name: 'field-qty-input',
          width: '112px',
          title: 'QTY',
          id: 'quantity',
          prop: 'quantity',
          isDisabled: (rowData, routeParams) => ['delivery', 'complete'].includes(get(routeParams, 'stage', '')),
          sortField: 'quantity',
        },
        {
          name: 'table-field-icon',
          width: '64px',
          title: '',
          id: 'qtyCalculator',
          customHeader: true,
          inputProps: {
            event: 'QtyCalculator',
            icon: 'icon-inventory',
          },
        },
        {
          name: 'field-qty-input',
          width: '112px',
          title: 'Measure',
          id: 'measure',
          prop: 'measure',
          min: 0.01,
          roundTo: 2,
          isDisabled: (rowData, routeParams) => ['delivery', 'complete'].includes(get(routeParams, 'stage', '')),
          sortField: 'measure',
        },
        {
          name: 'field-measure-unit-select',
          width: '152px',
          title: 'Measure units',
          id: 'measureUnits',
          prop: 'measureUnits',
          cardProps: {
            fromMasterCatalog: 'fromMasterCatalog',
            isEditing: 'isEditing',
            measure: 'measure',
          },
          inputProps: {
            isEditing: (item) => !['delivery', 'complete'].includes(get(item, 'order._customStage', '')),
          },
          sortField: 'measureUnits',
        }, {
          name: 'field-generic-input',
          width: '112px',
          title: 'Cost Code',
          prop: 'costCode',
          id: 'costCode',
          isDisabled: (rowData, routeParams) => ['delivery', 'complete'].includes(get(routeParams, 'stage', '')),
          cardProps: {
            isEditing: 'isEditing',
          },
          sortField: 'costCode',
        },
        // sortField: 'costCode',
        {
          title: '',
          name: 'field-card-edit-action',
          alwaysVisible: true,
          id: 'action',
          width: '112px',
          sticky: true,
          thAttrs: () => ({ class: 'action-sticky' }),
          tdClass: 'action-sticky',
        },
      ],
    },
  };
}

function sourcingCardItemDetails() {
  const tableOpts = {
    stickeyHeaders: true,
    toolBar: {
      // name: for drop down label, label: for action button label
      bulkActions: (customStage, orderStage, routeParams = {}, routePath = '', rowData) => {
        if (['delivery'].includes(orderStage)) return [];
        if (has(rowData, 'productionOrder') && !isEmpty(rowData.productionOrder)) return [];
        const opts = [{
          name: 'Reject Items',
          event: 'rejectItems',
          label: 'Reject Items',
        }];
        return opts;
      },
      hideSearchBar: true, // by default let the search bar be available for the table
      buttons: [
        {
          event: 'addNew',
          icon: 'icon-addnew',
          isLine: true,
          tooltip: 'Add New',
          isDisabled: (routeParams) => ['delivery', 'complete'].includes(get(routeParams, 'stage', '')),
          acl: {
            permissionModule: 'material-manager',
            type: 'edit',
          },
        },
        {
          event: 'addFromCatalog',
          icon: 'icon-addcatalog',
          isLine: true,
          tooltip: 'Add From Catalog',
          acl: {
            permissionModule: 'material-manager',
            type: 'edit',
          },
          isDisabled: (routeParams) => ['delivery', 'complete'].includes(get(routeParams, 'stage', '')),
        },
        {
          event: 'excelImport',
          icon: 'icon-importexcel',
          tooltip: 'Import From Excel',
          acl: {
            permissionModule: 'material-manager',
            type: 'edit',
          },
          isDisabled: (routeParams) => ['delivery', 'complete'].includes(get(routeParams, 'stage', '')),
        },
        {
          event: 'excel-export',
          icon: 'icon-exportexcel',
          isLine: true,
          tooltip: 'Export to Excel',
          acl: {
            permissionModule: 'material-manager',
            type: 'edit',
          },
          isDisabled: (routeParams) => ['complete'].includes(get(routeParams, 'stage', '')),
        },
      ],
      showButtons: ({ stage, cardId }, button) => {
        if (button.event !== 'excel-export' && stage === 'ordering' && cardId !== 'add') return true;
        if (button.event === 'addNew' && (['sourcing', 'ordering', 'delivery', 'complete'].includes(stage))) return true;
        return false;
      },
    },
  };
  return {
    'item-info': {
      ...extraTableParams(),
      ...tableOpts,
      isCardView: true,
      fields: [
        {
          name: 'field-generic-input',
          width: '400px',
          title: 'Items',
          prop: 'name',
          id: 'name',
          sticky: true,
          bulkActionButtonField: true,
          searchable: true,
          cardProps: {
            isEditing: false,
          },
          sortField: 'name',
        },
        {
          name: 'field-generic-input',
          width: '152px',
          title: 'Item ID',
          id: 'customId',
          prop: 'customId',
          cardProps: {
            isEditing: false,
          },
          sortField: 'customId',
        },
        {
          name: 'table-field-icon',
          width: '64px',
          title: '',
          id: 'lmvIcon',
          customHeader: true,
          inputProps: {
            icon: 'icon-linked-model',
          },
        },
        {
          name: 'catId',
          width: '152px',
          title: 'Catalog ID',
          id: 'catId',
          prop: 'catId',
          allowAutoComplete: true,
          verifyCatId: true,
          inputProps: {
            type: 'text',
            disableEdit: true,
          },
          sortField: 'catId',
        },
        {
          name: 'field-qty-input',
          width: '112px',
          title: 'QTY',
          id: 'quantity',
          prop: 'quantity',
          isDisabled(item, route) {
            let result = false;
            if (item.__t !== 'Materials' && item._customStage === 'ordering') {
              result = true;
            } else if (['ordering'].includes(item.order._customStage) && route.cardId !== 'add') return true;
            else if (['delivery', 'complete'].includes(get(item, 'order._customStage', ''))) return true;
            return result;
          },
          sortField: 'quantity',
          roundTo: 2,
        },
        {
          name: 'table-field-icon',
          width: '64px',
          title: '',
          id: 'qtyCalculator',
          customHeader: true,
          inputProps: {
            event: 'QtyCalculator',
            icon: 'icon-inventory',
          },
        },
        {
          name: 'field-generic-input',
          width: '64px',
          title: 'Purchase',
          id: 'purchase',
          prop: 'purchase',
          has: true,
          type: 'checkbox',
          isDisabled: (rowData, routeParams) => ['delivery', 'ordering', 'sourcing'].includes(get(routeParams, 'stage', '')),
        },
        {
          name: 'mmItemUnitCost',
          prop: 'unitCost',
          sortField: 'unitCost',
          width: '112px',
          title: 'Item Cost',
          id: 'unitCost',
          isDisabled(item) {
            let result = false;
            if (item.__t !== 'Materials' && item._customStage === 'ordering') {
              result = true;
            } else if (item.__t === 'Materials' && ['sourcing', 'ordering'].includes(item._customStage)) return true;
            else if (item.stage === 'in-transit') return true;
            else if (['delivery', 'complete', 'ordering'].includes(get(item, 'order._customStage', ''))) return true;
            return result;
          },
        },
        {
          name: 'field-generic-input',
          prop: 'totalCost',
          sortField: 'totalCost',
          width: '112px',
          title: 'Total Cost',
          id: 'totalCost',
          cardProps: {
            isEditing: false,
          },
        },
        {
          name: 'field-stage',
          id: 'stage',
          prop: 'stage',
          title: 'Stage',
          width: '152px',
          sortField: 'stage',
          isStatus: true,
          inputProps: {
            isPurchased: (item) => item?.purchase,
          },
        },
        {
          name: 'field-install-locs-icon',
          title: 'Install Location',
          width: '64px',
          id: 'installLocation',
          sortField: 'installLocation',
          isDisabled(item) {
            let result = false;
            if (item.__t !== 'Materials' && item._customStage === 'ordering') {
              result = true;
            } else if (item.__t === 'Materials' && ['sourcing', 'ordering'].includes(item._customStage)) return true;
            return result;
          },
        },
        {
          name: 'field-item-docs-icon',
          width: '64px',
          title: 'Docs',
          id: 'simpleFiles',
          for: 'Item',
          cardProps: {
            isEditing: false,
          },
        },
        {
          name: 'field-notes-icon',
          title: 'Notes',
          prop: 'simpleMemos[0].text',
          defaultVisible: true,
          width: '64px',
          id: 'notes',
          inputProps: {
            isItem: true,
          },
        },
        {
          title: '',
          name: 'field-card-edit-action',
          alwaysVisible: true,
          id: 'action',
          width: '112px',
          sticky: true,
          showDelete: (route) => route.stage === 'ordering' && route.cardId === 'add',
          thAttrs: () => ({ class: 'action-sticky' }),
          tdClass: 'action-sticky',
        },
      ],
    },
    'item-details': {
      ...extraTableParams(),
      stickeyHeaders: true,
      hideSearchBar: true,
      toolBar: pick(tableOpts.toolBar, ['hideSearchBar', 'buttons', 'showButtons']),
      isCardView: true,
      fields: [
        {
          name: 'field-generic-input',
          width: '400px',
          title: 'Items',
          prop: 'name',
          id: 'name',
          searchable: true,
          cardProps: {
            isEditing: false,
          },
          sticky: true,
          sortField: 'name',
        },
        {
          name: 'customId',
          width: '152px',
          title: 'Item ID',
          id: 'customId',
        },
        {
          name: 'field-generic-input',
          prop: 'category.name',
          width: '112px',
          title: 'Category',
          id: 'category',
          sortField: 'category',
        },
        {
          name: 'table-field-icon',
          prop: 'defVendor.name',
          width: '296px',
          title: 'Default Vendor',
          id: 'defVendor',
          customHeader: true,
          inputProps: {
            event: 'defVendor',
            icon: 'icon-default',
          },
          sortField: 'defVendor',
        },
        {
          name: 'table-field-icon',
          width: '64px',
          title: '',
          id: 'vendors',
          customHeader: true,
          inputProps: {
            event: 'vendors',
            icon: 'icon-preferred',
          },
          sortField: 'vendors',
        },
        {
          name: 'field-qty-input',
          width: '112px',
          title: 'QTY',
          id: 'quantity',
          prop: 'quantity',
          isDisabled(item) {
            let result = false;
            if (item?._customStage === 'ordering' || ['in-transit'].includes(item?.stage)) result = true;
            else if (['delivery', 'complete', 'ordering'].includes(get(item, 'order._customStage', ''))) return true;
            return result;
          },
          sortField: 'quantity',
        },
        {
          name: 'table-field-icon',
          width: '64px',
          title: '',
          id: 'qtyCalculator',
          customHeader: true,
          inputProps: {
            event: 'QtyCalculator',
            icon: 'icon-inventory',
          },
        },
        {
          name: 'field-qty-input',
          width: '112px',
          title: 'Measure',
          id: 'measure',
          prop: 'measure',
          min: 0.01,
          roundTo: 2,
          isDisabled(item) {
            if (item?.order?._customStage === 'ordering' || ['in-transit'].includes(item?.stage)) {
              if (!item._id && item.measureUnits) return false;
              return true;
            }
            if (['delivery', 'complete', 'ordering'].includes(get(item, 'order._customStage', ''))) return true;
            return false;
          },
          sortField: 'measure',
        },
        {
          name: 'field-measure-unit-select',
          width: '152px',
          title: 'Measure units',
          id: 'measureUnits',
          prop: 'measureUnits',
          inputProps: {
            isEditing: (item) => !['delivery', 'complete'].includes(get(item, 'order.stage', '')),
          },
          sortField: 'measureUnits',
        },
        {
          name: 'field-generic-input',
          width: '112px',
          title: 'Cost Code',
          prop: 'costCode',
          id: 'costCode',
          sortField: 'costCode',
          inputProps: {
            disableEdit: (item) => !['sourcing', 'preparation'].includes(get(item, 'stage', '')),
          },
        },
        {
          title: '',
          name: 'field-card-edit-action',
          alwaysVisible: true,
          id: 'action',
          width: '112px',
          showDelete: false,
          sticky: true,
          thAttrs: () => ({ class: 'action-sticky' }),
          tdClass: 'action-sticky',
        },
      ],
    },
  };
}

function runsCols() {
  return {
    'manager-edit-detailing': [
      {
        name: 'table-field-icon',
        width: '40px',
        title: '',
        id: 'reorder',
        searchable: true,
        sticky: true,
        inputProps: {
          icon: 'icon-reorder',
        },
      },
      {
        name: 'field-run-filter',
        width: '400px',
        title: t('Work Step'),
        prop: 'name',
        id: 'name',
        searchable: true,
        customSecondaryHeader: true,
        sticky: true,
        inputProps: {
          type: 'text',
          textArea: true,
        },
        dataClass: 'overflow-visible',
      }, {
        name: 'field-item-select',
        width: '112px',
        title: 'Prefab Items',
      }, {
        name: 'field-date',
        width: '152px',
        title: 'Production Start',
        id: 'start',
        isEdit: true,
        inputProps: {
          pos: 'is-bottom-right',
          kind: 'coord',
          max: 'deliver',
        },
      }, {
        name: 'field-date',
        width: '152px',
        title: 'Production End',
        id: 'end',
        isEdit: true,
        inputProps: {
          pos: 'is-bottom-right',
          kind: 'deliver',
          min: 'coord',
        },
      }, {
        name: 'field-clock-display',
        width: '193px',
        title: 'Planned Hours (Per Item)',
        prop: 'autoHours',
        id: 'hrs',
        inputProps: {
          type: 'number',
          min: '0',
          step: '1',
          max: '999',
        },
      }, {
        name: 'field-clock-display',
        width: '192px',
        title: 'Planned Hours(All Items)',
        prop: 'stats.plannedHrs',
        id: 'stats.plannedHrs',
        inputProps: {
          type: 'text',
          disableEdit: true,
        },
      }, {
        name: 'field-clock-display',
        width: '152px',
        title: 'Actual Hours',
        prop: 'autoActualHours',
        id: 'stats.actualHrs',
        inputProps: {
          type: 'number',
          min: '0',
          step: '1',
          max: '999',
        },
      },
      {
        name: 'run-timer',
        width: '122px',
        title: 'Productivity',
        customHeader: true,
        customSlotHeader: true,
      },
      {
        name: 'qa-qc',
        width: '64px',
        title: 'QA/QC',
      },
      {
        name: 'field-productivity-display',
        width: '112px',
        title: 'Productivity',
        inputProps: {
          type: 'text',
          disableEdit: true,
          kind: 'stats.pf',
        },
        dataClass: 'overflow-visible',
      },
      {
        name: 'owner-select',
        title: 'Owner',
        width: '296px',
        id: 'owner',
        dataClass: 'overflow-visible',
      },
      {
        name: 'location-select',
        title: 'Location',
        width: '296px',
        id: 'location',
        dataClass: 'overflow-visible',
      },
      {
        name: 'field-item-docs-icon',
        width: '64px',
        title: 'Docs',
        id: 'simpleFiles',
        for: 'Workstep',
        allowAdd: true,
        showForm: true,
      },
      {
        title: 'Actions',
        name: 'field-card-edit-action',
        id: 'action',
        width: '112px',
        searchable: true,
        thAttrs: () => ({ class: 'action-sticky' }),
        tdClass: 'action-sticky',
        permissionModule: 'production-manager',
        noRequiredCheck: true,
        sticky: true,
        showDetails: false,
        isDisabled(item, tableRowCounts) {
          if (tableRowCounts < 2) return true;
          if (['delivery', 'in-transit', 'complete'].includes(item.order._customStage)) {
            return true;
          }
          return false;
        },
      },
    ],
    'manager-edit-manufacturing': [
      {
        name: 'table-field-icon',
        width: '40px',
        title: '',
        id: 'reorder',
        searchable: true,
        sticky: true,
        inputProps: {
          icon: 'icon-reorder',
        },
      }, {
        name: 'field-run-filter',
        width: '400px',
        title: t('Work Step'),
        prop: 'name',
        searchable: true,
        sticky: true,
        id: 'name',
        customSecondaryHeader: true,
        inputProps: {
          type: 'text',
          textArea: true,
        },
        dataClass: 'overflow-visible',
      }, {
        name: 'field-item-select',
        width: '112px',
        title: 'Prefab Items',
        id: 'ric',
      }, {
        name: 'field-date',
        width: '152px',
        title: 'Production Start',
        id: 'start',
        isEdit: true,
        inputProps: {
          pos: 'is-bottom-right',
          kind: 'coord',
          max: 'deliver',
        },
      }, {
        name: 'field-date',
        width: '152px',
        title: 'Production End',
        id: 'end',
        isEdit: true,
        inputProps: {
          pos: 'is-bottom-right',
          kind: 'deliver',
          min: 'coord',
        },
      }, {
        name: 'field-clock-display',
        width: '192px',
        title: 'Planned Hours(Per Item)',
        prop: 'autoHours',
        id: 'hrs',
        inputProps: {
          type: 'number',
          min: '0',
          step: '1',
          max: '999',
        },
      }, {
        name: 'field-clock-display',
        width: '192px',
        title: 'Planned Hours(All Items)',
        prop: 'stats.plannedHrs',
        id: 'stats.plannedHrs',
        inputProps: {
          type: 'text',
          disableEdit: true,
        },
      }, {
        name: 'field-clock-display',
        width: '152px',
        title: 'Actual Hours',
        prop: 'autoActualHours',
        id: 'stats.actualHrs',
        inputProps: {
          type: 'number',
          min: '0',
          step: '1',
          max: '999',
        },
      },
      {
        name: 'run-timer',
        width: '122px',
        title: 'Productivity',
        customHeader: true,
        customSlotHeader: true,
      },
      {
        name: 'qa-qc',
        width: '64px',
        title: 'QA/QC',
      },
      {
        name: 'percCompleted',
        width: '112px',
        title: '% Done',
      },
      {
        name: 'isDone',
        width: '64px',
        title: 'Done'
      },
      {
        name: 'field-productivity-display',
        width: '112px',
        title: 'Productivity',
        inputProps: {
          type: 'text',
          disableEdit: true,
          kind: 'stats.pf',
        },
        dataClass: 'overflow-visible',
      }, {
        name: 'owner-select',
        title: 'Owner',
        width: '296px',
        dataClass: 'overflow-visible',
        id: 'owner',
      },
      {
        name: 'location-select',
        title: 'Location',
        width: '296px',
        dataClass: 'overflow-visible',
        id: 'location',
      },
      {
        name: 'field-item-docs-icon',
        width: '64px',
        title: 'Docs',
        id: 'simpleFiles',
        for: 'Workstep',
        allowAdd: true,
        showForm: true,
      },
      {
        title: 'Actions',
        name: 'field-card-edit-action',
        id: 'action',
        width: '112px',
        permissionModule: 'production-manager',
        thAttrs: () => ({ class: 'action-sticky' }),
        tdClass: 'action-sticky',
        sticky: true,
        noRequiredCheck: true,
        showDetails: false,
        isDisabled(item) {
          if (['delivery', 'in-transit', 'complete'].includes(item.order._customStage)) {
            return true;
          }
          return false;
        },
        isRemoveDisabled(item, tableRowCounts) {
          if (tableRowCounts < 2) return true;
          return false;
        },
      },
    ],
    'manager-edit-qa': [
      {
        name: 'table-field-icon',
        width: '40px',
        title: '',
        id: 'reorder',
        searchable: true,
        sticky: true,
        inputProps: {
          icon: 'icon-reorder',
        },
      }, {
        name: 'field-run-filter',
        width: '400px',
        title: t('Work Step'),
        prop: 'name',
        id: 'name',
        sticky: true,
        searchable: true,
        customSecondaryHeader: true,
        inputProps: {
          type: 'text',
          textArea: true,
        },
        dataClass: 'overflow-visible',
      }, {
        name: 'field-item-select',
        width: '112px',
        title: 'Prefab Items',
        id: 'ric',
      }, {
        name: 'field-date',
        width: '152px',
        title: 'Production Start',
        id: 'start',
        isEdit: true,
        inputProps: {
          pos: 'is-bottom-right',
          kind: 'coord',
          max: 'deliver',
        },
      }, {
        name: 'field-date',
        width: '152px',
        title: 'Production End',
        id: 'end',
        isEdit: true,
        inputProps: {
          pos: 'is-bottom-right',
          kind: 'deliver',
          min: 'coord',
        },
      }, {
        name: 'field-clock-display',
        width: '192px',
        title: 'Planned Hours(Per Item)',
        fieldClass: 'is-pulled-right',
        prop: 'autoHours',
        id: 'hrs',
        inputProps: {
          type: 'number',
          min: '0',
          step: '1',
          max: '999',
        },
      }, {
        name: 'field-clock-display',
        width: '192px',
        title: 'Planned Hours(All Items)',
        fieldClass: 'is-pulled-right',
        prop: 'stats.plannedHrs',
        id: 'stats.plannedHrs',
        inputProps: {
          type: 'text',
          disableEdit: true,
        },
      }, {
        name: 'field-clock-display',
        width: '152px',
        title: 'Actual Hours',
        prop: 'autoActualHours',
        id: 'stats.actualHrs',
        inputProps: {
          type: 'number',
          min: '0',
          step: '1',
          max: '999',
        },
      },
      {
        name: 'qa-qc',
        width: '64px',
        title: 'QA/QC',
      },
      {
        name: 'field-productivity-display',
        width: '112px',
        title: 'Productivity',
        inputProps: {
          type: 'text',
          disableEdit: true,
          kind: 'stats.pf',
        },
        dataClass: 'overflow-visible',
      }, {
        name: 'owner-select',
        title: 'Owner',
        width: '296px',
        dataClass: 'overflow-visible',
        id: 'owner',
      },
      {
        name: 'location-select',
        title: 'Location',
        width: '296px',
        dataClass: 'overflow-visible',
        id: 'location',
      },
      {
        name: 'field-item-docs-icon',
        width: '64px',
        title: 'Docs',
        id: 'simpleFiles',
        for: 'Workstep',
        allowAdd: true,
        showForm: true,
      },
      {
        name: 'field-generic-input',
        title: 'Done',
        id: 'isDone',
        width: '64px',
        prop: 'isCompleted',
        isDisabled: true,
        type: 'checkbox',
        inputProps: {
          disabled: true,
          disableEdit: true,
        },
      },
      {
        title: 'Actions',
        name: 'field-card-edit-action',
        id: 'action',
        width: '112px',
        thAttrs: () => ({ class: 'action-sticky' }),
        tdClass: 'action-sticky',
        sticky: true,
        permissionModule: 'production-manager',
        showDetails: true,
        noRequiredCheck: true,
        isDisabled(item) {
          if (['delivery', 'in-transit', 'complete'].includes(item.order._customStage)) {
            return true;
          }
          return false;
        },
        isRemoveDisabled(item, tableRowCounts) {
          if (tableRowCounts < 2) return true;
          return false;
        },
      },
    ],
  };
}

function managerCardDetails(screen) {
  const itemTableOpts = {
    stickeyHeaders: true,
    isCardView: true,
    toolBar: {
      // name: for drop down label, label: for action button label
      bulkActions: [],
      hideSearchBar: true, // by default let the search bar be available for the table
      buttons: [
        {
          event: 'addNew',
          icon: 'icon-addnew',
          isLine: true,
          tooltip: 'Add New',
          acl: {
            permissionModule: 'production-manager',
            type: 'edit',
          },
          isDisabled: (routeParams, props) => {
            if (['delivery', 'complete'].includes(get(routeParams, 'stage', '')) || ['kit', 'assembly'].includes(props.purpose)) {
              return true;
            }
            return false;
          },
        },
        {
          event: 'addFromCatalog',
          icon: 'icon-addcatalog',
          isLine: true,
          tooltip: 'Add From Catalog',
          acl: {
            permissionModule: 'production-manager',
            type: 'edit',
          },
          isDisabled: (routeParams, props) => {
            if (['delivery', 'complete'].includes(get(routeParams, 'stage', '')) || ['kit', 'assembly'].includes(props.purpose)) {
              return true;
            }
            return false;
          },
        },
        {
          event: 'addFromPrefab',
          icon: 'icon-addinventory',
          isLine: true,
          tooltip: 'Add From Prefab',
          acl: {
            permissionModule: 'production-manager',
            type: 'edit',
          },
          isDisabled: (routeParams, props) => {
            if (['delivery', 'complete'].includes(get(routeParams, 'stage', '')) || ['kit', 'assembly'].includes(props.purpose)) {
              return true;
            }
            return false;
          },
        },
        {
          event: 'excelImport',
          icon: 'icon-importexcel',
          tooltip: 'Import from Excel',
          acl: {
            permissionModule: 'production-manager',
            type: 'edit',
          },
          isDisabled: (routeParams, props) => {
            if (['delivery', 'complete'].includes(get(routeParams, 'stage', '')) || ['kit', 'assembly'].includes(props.purpose)) {
              return true;
            }
            return false;
          },
        },
      ],
    },
  };
  const runTableOpts = {
    stickeyHeaders: true,
    isCardView: true,
    toolBar: {
      // name: for drop down label, label: for action button label
      bulkActions: () => {
        const opts = [
          {
            name: 'Export Order QA/QC Forms',
            event: 'exportforms',
            label: 'Export Order QA/QC Forms',
          },
        ];
        return opts;
      },
      hideSearchBar: true, // by default let the search bar be available for the table
      buttons: [
        {
          event: 'run-addNew',
          icon: 'icon-addnew',
          isLine: true,
          tooltip: 'Add New',
          acl: {
            permissionModule: 'production-manager',
            type: 'edit',
          },
          isDisabled: (routeParams) => ['delivery', 'complete'].includes(get(routeParams, 'stage', '')),
        },
        {
          event: 'run-excelImport',
          icon: 'icon-importexcel',
          isLine: true,
          tooltip: 'Import From Excel',
          acl: {
            permissionModule: 'production-manager',
            type: 'edit',
          },
          isDisabled: (routeParams) => ['delivery', 'complete'].includes(get(routeParams, 'stage', '')),
        },
      ],
    },
  };
  if (screen && screen === 'manager-edit-qa') {
    itemTableOpts.toolBar.buttons = [];
    runTableOpts.toolBar.buttons = [];
    runTableOpts.toolBar.bulkActions = [];
  }
  const materialTabOpts = {
    toolBar: {
      hideSearchBar: true, // by default let the search bar be available for the table
      buttons: ((stage, materialItems, isReserveBtnDisabled) => {
        const buttons = [
          {
            event: 'addFromInventory',
            icon: 'icon-addinventory',
            tooltip: 'Add From Inventory',
            acl: {
              permissionModule: 'material-manager',
              type: 'edit',
            },
            isDisabled: (routeParams) => ['delivery', 'complete'].includes(get(routeParams, 'stage', '')),
          },
        ];
        if (stage === 'manufacturing') {
          buttons.push(
            {
              event: 'reserveMaterials',
              icon: 'icon-reserveinventory',
              tooltip: 'Reserve',
              isDisabled: (every(materialItems, (item) => !item.available) || isReserveBtnDisabled),
              acl: {
                permissionModule: 'production-manager',
                type: 'edit',
              },
            },
          );
        }
        if (stage !== 'manufacturing') {
          buttons.unshift({
            event: 'createBOMS',
            icon: 'icon-bomcreate',
            tooltip: 'Create Bills of Materials',
            acl: {
              permissionModule: 'material-manager',
              type: 'edit',
            },
          });
          buttons.push({
            event: 'addFromCatalog',
            icon: 'icon-addcatalog',
            tooltip: 'Add From Catalog',
            acl: {
              permissionModule: 'material-manager',
              type: 'edit',
            },
            isDisabled: (routeParams) => ['delivery', 'complete'].includes(get(routeParams, 'stage', '')),
          },
          {
            event: 'linkMaterialRequest',
            icon: 'icon-linkexistingrequest',
            tooltip: 'Link Existing Material Request',
            acl: {
              permissionModule: 'material-manager',
              type: 'edit',
            },
            isDisabled: (routeParams) => ['delivery', 'complete'].includes(get(routeParams, 'stage', '')),
          });
        }
        return buttons;
      }),
      showButtons: (route) => isUndefined(route.projectId),
    },
  };
  return {
    'item-info': {
      ...extraTableParams(),
      ...itemTableOpts,
      fields: [
        ...getManagerItemFields(screen),
      ],
    },
    'runs-info': {
      ...extraTableParams(),
      ...runTableOpts,
      fields: screen ? [...runsCols()[screen]] : [],
    },
    'materials-detail-tab': {
      ...extraTableParams(),
      ...materialTabOpts,
      isCardView: true,
      stickeyHeaders: true,
      fields: [
        {
          name: 'field-generic-input',
          width: '400px',
          title: 'Name',
          prop: 'name',
          sticky: true,
          id: 'name',
          searchable: true,
          sortField: 'name',
        }, {
          name: 'field-generic-input',
          width: '112px',
          title: 'Id',
          prop: 'customId',
          id: 'customId',
          sortField: 'customId',
        }, {
          name: 'field-cat-id',
          width: '152px',
          title: 'Catalog ID',
          id: 'catId',
          prop: 'catId',
          allowAutoComplete: true,
          verifyCatId: true,
          sortField: 'catId',
        }, {
          title: 'Measure',
          name: 'field-generic-input',
          id: 'measure',
          prop: 'measure',
          width: '152px',
          sortField: 'measure',
        }, {
          title: 'Measure Units',
          name: 'field-measure-unit-select',
          id: 'measureUnits',
          prop: 'measureUnits',
          width: '152px',
          sortField: 'measureUnits',
        }, {
          name: 'qty-consumed',
          width: '112px',
          title: 'Qty Consumed',
          id: 'qtyConsumed',
          prop: 'qtyConsumed',
          sortField: 'qtyToConsume',
        }, {
          name: 'qty-ship',
          id: 'qtyToShip',
          title: 'Qty To Ship',
          width: '112px',
          sortField: 'qtyToShip',
        }, {
          name: 'field-generic-input',
          prop: 'quantity',
          sortField: 'quantity',
          width: '112px',
          title: 'Qty Needed',
          id: 'quantity',
        }, {
          name: '',
          sortField: 'available',
          width: '112px',
          title: 'Qty In Inventory',
          classRight: 'is-pulled-right',
          id: 'available',
          inputProps: {
            disableEdit: true,
          },
          formatter(item) {
            if (!isEmpty(item.available) || item.available) return item.available;
            return 0;
          },
        }, {
          title: '',
          name: 'field-card-edit-action',
          alwaysVisible: true,
          id: 'action',
          width: '112px',
          // sticky: true,
          // thAttrs: () => ({ class: 'action-sticky' }),
          // tdClass: 'action-sticky',
          noButtons: true,
          isDisabled(rowData) {
            if (rowData.stage !== 'preparation' || rowData.combinedQuantity) {
              return true;
            }
            return false;
          },
        },
      ],
    },
    'materials-manufacturing-tab': {
      ...extraTableParams(),
      ...materialTabOpts,
      isCardView: true,
      stickeyHeaders: true,
      fields: [
        {
          name: 'field-generic-input',
          width: '400px',
          title: 'Name',
          prop: 'name',
          id: 'name',
          searchable: true,
          sticky: true,
          sortField: 'name',
        }, {
          name: 'field-generic-input',
          width: '152px',
          title: 'Id',
          prop: 'customId',
          id: 'customId',
          sortField: 'customId',
        }, {
          name: 'field-cat-id',
          width: '152px',
          title: 'Catalog ID',
          id: 'catId',
          prop: 'catId',
          allowAutoComplete: true,
          verifyCatId: true,
          sortField: 'catId',
        }, {
          title: 'Measure',
          name: 'field-generic-input',
          id: 'measure',
          prop: 'measure',
          width: '152px',
          sortField: 'measure',
        }, {
          title: 'Measure Units',
          name: 'field-measure-unit-select',
          id: 'measureUnits',
          prop: 'measureUnits',
          width: '152px',
          sortField: 'measureUnits',
        }, {
          name: 'qty-consumed',
          width: '112px',
          title: 'Qty Consumed',
          id: 'qtyConsumed',
          prop: 'qtyToConsume',
          sortField: 'qtyConsumed',
        }, {
          name: 'qty-ship',
          id: 'qtyToShip',
          prop: 'qtyToShip',
          title: 'Qty To Ship',
          width: '112px',
          sortField: 'qtyToShip',
        }, {
          name: 'field-generic-input',
          prop: 'quantity',
          sortField: 'quantity',
          width: '112px',
          title: 'Qty Needed',
          id: 'quantity',
        }, {
          name: '',
          sortField: 'available',
          width: '112px',
          title: 'Qty In Inventory',
          classRight: 'is-pulled-right',
          id: 'available',
          inputProps: {
            disableEdit: true,
          },
          formatter(item) {
            if (!isEmpty(item.available) || item.available) return item.available;
            return 0;
          },
        }, {
          name: '',
          sortField: 'reserved',
          width: '112px',
          title: 'Qty Reserved',
          classRight: 'is-pulled-right',
          id: 'reserved',
          inputProps: {
            disableEdit: true,
          },
          formatter(item) {
            if (!isEmpty(item.reserved) || item.reserved) return item.reserved;
            return 0;
          },
        },
      ],
    },
    'materials-qa-tab': {
      ...extraTableParams(),
      toolBar: { hideSearchBar: true },
      fields: [
        {
          name: 'field-generic-input',
          width: '400px',
          title: 'Name',
          prop: 'name',
          searchable: true,
          sticky: true,
          id: 'name',
          sortField: 'name',
        }, {
          name: 'field-generic-input',
          width: '112px',
          title: 'Id',
          prop: 'customId',
          id: 'customId',
          sortField: 'customId',
        }, {
          name: 'field-cat-id',
          width: '112px',
          title: 'Catalog ID',
          id: 'catId',
          prop: 'catId',
          allowAutoComplete: true,
          verifyCatId: true,
          sortField: 'catId',
        }, {
          title: 'Measure',
          name: 'field-generic-input',
          id: 'measure',
          prop: 'measure',
          width: '152px',
          sortField: 'measure',
        }, {
          title: 'Measure Units',
          name: 'field-measure-unit-select',
          id: 'measureUnits',
          prop: 'measureUnits',
          width: '152px',
          sortField: 'measureUnits',
        }, {
          name: 'qty-consumed',
          width: '112px',
          title: 'Qty Consumed',
          id: 'qtyConsumed',
          prop: 'qtyConsumed',
          sortField: 'qtyConsumed',
        }, {
          name: 'qty-ship',
          id: 'qtyToShip',
          title: 'Qty To Ship',
          width: '112px',
          sortField: 'qtyToShip',
        }, {
          name: 'field-generic-input',
          prop: 'quantity',
          sortField: 'quantity',
          width: '112px',
          title: 'Qty Needed',
          id: 'quantity',
        },
      ],
    },
  };
}

function prefabTabs(cardEdit = false) {
  const tabs = [
    {
      text: 'ITEMS',
      component: MfDetailTab,
      tableProps: cardEdit
        ? prefabCardItemDeatils()['item-info']
        : colsMap.PlanningRowDetails['item-info'],
      id: 'items',
      accessor: 'items',
    },
    {
      text: 'MANAGEMENT',
      component: MfDetailTab,
      tableProps: cardEdit
        ? prefabCardItemDeatils().management
        : colsMap.PlanningRowDetails.management,
      id: 'management',
    },
  ];
  if (cardEdit) tabs.push(orderChecklistTab('prefabs'));
  else {
    tabs.push(
      {
        text: 'TASKS',
        component: OrderCheckList,
        tableProps: { ...colsMap.taskCol },
        id: 'tasks',
        accessor: 'items',
      },
    );
  }
  return {
    ...simpleTab(),
    tableTabs: {
      activeIndex: 0,
      tabs,
    },
  };
}

function productionTabs(cardEdit = false) {
  const tabs = [
    {
      text: 'items',
      component: MfDetailManagerTab,
      tableProps: cardEdit
        ? productionCardItemDetails()['item-info']
        : colsMap.PlanningRowDetails['order-item-info'],
      id: 'items',
      accessor: 'items',
    },
    {
      text: 'MATERIALS',
      component: MaterialTab,
      tableProps: productionCardItemDetails()['materials-tab'],
      id: 'materials',
    },
  ];
  if (cardEdit) tabs.push(orderChecklistTab('production-order'));
  return {
    ...simpleTab(),
    tableTabs: {
      activeIndex: 0,
      tabs,
    },
  };
}

// eslint-disable-next-line no-unused-vars
function scheduleTabs(cardEdit = false) {
  const tabs = [
    {
      text: 'ITEMS',
      component: MfDetailTab,
      tableProps: colsMap.ScheduleRowDetails['item-info'],
      id: 'items',
      accessor: 'items',
      tableName: 'scheduleDetailItems',
    },
    {
      text: 'ACTIVITY DETAILS',
      component: MfDetailTab,
      id: 'schedule-details',
    },
  ];
  return {
    ...simpleTab(),
    tableTabs: {
      activeIndex: 0,
      tabs,
    },
  };
}

const getDetailItemInfo = (screen) => {
  const detailingConf = cloneDeep(colsMap.PMRowDetails['item-info']);
  if (['manager-edit-qa'].includes(screen)) {
    const indx = findIndex(detailingConf.fields, { name: 'item-check' });
    detailingConf.fields.splice(indx, 1);
  }
  return detailingConf;
};

function managerTabs(cardEdit = false, defActiveIndex = 0, screen = 'manager-edit-manufacturing', isPmCardView = false) {
  const tabs = [
    {
      text: 'WORK STEPS',
      id: 'workSteps',
      component: MfDetailManagerTab,
      tableProps: cardEdit
        ? managerCardDetails(screen)['runs-info']
        : colsMap.PMRowDetails['runs-info'],
      accessor: 'manager.runs',
      isPmCardView,
    }, {
      text: 'ITEMS',
      id: 'managerItem',
      component: MfDetailManagerTab,
      tableProps: cardEdit
        ? managerCardDetails(screen)['item-info']
        : getDetailItemInfo(screen),
      accessor: 'items',
      isPmCardView,
    }, {
      text: 'MATERIALS',
      id: 'materials',
      component: MaterialTab,
      // eslint-disable-next-line no-nested-ternary
      tableProps: (screen === 'manager-edit-manufacturing')
        ? managerCardDetails()['materials-manufacturing-tab']
        : (screen === 'manager-edit-detailing') ? managerCardDetails()['materials-detail-tab'] : managerCardDetails()['materials-qa-tab'],
    }, {
      text: 'TASKS',
      component: OrderCheckList,
      tableProps: !cardEdit ? colsMap.PMRowDetails['task-info'] : orderChecklistTabProps('production-manager'),
      id: 'tasks',
      accessor: 'items',
    },
  ];
  return {
    ...simpleTab(),
    tableTabs: {
      activeIndex: defActiveIndex,
      tabs,
    },
  };
}
function materialReqTab(cardEdit = false) {
  const tabs = [
    {
      text: 'ITEMS',
      component: MfDetailTab,
      tableProps: cardEdit
        ? materialCardItemDetails()['item-info']
        : colsMap.MaterialRowDetails['item-info'],
      id: 'items',
      accessor: 'items',
    },
    {
      text: 'DETAILS',
      component: MfDetailTab,
      tableProps: cardEdit
        ? materialCardItemDetails()['item-details']
        : colsMap.MaterialRowDetails['item-details'],
      id: 'details',
      accessor: 'items',
    },
    {
      text: 'DATES',
      component: MfDetailTab,
      tableProps: colsMap.MaterialRowDetails['item-dates'],
      id: 'dates',
      accessor: 'items',
    },
  ];
  if (cardEdit) tabs.push(orderChecklistTab('material-manager'));
  return {
    ...materialTab(),
    tableTabs: {
      activeIndex: 0,
      materialTab: true,
      tabs,
    },
  };
}

function materialSourcingTab(cardEdit = false) {
  const tabs = [
    {
      text: 'ITEMS',
      component: MfDetailTab,
      tableProps: cardEdit
        ? sourcingCardItemDetails()['item-info']
        : colsMap.SourcingRowDetails['item-info'],
      id: 'items',
      accessor: 'items',
    },
    {
      text: 'DETAILS',
      component: MfDetailTab,
      tableProps: cardEdit
        ? sourcingCardItemDetails()['item-details']
        : colsMap.SourcingRowDetails['item-details'],
      id: 'details',
      accessor: 'items',
    },
    {
      text: 'DATES',
      component: MfDetailTab,
      tableProps: colsMap.MaterialRowDetails['item-dates'],
      id: 'dates',
      accessor: 'items',
    },
  ];
  if (cardEdit) tabs.push(orderChecklistTab('material-manager'));
  return {
    ...materialTab(),
    tableTabs: {
      activeIndex: 0,
      materialTab: true,
      tabs,
    },
  };
}

function productionStatusTab(cardEdit = false) {
  const pmTabs = [
    {
      text: 'WORK STEPS',
      id: 'workSteps',
      component: MfDetailManagerTab,
      tableProps: colsMap.PMRowDetails['runs-info'],
      accessor: 'manager.runs',
    },
    {
      text: 'ITEMS',
      component: MfDetailManagerTab,
      tableProps: colsMap.PlanningRowDetails['order-item-info'],
      id: 'items',
      accessor: 'items',
    }, {
      text: 'TASKS',
      component: OrderCheckList,
      tableProps: { ...colsMap.taskCol },
      id: 'tasks',
      accessor: 'items',
    },
  ];
  const tabs = [
    {
      text: 'ITEMS',
      component: MfDetailTab,
      tableProps: colsMap.PlanningRowDetails['item-info'],
      id: 'items',
      accessor: 'items',
    },
    {
      text: 'MANAGEMENT',
      component: MfDetailTab,
      tableProps: colsMap.PlanningRowDetails.management,
      id: 'management',
    },
  ];
  const poTabs = [
    {
      text: 'ITEMS',
      component: MfDetailManagerTab,
      tableProps: colsMap.PlanningRowDetails['order-item-info'],
      id: 'items',
      accessor: 'items',
    },
    {
      text: 'MATERIALS',
      component: MaterialTab,
      tableProps: productionCardItemDetails()['materials-tab'],
      id: 'materials',
    },
  ];
  const mixedStageTabs = [
    {
      text: 'ITEMS',
      component: MfDetailManagerTab,
      tableProps: colsMap.PlanningRowDetails['order-item-info'],
      id: 'items',
      accessor: 'items',
    },
    {
      text: 'TASKS',
      component: OrderCheckList,
      tableProps: { ...colsMap.taskCol },
      id: 'tasks',
      accessor: 'items',
    },
  ];
  if (cardEdit) tabs.push(orderChecklistTab('scm'));
  else {
    tabs.push(
      {
        text: 'TASKS',
        component: OrderCheckList,
        tableProps: { ...colsMap.taskCol },
        id: 'tasks',
        accessor: 'items',
      },
    );
  }
  return {
    ...simpleTab(),
    tableTabs: {
      activeIndex: 0,
      poTabs,
      tabs,
      pmTabs,
      mixedStageTabs,
    },
  };
}

function materialStatusTab(cardEdit = false) {
  const tabs = [
    {
      text: 'ITEMS',
      component: MfDetailTab,
      tableProps: cardEdit
        ? materialCardItemDetails()['item-info']
        : colsMap.MaterialRowDetails['item-info'],
      id: 'items',
      accessor: 'items',
    },
    {
      text: 'DETAILS',
      component: MfDetailTab,
      tableProps: cardEdit
        ? materialCardItemDetails()['item-details']
        : colsMap.MaterialRowDetails['item-details'],
      id: 'details',
      accessor: 'items',
    },
    {
      text: 'DATES',
      component: MfDetailTab,
      tableProps: colsMap.MaterialRowDetails['item-dates'],
      id: 'dates',
      accessor: 'items',
    },
  ];
  if (cardEdit) tabs.push(orderChecklistTab('material-manager'));
  return {
    ...materialTab(),
    tableTabs: {
      activeIndex: 0,
      materialTab: true,
      tabs,
    },
  };
}

function assembliesTab() {
  const tabs = [
    {
      text: 'Parts',
      component: MfDetailTab,
      tableProps: colsMap.assemblyDetailCols['parts-info'],
      id: 'parts',
      accessor: 'parts',
      isAssembDet: true,
    },
    {
      text: 'Attributes',
      component: MfDetailTab,
      tableProps: colsMap.assemblyDetailCols['attribute-details'],
      id: 'customAttributes',
      accessor: 'customAttributes',
      isAssembDet: true,
    },
  ];
  return {
    tableTabs: {
      activeIndex: 0,
      assembliesTab: true,
      tabs,
    },
  };
}

function partsTab() {
  const tabs = [
    {
      text: 'Vendors',
      component: MfDetailTab,
      tableProps: colsMap.partsDetailCols['vendor-details'],
      id: 'vendors',
      accessor: 'vendor',
    },
  ];
  return {
    tableTabs: {
      activeIndex: 0,
      partsTab: true,
      tabs,
    },
  };
}

export default {
  untitled: {
    tabs: [],
    cols: [],
    filters: [],
    showColumns: false,
    showSearch: false,
    showFilter: false,
    showActivity: false,
    showListView: false,
    showImport: false,
    hideSubHeader: true,
    hideTitleBar: false,
    hideMainHeader: false,
  },
  login: {
    hideMainHeader: true,
  },
  home: {
    cols: [
      {
        text: 'Column1',
        selected: true,
      },
    ],
  },
  planning: {
    ...prefabTabs(),
    activityModule: 'PrefabPackage',
    cols: colsMap.planner.fields[0].planning,
    permissionModule: 'prefabs',
  },
  coordination: {
    ...productionTabs(),
    activityModule: 'ProductionOrder',
    cols: colsMap.planner.fields[1].coordination,
    permissionModule: 'production-order',
  },
  schedule: {
    ...scheduleTabs(),
    cols: colsMap.planner.fields[2].schedule,
    permissionModule: 'prefabs',
    activityModule: 'Schedules',
    invalidExcelColsType: 'schedule-excel-import',
  },
  detailing: {
    ...managerTabs(false, 0, 'manager-edit-detailing'),
    permissionModule: 'production-manager',
    cols: colsMap.managerCols.detailing,
  },
  manufacturing: {
    ...managerTabs(false, 0, 'manager-edit-manufacturing'),
    permissionModule: 'production-manager',
    cols: colsMap.managerCols.manufacturing,
  },
  qa: {
    ...managerTabs(false, 0, 'manager-edit-qa'),
    permissionModule: 'production-manager',
    cols: colsMap.managerCols.qa,
  },
  'mm-preparation': {
    ...materialReqTab(),
    activityModule: 'MaterialManager',
    cols: colsMap.material.fields[0]['mm-preparation'],
    permissionModule: 'material-manager',
    excelColumns: materialExcelCols,
    invalidExcelColsType: 'materials-import',
  },
  'mm-sourcing': {
    ...materialSourcingTab(),
    cols: colsMap.material.fields[1]['mm-sourcing'],
    activityModule: 'MaterialManager',
    permissionModule: 'material-manager',
  },
  'mm-ordering': {
    ...materialSourcingTab(),
    cols: colsMap.material.fields[1]['mm-sourcing'],
    activityModule: 'MaterialManager',
    permissionModule: 'material-manager',
  },
  'material-status-order-view': {
    ...materialStatusTab(),
    cols: colsMap.materialStatus.fields,
    permissionModule: 'scm',
    permissionKey: 'materialStatus',
  },
  'material-status-item-view': {
    cols: colsMap.MSItemViewCols.fields,
  },
  'production-status-order-view': {
    ...productionStatusTab(),
    activityModule: 'ProductionStatus',
    cols: colsMap.ProductionStatusCols.fields,
    permissionModule: 'scm',
    permissionKey: 'productionStatus',
  },
  'kit-view-edit': {
    hideMainHeader: true,
    cardSubHeader: {
      actions: {},
      backBtnText: 'Navigate Back',
    },
    cols: [
      {
        name: 'field-pause-restart',
        sortField: 'name',
        width: '400px',
        title: 'Orders',
        prop: 'name',
        sticky: true,
        id: 'name',
        alwaysVisible: true,
        isClickable: true,
        eventType: 'openCard',
        searchField: false,
        searchable: true,
        bulkActionButtonField: true,
        inputProps: {
          type: 'text',
          stageIcons: true,
          icon: {
            pauseIcon: 'icon-pause',
            restartIcon: 'icon-play',
          },
        },
      }, {
        name: 'field-generic-input',
        sortField: 'financialId',
        width: '152px',
        default: false,
        title: 'ID',
        id: 'customId',
        prop: 'financialId',
        defaultVisible: false,
        inputProps: {
          type: 'text',
        },
      },
      {
        name: 'field-item-select',
        width: '112px',
        title: 'Prefab Items',
        defaultVisible: true,
        itemView: true,
        id: 'ric',
      },
      {
        name: 'field-generic-input',
        sortField: 'customId',
        width: '152px',
        title: 'Kit ID',
        prop: 'customId',
        id: 'kitId',
        isDisabled: true,
        defaultVisible: false,
        inputProps: {
          type: 'text',
          disableEdit: (rowData) => rowData.purpose !== 'kit' || rowData.stage !== 'planning',
        },
      },
      {
        title: '',
        name: 'table-field-icon',
        width: '64px',
        id: 'lmvIcon',
        defaultVisible: false,
        customHeader: true,
        inputProps: {
          icon: 'icon-linked-model',
        },
      }, {
        name: 'field-generic-input',
        prop: 'project.name',
        id: 'project',
        title: 'Project',
        width: '296px',
        show: true,
        sortField: 'project',
        defaultVisible: false,
        searchable: false,
        filterText: 'Projects',
        isDisabled: true,
      }, {
        title: 'Stage',
        name: 'field-stage',
        prop: 'stage',
        searchable: false,
        filterText: 'Stages',
        id: 'productionStatusStages',
        width: '152px',
        defaultVisible: true,
        alwaysVisible: false,
        cardProps: {
          cardType: '__t',
        },
      }, {
        name: 'field-stage',
        id: 'orderStatus',
        title: 'Status',
        width: '196px',
        prop: 'status',
        show: true,
        sortField: 'status',
        searchable: false,
        filterText: 'Status',
        defaultVisible: false,
        isStatus: true,
        isStaticColor: true,
      },
      {
        title: 'Owner',
        name: 'field-user-select',
        prop: 'owner.user',
        width: '296px',
        sortField: 'effectiveOwner.user',
        id: 'owners',
        searchable: false,
        filterText: 'Owners',
        defaultVisible: true,
        cardProps: {
          companyId: 'created.by.company._id',
          projectId: 'project._id',
          isEditing: 'isEditing',
        },
        inputProps: {
          getUserName: (row) => (row.isManager ? 'manager.owner.user' : 'owner.user'),
        },
      },
      {
        name: 'field-generic-input',
        prop: 'owner.company.name',
        id: 'companies',
        title: 'Company',
        width: '296px',
        sortField: 'name',
        searchable: false,
        defaultVisible: false,
        isDisabled: true,
      }, {
        title: 'Location',
        name: 'field-location-select',
        prop: 'manager.location',
        width: '296px',
        sortField: 'manager.location',
        id: 'locations',
        defaultVisible: false,
        searchable: false,
        filterText: 'Locations',
        cardProps: {
          companyId: 'created.by.company._id',
          projectId: 'project._id',
          isEditing: 'isEditing',
        },
      },
      {
        name: 'field-generic-input',
        prop: 'level',
        sortField: 'level',
        width: '296px',
        title: 'Level',
        id: 'level',
        defaultVisible: false,
        isDisabled: true,
      }, {
        name: 'field-generic-input',
        prop: 'zone',
        sortField: 'zone',
        width: '296px',
        title: 'Zone',
        id: 'zone',
        defaultVisible: false,
        isDisabled: true,
      }, {
        name: 'totalItems',
        prop: 'totalItems',
        width: '112px',
        title: 'Total Items',
        id: 'totalItems',
        classRight: 'is-pulled-right',
        iPadVisible: true,
        defaultVisible: false,
      }, {
        title: 'Items (%Done)',
        name: 'field-progress-circle',
        prop: 'manager.stats.itemPerc',
        sortField: 'manager.stats.itemPerc',
        searchable: false,
        filterText: 'Items Done',
        width: '64px',
        id: 'itemPerc',
        isDisabled: true,
        defaultVisible: false,
        alwaysVisible: false,
      },
      {
        title: 'Runs (%Done)',
        name: 'field-progress-circle',
        prop: 'manager.stats.runsPerc',
        sortField: 'manager.stats.runsPerc',
        searchable: false,
        filterText: 'Runs Done',
        width: '64px',
        id: 'runsPerc',
        isDisabled: true,
        defaultVisible: false,
        alwaysVisible: false,
      }, {
        title: 'Order (%Done)',
        name: 'field-progress-circle',
        prop: 'manager.stats.percComplete',
        sortField: 'manager.stats.percComplete',
        searchable: false,
        filterText: 'Order Done',
        width: '64px',
        id: 'percComplete',
        isDisabled: true,
        defaultVisible: false,
        alwaysVisible: false,
      }, {
        name: 'field-clock-display',
        prop: 'manager.stats.plannedHrs',
        sortField: 'manager.stats.plannedHrs',
        width: '112px',
        title: 'Planned Hours',
        id: 'plannedHrs',
        iPadVisible: true,
        defaultVisible: true,
        inputProps: {
          disableEdit: true,
          showNum: true,
        },
      }, {
        name: 'field-clock-display',
        prop: 'manager.stats.actualHrs',
        sortField: 'manager.stats.actualHrs',
        width: '112px',
        title: 'Actual Hours',
        id: 'actualHrs',
        iPadVisible: true,
        defaultVisible: true,
        inputProps: {
          disableEdit: true,
          showNum: true,
        },
      }, {
        name: 'field-productivity-display',
        width: '64px',
        title: 'Productivity',
        id: 'productivity',
        iPadVisible: true,
        defaultVisible: true,
        inputProps: {
          type: 'text',
          disableEdit: true,
          kind: 'manager.stats.pf',
        },
      }, {
        name: 'field-date',
        sortField: 'dates.coord',
        width: '152px',
        title: 'Coordinate By',
        prop: 'date',
        id: 'coordination',
        searchable: false,
        defaultVisible: false,
        dateFilter: 'coordBy',
        inputProps: {
          type: 'mf-date',
          kind: 'coord',
          max: ['poDetailBy', 'poManufactureBy', 'poQaBy', 'deliver'],
          disableEdit(data) {
            if (['coordination', 'planning'].includes(data.stage)) {
              return false;
            }
            return true;
          },
        },
      }, {
        name: 'field-date',
        sortField: 'dates.detailBy',
        width: '152px',
        title: 'Detail By',
        prop: 'date',
        id: 'detailBy',
        searchable: false,
        defaultVisible: false,
        dateFilter: 'coordBy',
        inputProps: {
          type: 'mf-date',
          kind: 'detailBy',
          min: 'coord',
          max: ['manufactureBy', 'qaBy', 'deliver'],
          isManager: true,
          disableEdit(data) {
            if (['detailing', 'coordination', 'planning'].includes(data.stage)) {
              return false;
            }
            return true;
          },
        },
      }, {
        name: 'field-date',
        sortField: 'dates.manufactureBy',
        width: '152px',
        title: 'Manufacture By',
        prop: 'date',
        id: 'manufactureBy',
        searchable: false,
        defaultVisible: true,
        dateFilter: 'manufactureBy',
        inputProps: {
          type: 'mf-date',
          kind: 'manufactureBy',
          min: ['detailBy', 'coord'],
          max: ['qaBy', 'deliver'],
          isManager: true,
          disableEdit(data) {
            if (['qa', 'complete', 'delivery'].includes(data.stage)) {
              return true;
            }
            return false;
          },
        },
      }, {
        name: 'field-date',
        sortField: 'dates.deliver',
        width: '152px',
        title: 'Onsite',
        prop: 'date',
        id: 'onSiteBy',
        defaultVisible: true,
        iPadVisible: true,
        searchable: false,
        isEditing: true,
        dateFilter: 'deliver',
        inputProps: {
          type: 'mf-date',
          kind: 'deliver',
          min: ['qaBy', 'manufactureBy', 'detailBy', 'coord'],
          disableEdit(data) {
            if (['complete', 'delivery'].includes(data.stage)) {
              return true;
            }
            return false;
          },
        },
      },
      {
        name: 'field-date',
        sortField: 'dates.qaBy',
        width: '152px',
        title: 'QA/QC By',
        prop: 'date',
        id: 'qaBy',
        searchable: false,
        dateFilter: 'qaBy',
        defaultVisible: false,
        inputProps: {
          type: 'mf-date',
          kind: 'qaBy',
          min: ['manufactureBy', 'detailBy', 'coord'],
          max: 'deliver',
          isManager: true,
          disableEdit(data) {
            if (['complete', 'delivery'].includes(data.stage)) {
              return true;
            }
            return false;
          },
        },
      },
      {
        name: 'field-notes-icon',
        title: 'Notes',
        prop: 'notes',
        width: '64px',
        id: 'notes',
        isDisabled: true,
        inputProps: {
          isItem: false,
        },
      },
      {
        name: 'field-item-docs-icon',
        width: '64px',
        title: 'Docs',
        id: 'simpleFiles',
        defaultVisible: true,
        for: 'Item',
        isDisabled: true,
        cardProps: {
          isEditing: false,
        },
      },
      {
        title: 'Actions',
        name: 'table-field-actions',
        alwaysVisible: true,
        id: 'action',
        width: '112px',
        sticky: true,
        thAttrs: () => ({ class: 'action-sticky' }),
        tdClass: 'action-sticky',
        hideFromChooser: true,
        searchable: true,
        clearBtn: false,
        permissionModule: 'scm',
        permissionKey: 'production-status',
        inputProps: {
          options(card) {
            const opts = [];
            const cardStage = card.getCardStage();
            if (!['planning', 'preparation', 'coordination'].includes(cardStage)) {
              opts.push({
                icon: 'fa fa-qrcode',
                label: 'Print QR',
                event: 'printQr',
              },
              {
                icon: 'icon-activityhistory',
                label: 'History',
                event: 'activity',
              });
            }
            if (validateLmv.validate3dots(card.items)) {
              opts.push({
                icon: 'icon-lmv',
                label: 'View Model',
                event: 'lmv',
              });
            }
            return opts;
          },
          disableEdit(rowData) {
            if (rowData.stage === 'complete') {
              return true;
            }
            return false;
          },
          editPermission: (params) => {
            if (!find(params.allProjects, { _id: params.rowData.project._id })) {
              return true;
            }
            return false;
          },
        },
      },
    ],
    tableTabs: {
      activeIndex: 0,
      materialTab: true,
      tabs: [
        {
          text: 'KIT ORDERS',
          component: MfDetailTab,
          showTableGutter: true,
          apiMode: false,
          accessor: 'orders',
          tableProps: {
            stickeyHeaders: true,
            cardColumnChooser: true,
            gutterOpts: {
              buttons: [{
                event: 'excelExport',
                icon: 'icon-exportexcel',
                tooltip: 'Export to Excel',
              }, {
                event: 'columnChooser',
                icon: 'icon-tablecolumns',
                tooltip: 'Customize Columns',
                isLine: true,
              }],
            },
            toolBar: {
              // name: for drop down label, label: for action button label
              bulkActions: () => {
                const opts = [
                  {
                    name: 'Move Orders Forward',
                    event: 'kitForward',
                    label: 'Move Forward',
                  },
                  {
                    name: 'Move Orders Back',
                    event: 'kitBack',
                    label: 'Move Orders Back',
                  },
                  {
                    name: 'Move Orders to Kit Location',
                    event: 'moveForwardToKitLoc',
                    label: 'Move to Kit Location',
                  },
                  {
                    name: 'Change Dates',
                    event: 'massUpdateDates',
                    label: 'Change Dates',
                  },
                ];
                return opts;
              },
              hideSearchBar: true, // by default let the search bar be available for the table
            },
            ...extraTableParams(),
            tableOpts: {
              stickeyHeaders: true,
              isCardView: true,
              toolBar: {
              },
            },
          },
          id: 'orders',
        },
        {
          text: 'ALL KIT MATERIALS',
          component: MfDetailTab,
          apiMode: false,
          tableProps: {
            ...extraTableParams(),
            tableOpts: {
              stickeyHeaders: true,
              isCardView: true,
              toolBar: {
              },
            },
            fields: [
              {
                name: 'field-generic-input',
                width: '400px',
                title: 'Items',
                prop: 'name',
                id: 'name',
                searchable: true,
                sticky: true,
                cardProps: {
                  isEditing: false,
                },
              },
              {
                name: 'table-field-icon',
                width: '64px',
                title: '',
                id: 'lmvIcon',
                customHeader: true,
                inputProps: {
                  icon: 'icon-linked-model',
                },
              },
              {
                name: 'field-cat-id',
                width: '112px',
                title: 'Catalog ID',
                id: 'catId',
                prop: 'catId',
                allowAutoComplete: true,
                verifyCatId: true,
                inputProps: {
                  type: 'text',
                  disableEdit: true,
                },
              },
              {
                name: 'field-qty-input',
                width: '112px',
                title: 'QTY',
                id: 'quantity',
                prop: 'quantity',
                isDisabled: true,
              },
              {
                name: 'table-field-icon',
                width: '64px',
                title: '',
                id: 'qtyCalculator',
                customHeader: true,
                inputProps: {
                  event: 'QtyCalculator',
                  icon: 'icon-inventory',
                },
              },
              {
                name: 'field-qty-input',
                width: '112px',
                title: 'Measure',
                id: 'measure',
                prop: 'measure',
                isDisabled: true,
              },
              {
                name: 'field-measure-unit-select',
                width: '152px',
                title: 'Measure Units',
                id: 'measureUnits',
                prop: 'measureUnits',
              },
              {
                name: 'field-generic-input',
                width: '200px',
                title: 'Source Order',
                id: 'source order',
                prop: 'order.name',
                isDisabled: true,
                isClickable: true,
                eventType: 'openCard',
              },
              {
                name: 'field-generic-input',
                width: '112px',
                title: 'Category',
                id: 'category',
                prop: 'category.name',
                isDisabled: true,
              },
              {
                name: 'field-item-docs-icon',
                width: '64px',
                title: 'Docs',
                id: 'simpleFiles',
                for: 'Item',
                isDisabled: true,
                cardProps: {
                  isEditing: false,
                },
              },
              {
                name: 'field-notes-icon',
                title: 'Notes',
                prop: 'simpleMemos[0].text',
                defaultVisible: true,
                width: '64px',
                id: 'notes',
                isDisabled: true,
                inputProps: {
                  isItem: true,
                },
              },
            ],
          },
          id: 'materials',
        },
      ],
    },
  },
  'lmv-view': {
    cols: colsMap.LmvFilterCols.fields,
  },
  'lmv-view-card': {
    cols: colsMap.LmvFilterCols.fields,
  },
  'lmv-view-item': {
    cols: colsMap.LmvFilterCols.fields,
  },
  'production-status-item-view': {
    activityModule: 'ProductionStatus',
    cols: colsMap.PSItemViewCols.fields,
  },
  inventory: {
    activityModule: 'Inventory',
    permissionModule: 'inventory',
    tableTabs: {
      activeIndex: 0,
      tabs: [
        {
          text: 'LOCATIONS',
          component: ItemLocations,
          tableProps: colsMap.ShippingItemsDetails['detail-row-cols'],
          id: 'items',
        },
      ],
    },
    cols: colsMap.inventoryCols.fields,
    excelColumns: imExcelCols,
    invalidExcelColsType: 'inventory-import',
  },
  'transfer-request': {
    activityModule: 'TransferRequest',
    cols: colsMap.transferRequestCols.fields,
    activeTab: 'Transfer Requests',
    permissionModule: 'inventory',
    permissionKey: 'transfer_request',
  },
  'shipping-order-view': {
    activityModule: 'Shipping',
    permissionModule: 'shipping',
    tableTabs: {
      activeIndex: 0,
      tabs: [
        {
          text: 'ITEMS',
          component: MfDetailTab,
          tableProps: colsMap.ShippingItemsDetails['detail-row-cols'],
          id: 'items',
        },
        {
          text: 'DELIVERIES',
          component: MfDetailTab,
          accessor: 'partialShipments',
          tableProps: colsMap.ShippingItemsDetails['list-view-delivery-info'],
          id: 'deliveries',
        },
      ],
    },
    cols: shippingOrderViewCols.fields,
  },
  'shipping-item-view': {
    activityModule: 'Shipping',
    tableTabs: {
      activeIndex: 0,
      tabs: [
        {
          text: 'ITEMS',
          component: MfDetailTab,
          tableProps: colsMap.ShippingItemsDetails['detail-row-cols'],
          id: 'items',
        },
      ],
    },
    cols: shippingItemViewCols.fields,
  },
  'shipping-edit': {
    activityModule: 'Shipping',
    cardSubHeader: {
      actions: {
        ...baseCardEditHeader(),
        subscribe: false,
        qrPrint: true,
      },
      backBtnText: 'Logistics Manager/Shipping Orders',
    },
    tableTabs: {
      activeIndex: 0,
      tabs: [
        {
          text: 'ITEMS',
          component: MfDetailTab,
          tableProps: colsMap.ShippingItemsDetails['item-info'],
          id: 'items',
        },
        {
          text: 'DELIVERIES',
          component: MfDetailTab,
          accessor: 'partialShipments',
          tableProps: colsMap.ShippingItemsDetails['delivery-info'],
          id: 'deliveries',
        },
      ],
    },
    hideMainHeader: true,
    customSubHeader: true,
    showSearch: false,
    hideSubHeader: true,
    hideTitleBar: true,
    shippingOrderButtons: [
      {
        title: 'Delete Order',
        event: 'deleteOrder',
        status: ['in-transit', 'not-started', 'zombie', 'released-to-inventory'],
        position: 'left',
        permissionType: 'delete',
      },
      {
        title: 'Cancel Shipment',
        event: 'cancelShipment',
        status: 'in-transit',
        position: 'left',
        permissionType: 'cancelDelivery',
      },
      {
        title: 'Receive Items',
        event: 'receiveItems',
        status: 'in-transit',
        position: 'center',
        permissionType: 'receive',
      },
      {
        title: 'Send Shipping List',
        event: 'sendShippingList',
        status: ['in-transit', 'release-to-inventory', 'zombie', 'not-started', 'complete', 'in-storage'],
        position: 'right',
        permissionType: 'shippingList',
      },
      // {
      //   title: 'Manage Partial Shipment',
      //   event: 'managePartials',
      //   status: 'not-started',
      //   position: 'center',
      //   permissionType: 'managePartials',
      // },
      {
        title: 'Schedule delivery',
        event: 'schedule',
        status: 'in-storage',
        position: 'center',
      },
      {
        title: 'Release To Inventory',
        event: 'releaseToInventory',
        status: 'in-storage',
        position: 'center',
        permissionType: 'release',
      },
      {
        title: 'Mark Final',
        event: 'markFinal',
        status: 'in-storage',
        position: 'center',
        permissionType: 'markFinal',
      },
      // {
      //   title: 'Manage Partial Shipments',
      //   event: 'managePartials',
      //   status: 'mixed',
      //   position: 'center',
      //   permissionType: 'managePartials',
      // },
    ],
    cols: colsMap.shipping.fields,
  },
  'create-shipping': {
    activityModule: 'Shipping',
    cardSubHeader: {
      actions: {
        ...baseCardEditHeader(),
        subscribe: false,
        qrPrint: true,
      },
      backBtnText: 'Create Shipping Order',
    },
    tableTabs: {
      activeIndex: 0,
      tabs: [
        {
          text: 'ITEMS',
          component: MfDetailTab,
          tableProps: colsMap.ShippingItemsDetails['detail-row-cols'],
          id: 'items',
        },
      ],
    },
    hideMainHeader: true,
    customSubHeader: true,
    showSearch: false,
    hideSubHeader: true,
    hideTitleBar: true,
    cols: colsMap.shipping.fields,
    shippingOrderButtons: [
      {
        title: 'Create Shipment',
        event: 'createShipment',
        position: 'center',
      },
    ],
  },
  'material-edit-preparation': {
    ...simpleTab(),
    hideMainHeader: true,
    customSubHeader: true,
    showSearch: false,
    hideSubHeader: true,
    hideTitleBar: true,
    activityModule: 'MaterialManager',
    excelColumns: materialItemsCols,
    invalidExcelColsType: 'mm-items-import',
    permissionModule: 'material-manager',
    cardSubHeader: {
      actions: baseCardEditHeader(),
      backBtnText: 'Material Requesting',
    },
    isCardView: true,
    ...materialReqTab(true),
    cardBasics: {
      basicDetails: [
        {
          label: 'col1',
          fields: [
            {
              name: 'field-user-select',
              prop: 'owner.user',
              titleLabel: 'Owner',
              cardProps: {
                companyId: 'created.by.company._id',
                projectId: 'project._id',
              },
            },
            {
              name: 'field-generic-input',
              prop: 'activity',
              label: 'Activity',
              placeHolder: 'Add Activity',
            },
            {
              name: 'field-vendor-select',
              prop: 'baseDelivery.vendor',
              titleLabel: 'Vendor',
            },
          ],
        },
        {
          label: 'col2',
          fields: [
            {
              name: 'field-kit-id',
              prop: 'customId',
              label: 'Kit ID',
              cardProps: {
                projectId: 'project._id',
              },
            },
            {
              name: 'field-generic-input',
              prop: 'financialId',
              label: 'Description',
            },
            {
              name: 'field-generic-input',
              prop: 'purchaseOrder',
              label: 'PO#',
              cardProps: {
                projectId: 'project._id',
              },
              isDisabled: (rowData, store, project, companyData) => get(companyData, 'generatePon', false)
                  || get(project, 'generatePon', false),
            },
          ],
        },
        {
          label: 'col3',
          fields: [
            {
              name: 'field-submittal',
              prop: 'submittal',
              label: 'Submittal',
              cardProps: {
                projectId: 'project._id',
              },
            },
            {
              name: 'field-linked-production-order',
              prop: 'productionOrder',
              label: 'Linked Production Order',
              cardProps: {
                projectId: 'project._id',
              },
            },
          ],
        },
        {
          label: 'col4',
          fields: [
            {
              name: 'field-stage',
              prop: 'stage',
              label: 'Stage',
            },
          ],
        },
      ],
      deliveryDetails: [
        {
          label: 'col1',
          fields: [
            {
              name: 'field-location-select',
              prop: 'baseDelivery.location',
              titleLabel: 'Location',
              cardProps: {
                companyId: 'created.by.company._id',
                projectId: 'project._id',
              },
            },
            {
              name: 'field-lvl-zone',
              prop: 'baseDelivery.level',
              label: 'Level',
              placeHolder: 'Not Specified',
              cardProps: {
                cardId: '_id',
                projectId: 'project._id',
              },
              inputProps: {
                options: 'levels',
                isDisabled: false,
              },
            },
          ],
        },
        {
          label: 'col2',
          fields: [
            {
              name: 'field-user-select',
              prop: 'baseDelivery.recipient',
              titleLabel: 'Recipient',
              cardProps: {
                companyId: 'created.by.company._id',
                projectId: 'project._id',
              },
            },
            {
              name: 'field-lvl-zone',
              prop: 'baseDelivery.zone',
              label: 'Zone',
              placeHolder: 'Not Specified',
              cardProps: {
                cardId: '_id',
                projectId: 'project._id',
              },
              inputProps: {
                options: 'zones',
                isDisabled: false,
              },
            },
          ],
        },
      ],
      supplierDetails: [
        {
          label: 'col1',
          fields: [
            {
              name: 'field-vendor-select',
              prop: 'baseDelivery.vendor',
              titleLabel: 'Vendor',
            },
          ],
        },
      ],
      dates: [
        {
          label: 'col1',
          fields: [
            {
              name: 'field-date',
              prop: 'orderBy',
              label: 'Earliest Order By',
              id: 'earliestOrderBy',
              hideDate: false,
              inputProps: {
                disableEdit: true,
              },
            },
            {
              name: 'field-date',
              prop: 'deliver',
              label: 'Onsite',
              hideDate: false,
              isDisabled: (data) => {
                if (['complete', 'delivery'].includes(data._customStage)) {
                  return true;
                }
                return false;
              },
            },
          ],
        },
      ],
      subDetails: [
        {
          label: 'col1',
          fields: [
            {
              name: 'field-file-list',
              prop: 'simpleFiles',
              label: 'files',
            },
          ],
        },
        {
          label: 'col2',
          fields: [
            {
              name: 'field-notes',
              prop: 'currentNote.text',
              label: 'Notes',
            },
          ],
        },
        {
          label: 'col3',
          fields: [
            {
              name: 'field-tags',
              prop: 'tags',
              label: 'Tags',
            },
          ],
        },
      ],
    },
  },
  'material-edit-sourcing': {
    ...simpleTab(),
    hideMainHeader: true,
    customSubHeader: true,
    showSearch: false,
    hideSubHeader: true,
    hideTitleBar: true,
    activityModule: 'MaterialManager',
    excelColumns: materialItemsCols,
    invalidExcelColsType: 'mm-items-import',
    permissionModule: 'material-manager',
    moduleKey: 'shipping',
    isCardView: true,
    cardSubHeader: {
      actions: baseCardEditHeader(),
      backBtnText: 'Material Sourcing',
    },
    cardBasics: {
      basicDetails: [
        {
          label: 'col1',
          fields: [
            {
              name: 'field-user-select',
              prop: 'owner.user',
              titleLabel: 'Owner',
              cardProps: {
                companyId: 'owner.company._id',
                projectId: 'project._id',
              },
            },
            {
              name: 'field-generic-input',
              prop: 'activity',
              label: 'Activity',
              placeHolder: 'Add Activity',
            },
            {
              name: 'field-vendor-select',
              prop: 'baseDelivery.vendor',
              titleLabel: 'Vendor',
            },
          ],
        },
        {
          label: 'col2',
          fields: [
            {
              name: 'field-kit-id',
              prop: 'customId',
              label: 'Kit ID',
              cardProps: {
                projectId: 'project._id',
              },
            },
            {
              name: 'field-generic-input',
              prop: 'financialId',
              label: 'Description',
            },
            {
              name: 'field-generic-input',
              prop: 'purchaseOrder',
              label: 'PO#',
              isDisabled: (rowData, store, project, companyData) => get(companyData, 'generatePon', false)
                  || get(project, 'generatePon', false),
              cardProps: {
                projectId: 'project._id',
              },
            },
          ],
        },
        {
          label: 'col3',
          fields: [
            {
              name: 'field-submittal',
              prop: 'submittal',
              label: 'Submittal',
              cardProps: {
                projectId: 'project._id',
              },
            },
            {
              name: 'field-linked-production-order',
              prop: 'productionOrder',
              label: 'Linked Production Order',
              cardProps: {
                projectId: 'project._id',
              },
            },
          ],
        },
        {
          label: 'col4',
          fields: [
            {
              name: 'field-stage',
              prop: 'stage',
              label: 'Stage',
            },
          ],
        },
      ],
      deliveryDetails: [
        {
          label: 'col1',
          fields: [
            {
              name: 'field-location-select',
              prop: 'baseDelivery.location',
              titleLabel: 'Location',
              cardProps: {
                companyId: 'owner.company._id',
                projectId: 'project._id',
              },
            }, {
              name: 'field-lvl-zone',
              prop: 'baseDelivery.level',
              label: 'Level',
              placeHolder: 'Not Specified',
              cardProps: {
                cardId: '_id',
                projectId: 'project._id',
              },
              inputProps: {
                options: 'levels',
                isDisabled: false,
              },
            },
          ],
        },
        {
          label: 'col2',
          fields: [
            {
              name: 'field-user-select',
              prop: 'baseDelivery.recipient',
              titleLabel: 'Recipient',
              cardProps: {
                companyId: 'owner.company._id',
                projectId: 'project._id',
              },
            }, {
              name: 'field-lvl-zone',
              prop: 'baseDelivery.zone',
              label: 'Zone',
              placeHolder: 'Not Specified',
              cardProps: {
                cardId: '_id',
                projectId: 'project._id',
              },
              inputProps: {
                options: 'zones',
                isDisabled: false,
              },
            },
          ],
        },
      ],
      dates: [
        {
          label: 'col1',
          fields: [
            {
              name: 'field-date',
              prop: 'orderBy',
              label: 'Earliest Order By',
              id: 'earliestOrderBy',
              hideDate: false,
              inputProps: {
                disableEdit: true,
              },
            },
            {
              name: 'field-date',
              prop: 'deliver',
              label: 'Onsite',
              hideDate: false,
            },
          ],
        },
      ],
      financials: [
        {
          label: 'col1',
          fields: [
            {
              name: 'field-generic-input',
              prop: 'totalItemsCost',
              label: 'Item Cost',
              type: 'number',
              isDisabled: true,
              roundTo: 2,
            },
            {
              name: 'field-generic-input',
              prop: 'extraCost',
              label: 'Extra Cost',
              type: 'number',
              min: 0,
              max: 999999,
              roundTo: 2,
            },
            {
              name: 'field-generic-input',
              prop: 'totalCost',
              label: 'Total Cost',
              type: 'number',
              roundTo: 2,
              isDisabled: true,

            },
          ],
        },
      ],
      subDetails: [
        {
          label: 'col1',
          fields: [
            {
              name: 'field-file-list',
              prop: 'simpleFiles',
              label: 'files',
            },
          ],
        },
        {
          label: 'col2',
          fields: [
            {
              name: 'field-notes',
              prop: 'currentNote.text',
              label: 'Notes',
            },
          ],
        },
        {
          label: 'col3',
          fields: [
            {
              name: 'field-tags',
              prop: 'tags',
              label: 'Tags',
            },
          ],
        },
      ],
    },
    ...materialSourcingTab(true),
  },
  'material-edit-ordering': {
    ...simpleTab(),
    hideMainHeader: true,
    customSubHeader: true,
    showSearch: false,
    hideSubHeader: true,
    hideTitleBar: true,
    activityModule: 'MaterialManager',
    excelColumns: materialItemsCols,
    invalidExcelColsType: 'mm-items-import',
    permissionModule: 'material-manager',
    moduleKey: 'shipping',
    isCardView: true,
    cardSubHeader: {
      actions: baseCardEditHeader(),
      backBtnText: 'Material Ordering',
    },
    ...materialSourcingTab(true),
    cardBasics: {
      basicDetails: [
        {
          label: 'col1',
          fields: [
            {
              name: 'field-user-select',
              prop: 'owner.user',
              titleLabel: 'Owner',
              cardProps: {
                companyId: 'owner.company._id',
                projectId: 'project._id',
              },
            },
            {
              name: 'field-generic-input',
              prop: 'activity',
              label: 'Activity',
              placeHolder: 'Add Activity',
            },
            {
              name: 'field-vendor-select',
              prop: 'baseDelivery.vendor',
              titleLabel: 'Vendor',
            },
          ],
        },
        {
          label: 'col2',
          fields: [
            {
              name: 'field-kit-id',
              prop: 'customId',
              label: 'Kit ID',
              cardProps: {
                projectId: 'project._id',
              },
            },
            {
              name: 'field-generic-input',
              prop: 'financialId',
              label: 'Description',
            },
            {
              name: 'field-generic-input',
              prop: 'purchaseOrder',
              label: 'PO#',
              isDisabled: (rowData, store, project, companyData) => get(companyData, 'generatePon', false)
                  || get(project, 'generatePon', false),
              cardProps: {
                projectId: 'project._id',
              },
            },
          ],
        },
        {
          label: 'col3',
          fields: [
            {
              name: 'field-submittal',
              prop: 'submittal',
              label: 'Submittal',
              cardProps: {
                projectId: 'project._id',
              },
            },
          ],
        },
        {
          label: 'col4',
          fields: [
            {
              name: 'field-stage',
              prop: 'stage',
              label: 'Stage',
            },
          ],
        },
      ],
      deliveryDetails: [
        {
          label: 'col1',
          fields: [
            {
              name: 'field-location-select',
              prop: 'baseDelivery.location',
              titleLabel: 'Location',
              cardProps: {
                companyId: 'owner.company._id',
                projectId: 'project._id',
              },
            }, {
              name: 'field-lvl-zone',
              prop: 'baseDelivery.level',
              label: 'Level',
              placeHolder: 'Not Specified',
              cardProps: {
                cardId: '_id',
                projectId: 'project._id',
              },
              inputProps: {
                options: 'levels',
                isDisabled: false,
              },
            },
          ],
        },
        {
          label: 'col2',
          fields: [
            {
              name: 'field-user-select',
              prop: 'baseDelivery.recipient',
              titleLabel: 'Recipient',
              cardProps: {
                companyId: 'owner.company._id',
                projectId: 'project._id',
              },
            }, {
              name: 'field-lvl-zone',
              prop: 'baseDelivery.zone',
              label: 'Zone',
              placeHolder: 'Not Specified',
              cardProps: {
                cardId: '_id',
                projectId: 'project._id',
              },
              inputProps: {
                options: 'zones',
                isDisabled: false,
              },
            },
          ],
        },
      ],
      linkedShippingOrders: [
        {
          label: 'col1',
          fields: [
            {
              name: 'field-linked-shpping-orders',
              prop: '_id',
              label: 'Linked Shipping Orders',
              cardProps: {
                projectId: 'project._id',
              },
            },
          ],
        },
      ],
      dates: [
        {
          label: 'col1',
          fields: [
            {
              name: 'field-date',
              prop: 'orderBy',
              label: 'Earliest Order By',
              id: 'earliestOrderBy',
              hideDate: false,
              inputProps: {
                disableEdit: true,
              },
            },
            {
              name: 'field-date',
              prop: 'deliver',
              label: 'Onsite',
              hideDate: false,
              isEdit: true,
              inputProps: {
                disableEdit: (rowData = {}, route = {}, data) => {
                  if (['Materials'].includes(data?.__t)) {
                    return true;
                  }
                  return false;
                },
              },
            },
          ],
        },
      ],
      financials: [
        {
          label: 'col1',
          fields: [
            {
              name: 'field-generic-input',
              prop: 'totalItemsCost',
              label: 'Item Cost',
              type: 'number',
              isDisabled: true,
              roundTo: 2,
            },
            {
              name: 'field-generic-input',
              prop: 'extraCost',
              label: 'Extra Cost',
              type: 'number',
              min: 0,
              max: 999999,
              roundTo: 2,
            },
            {
              name: 'field-generic-input',
              prop: 'totalCost',
              label: 'Total Cost',
              type: 'number',
              roundTo: 2,
              isDisabled: true,
            },
          ],
        },
      ],
      subDetails: [
        {
          label: 'col1',
          fields: [
            {
              name: 'field-file-list',
              prop: 'simpleFiles',
              label: 'files',
            },
          ],
        },
        {
          label: 'col2',
          fields: [
            {
              name: 'field-notes',
              prop: 'currentNote.text',
              label: 'Notes',
            },
          ],
        },
        {
          label: 'col3',
          fields: [
            {
              name: 'field-tags',
              prop: 'tags',
              label: 'Tags',
            },
          ],
        },
      ],
    },
  },
  'prefab-edit': {
    ...prefabTabs(true),
    hideMainHeader: true,
    customSubHeader: true,
    showSearch: false,
    hideSubHeader: true,
    hideTitleBar: true,
    activityModule: 'PrefabPackage',
    excelColumns: prefabItemsCols,
    invalidExcelColsType: 'prefab-items-import',
    permissionModule: 'prefabs',
    moduleKey: 'move',
    isCardView: true,
    cardSubHeader: {
      actions: baseCardEditHeader(),
      backBtnText: 'Project Planner',
    },
    cardBasics: {
      basicDetails: [
        {
          label: 'col1',
          fields: [
            {
              name: 'field-user-select',
              prop: 'owner.user',
              titleLabel: 'Owner',
              cardProps: {
                companyId: 'owner.company._id',
                projectId: 'project._id',
              },
            },
            {
              name: 'field-generic-input',
              prop: 'activity',
              label: 'Activity',
              placeHolder: 'Add Activity',
            },
          ],
        },
        {
          label: 'col2',
          fields: [
            {
              name: 'field-kit-id',
              prop: 'customId',
              label: 'Kit ID',
              cardProps: {
                projectId: 'project._id',
              },
            },
            {
              name: 'field-generic-input',
              prop: 'financialId',
              label: 'Description',
            },
          ],
        },
        {
          label: 'col3',
          fields: [
            {
              name: 'field-submittal',
              prop: 'submittal',
              label: 'Submittal',
              cardProps: {
                projectId: 'project._id',
              },
            },
          ],
        },
        {
          label: 'col4',
          fields: [
            {
              name: 'field-stage',
              prop: 'stage',
              label: 'Stage',
            },
          ],
        },
      ],
      dates: [
        {
          label: 'col1',
          fields: [
            {
              name: 'field-date',
              prop: 'coord',
              label: 'Coordination',
              hideDate: false,
              isEdit: true,
              inputProps: {
                disableEdit: (rowData = {}, route = {}, data, screen) => {
                  if (['planning'].includes(data?._customStage)
                    && ['Prefabs'].includes(data?.__t)
                    && !['planning'].includes(data?.stage)
                    && screen === 'prefab-edit'
                    && !some(data.items, (item) => item.stage === 'planning')) {
                    return true;
                  }
                  return false;
                },
              },
            },
            {
              name: 'field-date',
              prop: 'poDetailBy',
              label: 'Detail',
              hideDate: true,
            },
            {
              name: 'field-date',
              prop: 'poManufactureBy',
              label: 'Manufacture',
              hideDate: true,
            },
            {
              name: 'field-date',
              prop: 'poQaBy',
              label: 'QA/QC',
              hideDate: true,
            },
            {
              name: 'field-date',
              prop: 'deliver',
              label: 'Onsite',
              hideDate: false,
              isEdit: true,
              inputProps: {
                disableEdit: (rowData = {}, route = {}, data, screen) => {
                  if (['planning'].includes(data?._customStage)
                    && ['Prefabs'].includes(data?.__t)
                    && !['planning'].includes(data?.stage)
                    && screen === 'prefab-edit'
                    && !some(data.items, (item) => item.stage === 'planning')) {
                    return true;
                  }
                  return false;
                },
              },
            },
            {
              name: 'field-date',
              prop: 'partsManufactureBy',
              label: 'Assembly Manufacture',
              hideDate: true,
            },
          ],
        },
      ],
      subDetails: [
        {
          label: 'col1',
          fields: [
            {
              name: 'field-file-list',
              prop: 'simpleFiles',
              label: 'files',
            },
          ],
        },
        {
          label: 'col2',
          fields: [
            {
              name: 'field-notes',
              prop: 'currentNote.text',
              label: 'Notes',
            },
          ],
        },
        {
          label: 'col3',
          fields: [
            {
              name: 'field-tags',
              prop: 'tags',
              label: 'Tags',
            },
          ],
        },
      ],
      calculated: [
        {
          label: 'col1',
          fields: [
            {
              name: 'field-weld',
              prop: 'specification',
              label: 'Weld Length',
              cardProps: {
                projectId: 'project._id',
              },
            },
          ]
        }
      ],
    },
  },
  'po-edit': {
    ...productionTabs(true),
    hideMainHeader: true,
    customSubHeader: true,
    showSearch: false,
    hideSubHeader: true,
    hideTitleBar: true,
    activityModule: 'PrefabPackage',
    excelColumns: poItemsExcelCols,
    permissionModule: 'prefabs',
    moduleKey: 'move',
    isCardView: true,
    cardSubHeader: {
      actions: baseCardEditHeader(),
      backBtnText: 'Project Planner',
    },
    cardBasics: {
      basicDetails: [
        {
          label: 'col1',
          fields: [
            {
              name: 'field-user-select',
              prop: 'owner.user',
              titleLabel: 'Owner',
              cardProps: {
                companyId: 'owner.company._id',
                projectId: 'project._id',
              },
            },
            {
              name: 'field-generic-input',
              prop: 'activity',
              label: 'Activity',
              placeHolder: 'Add Activity',
            },
          ],
        },
        {
          label: 'col2',
          fields: [
            {
              name: 'field-kit-id',
              prop: 'customId',
              label: 'Kit ID',
              cardProps: {
                projectId: 'project._id',
              },
            },
            {
              name: 'field-generic-input',
              prop: 'financialId',
              label: 'Description',
            },
            {
              name: 'field-generic-input',
              prop: 'uniqueOrderId.value',
              label: 'Order ID',
            },
          ],
        },
        {
          label: 'col3',
          fields: [
            {
              name: 'field-submittal',
              prop: 'submittal',
              label: 'Submittal',
              cardProps: {
                projectId: 'project._id',
              },
            },
          ],
        },
        {
          label: 'col4',
          fields: [
            {
              name: 'field-stage',
              prop: 'stage',
              label: 'Stage',
            },
          ],
        },
      ],
      linkedBomOrders: [
        {
          label: 'col1',
          fields: [
            {
              name: 'field-linked-bom',
              prop: 'materials',
              label: 'Linked BOMs',
              cardProps: {
                projectId: 'project._id',
                cardId: '_id',
                items: 'items',
                stage: 'stage',
                __t: '__t',
              },
            },
          ],
        },
      ],
      productionLocation: [
        {
          label: 'col1',
          fields: [],
        },
      ],
      destinationLocation: [
        {
          label: 'col1',
          fields: [
            {
              name: 'field-lvl-zone',
              prop: 'level',
              label: 'Level',
              placeHolder: 'Not Specified',
              cardProps: {
                cardId: '_id',
                projectId: 'project._id',
              },
              inputProps: {
                options: 'levels',
                isDisabled: false,
              },
            },
            {
              name: 'field-lvl-zone',
              prop: 'zone',
              label: 'Zone',
              placeHolder: 'Not Specified',
              cardProps: {
                cardId: '_id',
                projectId: 'project._id',
              },
              inputProps: {
                options: 'zones',
                isDisabled: false,
              },
            },
          ],
        },
      ],
      dates: [
        {
          label: 'col1',
          fields: [
            {
              name: 'field-date',
              prop: 'coord',
              label: 'Coordination',
              hideDate: false,
            },
            {
              name: 'field-date',
              prop: 'poDetailBy',
              label: 'Detail',
              hideDate: true,
            },
            {
              name: 'field-date',
              prop: 'poManufactureBy',
              label: 'Manufacture',
              hideDate: true,
            },
            {
              name: 'field-date',
              prop: 'poQaBy',
              label: 'QA/QC',
              hideDate: true,
            },
            {
              name: 'field-date',
              prop: 'deliver',
              label: 'Onsite',
              hideDate: false,
            },
          ],
        },
      ],
      subDetails: [
        {
          label: 'col1',
          fields: [
            {
              name: 'field-file-list',
              prop: 'simpleFiles',
              label: 'files',
            },
          ],
        },
        {
          label: 'col2',
          fields: [
            {
              name: 'field-notes',
              prop: 'currentNote.text',
              label: 'Notes',
            },
          ],
        },
        {
          label: 'col3',
          fields: [
            {
              name: 'field-tags',
              prop: 'tags',
              label: 'Tags',
            },
          ],
        },
      ],
      calculated: [
        {
          label: 'col1',
          fields: [
            {
              name: 'field-weld',
              prop: 'specification',
              label: 'Weld Length',
              cardProps: {
                projectId: 'project._id',
              },
            },
          ]
        }
      ],
    },
  },
  'manager-edit-detailing': {
    hideMainHeader: true,
    customSubHeader: true,
    showSearch: false,
    hideSubHeader: true,
    hideTitleBar: true,
    activityModule: 'ProductionOrder',
    excelColumns: poItemsExcelCols,
    permissionModule: 'production-manager',
    isCardView: true,
    cardSubHeader: {
      actions: baseCardEditHeader(),
      backBtnText: 'Production Tracking',
    },
    cardBasics: {
      basicDetails: [
        {
          label: 'col1',
          fields: [
            {
              name: 'field-user-select',
              prop: 'manager.owner.user',
              titleLabel: 'Owner',
              cardProps: {
                companyId: 'manager.owner.company._id',
                projectId: 'project._id',
              },
            },
            {
              name: 'field-generic-input',
              prop: 'activity',
              label: 'Activity',
              placeHolder: 'Add Activity',
            },
          ],
        },
        {
          label: 'col2',
          fields: [
            {
              name: 'field-kit-id',
              prop: 'customId',
              label: 'Kit ID',
              cardProps: {
                projectId: 'project._id',
              },
            },
            {
              name: 'field-generic-input',
              prop: 'financialId',
              label: 'Description',
            },
            {
              name: 'field-generic-input',
              prop: 'uniqueOrderId.value',
              label: 'Order ID',
            },
          ],
        },
        {
          label: 'col3',
          fields: [
            {
              name: 'field-submittal',
              prop: 'submittal',
              label: 'Submittal',
              cardProps: {
                projectId: 'project._id',
              },
            },
          ],
        },
        {
          label: 'col4',
          fields: [
            {
              name: 'field-stage',
              prop: 'stage',
              label: 'Stage',
            },
            {
              name: 'field-status',
              prop: 'status',
              titleLabel: 'Status',
              cardProps: {
                resumeOrdersByAll: 'resumeOrdersByAll'
              }
            },
          ],
        },
      ],
      productionLocation: [
        {
          label: 'col1',
          fields: [
            {
              name: 'field-location-select',
              prop: 'manager.location',
              titleLabel: 'Facility',
              cardProps: {
                companyId: 'owner.company._id',
                projectId: 'project._id',
              },
            },
          ],
        },
      ],
      destinationLocation: [
        {
          label: 'col1',
          fields: [
            {
              name: 'field-lvl-zone',
              prop: 'level',
              label: 'Level',
              placeHolder: 'Not Specified',
              cardProps: {
                cardId: '_id',
                projectId: 'project._id',
              },
              inputProps: {
                options: 'levels',
                isDisabled: false,
              },
            },
            {
              name: 'field-lvl-zone',
              prop: 'zone',
              label: 'Zone',
              placeHolder: 'Not Specified',
              cardProps: {
                cardId: '_id',
                projectId: 'project._id',
              },
              inputProps: {
                options: 'zones',
                isDisabled: false,
              },
            },
          ],
        },
      ],
      linkedBomOrders: [
        {
          label: 'col1',
          fields: [
            {
              name: 'field-linked-bom',
              prop: 'materials',
              label: 'Linked BOMs',
              cardProps: {
                projectId: 'project._id',
                cardId: '_id',
              },
            },
          ],
        },
      ],
      subDetails: [
        {
          label: 'col1',
          fields: [
            {
              name: 'field-file-list',
              prop: 'simpleFiles',
              label: 'files',
            },
          ],
        },
        {
          label: 'col2',
          fields: [
            {
              name: 'field-notes',
              prop: 'manager.notes',
              label: 'Notes',
            },
          ],
        },
        {
          label: 'col3',
          fields: [
            {
              name: 'field-tags',
              prop: 'tags',
              label: 'Tags',
            },
          ],
        },
      ],
      dates: [
        {
          label: 'col1',
          fields: [
            {
              name: 'field-date',
              prop: 'coord',
              label: 'Coordination',
              hideDate: true,
              isModal: true,
              isDisabled: (data) => {
                if (['planning', 'coordination'].includes(data._customStage)) {
                  return false;
                }
                return true;
              },
            },
            {
              name: 'field-date',
              prop: 'detailBy',
              label: 'Detail',
              hideDate: false,
              isModal: true,
              isDisabled: (data) => {
                if (['delivery', 'complete'].includes(data._customStage)) {
                  return true;
                }
                return false;
              },
            },
            {
              name: 'field-date',
              prop: 'manufactureBy',
              label: 'Manufacture',
              hideDate: true,
              isModal: true,
              isDisabled: (data) => {
                if (['delivery', 'complete'].includes(data._customStage)) {
                  return true;
                }
                return false;
              },
            },
            {
              name: 'field-date',
              prop: 'qaBy',
              label: 'QA/QC',
              hideDate: true,
              isModal: true,
              isDisabled: (data) => {
                if (['delivery', 'complete'].includes(data._customStage)) {
                  return true;
                }
                return false;
              },
            },
            {
              name: 'field-date',
              prop: 'deliver',
              label: 'Onsite',
              hideDate: false,
              isModal: true,
              isDisabled: (data) => {
                if (['delivery', 'complete'].includes(data.order?._customStage || data._customStage)) {
                  return true;
                }
                return false;
              },
            },
          ],
        },
      ],
      productivity: [
        {
          label: 'col1',
          fields: [
            {
              name: 'field-generic-input',
              prop: 'totalPlannedHrs',
              label: 'Planned Time (hrs:min:sec)',
              isInput: false,
            },
            {
              name: 'field-generic-input',
              prop: 'totalConsumedHrs',
              label: 'Actual Time (hrs:min:sec)',
              isInput: false,
            },
            {
              name: 'field-generic-input',
              prop: 'totalItems',
              label: 'Total Items',
              isInput: false,
            },
            {
              name: 'field-generic-input',
              prop: 'manager.stats.itemPerc',
              label: '% Done',
              showPercentage: true,
              isInput: false,
            },
          ],
        },
      ],
      calculated: [
        {
          label: 'col1',
          fields: [
            {
              name: 'field-weld',
              prop: 'specification',
              label: 'Weld Length',
              cardProps: {
                projectId: 'project._id',
              },
            },
          ]
        }
      ],
    },
    ...managerTabs(true, 0, 'manager-edit-detailing', true),
  },
  'manager-edit-manufacturing': {
    hideMainHeader: true,
    customSubHeader: true,
    showSearch: false,
    hideSubHeader: true,
    hideTitleBar: true,
    activityModule: 'ProductionOrder',
    excelColumns: poItemsExcelCols,
    permissionModule: 'production-manager',
    isCardView: true,
    cardSubHeader: {
      actions: baseCardEditHeader(),
      backBtnText: 'Production Tracking',
    },
    cardBasics: {
      basicDetails: [
        {
          label: 'col1',
          fields: [
            {
              name: 'field-user-select',
              prop: 'manager.owner.user',
              titleLabel: 'Owner',
              cardProps: {
                companyId: 'manager.owner.company._id',
                projectId: 'project._id',
              },
            },
            {
              name: 'field-generic-input',
              prop: 'activity',
              label: 'Activity',
              placeHolder: 'Add Activity',
            },
          ],
        },
        {
          label: 'col2',
          fields: [
            {
              name: 'field-kit-id',
              prop: 'customId',
              label: 'Kit ID',
              cardProps: {
                projectId: 'project._id',
              },
            },
            {
              name: 'field-generic-input',
              prop: 'financialId',
              label: 'Description',
            },
            {
              name: 'field-generic-input',
              prop: 'uniqueOrderId.value',
              label: 'Order ID',
            },
          ],
        },
        {
          label: 'col3',
          fields: [
            {
              name: 'field-submittal',
              prop: 'submittal',
              label: 'Submittal',
              cardProps: {
                projectId: 'project._id',
              },
            },
          ],
        },
        {
          label: 'col4',
          fields: [
            {
              name: 'field-stage',
              prop: 'stage',
              label: 'Stage',
            },
            {
              name: 'field-status',
              prop: 'status',
              titleLabel: 'Status',
              cardProps: {
                resumeOrdersByAll: 'resumeOrdersByAll'
              }
            },
          ],
        },
      ],
      productionLocation: [
        {
          label: 'col1',
          fields: [
            {
              name: 'field-location-select',
              prop: 'manager.location',
              titleLabel: 'Facility',
              cardProps: {
                companyId: 'owner.company._id',
                projectId: 'project._id',
              },
            },
          ],
        },
      ],
      destinationLocation: [
        {
          label: 'col1',
          fields: [
            {
              name: 'field-lvl-zone',
              prop: 'level',
              label: 'Level',
              placeHolder: 'Not Specified',
              cardProps: {
                cardId: '_id',
                projectId: 'project._id',
              },
              inputProps: {
                options: 'levels',
                isDisabled: false,
              },
            },
            {
              name: 'field-lvl-zone',
              prop: 'zone',
              label: 'Zone',
              placeHolder: 'Not Specified',
              cardProps: {
                cardId: '_id',
                projectId: 'project._id',
              },
              inputProps: {
                options: 'zones',
                isDisabled: false,
              },
            },
          ],
        },
      ],
      linkedBomOrders: [
        {
          label: 'col1',
          fields: [
            {
              name: 'field-linked-bom',
              prop: 'materials',
              label: 'Linked BOMs',
              cardProps: {
                projectId: 'project._id',
                cardId: '_id',
              },
            },
          ],
        },
      ],
      subDetails: [
        {
          label: 'col1',
          fields: [
            {
              name: 'field-file-list',
              prop: 'simpleFiles',
              label: 'files',
            },
          ],
        },
        {
          label: 'col2',
          fields: [
            {
              name: 'field-notes',
              prop: 'manager.notes',
              label: 'Notes',
            },
          ],
        },
        {
          label: 'col3',
          fields: [
            {
              name: 'field-tags',
              prop: 'tags',
              label: 'Tags',
            },
          ],
        },
      ],
      dates: [
        {
          label: 'col1',
          fields: [
            {
              name: 'field-date',
              prop: 'coord',
              label: 'Coordination',
              hideDate: true,
              isDisabled: true,
              isModal: true,
            },
            {
              name: 'field-date',
              prop: 'detailBy',
              label: 'Detail',
              hideDate: true,
              isDisabled: true,
              isModal: true,
            },
            {
              name: 'field-date',
              prop: 'manufactureBy',
              label: 'Manufacture',
              hideDate: false,
              isModal: true,
            },
            {
              name: 'field-date',
              prop: 'qaBy',
              label: 'QA/QC',
              hideDate: true,
              isModal: true,
            },
            {
              name: 'field-date',
              prop: 'deliver',
              label: 'Onsite',
              hideDate: false,
              isModal: true,
            },
          ],
        },
      ],
      productivity: [
        {
          label: 'col1',
          fields: [
            {
              name: 'field-generic-input',
              prop: 'totalPlannedHrs',
              label: 'Planned Time (hrs:min)',
              isInput: false,
            },
            {
              name: 'field-generic-input',
              prop: 'totalConsumedHrs',
              label: 'Actual Time (hrs:min)',
              isInput: false,
            },
            {
              name: 'field-generic-input',
              prop: 'totalItems',
              label: 'Total Items',
              isInput: false,
            },
            {
              name: 'field-generic-input',
              prop: 'manager.stats.itemPerc',
              label: '% Done',
              isInput: false,
            },
          ],
        },
      ],
      calculated: [
        {
          label: 'col1',
          fields: [
            {
              name: 'field-weld',
              prop: 'specification',
              label: 'Weld Length',
              cardProps: {
                projectId: 'project._id',
              },
            },
          ]
        }
      ],
    },
    ...managerTabs(true, 0, 'manager-edit-manufacturing', true),
  },
  'manager-edit-qa': {
    hideMainHeader: true,
    customSubHeader: true,
    showSearch: false,
    hideSubHeader: true,
    hideTitleBar: true,
    activityModule: 'ProductionOrder',
    excelColumns: poItemsExcelCols,
    permissionModule: 'production-manager',
    isCardView: true,
    cardSubHeader: {
      actions: baseCardEditHeader(),
      backBtnText: 'Production Tracking',
    },
    cardBasics: {
      basicDetails: [
        {
          label: 'col1',
          fields: [
            {
              name: 'field-user-select',
              prop: 'manager.owner.user',
              titleLabel: 'Owner',
              cardProps: {
                companyId: 'manager.owner.company._id',
                projectId: 'project._id',
              },
            },
            {
              name: 'field-generic-input',
              prop: 'activity',
              label: 'Activity',
              placeHolder: 'Add Activity',
            },
          ],
        },
        {
          label: 'col2',
          fields: [
            {
              name: 'field-kit-id',
              prop: 'customId',
              label: 'Kit ID',
              cardProps: {
                projectId: 'project._id',
              },
            },
            {
              name: 'field-generic-input',
              prop: 'financialId',
              label: 'Description',
            },
            {
              name: 'field-generic-input',
              prop: 'uniqueOrderId.value',
              label: 'Order ID',
            },
          ],
        },
        {
          label: 'col3',
          fields: [
            {
              name: 'field-submittal',
              prop: 'submittal',
              label: 'Submittal',
              cardProps: {
                projectId: 'project._id',
              },
            },
          ],
        },
        {
          label: 'col4',
          fields: [
            {
              name: 'field-stage',
              prop: 'stage',
              label: 'Stage',
            },
            {
              name: 'field-status',
              prop: 'status',
              titleLabel: 'Status',
              cardProps: {
                stage: 'stage',
              }
            },
          ],
        },
      ],
      productionLocation: [
        {
          label: 'col1',
          fields: [
            {
              name: 'field-location-select',
              prop: 'manager.location',
              titleLabel: 'Facility',
              cardProps: {
                companyId: 'owner.company._id',
                projectId: 'project._id',
              },
            },
          ],
        },
      ],
      destinationLocation: [
        {
          label: 'col1',
          fields: [
            {
              name: 'field-lvl-zone',
              prop: 'level',
              label: 'Level',
              placeHolder: 'Not Specified',
              cardProps: {
                cardId: '_id',
                projectId: 'project._id',
              },
              inputProps: {
                options: 'levels',
                isDisabled: false,
              },
            },
            {
              name: 'field-lvl-zone',
              prop: 'zone',
              label: 'Zone',
              placeHolder: 'Not Specified',
              cardProps: {
                cardId: '_id',
                projectId: 'project._id',
              },
              inputProps: {
                options: 'zones',
                isDisabled: false,
              },
            },
          ],
        },
      ],
      linkedBomOrders: [
        {
          label: 'col1',
          fields: [
            {
              name: 'field-linked-bom',
              prop: 'materials',
              label: 'Linked BOM',
              cardProps: {
                projectId: 'project._id',
              },
            },
          ],
        },
      ],
      subDetails: [
        {
          label: 'col1',
          fields: [
            {
              name: 'field-file-list',
              prop: 'simpleFiles',
              label: 'files',
            },
          ],
        },
        {
          label: 'col2',
          fields: [
            {
              name: 'field-notes',
              prop: 'manager.notes',
              label: 'Notes',
            },
          ],
        },
        {
          label: 'col3',
          fields: [
            {
              name: 'field-tags',
              prop: 'tags',
              label: 'Tags',
            },
          ],
        },
      ],
      dates: [
        {
          label: 'col1',
          fields: [
            {
              name: 'field-date',
              prop: 'coord',
              label: 'Coordination',
              hideDate: true,
              isDisabled: true,
              isModal: true,
            },
            {
              name: 'field-date',
              prop: 'detailBy',
              label: 'Detail',
              hideDate: true,
              isDisabled: true,
              isModal: true,
            },
            {
              name: 'field-date',
              prop: 'manufactureBy',
              label: 'Manufacture',
              hideDate: true,
              isDisabled: true,
              isModal: true,
            },
            {
              name: 'field-date',
              prop: 'qaBy',
              label: 'QA/QC',
              hideDate: false,
              isModal: true,
            },
            {
              name: 'field-date',
              prop: 'deliver',
              label: 'Onsite',
              hideDate: false,
              isModal: true,
            },
          ],
        },
      ],
      productivity: [
        {
          label: 'col1',
          fields: [
            {
              name: 'field-generic-input',
              prop: 'totalPlannedHrs',
              label: 'Planned Time (hrs:min)',
              isInput: false,
            },
            {
              name: 'field-generic-input',
              prop: 'totalConsumedHrs',
              label: 'Actual Time (hrs:min)',
              isInput: false,
            },
            {
              name: 'field-generic-input',
              prop: 'totalItems',
              label: 'Total Items',
              isInput: false,
            },
            {
              name: 'field-generic-input',
              prop: 'manager.stats.itemPerc',
              label: '% Done',
              isInput: false,
            },
          ],
        },
      ],
      calculated: [
        {
          label: 'col1',
          fields: [
            {
              name: 'field-weld',
              prop: 'specification',
              label: 'Weld Length',
              cardProps: {
                projectId: 'project._id',
              },
            },
          ]
        }
      ],
    },
    ...managerTabs(true, 0, 'manager-edit-qa', true),
  },
  'transfer-request-edit': {
    ...simpleTab(),
    hideMainHeader: true,
    customSubHeader: true,
    showSearch: false,
    hideSubHeader: true,
    hideTitleBar: true,
    activityModule: 'TransferRequest',
    permissionModule: 'inventory',
    moduleKey: 'logistics',
    isCardView: true,
    cardSubHeader: {
      actions: {
        ...baseCardEditHeader(),
        subscribe: false,
        qrPrint: false,
        notifyUsers: false,
      },
      backBtnText: 'Transfer Requests',
    },
  },
  forms: {
    ...simpleTab(),
    cols: formsListCols.fields,
  },
  notifications: {
    ...simpleTab(),
    cols: colsMap.notificationCols.fields,
  },
  'recentEvents-stage-view': {
    ...simpleTab(),
    cols: StageChangeViewCols.fields,
  },
  'recentEvents-owner-view': {
    ...simpleTab(),
    cols: OwnerChangeViewCols.fields,
  },
  'recentEvents-date-view': {
    ...simpleTab(),
    cols: DateChangeViewCols.fields,
  },
  assemblies: {
    ...simpleTab(),
    ...assembliesTab(),
    cols: assemblyCols.fields,
    activityModule: 'Company',
  },
  parts: {
    ...simpleTab(),
    ...partsTab(),
    cols: partsCols.fields,
    activityModule: 'Company',
  },
  tasks: {
    ...simpleTab(),
    activityModule: 'Tasks',
    cols: colsMap.tasks.fields,
  },
  resetPassword: {
    hideMainHeader: true,
  },
  mfPublic: {
    hideMainHeader: true,
  },
  signup: {
    hideMainHeader: true,
  },
  thankyou: {
    hideMainHeader: true,
  },
  userActivation: {
    hideMainHeader: true,
  },
  'qc-formBuilder-template-edit': {
    hideMainHeader: true,
  },
};
