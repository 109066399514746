<template>
  <div>
    <VDropdown placement="right" :shown="isOpen" :autoHide="false">
      <template #popper>
        <div class="card">
          <header class="card-header has-background-grey-lighter">
            <h4 class="card-header-title is-size-3 has-text-black-bis
                has-text-weight-bold is-paddingless">
              {{ docToRename.type === 'url' ? 'Edit URL' : (docToRename.type === 'file' ? 'Edit Document' : 'Edit URL') }}
            </h4>
          </header>
          <section v-if="isPrefabDoc" class="card-content is-marginless has-background-white">
            <div class="column has-text-danger is-paddingless">{{ `This ${docToRename.type === 'file' ? 'document' : 'URL'} must be edited in its original Prefab Package `}}</div>
          </section>
          <section v-else class="card-content is-marginless has-background-white">
            <div class="control" v-if="docToRename.type !== 'file'">
              <span class="is-size-6 is-italic has-text-weight-normal is-pulled-right
                  has-text-grey">
                (include http:// or https://)
              </span>
              <div class="field mb-5">
                <h3 class="is-size-3 has-text-black-bis">URL</h3>
                <input class="input" type="url" placeholder="Type or Paste URL" v-model.trim="docUrl"
                  pattern="https?://.+" @input="resetWarning" />
              </div>
            </div>
            <div class="field">
              <div class="control">
                <div v-if="docToRename.type !== 'url'" class="mb-5">
                  <h3 class="is-size-3 has-text-black-bis">Document Name</h3>
                  <h3 class="is-size-3 has-text-black-bis has-text-weight-normal ">{{ docToRename.name }}</h3>
                </div>
                <h3 class="is-size-3 has-text-black-bis">Display Name</h3>
                <div class="is-flex is-justify-content-space-between">
                  <input class="input mb-5 mr-2" type="text" v-model.trim="docName" @input="resetWarning" />
                  <label class="line-height has-text-black-bis is-size-3">{{ docToRename.type === 'file' ?
                    (docToRename.name.includes('.') ? '.' + docToRename.name.split('.').pop() : '') : '' }}</label>
                </div>
              </div>
            </div>
            <div class="column has-text-danger is-paddingless" v-if="warn">{{ warn }}</div>
          </section>
          <footer class="card-footer is-justify-content-flex-end
            has-background-grey-lighter">
            <div class="buttons">
              <button class="button is-outlined has-text-weight-bold is-size-5" @click="closeModal">
                Cancel
              </button>
              <button v-if="!isPrefabDoc" class="button is-success has-text-weight-bold is-size-5" @click="editDoc"
                :disabled="docName.length === 0">
                Save Edits
              </button>
              <button v-if="isPrefabDoc" 
                class="button is-success has-text-weight-bold is-size-5" 
                @click="openPrefab">
                Open Prefab Package
              </button>
            </div>
          </footer>
        </div>
      </template>
    </VDropdown>
    <div class="file-lists">
      <div class="columns mf-file is-marginless" v-for="file in files" :key="file.key">
        <div class="column pt-0 pr-0">
          <div v-if="file.type === 'sheet'">
            <i class="is-pulled-left" :class="['fas', isPublic(file) ?
              'icon-documents' : 'fa-building icon-link-lock']"></i>
            <a :href="getLmvUrl(file)" @click="clicked($event, file)">
              <span class="is-size-3 has-text-weight-normal text-overflow p-1">
                {{ file.name }}
              </span>
            </a>
          </div>
          <div v-else-if="file.type === 'url'
            || (isQaQc && file.docType === 'url')">
            <i class="is-pulled-left" :class="['icon-url', isPublic(file) ? 'has-text-black-bis'
              : 'has-text-danger']"></i>
            <a :href="file.url" target="_blank" @click="clicked($event)" class="tag-reverse file-link">
              <span class="is-size-3 has-text-weight-normal text-overflow p-1">
                <span v-if="file.name">{{ file.name }}</span>
                <span v-else>{{ file.url }}</span>
              </span>
            </a>
          </div>
          <div v-else-if="file.type === 'form'">
            <i class="is-pulled-left" :class="['icon-manufactonform', isPublic(file) ? 'has-text-black-bis'
              : 'has-text-danger']"></i>
            <a @click="printForm({ file: file, isHeadless: false, event: $event })" v-tooltip="file.name">
              <span class="is-size-3 has-text-weight-normal text-overflow p-1">
                {{ file.name }}
              </span>
            </a>
          </div>
          <div v-else>
            <i class="is-pulled-left" :class="['icon-documents', isPublic(file) ? 'has-text-black-bis'
              : 'has-text-danger']"></i>
            <a class="" @click="downloadFile($event, file)" v-tooltip="file.name">
              <span class="is-size-3 has-text-weight-normal text-overflow p-1">
                {{ file.name }}
              </span>
            </a>
          </div>
        </div>
        <div class="column is-3 pt-0 pr-0">
          <div class="buttons is-pulled-right is-hidden">
            <button class="button" v-if="!checklistView && !hideFormPrint && file.type === 'form'"
              @click.stop="printForm({ file: file, isHeadless: true, event: $event })">
              <i class="icon-print"></i>
            </button>
            <button class="button" v-if="file.type === 'url' || file.type === 'file'" @click.prevent="openModal(file)">
              <i class="icon-edit"></i>
            </button>
            <button class="button " v-if="isClosable(file) && !isDisabled" @click="emit('close', file)">
              <i class="icon-removedelete"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <form-preview v-if="openForm" :viewForm="openForm" :editForm="false" :formCheckList="formCheckList"
      :checklistView="checklistView" :templateForm="templateForm" :formOrder="formOrder" :order="card || order"
      @close="closeForm" :isHeadless="isHeadless" :hideFormPrint="hideFormPrint" :isCreateOrderModal="isCreateOrderModal">
    </form-preview>
  </div>
</template>

<script>
import {
  defineComponent, reactive, toRefs,
} from 'vue';
import {
  isEmpty, some, keyBy, find, get, isFunction,
} from 'lodash';
import urls from '@/urls';
import Order from '@/models/Orders';
import Upload from '@/models/Upload';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import MaterialManager from '@/models/MaterialManager';
import FormPreview from '@/components/modals/FormPreview.vue';
import ShippingLabel from '@/models/ShippingLabel';
import { useToast } from 'vue-toastification';
import { CardDirtyBus } from '@/utils/CardDirtyBus';
import { useRoute } from 'vue-router';
import axios from 'axios';

export default defineComponent({
  name: 'FileList',
  props: {
    files: {
      type: Array,
      default: () => [],
    },
    closable: {
      type: [Boolean, Function],
      default: false,
    },
    projectId: {
      type: String,
    },
    checklistView: {
      type: Boolean,
      default: false,
    },
    order: {
      type: Object,
      default: () => { },
    },
    fromCard: {
      type: Boolean,
      default: false,
    },
    formChecklist: {
      type: Boolean,
      default: true,
    },
    showCloseButton: {
      type: Boolean,
      default: true,
    },
    card: Object,
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isQaQc: {
      type: Boolean,
      default: false,
    },
    hideFormPrint: {
      type: Boolean,
      default: false,
    },
    isCreateOrderModal: {
      type: Boolean,
      default: false,
    },
    fromQcModal: {
      type: Boolean,
      default: false,
    },
    isShipping: {
      type: Boolean,
      default: false,
    },
    refreshFunction: {
      type: Function,
    }
  },
  components: {
    'form-preview': FormPreview,
  },
  setup(props, { emit }) {
    const store = useStore();
    const toast = useToast();
    const router = useRouter();
    const route = useRoute();
    const {
      closable,
      projectId,
      order,
      formChecklist,
      showCloseButton,
      fromCard,
    } = props;

    const state = reactive({
      user: {},
      docToRename: {},
      docUrl: '',
      docName: '',
      warn: '',
      isOpen: false,
      indexedProjects: {},
      userCompany: {},
      templateForm: null,
      formOrder: null,
      openForm: false,
      printListForm: false,
      formEleId: null,
      isHeadless: false,
      extension: '',
      fileWithExtension: '',
      orderCard: {},
      isSL: props.order && props.order instanceof ShippingLabel,
      isPrefabDoc: false,
      prefabId: '',
    });
    state.user = store.state.userData;
    state.userCompany = get(state.user, 'company', '');
    state.indexedProjects = keyBy(
      store.state.queryParams._projects,
      '_id',
    );
    const openModal = (doc) => {
      if (order) {
        state.orderCard = order?.order || props.card;
        if (['ProductionOrder', 'Prefabs', 'Materials'].includes(order.__t) || state.isSL) {
          state.orderCard = order || props.card;
        }
      }
      state.docToRename = doc;
      if (doc.type !== 'file') {
        state.docName = doc.name;
      } else {
        state.docName = '';
      }
      if (order.__t === 'ProductionOrder' && some(doc.sources, { stage: 'planning' }) 
        && store.state.activeScreen !== 'prefab-edit') {
        state.isPrefabDoc = true;
        state.prefabId = doc.sources[0]._id;
      }
      state.docUrl = doc.url;
      state.extension = doc.name.includes('.') ? `.${doc.name.split('.').pop()}` : '';
      state.isOpen = true;
    };
    const closeModal = () => {
      state.warn = '';
      state.docName = '';
      state.docUrl = '';
      state.isOpen = false;
      state.isPrefabDoc = false;
      state.prefabId = '';
    };


    const fetchExistingOrderFileNames = () => {
      let filteredFileNames = [];
      let todofilteredFileNames = [];
      let filteredSimpleFileNames = [];
      state.orderCard = fromCard ? props.order : (props.order.order || props.card);

       // get all the order files
       if (state.orderCard.files && !isEmpty(state.orderCard.files)) {
        const filterFiles = state.orderCard.files.filter((file)=> !file.archived?.value)
        filteredFileNames = _.uniq(filterFiles.map((file) => file.name))
      }
      // get all the order todos
      if (state.orderCard.todos && !isEmpty(state.orderCard.todos)) {
        const filteredTodos = [];
        state.orderCard.todos.forEach((todo) =>{
          if (todo.files && !isEmpty(todo.files)) {
            todo.files.forEach((file) => {
              if (!file.archived.value) {
                filteredTodos.push(file);
              }
            })
          }
        })
        todofilteredFileNames = _.uniq(filteredTodos.map((file) => file.name))
      }

      // get all the simple files
      if (state.orderCard.simpleFiles && !isEmpty(state.orderCard.simpleFiles)) {
        const filteredSimpleFiles = state.orderCard.simpleFiles.filter((file) => !file.archived.value)
  
        filteredSimpleFileNames = _.uniq(filteredSimpleFiles.map((file) => file.name));
      }

      // existing uniq file names
      const existingOrderFileNames = _.uniq([...filteredFileNames, ...filteredSimpleFileNames, ...todofilteredFileNames]);
      return existingOrderFileNames;
    }

    const checkIfDuplicateExists = (fileName) => {
      const existingOrderFileNames = fetchExistingOrderFileNames();
      const finalList = [...existingOrderFileNames];
  
      finalList.push(fileName);

      // check if same file name already exists
      let duplicate = false;

      if (finalList.includes(fileName)) {        
        const filteredName = finalList.filter((key) => key == fileName);
          if (filteredName.length > 1) {
            duplicate = true;
          }
      }
      return duplicate;
    }

    const editDoc = async () => {
      const pattern = /\b(http|https)/;
      const docName = state.docName.trim();
      if (docName.length < 3) {
        state.warn = 'Document name must contain at least 3 characters!';
      } else if (state.docToRename.type !== 'file' && !pattern.test(state.docUrl)) {
        state.warn = 'Warning! Please enter a valid URL';
      } else {
        if (state.docToRename.type === 'file') {
          state.fileWithExtension = docName + state.extension;

          if (state.docToRename.name !== state.fileWithExtension) {
            const isExistingFileInOrder = checkIfDuplicateExists(state.fileWithExtension);

            if (isExistingFileInOrder) {
              toast.error('Duplicate Files Found');
              closeModal();
              return;
            }

          }
          // for shipping order we need to add this field to update file
          if (props.order) {
            state.docToRename.edited = true;
          }
          state.docToRename.name = state.fileWithExtension;
          state.docToRename.url = state.docUrl;
        }
        if (state.docToRename.type === 'url') {
          const newUrlName = docName + state.extension;

          if (state.docToRename.name !== newUrlName) {
            const isExistingUrlInOrder = checkIfDuplicateExists(newUrlName);
            if (isExistingUrlInOrder) {
              toast.error('Duplicate URL Found');
              closeModal();
              return;
            }
          }
          state.docToRename.name = newUrlName;
          state.docToRename.url = state.docUrl;
        }
        closeModal();
        CardDirtyBus.emit('setCustomDirty');
      }
      if (props.fromQcModal) {
        emit('files-updated', true)
      }
      CardDirtyBus.emit('setCustomDirty');
    };
    const isPublic = (file) => file.visible === 'to-all';

    const isClosable = (file) => {
      if (file.type === 'form' && formChecklist
        && !(isEmpty(file.formData) && showCloseButton)) {
        return false;
      } if (!isEmpty(props.card)
        && !['Prefabs'].includes(props.card.__t)
        && some(file.sources, { stage: 'planning' })
        && file.type === 'form') {
        return false;
      } if (typeof closable === 'function') {
        return closable(file);
      }
      return closable;
    };
    const getProjectId = () => {
      let ProjectId = order?.project?._id || projectId;
      if (['run', 'item'].includes(order._place)) {
        ProjectId = order.order.project._id;
      }
      return ProjectId;
    }

    const getUrl = (file) => {
      let url = [urls.s3, 'projects', getProjectId(), file.url];
      if (props.isQaQc) {
        url = [urls.s3, 'companies', store.state.userData.company, file.url];
      }
      return url.join('/').replace(/([^:])(\/{2,})/g, '$1/');
    };

    const getLmvUrl = (file) => {
      const routeName = 'lmv-view';
      const orderId = '';
      const itemId = '';
      let lmvurl = '';
      if (isEmpty(state.indexedProjects) && !isEmpty(store.state.queryParams._projects)) {
        state.indexedProjects = keyBy(
          store.state.queryParams._projects,
          '_id',
        );
      }
      if (!state.indexedProjects[projectId]) {
        return '';
      }
      const { projectSettings } = state.indexedProjects[projectId];
      const ps = find(projectSettings, (setting) => setting.companyId === state.userCompany);
      const urn = get(ps, 'forgeSync.urn', '');
      if (urn) {
        const fvParams = {
          module: 'viewer',
          urn,
          projectId,
          orderId,
          itemId,
        };
        const route = router.resolve({ name: routeName, params: fvParams });
        lmvurl = urls[urls.active] + [route.href, `?sheet=${file.url}`].join('/');
        return lmvurl;
      }
      return null;
    };

    async function downloadS3File(url, fileName) {
      try {
        const response = await axios({
          url,
          method: 'GET',
          responseType: 'blob',
        });

        // Convert the binary buffer to a Blob
        const blob = new Blob([response.data], { type: response.headers['content-type'] });

        // Create a download link
        const downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(blob);
        downloadLink.download = fileName; // Set the desired file name (fileName)

        // Programmatically click the download link to trigger the download
        downloadLink.click();

      } catch (err) {
        throw new Error(`Error downloading file: ${err.message}`);
      }
    }

    const clicked = async (e, file) => {

      if (!isEmpty(file) && !getLmvUrl(file)) toast.error('This project does not have a linked model');
      if (e.target.classList.contains('is-delete')) e.preventDefault();
    };

    const downloadFile = async (e, file) => {
      const url = getUrl(file);
      await downloadS3File(url, file.name);
      if (e.target.classList.contains('is-delete')) e.preventDefault();
    }

    const getForm = async (file, event) => {
      const doc = fromCard ? order : order.order;
      if (!event.target.classList.contains('is-delete')) {
        if (!isEmpty(file.formData)) {
          state.templateForm = file;
        } else if (!isFunction(doc.getFormOrder)) {
          if (['Materials', 'Sourcing'].includes(doc.itemKind)) {
            state.formOrder = await MaterialManager.getOne({
              cardId: file.sources[0]._id,
              projectId: doc.project._id,
            });
          } else {
            state.formOrder = await Order.get({
              orderId: file.sources[0]._id,
              projectId: doc.project._id,
            });
          }
          state.templateForm = state.formOrder.getFormOrder(file);
        } else {
          state.templateForm = doc.getFormOrder(file);
        }
        state.templateForm.project = get(doc, 'project', {});
        state.openForm = true;
      }
    };

    const resetWarning = () => {
      state.warn = '';
    };

    // toggle form modal
    const closeForm = async () => {
      state.openForm = false;
      if (props.isShipping) {
        await props.order.updateShippingDetails();
        if (props.refreshFunction) {
          props.refreshFunction();
        }
      }
      CardDirtyBus.emit('setCustomDirty');
    };

    const printForm = (eveObj) => {
      /* eveObj
      * file - file to render template for
      * isHeadless - whether its headless or not
      * event - event
      */
      const { file, event } = eveObj;
      state.isHeadless = eveObj.isHeadless;
      getForm(file, event);
    };

    const openPrefab = () => {
      const routeObj = {
          name: 'prefab-edit',
          params: {
            projectId,
            cardId: state.prefabId
          },
        };
      window.open(router.resolve(routeObj)?.href, '_blank');
      closeModal();
    }

    return {
      clicked,
      ...toRefs(state),
      isPublic,
      isClosable,
      getUrl,
      getForm,
      openModal,
      closeModal,
      editDoc,
      emit,
      getLmvUrl,
      resetWarning,
      closeForm,
      printForm,
      downloadFile,
      openPrefab,
    };
  },
});
</script>

<style scoped></style>
