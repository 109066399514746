<template>
  <h4
    class="is-size-3 has-text-black-bis"
  >
    Documents ({{ filesLength }})
    <div class="is-flex is-pulled-right">
      <span class="has-text-weight-normal is-italic is-size-5 has-text-danger mr-1"
        >(Red/Private)</span
      >
      <mf-add-file
        :order="order"
        :projectId="getProjectId"
        @docs-added="docsAdded"
        :showForm="true"
        :defaultPrivate="isDefPrivate"
      >
      </mf-add-file>
    </div>
  </h4>
  <file-list
    :key="refreshList"
    :order="order"
    :files="getAllFiles"
    :closable="isClosable"
    :projectId="getProjectId"
    :formChecklist="getFormClVal"
    @close="deleteFile"
    :fromCard="true"
    :hideFormPrint="hideFormPrint"
    :isCreateOrderModal="createOrderModal"
            :isShipping="isShipping"
            :refreshFunction="refreshFunction"
      ></file-list>
</template>

<script>
import {
  defineComponent, computed, reactive, toRefs, inject,
} from 'vue';
import FileList from '@/components/card-edit/FileList.vue';
import MfAddFile from '@/components/MfAddFile.vue';
import { useStore } from 'vuex';
import { CardDirtyBus } from '@/utils/CardDirtyBus';
import {
  find, cloneDeep, get, some, pick, isEmpty, findIndex, filter,
} from 'lodash';
import { BaseOrder } from '@/models/BaseOrder';
import ShippingLabel from '@/models/ShippingLabel';
import { DialogProgrammatic } from '@/components/Dialog';

export default defineComponent({
  components: {
    'file-list': FileList,
    'mf-add-file': MfAddFile,
  },
  props: {
    order: {
      type: Object,
      default: () => { },
    },
    prop: {
      type: String,
      default: 'simpleFiles',
    },
    isShipping: {
      type: Boolean,
      default: false,
    },
    refreshFunction: {
      type: Function,
    },
    type: {
      type: String,
      default: '',
    },
    createOrderModal: {
      type: Boolean,
      default: false,
    },
    hideFormPrint: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { type } = props;
    const store = useStore();
    const emitter = inject('emitter');
    const state = reactive({
      user: {},
      refreshList: 0,
      isBO: props.order && props.order instanceof BaseOrder,
      isSL: props.order && props.order instanceof ShippingLabel,
    });

    const { isBO, isSL } = state;
    state.user = store.state.userData;

    // computed
    const getAllFiles = computed(() => {
      if (isBO) {
        let files = get(props, `order.${props.prop}`, []);
        files = files.filter((f) => !f.archived.value);
        if (isBO && props.order && !props.order.isPM()) return files;
        if (props.order?.stage === 'qa') return files;
        return files.filter((f) => some(f.sources, (s) => s.stage !== 'qa'));
      } if (isSL && props.order && props.order.delivery) return get(props, `order.${props.prop}`, []);
      if (type === 'pauseRestartModal') {
        let todoFiles = get(props, 'order.files', []);
        todoFiles = todoFiles.filter((file) => !file.archived.value);
        return todoFiles;
      }
      return [];
    });
    const filesLength = computed(() => getAllFiles.value.length || 0);

    const isDefPrivate = computed(() => isBO && props.order && props.order.isPM());

    const getProjectId = computed(() => {
      if (isBO) {
        return get(props, 'order.project._id', '');
      } if (isSL) {
        return get(props.order, 'delivery.currentProject._id', '');
      }
      return '';
    });

    const getFormClVal = computed(() => {
      if (isSL) return false;
      return true;
    });

    // methods 
    const deleteFile = async (fileToDelete) => {
      // TODO: should add dialog for file removing.
      const confirmParam = {
        title: 'DELETE [THIS DATA]',
        message: 'Are you sure you want to delete document ? This can not be recovered',
        okButton: 'Delete',
        cancelButton: 'Cancel',
        type: 'danger',
        onConfirm: () => {  
          if (isBO) {
            const removingFile = find(props.order.simpleFiles, (file) => {
              if (!fileToDelete.uuid) return file._id === fileToDelete._id;
              return file.uuid === fileToDelete.uuid;
            });
            removingFile.archived.value = true;

            if (props.createOrderModal || ['file', 'url'].includes(removingFile.type)) {
              emit('delete-file', removingFile);
            }
            if (removingFile.type === 'form') {
              props.order.deleteForm({ doc: removingFile, rowData: props.order });
            }
            if (removingFile.type === 'form') {
              const removingCheckList = find(props.order.todos, (todo) => {
                const filteredFiles = todo.files.filter((file) => file.uuid === fileToDelete.uuid);
                if (filteredFiles.length) {
                  return todo;
                }
              });
              removingCheckList.archived.value = true;
              if (removingCheckList.type === 'form') {
                props.order.deleteForm({ doc: removingCheckList, rowData: props.order });
              }

              // emit to refresh task table
              emitter.emit('deletedForm');
            }
          }
          if (isSL) {
            if (props.order && props.order.delivery) {
              const removingFile = find(props.order.files, (file) => {
                if (!file.uuid) return file._id === fileToDelete._id;
                return file.uuid === fileToDelete.uuid;
              });
              if (removingFile.uuid) {
                // eslint-disable-next-line vue/no-mutating-props
                props.order.files = filter(props.order.files, (file) => {
                  return file.uuid !== removingFile.uuid;
                });
              } else {
                removingFile.archived.value = true;
              }
            }
          }
          if (type === 'pauseRestartModal') {
            const index = findIndex(props.order.files, {
              uuid: fileToDelete.uuid,
            });
            // eslint-disable-next-line vue/no-mutating-props
            props.order.files[index].archived.value = true;
          }
          CardDirtyBus.emit('setCustomDirty');
        },
      };
      DialogProgrammatic.confirm(confirmParam);
    };

    const docsAdded = (docs) => {
      for (const doc of docs) {
        if (isBO) {
          const file = props.order.newFile();
          const docCopy = cloneDeep(doc); // _.assign(props.rowData.todos[clIndex], checklist);
          delete doc.formData;
          Object.assign(file, doc);
          if (doc.type === 'form') {
            props.order.addCheckListToForm(
              { doc: docCopy, rowData: props.order },
              state.user,
            );
          }
        } else if (isSL) {
          const file = pick(doc, ['name', 'type', 'visible', 'url', 'uuid', 'copiedFrom', 'formData']);
          file.sources = [{
            type: 'shipping',
          }];
          file.archived = {
            value: false, // _.assign(props.rowData.todos[clIndex], checklist);
          };
          if (props.order && props.order.delivery) {
            if (isEmpty(props.order.files)) {
              // eslint-disable-next-line vue/no-mutating-props
              props.order.files = [file];
            } else {
              // eslint-disable-next-line vue/no-mutating-props
              props.order.files.push(file);
            }
          }
        } else if (type === 'pauseRestartModal') {
          const file = pick(doc, ['name', 'type', 'visible', 'url', 'uuid', 'formData', 'copiedFrom']);
          file.archived = {
            value: false,
          };
          const { order } = props;
          // eslint-disable-next-line no-unused-expressions
          isEmpty(order.files) ? order.files = [file] : order.files.push(file);
        }
      }
      emitter.emit('emitGetAllFiles', getAllFiles.value);
    };

    const isClosable = (file) => {
      if (isSL) {
        return !file.master;
      } if (isBO && some(file.sources, { stage: 'planning' })) {
        const { order } = props;
        return order.stage === 'planning'
         || order._customStage === 'planning'
         || file.type === 'form';
      }
      return true;
    };

    return {
      filesLength,
      deleteFile,
      docsAdded,
      getAllFiles,
      ...toRefs(state),
      isDefPrivate,
      getProjectId,
      isClosable,
      getFormClVal,
    };
  },
});
</script>
<style scoped>
.is-flex.has-text-danger span {
  padding-top: 1px;
}
</style>
