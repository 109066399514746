<template>
  <div>
    <div class="modal-card">
        <header class="modal-card-head">
          <h4 class="modal-card-title">Create New Delivery</h4>
          <div class="is-divider-vertical"></div>
          <i class="icon-close is-clickable" @click="$emit('close')"></i>
        </header>
        <section class="modal-card-body is-size-2 is-marginless">
          <div class="columns">
            <div class="column">
              <h4 class="has-text-black-bis is-italic is-size-3 line-height">
                New Delivery Details
              </h4>
              <div class="field">
                <div class="field-label">
                  <label class="label">Name *</label>
                </div>
                <div class="field-body">
                  <input v-model="deliveryName" class="input" type="text"
                        placeholder="Enter name">
                </div>
              </div>
              <div class="field">
                <div class="field-label">
                  <label class="label">Tracking #</label>
                </div>
                <div class="field-body">
                  <input v-model="deliveryTrackingId" class="input" type="text"
                    placeholder="Enter Tracking Id">
                </div>
              </div>
            </div>
            <div class="column">
              <h4 class="has-text-black-bis is-italic is-size-3 line-height">
                Dates
              </h4>
              <div class="field">
                <div class="field-label">
                  <label class="label">Ship By *</label>
                </div>
                <div class="field-body">
                  <div class="line-height has-text-black-bis">
                    <mf-date
                      :item="card"
                      :input-props="{
                          kind: '_delivery.deliveryStart',
                          max: '_delivery.deliverBy',
                        }"
                      :is-edit="true"
                    ></mf-date>
                  </div>
                </div>
              </div>
              <div class="field">
                <div class="field-label">
                  <label class="label">Deliver By *</label>
                </div>
                 <div class="field-body">
                    <mf-date
                      :item="card"
                      :input-props="{
                          kind: '_delivery.deliverBy',
                          min: '_delivery.deliveryStart'
                      }"
                      :is-edit="true"
                    >
                    </mf-date>
                  </div>
                </div>
              </div>
          </div>
          <search-bar
            :shouldEmit="true"
            @search="getSearchValue"
            placeholder="Search"
          >
          </search-bar>
          <div class="table-container delivery-col">
            <mf-table
              :tableProps="tableProps"
              :apiMode="false"
              :loadData="loadData"
              :hideGutter="true"
              :checkedRows="checkedRow"
              @checkbox-toggled="selectedRows"
              ref="deliveryItemsTable"
            >
              <template v-slot:qty-available="{ rowData : item }">
                <label>
                  {{ item?.quantity - item?.selectedQty }}
                </label>
              </template>
              <template v-slot:qty-needed="{ rowData : item }">
                <qty-input
                  :value="0"  @update:value="itemQtyChanged($event, item)"
                  :roundTo="2" :max="maxQtyAllowed(item)"
                >
                </qty-input>
              </template>
            </mf-table>
          </div>
        </section>
        <footer class="modal-card-foot is-justify-content-flex-end">
          <button class="button is-outlined" @click="cancel()">Cancel</button>
          <button
            class="button has-background-black-bis"
            @click="createDelivery()"
            :disabled="isDisabled"
          >Create</button>
        </footer>
      </div>
      <o-loading
        :full-page="false"
        :active="isLoading"
        :can-cancel="true"
      ></o-loading>
    </div>
</template>
<script>
import {
  defineComponent, defineAsyncComponent, reactive, toRefs, ref, computed, inject,
} from 'vue';
import {
  isEmpty, filter, set, cloneDeep,
} from 'lodash';

import MfDate from '@/components/abstract/MfDate.vue';
import tableDefinition from '@/components/table-cols/createShippingDeliveryCols';
import { useToast } from 'vue-toastification';
import QtyInput from '@/components/fields/QtyInput.vue';
import SearchBar from '@/components/SearchBar.vue';
import ShippingLabel from '@/models/ShippingLabel';
import AddRow from '@/components/utils/AddRow';

const MfTable = defineAsyncComponent(() => import('@/components/table-fields/MfTable.vue'));

export default defineComponent({
  name: 'CreateDeliveries',
  components: {
    MfTable,
    QtyInput,
    SearchBar,
    MfDate,
  },
  props: {
    card: Object,
    masterShipment: Object,
    isActive: Boolean,
  },
  setup(props, { emit }) {
    const tableProps = tableDefinition;
    const toast = useToast();
    const emitter = inject('emitter');
    const deliveryItemsTable = ref(null);
    const state = reactive({
      searchText: '',
      selectedItems: [],
      checkedRow: [],
      deliveryName: '',
      deliveryTrackingId: '',
      isLoading: false,
      card: props.card,
      masterShipment: {},
    });

    // set masterShipment
    const { masterShipment } = props;
    state.masterShipment = masterShipment;

    const loadData = () => {
      let fetchedData;
      fetchedData = state.card.items;
      fetchedData.forEach((item) => {
        item.selectedQty = 0;
      });

      // search
      if (fetchedData.length > 0 && state.searchText) {
        fetchedData = filter(fetchedData,
          (item) => item.name.toLowerCase().includes(state.searchText.toLowerCase()));
      }

      state.checkedRow = fetchedData;
      state.selectedItems = fetchedData;
      return fetchedData;
    };

    const cancel = () => {
      emit('close');
    };
    const selectedRows = (rows) => {
      state.selectedItems = rows;
    };

    const getSearchValue = (searchText) => {
      state.searchText = searchText;
      deliveryItemsTable.value.refreshTable();
    };

    const itemQtyChanged = ((newQty, item) => {
      item.selectedQty = newQty;
      if (_.isEmpty(_.find(state.selectedItems, { _id: item._id }))) {
        state.selectedItems.push(item);
      }
    });

    const maxQtyAllowed = (item) => item.quantity;

    const addItemsFromMaster = () => {
      const itemsToAdd = [];
      for (const item of state.selectedItems) {
        const newSItem = cloneDeep(item);
        newSItem.quantity = item.selectedQty;
        newSItem.isUsed = true;
        Object.assign(newSItem, {
          id: newSItem._id + state.masterShipment?._id,
          isMasterItem: true,
          selected: true,
          showInput: true,
          selectedQty: newSItem.quantity,
          isEditing: true,
          isTemporaryAddition: true,
        });
        itemsToAdd.push(newSItem);
      }
      return itemsToAdd;
    };

    const createDelivery = async () => {
      state.isLoading = true;
      const emptyRow = AddRow.createEmptyRow(tableDefinition);

      state.card._delivery.trackingId = state.deliveryTrackingId;
      state.deliveryTrackingId = state.card._delivery.trackingId;

      const deliveryObj = Object.assign(state.masterShipment.delivery, {
        deliverBy: state.card._delivery.deliverBy,
        deliveryStart: state.card._delivery.deliveryStart,
        trackingId: state.card._delivery.trackingId,
      });

      // create new del
      const newPartial = new ShippingLabel(Object.assign(emptyRow, {
        name: state.deliveryName,
        delivery: deliveryObj,
        status: 'not-started',
        project: state.masterShipment.project,
        trackingId: state.card._delivery.trackingId,
      }));
      newPartial.files = [];

      const itemsToBeAdded = addItemsFromMaster();
      if (itemsToBeAdded.length) {
        try {
          for (const item of itemsToBeAdded) {
            if (item.selected && item.isMasterItem) {
              newPartial.addItemFromShipment(item, state.masterShipment, item.selectedQty);
            }
          }
        } catch (e) {
          console.log('Failed to addItems', e);
        }
      }

      // save the shipment
      try {
        // save p2
        const updatedShipment = await newPartial.save();
        set(updatedShipment, 'isEditing', false);
        state.masterShipment.partialShipments.push(updatedShipment);
        // save mastershipment
        await state.masterShipment.save();
      } catch (e) {
        console.log('Failed to save shipment', e);
      }

      toast.success('Created delivery successfully...');
      state.isLoading = false;
      emit('close');
      emitter.emit('refreshDelTable');
    };

    const isDisabled = computed(() => {
      // check if any item is selected
      const isNoItemsSelected = isEmpty(state.selectedItems);
      const isNameNotProvided = isEmpty(state.deliveryName);

      // check if any selected item has selectedQty <= 0
      const itemsWithNoSelectedQty = state.selectedItems.filter((item) => item.selectedQty === 0);
      const isReqDatesSelected = !isEmpty(state.card?._delivery.deliverBy) && !isEmpty(state.card?._delivery.deliveryStart);
      if (isNameNotProvided || isNoItemsSelected || itemsWithNoSelectedQty.length > 0 || !isReqDatesSelected) {
        return true;
      }
      return false;
    });

    return {
      ...toRefs(state),
      tableProps,
      loadData,
      isEmpty,
      cancel,
      selectedRows,
      createDelivery,
      getSearchValue,
      deliveryItemsTable,
      itemQtyChanged,
      maxQtyAllowed,
      isDisabled,
    };
  },
});
</script>
