export default {
  'TEMPLATE ID': {
    field: 'templateId',
    multiple: false,
    required: true,
  },
  'ORDER NAME': {
    field: 'name',
    multiple: false,
    required: true,
  },
  LOCATION: {
    field: 'location',
    multiple: false,
    required: true,
  },
  DETAILBY: {
    field: 'poDetailBy',
    multiple: false,
    required: true,
  },
  ONSITE: {
    field: 'deliver',
    multiple: false,
    required: true,
  },
  'ORDER ID': {
    field: 'uniqueOrderId',
    multiple: false,
  },
  'DESCRIPTION': {
    field: 'financialId',
    multiple: false,
  },
  MANUFACTUREBY: {
    field: 'poManufactureBy',
    multiple: false,
  },
  'DOCUMENT NAMES': {
    field: 'docNames',
  },
  DOCUMENTS: {
    field: 'documents',
  },
  NOTES: {
    field: 'note',
    multiple: false,
  },
};
