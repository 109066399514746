<template>
  <div>
    <div class="modal-card">
      <header>
        <div class="modal-card-head">
          <h4 class="modal-card-title">{{title}}</h4>
          <div class="is-divider-vertical"></div>
          <i class="icon-close is-clickable" @click="cancel()"></i>
        </div>
        <div class="header-note" v-if="!isSubstitute">
          <span class="is-size-5 has-text-white"><b> Note : </b>{{ msgToDisplay }}</span>
        </div>
      </header>
      <section class="modal-card-body is-size-2 is-marginless">
        <div class="columns" v-if="isSubstitute">
          <div class="column">
            <div class="field mb-0">
              <div class="field-label">
                <label class="label is-size-3"> Item To Substitute </label>
              </div>
              <div class="field-body has-text-black-bis">
                {{ substituteItem }}
              </div>
            </div>
            <span>
            </span>
          </div>
          <div class="column">
            <div class="field mb-0">
              <div class="field-label">
                <label class="label is-size-3"> Selected Substitution </label>
              </div>
              <div class="field-body is-size-3">
                <span v-if="!selectedRow.name" class="is-italic">
                  Nothing selected
                </span>
                <span v-else class="has-text-black-bis">
                  {{selectedRow.name}}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="columns is-marginless">
          <div class="column p-0">
            <search-bar
              :shouldEmit="true"
              @search="getSearchValue($event)"
              placeholder="Search"
            >
            </search-bar>
          </div>
          <div class="is-divider-vertical"></div>
          <div class="column p-0 is-narrow">
            <button v-if="showButtonForPartsAdding" class="button px-0">
              <i class="icon-addnew" @click="openPartNewModal()"></i>
            </button>
          </div>
        </div>
        <div class="table-container">
          <mf-table
            :tableProps="tableProps"
            :loadData="loadData"
            :hideGutter="true"
            @row-select="getSelectedRows($event)"
            @checkbox-toggled="getSelectedRows"
            ref="itemTable"
            :checkedRows="selectedItems"
            :key="loadTable"
            :selectedRow="selectedRow"
            :limitSelection="itemsCanSelect"
          >
          </mf-table>
        </div>
      </section>
      <footer class="modal-card-foot is-justify-content-flex-end">
        <button class="button is-outlined" @click="cancel()">Cancel</button>
        <button
          class="button has-background-black-bis"
          @click="addItems"
          :disabled="!selectDisable"
        >Select</button>
      </footer>
    </div>
    <o-loading
      :full-page="false"
      :active="isLoading"
      :can-cancel="true"
    ></o-loading>
  </div>
</template>

<script>
import {
  defineComponent, reactive, toRefs, ref, computed,
} from 'vue';
import tableDefinition from '@/components/table-cols/catalogListCols';
import subituteItem from '@/components/table-cols/subtituteItemCols';
import Catalogs from '@/models/Catalogs';
import {
  cloneDeep, isEmpty, get, map,
} from 'lodash';
import SearchBar from '@/components/SearchBar.vue';
import MfTable from '@/components/table-fields/MfTable.vue';
import PartsAddToCatalog from '@/components/modals/PartsAddToCatalog.vue';
import { ModalProgrammatic } from '@oruga-ui/oruga-next';
import { useRoute } from 'vue-router';
import { useToast } from 'vue-toastification';

export default defineComponent({
  name: 'AddFromCatalog',
  components: {
    MfTable,
    SearchBar,
  },
  props: {
    module: String,
    projectId: String,
    companyId: String,
    maxAddable: Number,
    isSubstitute: Boolean,
    substituteItem: String,
    title: String,
    substitutedItem: {
      type: Object,
      default: () => {},
    },
    rowData: {
      type: Object,
      required: false,
      default: () => {},
    },
    row: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const itemTable = ref(null);
    const toast = useToast();
    const route = useRoute();
    const state = reactive({
      tableProps: props.isSubstitute ? subituteItem : tableDefinition,
      searchKey: '',
      maxCount: props.maxAddable ? props.maxAddable : 500,
      selectedItems: [],
      isLoading: false,
      tableData: [],
      loadTable: 0,
      selectedRow: {},
      prevSelectedItemCount: ((props.rowData?.items?.length) ? props.rowData?.items?.length : 0),
    });
    const itemsCanSelect = computed(() => (state.maxCount - state.prevSelectedItemCount));

    const msgToDisplay = computed(() => `You can select ${itemsCanSelect.value - state.selectedItems.length} more items`);
    const selectDisable = computed(() => ((!isEmpty(state.selectedRow)
      || !isEmpty(state.selectedItems)) && (state.selectedItems.length <= itemsCanSelect.value)));
    const getSelectedRows = (items) => {
      if (props.isSubstitute) {
        state.selectedRow = items;
      } else {
        state.selectedItems = items;
      }
    };

    const getSortField = () => {
      const sortField = itemTable.value?.sort || '';
      if (sortField !== '') sortField[0].field = get(itemTable.value, 'sort[0].sortField', '');
      return sortField;
    };

    const loadData = async () => {
      const queryParams = {
        limit: 1000,
        search: state.searchKey,
        type: ['Prefabs', 'ProductionOrder'].includes(props.module) ? 'assembly' : 'parts',
        sort: getSortField(),
      };
      state.tableData = await Catalogs.getAssemblyParts(queryParams);
      return state.tableData;
    };
    const cancel = () => {
      emit('close', false);
    };
    const getSearchValue = async (searchText) => {
      state.searchKey = searchText;
      await itemTable.value.refreshTable();
    };
    const addItems = () => {
      const prevItems = props.row?.items ? cloneDeep(props.row?.items) : [];
      if (props.isSubstitute) {
        emit('close', { selectedRow: state.selectedRow, substitutedItem: props.substitutedItem });
      } else {
        const selectedItems = cloneDeep(state.selectedItems);
        if (prevItems.length) {
          const prevItemCatIds = map(prevItems, 'catId');
          for (let i = 0; i < selectedItems.length; i++) {
            if (prevItemCatIds.includes(selectedItems[i].catId)) {
              toast.error(`Item with same catalog id ${selectedItems[i].catId} already exists`);
              return;
            }
          }
        }
        emit('close', selectedItems);
      }
      state.selectedItems = [];
    };
    const openPartNewModal = () => {
      ModalProgrammatic.open({
        component: PartsAddToCatalog,
        props: {
          addName: state.searchKey,
        },
        trapFocus: true,
        canCancel: false,
        rootClass: 'modal-sm',
        events: {
          addedPart: async (val) => {
            if (props.isSubstitute) {
              state.selectedRow = val;
            } else {
              state.selectedItems.push(val);
            }
            state.loadTable++;
            await itemTable.value.refreshTable();
            toast.success('You added a new catalog Item');
          },
          'refresh-table': async () => {
            await itemTable.value.refreshTable();
          },
        },
      });
    };
    const showButtonForPartsAdding = computed(() => {
      if (['preparation', 'sourcing'].includes(route.params.stage)) {
        return true;
      }
      return false;
    });

    return {
      ...toRefs(state),
      getSelectedRows,
      loadData,
      getSearchValue,
      cancel,
      addItems,
      itemTable,
      msgToDisplay,
      openPartNewModal,
      selectDisable,
      showButtonForPartsAdding,
      itemsCanSelect,
    };
  },
});
</script>
<style scoped>
.columns.is-marginless .column:first-child {
  margin-right: 8px;
}
::v-deep(.o-table tbody) {
  min-height: 385px !important;
  max-height: 385px !important;
}
</style>
