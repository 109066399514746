import { useStore } from 'vuex';
import _, { cloneDeep } from 'lodash';
import { useToast } from 'vue-toastification';
import constants from '@/constants';
import { useRouter } from 'vue-router';
import ExcelExport from '@/models/ExcelExport';
import MaterialManager from '@/models/MaterialManager';
import ShippingLabel from '@/models/ShippingLabel';
import translator from '@/languages/translator';
import SupplyChain from '@/models/SupplyChain';
import Locations from '@/models/Locations';
import Projects from '@/models/Projects';
import Print from '@/components/utils/Print';
import { DialogProgrammatic } from '@/components/Dialog';
import ProductionManager from '@/models/ProductionManager';
import Order from '@/models/Orders';
import Shipping from '@/models/Shipping';
import Prefab from '@/models/Prefabs';
import { CardDirtyBus } from '../../utils/CardDirtyBus';
import UtilityMixin from './UtilityMixin';
import GeneralShippingMixin from '../mixins/GeneralShippingMixin';

const { t } = translator;
export default function () {
  const store = useStore();
  const toast = useToast();
  const router = useRouter();
  const { userData } = store.state;
  const shippingStages = ['not-started', 'in-transit', 'complete', 'delivery', 'released-to-inventory',
    'fulfilled', 'in-storage', 'mixed-shipping'];
  const { getAllLocations } = UtilityMixin();
  const { unreserveAll } = GeneralShippingMixin();
  const getOrderCreator = (card) => {
    const mmUser = {
      _id: userData._id,
      name: userData.name,
      id: userData._id,
    };
    const user = {
      _id: userData._id,
      name: userData.name,
    };
    const company = {
      _id: user.company,
      name: user.companyName,
    };
    if (_.get(card, 'owner.user.name') === '(Order Creator)') {
      card.orderCreator = true;
      card.owner = '';
    } else {
      card.orderCreator = false;
    }
    if (card.todos.length > 0) {
      for (const todo of card.todos) {
        if (
          todo.assignedTo.user
          && todo.assignedTo.user.name === '(Order Creator)'
        ) {
          todo.orderCreator = true;
          todo.assignedTo.user = user;
          todo.assignedTo.company = company;
        } else if (
          todo.assignedTo.user
          && todo.assignedTo.user.name === t('Work Step Owner')
          && todo.sources.length > 1
          && todo.sources[1].type === 'run'
        ) {
          const linkedRun = _.find(card.manager.runs, {
            _id: todo.sources[1]._id,
          });
          if (!(_.isEmpty(linkedRun) || linkedRun.orderCreator)) {
            _.assign(todo.assignedTo.user, linkedRun.owner.user);
          } else {
            todo.assignedTo.user = user;
          }
          todo.orderCreator = false;
          todo.runCreator = true;
          todo.assignedTo.company = company;
        } else {
          todo.orderCreator = false;
          todo.runCreator = false;
        }
      }
    }
    if (card.__t === 'ProdTemplates') {
      card.manager.owner = '';
      if (card.manager.runs.length > 0) {
        for (const run of card.manager.runs) {
          if (run.owner.user.name === '(Order Creator)') {
            run.orderCreator = true;
            run.owner.user = user;
            run.owner.company = company;
          } else {
            run.orderCreator = false;
          }
        }
      }
    } else {
      if (card.baseDelivery.supplier) {
        if (card.baseDelivery.supplier.name === '(Order Creator)') {
          card.baseDelivery.orderCreator = true;
          card.baseDelivery.supplier = mmUser;
        } else {
          card.baseDelivery.orderCreator = false;
        }
      }
      if (card.items) {
        for (const item of card.items) {
          if (
            item.qa.assignedTo
            && item.qa.assignedTo.name === '(Order Creator)'
          ) {
            item.qa.orderCreator = true;
            item.qa.assignedTo = mmUser;
          } else {
            item.qa.orderCreator = false;
          }
        }
      }
    }
  };

  const _nonBOValidation = (card) => {
    const catMap = _(card.items)
      .map((item) => (item.catId && !item.archived.value ? item.catId : ''))
      .compact()
      .value();
    const catIdCount = _.countBy(catMap);
    const duplicateExists = _.findKey(catIdCount, (count) => count > 1);
    if (duplicateExists) {
      const errorObj = {
        data: {
          msg: 'Items must have unique Catalog IDs. Please re-enter.',
        },
      };
      throw errorObj;
    }
    if (
      card.__t === 'Sourcing'
      && card.stage === 'ordering'
      && _.some(card.items, (item) => _.isEmpty(item.catId))
    ) {
      const errorObj = {
        data: {
          msg: 'CatId is mandatory for material items',
        },
      };
      throw errorObj;
    }
  };
  const onCardLoaded = (card, stage) => {
    if (!_.isEmpty(card)) {
      card._customStage = stage;
      card.items.forEach((item) => {
        _.set(item, 'isEditing', true);
        if (!item.simpleMemos || item.simpleMemos.length === 0) {
          item.newFTD('memos');
        }
        // this is needed so that the multiselect in qa-select in mm/qa shows the previous val
        if (item.qa && item.qa.assignedTo) { item.qa.assignedTo.name = item.qa.assignedTo._name; }
      });
    }
    return card;
  };

  const saveCard = async (cardId, stage, card) => {
    _.set(card, 'updatedVia', 'manual');
    try {
      const isDirty = (_.has(card, 'isDirty') && _.get(card, 'isDirty', false));
      _nonBOValidation(card);
      if (stage === 'ordering') card.readyForShipment();
      if (card.isPrefab() && card.purpose === 'kit') {
        const datesOrder = [
          'coord',
          'poDetailBy',
          'partsManufactureBy',
          'poManufactureBy',
          'deliver',
        ];
        const { isValid } = card.checkDateValidation('orderSave', datesOrder);
        if (!isValid) {
          toast.error('Dates cannot be empty');
        }
      }
      if (['ProdTemplates', 'MatTemplates'].includes(card.__t)) getOrderCreator(card);
      if (card.__t === 'MatTemplates' && card.items.length > 0) {
        const itemChunks = _.chunk(card.items, 150);
        for (const chunk of itemChunks) {
          card.items = chunk;
          // eslint-disable-next-line
          card = await card.slimSave();
        }
      } else {
        card = await card.slimSave();
      }
      const message = ['ProdTemplates', 'MatTemplates'].includes(card.__t) && cardId === 'add'
        ? 'New template added'
        : 'Changes saved.';
      if (isDirty) toast.success(`${message}`, 'is-success');

      return onCardLoaded(card, stage);
    } catch (e) {
      throw e;
    }
  };
  const setDirty = (e, data) => {
    // avoid the event when item level tabs are switched.
    if (
      e
      && (e.target.className.includes('o-tabs')
        || (e.type === 'focusout' && e.target.className.includes('generic-input')))
    ) return;
    CardDirtyBus.off('setCustomDirty');
    // once page goes dirty, it can stay dirty.
    data.dirtyListeners = {};
    data.isDirty = true;
    if (_.has(data, 'card')) {
      _.set(data.card, 'isDirty', data.isDirty);
    }
  };
  const onInitDirty = (data) => {
    data.isDirty = false;
    data.dirtyListeners = {
      input: (e) => setDirty(e, data),
    };
    CardDirtyBus.on('setCustomDirty', () => setDirty(null, data));
  };
  const getConfigDetails = ((type, module, stage) => {
    if (type === 'untitled') {
      if (module === 'Materials') {
        return constants['material-edit-preparation'];
      }
      if (module === 'Sourcing' && stage === 'sourcing') {
        return constants['material-edit-sourcing'];
      }
      if (module === 'Sourcing' && [...shippingStages, 'mixed'].includes(stage)) {
        return constants['material-edit-ordering'];
      }
      if (module === 'ProductionOrder') {
        if (stage === 'coordination') return constants['po-edit'];
        return constants['manager-edit-detailing'];
      }
      if (module === 'Prefabs') return constants['prefab-edit'];
    }
    return constants[type];
  });
  const updateMaterialWishlist = async (params, materialsData) => {
    const neededItems = _.map(materialsData, (item) => _.pick(item, ['cards', 'quantity', '_id', 'catId', 'combinedQuantity', 'qtyToConsume', 'qtyToShip']));
    const itemsToUpdate = _.filter(neededItems, { combinedQuantity: false });
    if (params?.updateQty) {
      for (const item of itemsToUpdate) {
        const oldQuantity = item.quantity;
        item.quantity = (item.qtyToShip + item.qtyToConsume);
        item.quantity = _.isNaN(item.quantity) ? oldQuantity : item.quantity;
      }
    }
    await MaterialManager.updateWishlist({ items: itemsToUpdate });
    return materialsData;
  };
  const exportMaterialItems = async (opts) => {
    const selectedCols = [];
    const { data: card } = opts;
    const reportFileName = `Items-${card.project.name}-${card.name}`;
    const configDetails = getConfigDetails(store.state.activeScreen,
      card.__t, card._customStage);
    const detailRowOptions = _.get(configDetails, 'tableTabs', {});
    const detailRowTabs = _.filter(detailRowOptions.tabs, (tab) => tab.text !== 'TASKS');
    const tabRowData = detailRowTabs.reduce((acc, it) => {
      acc.push(...it.tableProps.fields);
      return acc;
    }, []);
    let itemCols = tabRowData;
    itemCols = _.uniqBy(itemCols, 'id');
    const extraCols = [{
      title: 'Source',
      id: 'source',
    }];
    itemCols = _.concat(itemCols, extraCols);
    itemCols.forEach((obj) => {
      selectedCols.push({
        field: obj.id,
        title: obj.title,
        show: true,
        disabled: false,
        sortAttr: obj.sortField,
      });
    });
    await ExcelExport.export({
      name: 'MaterialStatus',
      orderId: card._id,
      type: 'excel',
      module: ['Materials', 'Sourcing'],
      exportFromCard: true,
      excelHeaders: { columns: selectedCols },
      itemView: true,
      projectId: card.project._id,
      reportFileName,
      fromSourcing: card.stage === 'sourcing',
    });
    toast.success('Excel report emailed to you');
  };

  const exportItems = async (opts, rowData, route) => {
    try {
      if (['sourcing', 'ordering'].includes(rowData.stage)
      && route.params.cardId === 'add') {
        if (_.isEmpty(rowData.items)) {
          toast.error(
            'Item Validation failed. Please add an Item to save the Order!',
          );
        }
        if (rowData.stage === 'ordering') {
          rowData.readyForShipment();
          if (_.isUndefined(rowData.baseDelivery.vendor)) {
            toast.error(
              'Vendor cannot be Empty. Please select a vendor',
            );
          }
        }
        const actualStage = rowData._customStage;
        rowData = await saveCard(route.params.cardId, rowData.stage, rowData);
        if (['sourcing', 'ordering'].includes(actualStage)) {
          const srcCard = (await MaterialManager.pullToSourcing([rowData._id], rowData))[0];
          if (actualStage === 'ordering') {
            srcCard.stage = 'ordering';
            const orderingCard = await MaterialManager.moveToNextStageFromCard(srcCard);
            rowData = orderingCard;
            const { commonStockProject } = store.state.queryParams;
            await ShippingLabel.createShipment(orderingCard, commonStockProject);
          } else rowData = srcCard;
          router.push({
            name: route.name,
            params: {
              cardId: rowData._id,
              projectId: rowData.project._id,
              stage: rowData.stage,
            },
          });
        }
      } else {
        await saveCard(route.params.cardId, rowData.stage, rowData);
      }
      opts.data = rowData;
      await exportMaterialItems(opts);
    } catch (e) {
      console.log('error', e);
    }
  };
  const printQr = async (data) => {
    const params = {
      stage: _.get(data, '_customStage', ''),
      orderId: data._id,
      projectIds: data.project._id,
      tzinfo: new Date().getTimezoneOffset(),
      printSerial: false,
    };

    const [qrLabel, user] = await Promise.all([
      SupplyChain.getBaseOrderQr(params),
      store.getters.userPromise,
    ]);
    await Print.printQrLabel(qrLabel, user);
  };

  const printShipQr = async (data) => {
    const itemData = data.items;
    const params = {
      shippingId: data._id,
      projectId: data.project._id,
      tzinfo: new Date().getTimezoneOffset(),
      printSerial: false,
      type: 'shipping',
    };
    const qrLabel = await Shipping.getShippingLabel(params);
    const itemArray = qrLabel.filter((qr) => qr.type !== 'shipping');
    const compArray = qrLabel[0];
    _.set(compArray, 'viewIndex', 0);
    const sortedArr = itemArray;
    sortedArr.unshift(compArray);
    for (const item of itemData) {
      const obj = _.find(itemArray, (dt) => dt._id === item._id);
      _.set(obj, 'viewIndex', item.viewIndex);
    }
    const qrData = sortedArr;
    const user = await store.state.userData;
    Print.printQrLabel(qrData, user);
  };
  const getModuleName = (card) => {
    let moduleName = '';
    if (card.isPrefab()) moduleName = 'prefab';
    else if (card.isPO()) moduleName = 'order';
    else if (card.isPM()) moduleName = 'manager';
    else if (card.isMM()) moduleName = 'material';
    else if (card.isSourcing()) moduleName = 'sourcing';
    else if (card.isMaterialTemplate()) moduleName = 'materialTemplate';
    else if (card.isProductionTemplate()) moduleName = 'productionTemplate';
    return moduleName;
  };
  const openWithUserPref = (card) => {
    const moduleName = getModuleName(card);
    const routeData = {
      newTab: '_blank',
      sameTab: '_self',
    };
    let orderStage = '';
    if (card._customStage && !['mm-preparation', 'mm-ordering'].includes(store.state.activeScreen)) {
      orderStage = card._customStage;
    } else {
      orderStage = card.stage === 'mixed' ? card.getCardStage() : card.stage;
    }
    const routeObj = {
      name: `${moduleName}-edit`,
      params: {
        cardId: card._id,
        stage: orderStage,
        projectId: card.project._id,
      },
    };
    routeData.link = router.resolve(routeObj);
    window.open(routeData.link.href, routeData[store.state.userData.openPreference]);
  };
  const openLmvModel = async (data, viewModal = true, projectList) => {
    let userCompany = '';
    let routeName = 'lmv-view';
    let orderId = '';
    let itemId = '';
    let urn = '';
    if (_.get(data, 'order')) {
      orderId = data.order._id;
      itemId = data._id;
      routeName = 'lmv-view-item';
    } else {
      orderId = data._id;
      routeName = 'lmv-view-card';
    }
    const allProjects = viewModal ? await Projects.allProjects() : projectList;
    const user = store.state.userData;
    const projectId = _.get(data.project, '_id', '');
    const projectForOrder = store.getters.findProject({ _id: projectId });
    if (_.isEmpty(projectForOrder)) { return; }
    const parentCompany = _.get(projectForOrder, 'parentProject.createdCompany', '');
    let project;
    if (projectForOrder && projectForOrder.parentProject && parentCompany
          && parentCompany === user.company) {
      project = _.find(
        allProjects,
        { _id: projectForOrder.parentProject._id },
      );
    } else if (projectForOrder.createdCompany._id === user.company) {
      project = _.find(
        allProjects,
        { _id: projectId },
      );
    }
    if (!_.isUndefined(project)) {
      if (project && project.childProjects) {
        userCompany = project.createdCompany._id;
      } else {
        userCompany = _.get(user, 'company', '');
      }
      const ps = _.find(project.projectSettings, (setting) => setting.companyId === userCompany);
      urn = _.get(ps, 'forgeSync.urn', '');
      if (urn && viewModal) {
        const Params = {
          module: 'viewer',
          projectId,
          urn,
          orderId,
          itemId,
        };
        store.commit('setQueryParams', { selectedLmvProject: [project] });
        router.push({ name: routeName, params: Params });
      }
    }
    return urn;
  };

  const checkIfAllItemsInPM = (rowData) => _.some(_.get(rowData, 'items', []), (item) => (['detailing', 'manufacturing', 'qa', 'coordination'].indexOf(item.stage) === -1));

  const checkForBomPresence = async (purpose, order) => {
    if (purpose === 'kit') {
      const allBoms = await SupplyChain.supplyChain({
        projectId: order.project._id,
        customId: order.customId,
        module: 'Materials',
        stage: 'preparation',
      });
      if (allBoms.total) { return true; }
    } else if (!_.isEmpty(order.materials)) {
      for (const mm of order.materials) {
        const bom = await MaterialManager.getOne({
          cardId: mm._id,
          projectId: order.project._id,
        });
        if (bom.stage === 'preparation') {
          return true;
        }
      }
    }
    return false;
  };

  const deleteBom = async (archiveBom, order) => {
    if (archiveBom) {
      const promises = [];
      const linkBomId = _.map(order.materials, '_id');
      for (const id of linkBomId) {
        promises.push(MaterialManager.archive({ _id: id }));
      }
      await Promise.all(promises);
    }
  };

  const archive = async (kind, order, archiveBom, refreshTable) => {
    try {
      if ((order._customStage || order.stage) === 'planning') {
        await Prefab.archive(order);
      } else {
        await deleteBom(archiveBom, order);
        if ((order._customStage || order.stage) === 'coordination') {
          await Order.archive(order, kind, archiveBom);
        } else {
          // while deleting manufacturing order we need to unreserve items if any materials reserved
          if (order.stage === 'manufacturing' && !_.isEmpty(order.catQtyMaps)) await unreserveAll(order);
          await ProductionManager.archive(order, kind, archiveBom);
        }
      }
    } catch (error) {
      toast.error('Error archiving: please contact ManufactOn support');
    }
  };

  const archiveCard = async (rowData, refreshTable, setLoading) => {
    if (checkIfAllItemsInPM(rowData) && rowData.stage !== 'planning') {
      toast.warning(`Not able to remove. One or more items
        from this order is already in Shipping and
        hence the order cannot be deleted`);
      return;
    }
    const toastObj = {
      kit: 'The Kit, all associated Assembly Production Orders, and the original Prefab Package will be permanently deleted.',
      assembly: 'The Assembly Production Order and all its items will be permanently deleted.',
      general: `Are you sure you want to delete <b>${rowData.name}</b> and all items? This can't be undone.`,
    };
    const { purpose } = rowData;
    const title = _.upperCase(`Delete ${(purpose !== 'general' ? purpose : '')} Production Order`);
    const message = toastObj[purpose];
    const confirmParams = {
      title,
      message,
      okButton: `${purpose === 'kit' ? '' : ''} Delete`,
      cancelButton: 'Cancel',
      type: 'danger',
      onConfirm: async (cbVal) => {
        if (setLoading) setLoading(true);
        await archive('remove', rowData, cbVal, refreshTable);
        if (setLoading) setLoading(false);
        refreshTable();
        toast.success('Order deleted');
      },
      additionalMethod: async (cbVal) => {
        await archive('return', rowData, cbVal, refreshTable);
        refreshTable();
      },
    };
    if (await checkForBomPresence(purpose, rowData)) {
      _.assign(confirmParams, {
        isCheckboxAvailable: true,
        cbMessage: 'Also delete linked Bill of Material',
      });
    }
    DialogProgrammatic.confirm(confirmParams);
  };

  const fetchProjects = async (shipFromInventory = false) => {
    let allProjects = store.state.queryParams._projectsAndGI;
    if (!allProjects.length) {
      const projects = await Promise.all([
        Projects.allProjects(),
        Projects.allProjects({ isGI: true }),
      ]);
      allProjects = _.flatMap(projects);
    }
    if (shipFromInventory) {
      let { commonStockProject } = store.state.queryParams;
      commonStockProject = !_.isEmpty(commonStockProject) ? commonStockProject : await Projects.getCommonStockProject();
      allProjects.unshift(commonStockProject);
    }
    return _.cloneDeep(allProjects);
  };

  const fetchLocations = async (pId, isGIProject, shipFromInventory = false) => {
    let locs = await getAllLocations(pId);
    if (isGIProject) {
      _.remove(locs, (loc) => loc.type !== 'gi_location');
    }
    if (shipFromInventory) {
      locs = _.partition(locs, (loc) => loc.nestedLocation)[
        +(pId !== store.state.queryParams.commonStockProject._id)
      ];
    }
    return Locations.groupLocations(locs, store.state.companyData);
  };

  const fetchUsers = async (pId) => Projects.linkedProjectUsers([pId]);

  const getCardStatus = async(orderCard) => {
    if (orderCard.isManager && !['pause'].includes(orderCard.status)) {

      const orderItems = orderCard.items;
      const itemsWithActHrs = _.some(orderItems, (item) => {
        if (item.actualHrs > 0) {
          return true;
        }
        return false;
      });
      if (_.isEmpty(orderCard.manager.runs) && orderCard.stage === 'manufacturing') {
       const newCard = await ProductionManager.get({
        orderId: orderCard._id,
        projectId: orderCard.project._id,
      });
      // _.assign(orderCard, newCard);
      orderCard.manager.runs = [...newCard.manager.runs]

      }
      const unarchivedRuns = _.filter(orderCard.manager.runs, (run) => !run.archived.value);
      const allRunsCompleted = _.every(unarchivedRuns, { completed: true });
      let runsInProgress;
      if (!_.isEmpty(unarchivedRuns)) {
        runsInProgress = unarchivedRuns.filter((run) => {
          if (run.stats.actualHrs > 0) {
            return run;
          }
        });
      }

      const nonDetailStage = ['manufacturing', 'mixed', 'qa'].includes(orderCard.stage);
      const detailStage = ['detailing'].includes(orderCard.stage);

      // detailing
      if (detailStage) {
        switch (orderCard.status) {
          case 'in-progress':
            orderCard.status = 'in-progress';
            break;
          case 'not-started':
            orderCard.status = 'not-started';
            if (!_.isEmpty(runsInProgress) || itemsWithActHrs) {
              orderCard.status = 'in-progress';
            }
            break;
          case 'complete':
            orderCard.status = 'complete';
            break;
          default:
            orderCard.status = 'not-started';
            break;
        }
      }

      // for manufacturing, mixed, qa
      if (nonDetailStage) {
        if (!allRunsCompleted) {
          switch (orderCard.status) {
            case 'not-started':
              orderCard.status = 'not-started';
              if (!_.isEmpty(runsInProgress)) orderCard.status = 'in-progress';
              break;
            case 'in-progress':
              orderCard.status = 'in-progress';
              break;
            case 'complete':
              orderCard.status = 'complete';
              if (!_.isEmpty(runsInProgress) || !allRunsCompleted) orderCard.status = 'in-progress';
              break;
            default:
              orderCard.status = 'not-started';
              break;
          }
        }
      }
    }
    return orderCard;
  };
  const getRestartPermission = async (order) => {
    const { userData } = store.state;
    let member = false;
    if (_.get(order,'resumeOrdersByAll', false)) {
      return false;
    }
	  const project = await Projects.getOne(order.project._id);
    member = _.get(_.find(project.projectUsers, pUser => _.get(pUser, 'user._id', '') === userData._id), 'isAdmin', false);
    return !member;
  };
  const disableStatus = (status) => {
   return status === 'pause';
  }

  return {
    setDirty,
    onCardLoaded,
    onInitDirty,
    getOrderCreator,
    userData,
    _nonBOValidation,
    saveCard,
    getConfigDetails,
    exportMaterialItems,
    exportItems,
    updateMaterialWishlist,
    printQr,
    openLmvModel,
    archiveCard,
    printShipQr,
    openWithUserPref,
    fetchProjects,
    fetchLocations,
    fetchUsers,
    archive,
    checkForBomPresence,
    getCardStatus,
    getRestartPermission,
    disableStatus,
  };
}