import {
  reactive, toRefs,
} from 'vue';
import _, { isEmpty, some } from 'lodash';
import { useToast } from 'vue-toastification';
import { ModalProgrammatic } from '@oruga-ui/oruga-next';
import SelectProject from '@/components/modals/SelectProject.vue';
import SelectLocation from '@/components/modals/SelectLocation.vue';
import CreateShipping from '@/components/modals/CreateShipping.vue';
import CombineOrders from '@/components/modals/CombineOrders.vue';
import EditOrderIds from '@/components/modals/EditOrderIds.vue';
import ReorganizeItems from '@/components/modals/ReorganizeItems.vue';
import WorkstepEdit from '@/components/modals/WorkstepEdit.vue';
import ResolveConflict from '@/components/modals/ResolveConflict.vue';
import DatePickerSchedule from '@/components/modals/DatePickerSchedule.vue';
import MoveKit from '@/components/modals/MoveKit.vue';
import MassUpdate from '@/components/modals/MassUpdate.vue';
import MoveOrders from '@/components/modals/MoveOrders.vue';
import MoveOrdersForward from '@/components/modals/MoveOrdersForward.vue';
import PrefabsMixin from '@/components/mixins/PrefabsMixin';
import GeneralShippingMixin from '@/components/mixins/GeneralShippingMixin';
import CardEditMixin from '@/components/mixins/CardEditMixin';
import ListSubmittals from '@/components/modals/ListSubmittals.vue';
// import MaterialManager from '@/models/MaterialManager';
import Tasks from '@/models/Task';
import Vendors from '@/models/Vendors';
import Catalogs from '@/models/Catalogs';
import ShippingLabel from '@/models/ShippingLabel';
import SupplyChain from '@/models/SupplyChain';
import Schedule from '@/models/Schedule';
import { DialogProgrammatic } from '@/components/Dialog';
import Validation from '@/components/utils/Validations';
import CreateMaterialFromInv from '@/components/modals/CreateMaterialFromInv.vue';
import moment from 'moment';
import { useRouter, useRoute } from 'vue-router';
import RejectSourcing from '@/components/modals/RejectSourcing.vue';
import SelectStageModal from '@/components/modals/SelectStageForKit.vue';
import Todo from '@/models/Todo';
import ExcelExport from '@/models/ExcelExport';
import Qr from '@/models/Qr';
import Projects from '@/models/Projects';
import store from '../../store';
import PermissionHelper from '@/components/mixins/PermissionHelper';
import ShippingReceive from '@/components/modals/ShippingReceive.vue';
import QaQcFormExport from '@/components/modals/QaQcFormExport.vue';

export default function (refreshTable, reset, refreshCard, loadSpinner,
  archiveOrders) {
  const router = useRouter();
  const route = useRoute();
  const toast = useToast();
  const state = reactive({
    temp: '',
    selectedProject: {},
    project: store.state.queryParams.project,
    isActive: false,
    selectedLocation: {},
    selectedDestLocation: {},
    vendors: [],
    selectedStage: null,
    selectedWorkStep: null,
  });
  const { activeScreen } = store.state;
  const kitMoveStages = {
    coord: 'coordination',
    detail: 'detailing',
    manuf: 'manufacturing',
  };
  const { pullBackwardToPlanning, createBomAndPR } = PrefabsMixin();
  const {
    moveToKitLoc, sendOrdersToInventory, multiMoveToShip, checkIfKitComponentsMoved,
  } = GeneralShippingMixin();
  const { saveCard, checkForBomPresence } = CardEditMixin();
  const { isDisabledOnSaveMove } = PermissionHelper();
  const hasNotPurchasedItems = (row) => _.some(row?.items, { purchase: false });

  const isDisabledOnPermission = (_row, permissionModule, key) => {
    if (!isEmpty(permissionModule)) {
      const permissionValue = store.getters.getPermissionVal({
        permissionModule,
        rowData: _row,
      }, key);
      return permissionValue;
    }
   return false;
  };

  const triggerReorganizeModal = (e) => {
    ModalProgrammatic.open({
      component: ReorganizeItems,
      props: {
        selectedProject: e,
      },
      trapFocus: true,
      canCancel: false,
      rootClass: 'modal-sm',
      events: {
        'refresh-table': async () => {
          await reset();
        },
        cancel: () => {
          reset();
        },
      },
    });
  };
  const triggerWorkstepEditModal = (cards) => {
    ModalProgrammatic.open({
      component: WorkstepEdit,
      props: {
        selectedCards: cards,
        selectedWorkStep: state.selectedWorkStep,
      },
      trapFocus: true,
      canCancel: false,
      rootClass: 'modal-xs',
      events: {
        'refresh-table': async () => {
          await reset();
          await refreshTable();
          store.commit('setSelectedWorkStep', '');
        },
        cancel: () => {
          reset();
        },
      },
    });
  };

  const triggerSelectProjectModal = (eventType) => {
    let title;
    let description;
    // eslint-disable-next-line no-unused-vars
    let bulkQrPrint;
    let editWorkStep;

    switch (eventType) {
      case 'combine':
        title = 'Combine Orders';
        description = 'Select a project, then select two orders to combine';
        break;
      case 'massUpdateDates':
        title = 'Select Project';
        description = 'To change dates, first select a single project';
        break;
      case 'moveForward':
        title = 'Select Project';
        description = 'Select a project and then Move Orders';
        break;
      case 'moveOrdersForward':
        switch (activeScreen) {
          case 'planning':
            title = 'Create Production Orders';
            description = 'Select a Project to create Production Orders.';
            break;
          default:
            title = 'Move Orders Forward';
            description = 'Select a Project to move Orders forward.';
            break;
        }
        break;
      case 'reorganize':
        title = 'Select Project';
        description = 'Select a project and then Reorganize Items';
        break;
      case 'editOrderIds':
        title = 'Select Project';
        description = 'Select a project to edit Order IDs';
        break;
      case 'bulkQrPrint':
        title = 'Print QR Labels';
        description = 'Select a project to print QR labels';
        bulkQrPrint = true;
        break;
      case 'editWorkStep':
        title = 'Select Project And Work Step';
        description = 'Select a project,then select a Work Step to edit';
        editWorkStep = true;
        break;
      default:
        title = '';
        description = '';
        break;
    }
    ModalProgrammatic.open({
      component: SelectProject,
      props: {
        title,
        description,
        bulkQrPrint,
        editWorkStep,
      },
      trapFocus: true,
      canCancel: false,
      rootClass: 'modal-xs',
      events: {
        'selected-project': async (e) => {
          store.commit('setQueryParams', { filteredProjects: _.castArray(e) });
          if (eventType === 'bulkQrPrint') store.commit('setQueryParams', { bulkQrPdf: true });
          if (eventType === 'reorganize') {
            await triggerReorganizeModal(e);
          } else {
            state.selectedProject = e;
            await refreshTable();
          }
        },
        'select-workstep': async (e) => {
          state.selectedWorkStep = e.selectedWorkstep;
          store.commit('setSelectedWorkStep', state.selectedWorkStep);
          store.commit('setSelectedProject', e.selectedProject);
          if (!isEmpty(state.selectedWorkStep)) {
            await refreshTable(e);
          }
        },
        cancel: async () => {
          if (eventType === 'bulkQrPrint') store.commit('setQueryParams', { bulkQrPdf: true });
          await reset();
        },
      },
    });
  };

  const printForms = {
    isRowCheckable: (_row) => {
      if (_row.inComplete !== 0) return false;
      return true;
    },
  };
  const triggerSelectStage = () => {
    ModalProgrammatic.open({
      component: SelectStageModal,
      trapFocus: true,
      canCancel: false,
      rootClass: 'modal-xs',
      props: {
        title: 'Select A Stage',
        description: 'Select a single stage, then select the Order(s) you want to move',
      },
      events: {
        'selected-stage': async (e) => {
          state.selectedStage = e;
        },
        cancel: async () => {
          state.selectedStage = null;
          reset();
        },
      },
    });
  };
  const bulkQrPrint = {
    permissionType: 'bulkQrPrint',
    selectProject: triggerSelectProjectModal,
    initiate: async (cards) => {
      let { commonStockProject } = store.state.queryParams;
      commonStockProject = !_.isEmpty(commonStockProject)
        ? commonStockProject : await Projects.getCommonStockProject();
      const selectProject = store.getters.selectedIdsForKey('filteredProjects');
      const cardIds = _.map(cards, '_id', '');
      const uids = _.map(cards, 'uid', '');
      const locIds = _.map(cards, 'rootLoc._id', '');
      const user = store.state.userData;
      const { separatePdf } = store.state.qrScan;
      const { orderLevel } = store.state.qrScan;
      const params = {
        projectId: selectProject,
        tzinfo: new Date().getTimezoneOffset(),
        userInfo: user.fullName,
        emails: [user.email],
        isBulk: true,
        cardIds,
        separatePdf,
        orderLevel,
        name: 'QrPdf',
        type: 'pdf',
      };
      if (store.state.activeScreen !== 'inventory' && store.state.activeScreen !== 'shipping-order-view') {
        params.name = 'baseOrdersQrPdf';
      } else if (store.state.activeScreen !== 'inventory' && store.state.activeScreen === 'shipping-order-view') {
        if (!_.isEmpty(commonStockProject)) params.projectId.push(commonStockProject._id);
      } else {
        const { company } = store.state.userData;
        params.name = 'inventoryQrPdf';
        params.uid = uids;
        params.companyId = company;
        params.locId = locIds;
        if (!_.isEmpty(commonStockProject)) params.projectId.push(commonStockProject._id);
      }
      await Qr.generateBulkQrPdf(params);
      await reset();
      toast.success('QR Codes emailed to you');
    },
    isRowCheckable: (row) => {
      if (store.state.activeScreen === 'shipping-edit' && row.status === 'mixed') return false;
      if (['planning', 'coordination', 'preparation', 'sourcing', 'rejected'].includes(row.stage) || (row.stage === 'mixed' && _.some(row.items, (item) => ['planning', 'coordination', 'preparation', 'rejected'].includes(item.stage)))) return false;
      return true;
    },
  };
  const editWorkStep = {
    permissionType: 'editWorkStep',
    selectProject: triggerSelectProjectModal,
    initiate: async (cards) => {
      triggerWorkstepEditModal(cards);
      await refreshTable();
    },
    isRowCheckable: (row) => row.stage !== 'mixed',
  };
  const reorganize = {
    permissionType: 'reorganize',
    selectProject: triggerSelectProjectModal,
  };
  const editOrderIds = {
    permissionType: 'editOrderIds',
    selectProject: triggerSelectProjectModal,
    initiate: (cards) => {
      const cardIds = _.map(cards, '_id');
      if (cardIds.length) {
        ModalProgrammatic.open({
          component: EditOrderIds,
          props: {
            cards,
            projectId: state.selectedProject._id,
            batchSize: 5,
          },
          trapFocus: true,
          canCancel: false,
          rootClass: 'modal-xs',
          events: {
            'refresh-table': async () => {
              await reset();
              await refreshTable();
            },
            cancel: () => {
              reset();
              refreshTable();
            },
          },
        });
      }
    },
  };
  const combine = {
    permissionType: 'combine',
    selectProject: triggerSelectProjectModal,
    initiate: (cards) => {
      if (cards.length !== 2) {
        toast.error('Select two orders!');
      } else {
        ModalProgrammatic.open({
          component: CombineOrders,
          props: {
            cards,
          },
          trapFocus: true,
          canCancel: false,
          rootClass: 'modal-xs',
          events: {
            'refresh-table': async () => {
              reset();
            },
            cancel: () => {
              reset();
            },
          },
        });
      }
    },
    isRowCheckable: (_row, selectedRows) => {
      if (_row?.fromSchedule) return false;
      if (_row.purpose === 'kit') return false;
      if (selectedRows.length === 1) {
        if (selectedRows[0].purpose === 'assembly') {
          let clickable = true;
          selectedRows[0].kitIds.forEach((kitId) => {
            if (_row.kitIds.includes(kitId)) {
              clickable = false;
            }
          });
          return !clickable;
        } if (_row.purpose === 'assembly') {
          return false;
        }
      }
      return selectedRows.length < 2;
    },
  };

  const getCatalogType = () => {
    if (activeScreen === 'assemblies') return 'Assembly';
    return 'Parts';
  };

  const multiArchive = {
    initiate: (rows) => {
      const archivedNames = _.map(rows, 'name');
      const partOfAssemblies = _.find(rows, (item) => item.partOfAssemblies > 0);
      const confirmParam = {
        title: `ARCHIVE CATALOG ${activeScreen.toUpperCase()}`,
        message: `</br>Archive (${archivedNames.length}) selected Parts/Assemblies?`,
        okButton: 'Archive',
        cancelButton: 'Cancel',
        type: (partOfAssemblies) ? 'danger' : '',
        note: '</br>NOTE: Archived items can be viewed and recovered by enabling "Show Archived."',
        beforeMessage: (partOfAssemblies) ? `Selected Parts/Assemblies are included in other assemblies.
        Archiving selected Parts/Assemblies will remove them from those assemblies.` : '',
        onConfirm: async () => {
          state.isLoading = true;
          const type = getCatalogType() === 'Parts' ? 'items' : 'assemblies';
          const archivedCatalogIds = _.map(rows, 'catId') || [];
          try {
            await Catalogs.archive({
              catIds: archivedCatalogIds,
              isTemporaryDelete: true,
            }, type);
            toast.success('Archived Successfully');
            reset();
            await refreshTable();
          } catch (error) {
            toast.error(`Error while archiving - ${error.data}`);
            reset();
          } finally {
            state.isLoading = false;
          }
        },
        rootClass: 'danger-dialog is-header-danger',
      };
      DialogProgrammatic.confirm(confirmParam);
    },
    isRowCheckable: (_row) => {
      const val = isDisabledOnPermission(_row, 'app&projects', 'assembly_parts');
      return val;
    },
  };
  const multiDelete = {
    initiate: (rows) => {
      const archivedNames = _.map(rows, 'name');
      const { activeScreen } = store.state;
      const confirmParam = {
        title: `DELETE CATALOG ${activeScreen.toUpperCase()}`,
        message: `Delete (${archivedNames.length}) selected Parts/Assemblies?`,
        okButton: 'Delete',
        cancelButton: 'Cancel',
        type: 'danger',
        note: 'NOTE: Items will be permanently removed from the Manufacton Catalog and cannot be recovered.',
        noteType: 'danger',
        onConfirm: async () => {
          state.isLoading = true;
          const type = getCatalogType() === 'Parts' ? 'items' : 'assemblies';
          const archivedCatalogIds = _.map(rows, 'catId') || [];
          try {
            await Catalogs.archive({
              catIds: archivedCatalogIds,
            }, type);
            toast.success('Delete Successful');
            reset();
            await refreshTable();
          } catch (error) {
            toast.error(`Error while archiving - ${error.data}`);
            reset();
          } finally {
            state.isLoading = false;
          }
        },
        rootClass: 'danger-dialog is-header-danger',
      };
      DialogProgrammatic.confirm(confirmParam);
    },
    isRowCheckable: (_row) => {
      const val = isDisabledOnPermission(_row, 'app&projects', 'assembly_parts');
      if (_row.partOf > 0) return false;
      return val;
    },
  };

  const srcingForwardValidations = (cards) => {
    let errMsg = '';
    for (const card of cards) {
      if (_.isUndefined(card.baseDelivery.recipient)) {
        errMsg = 'Recipient is mandatory';
      } else if (_.some(card.items, (item) => !Validation.validateCatalogId(item.catId))) {
        errMsg = 'Catalog ID should be 4 to 32 character long';
      } else if (_.isEmpty(card.baseDelivery.vendor)) {
        errMsg = 'Vendor cannot be Empty. Please select a vendor';
      } else if (_.isEmpty(card.baseDelivery.location)) {
        errMsg = 'Delivery location cannot be Empty. Please select a delivery location';
      }
      if (errMsg) return errMsg;
      let itemVendors = _.flatMap(_.compact(_.map(card.items, 'vendors')));
      itemVendors = _.keyBy(itemVendors, '_id');
      if (!_.isEmpty(itemVendors)
      && !_.keys(itemVendors).includes(card.baseDelivery.vendor._id)) {
        const confirmParam = {
          title: '',
          message: 'The Order vendor does not match one or more item vendors. do you want to still continue ?',
          okButton: 'Ok',
          cancelButton: 'Cancel',
          type: 'danger',
          onCancel: () => {},
          rootClass: 'danger-dialog is-header-danger',
        };
        DialogProgrammatic.confirm(confirmParam);
      }
      if (card.items && card.items.length > 50) {
        errMsg = `Too many items to move this order.
          Please break it down into orders of 50 items or less, and then move.`;
      }
    }
    return errMsg;
  };
  const receiveMultiDeliveries = {
    initiate: (deliveries, shippingLabel) => {
      shippingLabel.modalName = "(multiple)"
      ModalProgrammatic.open({
        component: ShippingReceive,
        props: {
          deliveries,
          shippingLabel,
          shippingId: router.currentRoute.value?.params?.cardId,
          projectId: router.currentRoute.value?.params?.projectId,
          isActive: true,
        },
        trapFocus: true,
        canCancel: false,
        rootClass: 'modal-sm',
        events: {
          close: () => {
            refreshTable();
          },
          'refresh-card': async () => {
            await refreshCard();
          },
        },
      });
    },
    isRowCheckable: (row) => {
      return ['in-transit'].includes(row.status);
    }
  };
  const rejectItems = {
    initiate: (items) => {
      const orderId = router.currentRoute.value.params.cardId;
      ModalProgrammatic.open({
        component: RejectSourcing,
        props: {
          items,
          orderId,
          itemReject: true,
        },
        canCancel: false,
        rootClass: 'modal-xs',
        events: {
          'refresh-card': async () => {
            await refreshCard();
          },
        },
      });
    },
  };

  // fetch the current stage based on the activeScreen
  const getStageType = () => {
    const { activeScreen } = store.state;
    const stages = {
      'mm-preparation': 'material-edit-preparation',
      'mm-sourcing': 'material-edit-sourcing',
      planning: 'prefab-edit',
      coordination: 'po-edit',
      detailing: 'manager-edit-detailing',
      manufacturing: 'manager-edit-manufacturing',
    };
    if (activeScreen === 'kit-view-edit') {
      return stages[kitMoveStages[state.selectedStage]] ?? 'Stage not found';
    }
    return stages[activeScreen] ?? 'Stage not found';
  };

  const moveForwardKits = {
    openModal: () => {
      ModalProgrammatic.open({
        component: MoveKit,
        props: {

        },
        trapFocus: true,
        canCancel: false,
        rootClass: 'modal-sm',
        events: {
          'refresh-table': async () => {
            reset();
            await refreshTable();
          },
          cancel: () => {
            reset();
          },
        },
      });
    },
  };
  const changeVendor = async (row, vendor) => {
    if (!state.vendors.length) {
      const vendors = await Vendors.getVendors({ limit: 1000, showTemporary: true });
      state.vendors = vendors.data;
    }
    if (!_.isEmpty(vendor)) {
      const matchedVendor = _.find(state.vendors, { name: vendor.name });
      row.vendor = {
        _id: matchedVendor?._id,
        name: vendor.name,
        newVendor: matchedVendor?.isTemporary || !matchedVendor,
      };
      if (!matchedVendor) {
        state.vendors = [];
      }
    }
    return row;
  };
  const moveModal = async (props) => ModalProgrammatic.open({
    component: MoveOrders,
    props,
    trapFocus: true,
    canCancel: false,
    rootClass: ['mm-sourcing', 'schedule'].includes(store.state.activeScreen) ? 'modal-md' : 'modal-sm',
    events: {
      'refresh-table': async () => {
        reset();
        await refreshTable();
      },
      cancel: () => {
        reset();
      },
    },
  });

  const moveOrdersForwardModal = async (props) => ModalProgrammatic.open({
    component: MoveOrdersForward,
    props,
    trapFocus: true,
    canCancel: false,
    rootClass: 'modal-lg',
    events: {
      'refresh-table': async () => {
        reset();
        await refreshTable();
      },
      cancel: () => {
        reset();
      },
    },
  });

  const readyToMoveManf = async (card) => {
    let errMsg = '';
    const shipMaterials = await card.materialsReadyToShip();
    if (!shipMaterials) {
      errMsg = 'Some of the materials are not reserved. Please reserve before moving.';
    }
    return errMsg;
  };

  const setMaterialsReserved = async (cards) => {
    const promisses = [];
    for (let i = 0; i < cards.length; i++) {
      promisses.push(cards[i].materialsReadyToShip());
    }
    await Promise.all(promisses);
  };

  // Bulk move orders forward to any stage
  const moveOrdersForward = {
    selectProject: triggerSelectProjectModal,
    setMaterialsReserved: async (cards) => {
      await setMaterialsReserved(cards);
    },
    refresh: async () => {
      await refreshTable();
    },
    initiate: async (cards) => {
      if (cards.length < 1) {
        return toast.error('Select atleast one order!');
      }

      const selectedCards = _.cloneDeep(cards);

      // initiate move forward
      const kind = 'forward';
      const stageType = getStageType();
      const props = {
        type: stageType,
        cards: selectedCards,
        kind,
      };
      if (isDisabledOnSaveMove(cards[0])) {
        toast.error('User do not have permission to move the orders');
      } else {
        return moveOrdersForwardModal(props);
      }
    },
    isRowCheckable: (row) => {
      if (row.owner.company._id !== store.state.userData.company) {
        return false;
      }
      if (activeScreen === 'planning' && row.items.length > 50) {
        return false;
      }
      // from list if few of the assemblies not moved to kit location
      // we can't allow user to move associated kit to next stage
      if (activeScreen === 'manufacturing' && row._customStage === 'manufacturing' && _.get(row, 'purpose') === 'kit'
          && row.isLocked) {
        return false;
      }
      // below condition is to allow movement of kit orders
      if (activeScreen !== 'planning' && ['assembly', 'kit'].includes(row.purpose)) {
        if (activeScreen === 'manufacturing' && (row.status !== 'complete' || !row.materialsReserved)) {
          return false;
        }
        return true;
      }

      if ((activeScreen === row.stage || row.stage === 'mixed') && row.purpose === 'general' && !_.isEmpty(row.items)) {
        if (activeScreen === 'planning' && !some(row.items, { stage: 'planning' })) return false;
        if (activeScreen === 'manufacturing' && (row.status !== 'complete' || !row.materialsReserved)) {
          return false;
        }
        return true;
      }
      if (activeScreen === 'planning'
        && row.stage === 'mixed'
        && row.purpose === 'general'
        && some(row.items, { status: 'NotUsed' }) && some(row.items, { stage: 'planning' })
      ) {
        return true;
      }
      return false;
    },
  };

  const moveForward = {
    permissionType: 'moveForward',
    selectProject: triggerSelectProjectModal,
    refresh: async () => {
      await refreshTable();
    },
    initiate: async (cards) => {
      if (cards.length < 1) {
        return toast.error('Select atleast one order!');
      }

      // check if items are present in selected cards
      const selectedCards = _.cloneDeep(cards);
      const selectedCardsWithNoItems = selectedCards.filter((card) => !(card.items.length));
      if (selectedCardsWithNoItems.length) {
        return toast.error('Can not move selected orders, no items found');
      }

      // initiate move forward
      const isMulti = cards.length > 1;
      const kind = 'forward';
      const stageType = getStageType();
      let errMsg = '';
      if (!isMulti) {
        // for single movement
        let selectedCard = selectedCards[0];
        if (stageType === 'material-edit-sourcing') {
          errMsg = srcingForwardValidations(selectedCards);
        } else if (stageType === 'manager-edit-detailing') {
          selectedCard = await createBomAndPR(selectedCard);
        } else if (stageType === 'manager-edit-manufacturing') {
          errMsg = await readyToMoveManf(selectedCard);
        }
        if (errMsg) return toast.error(errMsg);
        selectedCards[0] = selectedCard;
      } else {
        for (let card of selectedCards) {
          if (stageType === 'material-edit-sourcing') {
            errMsg = srcingForwardValidations(selectedCards);
          } else if (stageType === 'manager-edit-detailing') {
            card = await createBomAndPR(card);
          } else if (stageType === 'manager-edit-manufacturing') {
            errMsg = await readyToMoveManf(card);
          }
          if (errMsg) return toast.error(errMsg);
        }
      }
      const props = {
        type: stageType,
        cards: selectedCards,
        isActive: true,
        isMulti,
        kind,
      };
      return moveModal(props);
    },
    isRowCheckable: (row) => {
      if (row.stage === 'manufacturing' && row.purpose === 'kit'
        && (row.isLocked || row.status !== 'complete')) return false;
      if (row.stage === 'sourcing' && (_.isUndefined(row.baseDelivery.recipient)
            || _.some(row.items, (item) => !Validation.validateCatalogId(item.catId))
            || _.isEmpty(row.baseDelivery.vendor)
            || _.isEmpty(row.baseDelivery.location))) {
        return false;
      }
      let isPurchase = true;
      if (store.state.activeScreen === 'mm-preparation') isPurchase = !hasNotPurchasedItems(row);
      if (row.stage === 'mixed') return _.some(row.items, { stage: row._customStage });
      return row.stage === row._customStage && row.items.length > 0 && isPurchase;
    },
  };

  const moveBackward = {
    permissionType: 'moveBackward',
    initiate: async (cards) => {
      if (cards.length < 1) {
        return toast.error('please select atleast one Order');
      }
      const isMulti = cards.length > 1;
      const kind = 'backward';
      let { activeScreen } = store.state;
      let stageType;
      let proceedMovement = true;
      if (activeScreen === 'kit-view-edit') {
        activeScreen = kitMoveStages[state.selectedStage];
      }

      if (cards.length === 1 && activeScreen === 'coordination') {
        const selectedCard = cards[0];
        // coord -> planning
        proceedMovement = false;
        const confirmParam = {
          title: 'MOVE ORDER BACK TO PLANNING',
          message: `Are you sure you want to return "${selectedCard.name}" to its original Prefab Package? Documents and notes will be lost.`,
          okButton: 'Return',
          cancelButton: 'Cancel',
          type: 'danger',
          onConfirm: async () => {
            const result = await pullBackwardToPlanning([selectedCard]);
            if (result.success) {
              toast.success(result.message);
              reset();
              await refreshTable();
            } else {
              toast.error(result.message);
              reset();
            }
          },
          rootClass: 'danger-dialog is-header-danger',
        };
        DialogProgrammatic.confirm(confirmParam);
      } else if (isMulti && activeScreen === 'coordination') {
        // coordination -> planning
        const selectedOrders = cards;
        proceedMovement = false;
        const confirmParam = {
          title: _.upperCase('Move Order Back To Planning'),
          message: `Are you sure you want to return ${selectedOrders.length} orders back to Planning ? Documents and notes will be lost.`,
          okButton: 'Return',
          cancelButton: 'Cancel',
          type: 'danger',
          onConfirm: async () => {
            loadSpinner(true);
            const result = await pullBackwardToPlanning(selectedOrders);
            if (result.success) {
              toast.success(result.message);
              reset();
              await refreshTable();
            } else {
              toast.error(result.message);
              reset();
            }
          },
          rootClass: 'danger-dialog is-header-danger',
        };
        DialogProgrammatic.confirm(confirmParam);
      } else if (activeScreen === 'detailing') {
        // detailing -> coordination
        stageType = 'manager-edit-detailing';
      } else if (activeScreen === 'manufacturing') {
        // manufacturing -> detailing
        stageType = 'manager-edit-manufacturing';
      } else if (activeScreen === 'qa') {
        // qa -> manufacturing
        stageType = 'qa-edit';
      }
      if (proceedMovement) {
        const selectedCardsWithNoItems = cards.filter((card) => !card.items.length);
        if (selectedCardsWithNoItems.length) {
          return toast.error('no items found in one of the Order');
        }
        // set isActive to true for the order
        ModalProgrammatic.open({
          component: MoveOrders,
          props: {
            type: stageType,
            cards,
            isActive: true,
            isMulti,
            kind,
          },
          trapFocus: true,
          canCancel: false,
          rootClass: 'modal-sm',
          events: {
            'refresh-table': async () => {
              reset();
              await refreshTable();
            },
            cancel: () => {
              reset();
            },
          },
        });
      }
      return null;
    },
  };

  const massUpdateDates = {
    permissionType: store.state.activeScreen === 'production-status-order-view' ? 'psMassUpdate' : 'msMassUpdate',
    selectProject: triggerSelectProjectModal,
    initiate: async (cards) => {
      let currentTab;
      switch (store.state.activeScreen) {
        case 'production-status-order-view':
          currentTab = 'psMassUpdate';
          break;
        case 'material-status-order-view':
          currentTab = 'msMassUpdate';
          break;
        default:
          currentTab = 'psMassUpdate';
          break;
      }
      ModalProgrammatic.open({
        component: MassUpdate,
        props: {
          cards,
          tab: currentTab,
          type: 'mass-update-dates',
        },
        trapFocus: true,
        canCancel: false,
        events: {
          restoreFromMassUpdate: async () => {
            reset();
            await refreshTable();
          },
          cancel: () => {
            reset();
          },
        },
      });
    },
    isRowCheckable: (row) => {
      if (activeScreen === 'kit-view-edit' && row.stage === 'complete') {
        return false;
      }
      return true;
     }
  };

  const ordermore = {
    permissionType: 'createTransferRequest',
    selectProject: () => {
      store.commit('setMultiCreateMatActive', true);
    },
    initiate: (items) => {
      ModalProgrammatic.open({
        component: CreateMaterialFromInv,
        props: {
          itemsData: items,
        },
        trapFocus: true,
        canCancel: false,
        rootClass: 'modal-sm',
      });
    },
  };

  const updateOnSiteDateSchedule = {
    initiate: async (cards) => {
      state.isActive = true;
      ModalProgrammatic.open({
        component: DatePickerSchedule,
        props: {
          cards,
          isActive: state.isActive,
        },
        trapFocus: true,
        canCancel: false,
        rootClass: 'modal-xm',
        events: {
          'close-modal': () => {
            reset();
            state.isActive = false;
          },
          'update-schedule': async (data) => {
            loadSpinner(true);
            const promises = [];
            for (const schedule of cards) {
              schedule.deliver.value = data.value;
              promises.push(Schedule.updateSchedule(schedule));
            }
            await Promise.all(promises);
            toast.success('On-Site dates updated');
            loadSpinner(false);
            reset();
            await refreshTable();
          },
          cancel: () => {
            reset();
          },
        },
      });
    },
  };
  const hideSchedule = {
    initiate: async (cards) => {
      loadSpinner(true);
      const promises = [];
      for (const card of cards) {
        card.isHidden = true;
        promises.push(Schedule.updateSchedule(card));
      }
      await Promise.all(promises);
      loadSpinner(false);
      reset();
      await refreshTable();
    },
    isRowCheckable: (row) => !row.isHidden,
  };
  const resolveConflict = {
    initiate: async (cards) => {
      state.isActive = true;
      ModalProgrammatic.open({
        component: ResolveConflict,
        props: {
          isActive: state.isActive,
        },
        trapFocus: true,
        canCancel: false,
        rootClass: 'modal-xs',
        events: {
          'close-modal': () => {
            reset();
            state.isActive = false;
          },
          'update-schedule': async (data) => {
            loadSpinner(true);
            await Schedule.resolveConflict({
              ids: _.map(cards, '_id'),
              mode: data.option || data.value,
            });
            loadSpinner(false);
            reset();
            await refreshTable();
          },
          cancel: () => {
            reset();
          },
        },
      });
    },
    isRowCheckable: (row) => (row.deliver?.value && !row.deliver?.isValid),
  };
  const moveSchedules = {
    initiate: async (cards) => {
      const selectProject = store.getters.selectedIdsForKey('filteredProjects');
      const orders = await SupplyChain.supplyChain({
        projectId: selectProject,
        orderId: _.map(cards, 'order._id'),
      });
      const errMsg = srcingForwardValidations(orders.data);
      if (errMsg) return toast.error(errMsg);
      const props = {
        type: 'material-edit-sourcing',
        cards: orders.data,
        isActive: true,
        isMulti: true,
        kind: 'forward',
      };
      await moveModal(props);
      reset();
      await refreshTable();
    },
    isRowCheckable: (row) => row.orderStage === 'sourcing' && !_.isEmpty(row.vendor),
  };
  const updateSubmittal = {
    initiate: async (cards) => {
      const { project } = store.state.queryParams;
      const projId = _.isArray(project) ? project[0]._id : project._id;
      ModalProgrammatic.open({
        component: ListSubmittals,
        props: {
          projectId: projId,
          isListSubsActive: String,
        },
        trapFocus: true,
        canCancel: false,
        rootClass: 'modal-xs',
        events: {
          'close-modal': () => {
            reset();
            state.isActive = false;
          },
          selectedSubmittal: async (obj) => {
            loadSpinner(true);
            for await (let row of cards) {
              row.submittal = obj;
              row.submittal.name = obj.fullName;
              for (const dateType of ['approveBy', 'submitBy', 'orderBy']) {
                const date = obj[dateType];
                if (!_.isEmpty(date)) {
                  if (_.isEmpty(row[dateType])) { row[dateType] = { value: '', isValid: true }; }
                  _.assign(row[dateType], { value: moment(date).hours(12).toDate() });
                }
              }
              row.submittalResponse = _.get(obj, 'submittalResponse', 'noWorkflow');
              row = await changeVendor(row, _.get(obj, 'vendor', null));
              await Schedule.updateSchedule(row);
            }
            refreshTable();
            loadSpinner(false);
            reset();
          },
          cancel: () => {
            reset();
          },
        },
      });
    },
    isRowCheckable: (row) => row.orderStage !== 'delivery',
  };
  const moveToKitLocation = {
    refresh: async () => {
      await refreshTable();
    },
    initiate: async (cards) => {
      const promises = [];
      for (const card of cards) {
        promises.push(moveToKitLoc(card, saveCard));
      }
      await Promise.all(promises);
      reset();
      await refreshTable();
    },
  };
  const createTask = async (items) => {
    try {
      const invItems = [];
      for (const idx of items) {
        const item = {
          catId: idx.catId,
          name: idx.name,
        };
        item.quantityMap = {
          taken: 0,
        };
        item.availableQty = idx.actual.available;
        invItems.push(item);
        item.project = idx.project;
      }
      const user = await store.getters.userPromise;
      if (!Validation.validateTask(invItems)) {
        const err = 'Some of the items dosent have Catalog ID. Please add Catalog ID before creating Task request!';
        toast.error(err);
        return;
        // throw new Error(err);
      }
      const task = {
        type: 'pick',
        items: invItems,
        project: '', // ||  selectedProject,
        dueDate: new Date(),
        fromInv: true,
      };
      task.assignee = {
        user: { _id: user._id, name: user.name },
        company: { _id: user.company, name: user.companyName },
      };
      task.sourceLocation = {};
      task.sourceLocation._id = _.get(items[0], 'rootLoc._id', '');
      // task.project = {};
      // task.project._id = _.get(opts.data, 'project._id', '');
      const req = (await Tasks.create(task)).data;
      store.commit('setMultiCreateTransferReqActive', false);
      await router.push({
        path: `/transfer-request/${req[0]._id}`,
        params: { id: req[0]._id },
      });
    } catch (e) {
      console.log('error', e);
    }
  };
  const localInventoryTransfer = {
    permissionType: 'createTr',
    refresh: async () => {
      await refreshTable();
    },
    initiate: async (items) => {
      await createTask(items);
    },
  };

  const removeOrders = {
    permissionType: 'archive',
    initiate: async (cards) => {
      if (cards.length < 1) {
        toast.error('Select atleast one order!');
      } else {
        const { purpose } = cards;
        const selectedCards = cards;
        const confirmParam = {
          title: selectedCards.length === 1 ? `DELETE ${selectedCards.length} ORDER` : `DELETE ${selectedCards.length} ORDERS`,
          message: selectedCards.length === 1 ? `Are you sure you want to delete <b>${selectedCards[0].name}</b> ? This can't be undone.`
            : 'Are you sure you want to delete the selected orders ? This can\'t be undone.',
          okButton: 'Delete',
          cancelButton: 'Cancel',
          type: 'danger',
          onConfirm: async (cbVal) => {
            await archiveOrders(selectedCards, cbVal);
            reset();
            await refreshTable();
          },
          rootClass: 'danger-dialog is-header-danger',
        };
        for (const card of cards) {
          if (card.stage !== 'planning' && await checkForBomPresence(purpose, card)) {
            _.assign(confirmParam, {
              isCheckboxAvailable: true,
              cbMessage: selectedCards.length === 1 ? 'Also delete the linked Bill of Material'
                : 'Also delete any linked Bills of Material',
            });
          }
        }
        DialogProgrammatic.confirm(confirmParam);
      }
    },
    isRowCheckable: (row) => {
      if (store.state.activeScreen === 'planning' && !['planning', 'coordination'].includes(row.stage)) return false;
      if (['planning', 'coordination', 'detailing', 'manufacturing', 'qa'].includes(row.stage)) return true;
      return false;
    },
  };

  const formatItemDeleteObj = (item, singleDelete = false) => {
    const projectIds = _.uniq(_.map(_.castArray(item.project), '_id'));
    const deleteItem = {
      itemIds: item.itemIds,
      projectIds,
      project: { name: _.uniq(_.map(_.castArray(item.project), 'name')) },
      locIds: singleDelete ? [item.rootLoc._id] : store.getters.selectedIdsForKey('selectedCompanyLocations'),
      isTerminal: singleDelete,
      kind: store.getters.selectedValuesForKey('source', true),
      uid: item.uid,
    };
    if (item.catId) {
      deleteItem.catId = item.catId;
    } else {
      deleteItem.name = item.name;
    }
    return deleteItem;
  };

  const prepareMultiDelete = (items) => {
    const itemList = [];
    for (const item of items) {
      itemList.push(formatItemDeleteObj(item));
    }
    return itemList;
  };
  const archiveInvItems = async (items = null) => {
    try {
      const itemsToDelete = items && items.length === 1
        ? [formatItemDeleteObj(items[0], true)] : prepareMultiDelete(items);
      const res = await SupplyChain.deleteInventoryItem(itemsToDelete);
      if (res.done && res.failed.length) {
        const failedItemNames = _.map(res.failed, 'name');
        toast.error(`Item(s) deleted! But, following items could not be deleted
          due to one or more reasons: <b>${String(failedItemNames).replace(/,/g, ', ')}</b>`);
      } else {
        toast.success('Item(s) deleted!', 'is-success');
      }
    } catch (e) {
      console.log('error------------', e);
      toast.error('Error while deleting');
    } finally {
      reset();
      await refreshTable();
    }
  };
  const archiveInventoryItem = {
    permissionType: 'invMultiRemove',
    selectProject: () => {
      store.commit('setMultiRemoveActive', true);
    },
    initiate: (items) => {
      const confirmParam = {
        title: 'REMOVE INVENTORY',
        message: 'Are you sure you want to remove Selected Inventory items? This cannot be undone.',
        okButton: 'Remove',
        type: 'danger',
        cancelButton: 'Cancel',
        onConfirm: async () => {
          try {
            await archiveInvItems(items);
          } catch (error) {
            toast.error(`Error - ${error}`);
          }
        },
      };
      DialogProgrammatic.confirm(confirmParam);
    },
  };

  const selectProjectLocationModal = (eventType) => {
    let title;
    let description;

    switch (eventType) {
      case 'shipInventory':
        title = 'Select Items For Shipment';
        description = 'Select a single source project.';
        break;
      case 'moveToInventory':
        title = _.startCase('move orders to inventory');
        description = 'Moved Orders must come from the same project and source location';
        break;
      case 'shipMulti':
        title = 'Select Source';
        description = 'Before choosing orders, Select a single source';
        break;
      default:
        title = '';
        description = '';
        break;
    }
    ModalProgrammatic.open({
      component: SelectLocation,
      trapFocus: true,
      canCancel: false,
      rootClass: 'modal-xs',
      props: {
        title,
        description,
        eventType,
      },
      events: {
        'selected-values': async (e) => {
          state.selectedProject = e.selectedProject;
          state.selectedLocation = e.selectedLocation;
          state.selectedDestLocation = e.selectedDestLocation;
          store.commit('setQueryParams', { project: e.selectedProject });
          store.commit('setQueryParams', { selectedCompanyLocations: _.castArray(e.selectedLocation) });
          store.commit('setQueryParams', { getOnlyRoot: true });
          store.commit('setQueryParams', { multiMoveShipLocId: e.selectedLocation._id });
          store.commit('setQueryParams', { multiMoveProjectId: e.selectedProject._id });
          store.commit('setQueryParams', { multiMoveInvLoc: e.selectedDestLocation });
          if (store.state.activeScreen === 'inventory') {
            const storeKey = 'inStock';
            const isAvailableStk = {
              label: 'In Stock',
              value: 'isAvailable',
            };
            store.commit('setQueryParams', { [storeKey]: isAvailableStk });
          }
          await refreshTable();
        },
        cancel: async () => {
          reset();
        },
      },
    });
  };
  const shipInventory = {
    permissionType: 'createShipment',
    selectLocation: selectProjectLocationModal,
    initiate: async (items) => {
      const params = {
        name: '',
        purpose: 'general',
        project: { name: state.selectedProject.name, _id: state.selectedProject._id },
        reserveFor: null,
        items: [],
        externalEmails: [],
        shipType: 's-m',
        delivery: {
          currentProject: state.selectedProject,
          deliveryProject: '',
          currentLocation: state.selectedLocation,
          deliveryLocation: '',
          owner: '',
          recipient: '',
          trackingId: '',
        },
      };
      const itemsData = [];
      for (const item of items) {
        item.isInventoryItem = true;
        itemsData.push(item);
      }
      params.items = itemsData;
      const shippingOrder = new ShippingLabel(params);
      router.push({
        name: 'shipping-edit',
        params: {
          projectId: state.selectedProject._id,
          shipFromInventory: true,
          cardId: 'add',
          orderJSON: JSON.stringify(shippingOrder),
        },
      });
    },
  };

  const moveToInventory = {
    permissionModule: 'inventory',
    permissionType: 'inventoryMulti',
    selectLocation: selectProjectLocationModal,
    initiate: async (items) => {
      if (!checkIfKitComponentsMoved(items)) return;
      await sendOrdersToInventory(items, { dest: store.state.queryParams.multiMoveInvLoc });
      reset();
      await refreshTable();
    },
  };

  // For kit View
  const kitForward = {
    selectStage: triggerSelectStage,
    isRowCheckable: (row) => {
      if (row.stage === 'manufacturing' && row.purpose === 'kit'
         && (row.isLocked || row.status !== 'complete')) return false;

      if (row.stage === kitMoveStages[state.selectedStage] && row.status !== 'pause') {
        return (row.stage === 'manufacturing' ? row.status === 'complete' : true);
      }
      return false;
    },
    initiate: moveForward.initiate,
  };

  const kitBack = {
    selectStage: triggerSelectStage,
    isRowCheckable: (row) => row.stage === kitMoveStages[state.selectedStage],
    initiate: moveBackward.initiate,
  };

  const moveForwardToKitLoc = {
    isRowCheckable: (row) => {
      if (['manufacturing', 'qa'].includes(row.stage) && row.purpose === 'assembly') {
        if (row.stage === 'manufacturing' && row.status !== 'complete') {
          return false;
        }
        return true;
      }
      return false;
    },
    initiate: moveToKitLocation.initiate,
  };

  const triggerCreateSingleShipModal = async (card, aggregateFlag = false) => {
    if (card._customStage === 'manufacturing') {
      const shipMaterials = await card.materialsReadyToShip();
      if (!shipMaterials) {
        toast.error('Some of the materials are not reserved. Please reserve before moving.');
        return;
      }
    }
    ModalProgrammatic.open({
      component: CreateShipping,
      props: {
        orders: [card],
        aggregateFlag,
      },
      events: {
        close: async (moved) => {
          if (moved) {
            reset();
            await refreshTable();
          }
        },
      },
      canCancel: false,
      rootClass: 'modal-sm',
    });
  };

  const shipOrders = {
    permissionModule: 'shipping',
    permissionType: 'shipMulti',
    initiate: async (cards) => {
      if (cards.length > 1) {
        toast.error('please select only one Order');
      } else {
        const card = cards[0];
        if (!checkIfKitComponentsMoved([card])) return;
        await triggerCreateSingleShipModal(card);
      }
    },
    refresh: async () => {
      await refreshTable();
    },
    isRowCheckable: (_row, selectedRows) => selectedRows.length < 1,
  };

  const shipMulti = {
    permissionModule: 'shipping',
    permissionType: 'shipMulti',
    selectLocation: selectProjectLocationModal,
    initiate: async (cards) => {
      if (cards.length === 1) {
        const card = cards[0];
        if (!checkIfKitComponentsMoved([card])) return;
        await triggerCreateSingleShipModal(card, true);
      } else {
        await multiMoveToShip(cards, refreshTable, reset);
      }
    },
    refresh: async () => {
      await refreshTable();
    },
  };

  const excelExport = {
    isRowCheckable: (row, selectedRows) => row.complete > 0,
    initiate: async (selectedRows) => {
      const cards = await Todo.getFormsBuilder({ cardIds: _.castArray(_.map(selectedRows, (row) => row._id)) });
      const reqObj = [];
      const screen = store.state.activeScreen;
      const company = store.getters.selectedIdsForKey('companies', false);
      const owners = store.getters.selectedOwners;
      const search = store.state.queryParams.searchText;
      const projectId = store.state.queryParams.project._id;
      const userCompanyId = store.getters.isViewer() ? company : store.state.userData.company;

      for (const card of cards) {
        const excelHeaders = _.compact(_.map(card.formData, (data) => {
          if (data.label) {
            if (!['header', 'paragraph'].includes(data.type)) {
              return {
                field: data.name,
                title: data.label,
                show: true,
                disabled: false,
                sortAttr: data.label,
              };
            }
            if (data.type === 'paragraph' && data.className === 'time-saved') {
              return {
                field: 'Date/Time Saved',
                title: 'Date/Time Saved',
                show: true,
                disabled: false,
                sortAttr: 'Date/Time Saved',
              };
            }
          }
          return null;
        }));
        excelHeaders.unshift({
          field: 'Order Name',
          title: 'Order Name',
          show: true,
          disabled: false,
          sortAttr: 'name',
        });
        reqObj.push({
          company,
          owner: owners,
          template: card._id,
          search,
          screen,
          limit: 1000,
          type: 'excel',
          name: 'forms',
          excelHeaders: { columns: excelHeaders },
          projectId,
          userCompanyId,
        });
      }
      const res = await ExcelExport.export(reqObj);
      if (res.done) {
        toast.success('Excel report emailed to you');
        reset();
        await refreshTable();
      }
    },
  };

  const exportforms = {
    openModal: (event, card) => {
      ModalProgrammatic.open({
        component: QaQcFormExport,
        props: {
          event,
          card,
        },
        trapFocus: true,
        canCancel: false,
        rootClass: 'modal-sm',
        events: {
          'refresh-table': async () => {
            reset();
            // await refreshTable();
          },
          'close': () => {
            reset();
          },
        },
      });
    },
  };

  return {
    ...toRefs(state),
    combine,
    reorganize,
    editOrderIds,
    editWorkStep,
    moveOrdersForward,
    printForms,
    moveForward,
    moveForwardKits,
    moveBackward,
    ordermore,
    massUpdateDates,
    shipOrders,
    removeOrders,
    archiveInventoryItem,
    prepareMultiDelete,
    formatItemDeleteObj,
    localInventoryTransfer,
    shipInventory,
    moveToKitLocation,
    resolveConflict,
    moveToInventory,
    rejectItems,
    hideSchedule,
    updateOnSiteDateSchedule,
    moveSchedules,
    updateSubmittal,
    changeVendor,
    shipMulti,
    kitForward,
    kitBack,
    moveForwardToKitLoc,
    excelExport,
    multiArchive,
    multiDelete,
    bulkQrPrint,
    setMaterialsReserved,
    receiveMultiDeliveries,
    exportforms,
  };
}
