import {map,isUndefined,groupBy} from 'lodash';
import GenerateRequest from './resources';
import Project from './ProjectClass';

const req = GenerateRequest();
const reqFromClass = new GenerateRequest();

export default {
  async allProjects(params = {}) {
    params = {
      childProjects: true,
      forMembersOnly: true,
      parentProject: true,
      populateData: true,
      showArchived: false,
      showTemplate: !!params.showTemplate,
      ...params,
    };
    try {
      const { data: projects } = await req.get('projects/allProjectsForUser', { params });
      const allProject = projects.map(project => new Project(project));
      return allProject;
    } catch (e) {
      // TODO: Add an alert here?
      console.log(e);
      return [];
    }
  },
  async getCommonStockProject() {
    try {
      const { data } = await req.get('projects/getCommonStockProject');
      return new Project(data);
    } catch (e) {
      console.log(e);
      return {};
    }
  },
  async projectsForCompany(companyId, sortParam = {}) {
    let [allProjects, allGIs] = await Promise.all([
      this.allProjects({ ...{ showTemplate: true, companyId }, ...sortParam }),
      this.allProjects({ ...{ isGI: true, companyId }, ...sortParam }),
    ]);
    // Filter to remove all System generated projects.
    const userCreatedProjects = _(allProjects)
      .filter(p => !p.isCommonStockProject
        && !p.isGI
        && !p.isTemplateProject)
      .map(p => new Project(p))
      .value();
    allProjects = allProjects.map(project => new Project(project));
    allGIs = allGIs.map(gi => new Project(gi));
    return { allProjects, allGIs, userCreatedProjects };
  },
  async allProjectsForViewMember(params) {
    const { data } = await req.get('projects/allProjectsForViewMember', { params });
    const projects = data.map(project => new Project(project));
    return projects;
  },
  async linkedProjectUsers(project, largeDataSet = false) {
    let data = {};
    if (largeDataSet) {
      data = await req.post('projects/getLinkedProjectUsers', { project });
    } else {
      data = await req.get('projects/getAllLinkedProjectUsers', { params: { project } });
    }
    return data.data;
  },
  async updateOrderRoute(project, route) {
    const params = {
      project,
      route,
    };
    try {
      const { data: result } = await req.post('projects/updateOrderRoute', params);
      return new Project(result);
    } catch (e) {
      console.log(e);
      return {};
    }
  },
  async update(project) {
    try {
      const { data: result } = await req.post(
        'projects/update',
        { form: project, projectId: project._id },
      );
      return new Project(result);
    } catch (e) {
      // TODO: Add an alert here?
      console.log(e);
      throw (e);
    }
  },
  async get(projectId) {
    try {
      const { data: project } = await req.get('projects/get', { params: { projectId } });
      return new Project(project);
    } catch (e) {
      console.log(e);
      return {};
    }
  },
  async getSubCompany(params, largeDataSet = false) {
    try {
      let data = {};
      if (largeDataSet) {
        data = await req.post('projects/getAllSubCompany', params);
      } else {
        data = await req.get('projects/getSubCompany', { params });
      }
      return data.data;
    } catch (e) {
      console.log(e);
      return {};
    }
  },
  async getProjectWithPrefix(params) {
    try {
      const { data } = await req.get('projects/getProjectWithPoPrefix', { params });
      return data;
    } catch (e) {
      console.log(e);
      return {};
    }
  },
  async getOne(id) {
    const project = (await req.get(`projects/${id}`)).data;
    project.projectSettings = map(project.projectSettings, (projectSetting) => {
      if (isUndefined(projectSetting.projectCompanyRuns)) {
        projectSetting.projectCompanyRuns = [];
      }
      return projectSetting;
    });
    return new Project(project);
  },
  groupProjects(data) {
    const projects = data.map(project => new Project(project));
    const projectGroups = groupBy(projects, (pro) => {
      let grp = 'Project(s)';
      if (pro.isGI) grp = 'General Inventory';
      if (pro.isCommonStockProject) grp = 'Nested Location Project';
      return grp;
    });
    return [{
      type: 'Project(s)',
      projects: projectGroups['Project(s)'],
    },
    {
      type: 'General Inventory',
      projects: projectGroups['General Inventory'],
    },
    {
      type: 'Nested Location Project',
      projects: projectGroups['Nested Location Project'],
    }];
  },
  async removeCompany(params) {
    return req.delete('projects/removeCompany', { params });
  },
  async getAllNonSubCompanies(params) {
    const { data: result } = await req.get('projects/getAllNonSubCompanies', { params });
    return result;
  },
  async inviteCompanyToProjects(params) {
    return req.post('projects/linkCompany', params);
  },
  async allMyCompanyUsers() {
    return (await req.get('users/getMyCompanyUsers', { params: { archived: false } })).data;
  },
  async nonProjectUsers(params) {
    return (await req.get('projects/nonProjectUsers', { params })).data;
  },
  async addUserToProject(params) {
    return (await req.post('projects/addUserToProject', params)).data;
  },
  async getLAZFromCards(params) {
    return (await req.get('projects/getLAZFromCards', { params })).data;
  },
  async importCompanyRuns(params) {
    return (await req.post('projects/importCompanyRuns', params)).data;
  },
  haveTemplateProject() {
    return reqFromClass.makeRequest({
      method: 'GET',
      url: 'projects/haveTemplateProject',
    });
  },
  async archive(params) {
    return (await req.put(`projects/${params.id}`)).data;
  },
  async deleteUser(params) {
    return (await req.delete(`projects/${params.id}/${params.userId}`)).data;
  },
  async getLevelsAndZonesForProject(params) {
    return (await req.get('projects/getLevelsAndZonesForProject', params)).data;
  },
  async getProjectsSubCompany(params) {
    try {
      const { data } = await req.get('projects/getProjectsSubCompany', {
        params: { projectId: params.projectId },
      });
      return data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  async updateRole(params) {
    return (await req.post(`projects/updateRole?id=${params._id}&projectId=${params.projectId}`, params)).data;
  },
  async listProjectUsers(params) {
    try {
      const data = await req.get('projects/listUsers', { params });
      return {
        data: data.data.items,
        total: data.data.totalCount,
      };
    } catch (e) {
      throw e.response;
    }
  },
  async checkProjectAdmin(params) {
    try {
      const { data } = await req.get('projects/isLinkedProjectAdmin', {
        params: {
          projectId: params[0],
          userId: params[1],
        },
      });
      return data;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  async getSubmittalsForProject(params) {
    try {
      const { data } = await req.get(`procore/submittals/${params.project}`);
      return data.raw;
    } catch (e) {
      console.log(e);
      throw e;
    }
  },
  async getUsedRuns(params) {
    try {
      /*
      * params:{projectId:'',
      * type:['projectCompanyRuns','defaultProjectCompanyRuns']} default type is projectCompanyRuns
      * runType:['projectRun','companyRuns']} default runType is projectRun
      * */
      const { data } = await req.get('projects/getUsedRuns', {
        params: {
          projectId: params.projectId,
          type: params.type,
          runType: params.runType,
        },
      });
      return data;
    } catch (e) {
      throw e;
    }
  },
  async reNameLAZ(params) {
    return (await req.post('projects/reNameLAZ', params)).data;
  },
  async configureOpc(params) {
    const data = await req.post(`opc/saveOpcData/${params.project}`, params);
    return data;
  },
  async getOpcProjects(params) {
    const data = await req.post('opc/getProjects', { ...params });
    return data.data;
  },
};
