<template>
  <div class="">
    <TableGutter
      v-if="tab.showTableGutter"
      :selectedAction="isBulkActionEnabled"
      :tools="tab.tableProps.gutterOpts"
      @cc-modal-closed="triggerSetHeaderHeight"
      @cellClicked="eventHandler"
      @re-render-table="rerenderMfTable += 1"
    ></TableGutter>
    <div :class="$store.state.activeScreen === 'kit-view-edit' ? 'is-hidden'
      : 'is-flex is-justify-content-flex-end my-2'">
      <button v-if="edit && tab.accessor === 'items'"
        :disabled="isCatalogDisabled"
        class="button has-text-black-bis px-0" v-tooltip="'Items From Catalog'"
        @click="onTableCellClicked({event: 'addFromCatalog'})">
        <i class="icon-addcatalog"></i>
      </button>
    </div>
    <mf-table
      ref="detailTable"
      v-if="tab.tableProps"
      :tableProps="tab.tableProps"
      :tableName='tab.tableName'
      :loadData="getLoadData()"
      :apiMode="getApiMode()"
      :hideGutter="true"
      :orderStage="orderStage"
      :refreshCard="refreshCard"
      @cellClicked="onTableCellClicked"
      :key="rerenderMfTable"
      :isLocallyFiltered="isLocallyFiltered"
      :rowData="rowData"
      :isAssembDet="tab.isAssembDet"
      :isPartQtyUpdated="updatedQty"
    >
      <template v-if="tab.id === 'items'" v-slot:itemQty="{ rowData: item }">
        <qty-input
          v-model.number="item.selectedQty"
          :value="item.masterSummary?.total || item.quantity || 1"
          @update:value="itemQtyChanged($event, item)"
          :roundTo="2"
          :isDisabled="!['not-started','in-transit'].includes(rowData.status)"
          :min="item.masterSummary?.scheduled || 0"
          :max="setItemQtyLimit(item)"
        >
        </qty-input>
      </template>
      <template #mmItemUnitCost="{ rowData }">
        <field-generic-input
          :key="rowData._id || rowData.uuid"
          :value="rowData.calcUnitCost || 0"
          :isEditing="rowData.isEditing"
          type="number"
          :min="0"
          :max="999999"
          fieldClass="has-text-right"
          @update:value="(val) => $_.set(rowData, 'calcUnitCost', val < 999999 ? val : 999999)">
        </field-generic-input>
      </template>
      <template v-if="tab.id === 'deliveries'" v-slot:shippingStatus="{ rowData: delivery }">
        <field-stage
          :isEditing="true"
          :shipType="delivery.shipType"
          :value="delivery.status"
          :shippingItems="delivery.items"
          :isStatus="true"
        ></field-stage>
      </template>
      <template v-if="tab.id === 'deliveries'"
        v-slot:notify="{ rowData: delivery, rowField: deliveryRow,}">
        <field-notify-icon
          :rowData="delivery"
          :rowField="deliveryRow"
          :options="userOptions"
          :value="delivery._delivery.notify"
          :title="deliveryRow.title"
          @sendNotify="updateShippingLabel"
        ></field-notify-icon>
      </template>
      <template v-slot:categorySelect="{ rowData }"
        v-if="['assemblies', 'parts'].includes($store.state.activeScreen)" >
        <mf-multi-select
          v-model="rowData.category"
          v-if="rowData.isEditing"
          label="name"
          placeholder="Select Category"
          :searchable="false"
          :allow-empty="false"
          :options="categories?.data"
          :multiple="false"
          track-by="_id"
          @update:modelValue="fetchSubCategories()"
        ></mf-multi-select>
        <field-generic-input v-else
          :value="rowData?.category?.name"
        >
        </field-generic-input>
      </template>
      <template v-slot:subCategorySelect="{ rowData }"
        v-if="['assemblies', 'parts'].includes($store.state.activeScreen)">
        <mf-multi-select
          v-model="rowData.subCategory"
          v-if="rowData.isEditing"
          label="name"
          placeholder="Select Subcategory"
          :searchable="false"
          :allow-empty="false"
          :options="subCategories"
          track-by="_id"
          @update:modelValue="setSubCategory($event)"
        ></mf-multi-select>
        <field-generic-input v-else
          :value="rowData?.subCategory?.name"
        >
        </field-generic-input>
      </template>
      <template v-slot:vendorName="{ rowData: vendorRow }"
        v-if="['parts'].includes($store.state.activeScreen)">
        <mf-multi-select
          :modelValue="vendorRow"
          track-by="_id"
          label="name"
          v-if="vendorRow.newRow"
          placeholder="Select Vendor"
          :searchable="false"
          :allow-empty="false"
          :options="allVendors"
          @update:modelValue="setVendor($event, vendorRow)"
        ></mf-multi-select>
        <field-generic-input v-else :value="vendorRow.name"></field-generic-input>
      </template>
      <template v-slot:defVendor="{ rowData: vendorRow }"
        v-if="['parts'].includes($store.state.activeScreen)">
        <o-checkbox class="is-success"
          v-model="vendorRow.isDefault"
          @update:modelValue="setDefaultVendor($event, vendorRow)">
        </o-checkbox>
      </template>
      <template v-slot:code="{ rowData: vendorRow }"
        v-if="['parts'].includes($store.state.activeScreen)">
        <field-generic-input
          :value="vendorRow.code"
          :isEditing="rowData.isEditing"
          @update:value="(val) => $_.set(vendorRow, 'code', val)">
        </field-generic-input>
      </template>
      <template v-slot:desc="{ rowData: vendorRow }"
        v-if="['parts'].includes($store.state.activeScreen)">
        <field-generic-input
          :value="vendorRow.desc"
          :isEditing="rowData.isEditing"
          @update:value="(val) => $_.set(vendorRow, 'desc', val)">
        </field-generic-input>
      </template>
      <template v-slot:parts-quantity="{ rowData: partsRow }"
        v-if="['assemblies'].includes($store.state.activeScreen)">
        <qty-input
          v-model.number="partsRow.quantity"
          :value="partsRow.quantity"
          @update:value="setPartQuantity($event, partsRow)"
          :roundTo="2">
        </qty-input>
      </template>
      <template v-slot:unitCost="{ rowData: vendorRow }"
        v-if="['parts'].includes($store.state.activeScreen)">
        <field-generic-input
          :value="vendorRow.unitCost || 0"
          :isEditing="rowData.isEditing"
          type="number"
          :min="0"
          :max="9999"
          fieldClass="has-text-right"
          @update:value="(val) => $_.set(vendorRow, 'unitCost', val)">
        </field-generic-input>
      </template>
      <template v-slot:leadTime="{ rowData: vendorRow }"
        v-if="['parts'].includes($store.state.activeScreen)">
        <field-generic-input
          :value="vendorRow.leadTime || 0"
          :isEditing="rowData.isEditing"
          type="number"
          :min="0"
          :max="9999"
          fieldClass="has-text-right"
          @update:value="(val) => $_.set(vendorRow, 'leadTime', val)">
        </field-generic-input>
      </template>
      <template #available-qty="{ rowData: item }">
        <qty-input
          v-model.number="item.selectedQty"
          :value="item.selectedQty" @update:value="itemQtyChanged($event, item)"
          :roundTo="2" :max="setItemQtyLimit(item)">
        </qty-input>
      </template>
      <template #catId="{ rowData, rowField }">
        <cat-id-field
          :key="rowData.uuid || rowData._id"
          :rowData="rowData"
          :rowField="rowField"
          :catalogCollection="catCollection"/>
      </template>
      <template #delivsField="{ rowData, rowField }">
        <icon-field
        :rowData="rowData"
        :rowField="rowField"
        :shipItemDelivs="getItemDeliveries(rowData)">
        </icon-field>
      </template>
    </mf-table>
    <excel-import
      :card="rowData"
      :isExcelModalActive="excelModal"
      :dates="dates"
      :batchSize="50"
      modalHeaderTitle="import items from excel"
      @close-modal="closeModal"
      :maxLimit="importableLimit"
      @refresh-table="refreshTable"
      :uniqueFields="['catId']"
    ></excel-import>
    <div v-if="tab.id === 'schedule-details'" class="columns">
      <div class="column">
        <mf-notes
          :schedule-edit="edit"
          :isSchedule="true"
          :value="mm.currentNote.text"
          @update:value="(v) => $_.set(mm, 'currentNote.text', v)"/>
      </div>
      <div class="column buttons">
        <card-tags :card="mm" :edit="edit"></card-tags>
      </div>
    </div>
    <o-loading
      :full-page="true"
      :active="isLoading"
      :can-cancel="false"
    ></o-loading>
  </div>
  <activity-log
    v-if="activityModalOpen"
    :isActive="activityModalOpen"
    :id="activityDetails.id"
    :projectId="activityDetails.projectId"
    :orderName="activityDetails.orderName"
    @update:isActive="activityModalOpen=false"
    ></activity-log>

</template>

<script>
import {
  defineComponent,
  reactive,
  toRefs,
  ref,
  computed,
  watch,
  onMounted,
  inject,
} from 'vue';
import {
  get,
  isEmpty,
  find,
  findIndex,
  set,
  cloneDeep,
  isUndefined,
  every,
  remove,
  includes,
  forEach,
  concat,
  inRange,
  countBy,
  assign,
  some,
  map,
  differenceBy,
  compact,
  has,
} from 'lodash';
import uuid from 'uuid/v4';
import LocalSort from '@/components/utils/LocalSort';
import { ModalProgrammatic } from '@oruga-ui/oruga-next';
import AddFromCatalog from '@/components/modals/AddFromCatalog.vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import { DialogProgrammatic } from '@/components/Dialog';
import QtyInput from '@/components/fields/QtyInput.vue';
import AddFromCatalogMixin from '@/components/mixins/AddFromCatalogMixin';
import GeneralShippingMixin from '@/components/mixins/GeneralShippingMixin';
import AddFromInventory from '@/components/modals/AddFromInventory.vue';
import SplitDeliveries from '@/components/modals/SplitDeliveries.vue';
import ShippingReceive from '@/components/modals/ShippingReceive.vue';
import CreateDeliveries from '@/components/modals/CreateDeliveries.vue';
import PrefabMixin from '@/components/mixins/PrefabsMixin';
import ExcelImport from '@/components/ExcelImport.vue';
import CardEditMixin from '@/components/mixins/CardEditMixin';
import CardTags from '@/components/card-edit/CardTags.vue';
import { BaseOrder } from '@/models/BaseOrder';
import Print from '@/components/utils/Print';
import Shipping from '@/models/Shipping';
import MfTable from '@/components/table-fields/MfTable.vue';
import ActivityLog from '@/components/ActivityLog.vue';
import CreatePartInAssembly from '@/components/modals/CreatePartInAssembly';
import MfNotes from '@/components/card-edit/MfNotes';
import TableGutter from '@/components/TableGutter.vue';
import catIdField from '@/components/fields/CatIdField';
import Projects from '@/models/Projects';
import iconField from '@/components/fields/IconField';
import AddRow from '@/components/utils/AddRow';
import Vendors from '@/models/Vendors';
import Catalogs from '@/models/Catalogs';
import Validation from '@/components/utils/Validations';
import UpdateBomQty from '../modals/UpdateBomQty.vue';

export default defineComponent({
  emits:['updateMatItemQty', 'dateMatItemQty', 'cellClicked', 'updateItemQty'],
	props: {
    rowData: {
      type: Object,
      default: () => {},
    },
    rowIndex: Number,
    edit: {
      type: Boolean,
      default: false,
    },
		class: String,
		stage: String,
    tab: {
      type: Object,
      default: () => {},
    },
    refreshCard: Function,
    // mm prop is only for schedule list flyout
    mm: {
      type: Object,
      default: () => {},
    },
    catalogCollection: {
      type: Array,
      default: () => [],
    },
    deliveryDetails: {
      type: [Array, Object],
      default: () => [],
    },
  },
  components: {
    MfTable,
    ExcelImport,
    CardTags,
    MfNotes,
    TableGutter,
    ActivityLog,
    'qty-input': QtyInput,
    'cat-id-field': catIdField,
    'icon-field': iconField,
  },
  setup(props, { emit }) {
    let { rowData } = props;

    const {
      tab, refreshCard, edit, mm,
    } = props;
    const store = useStore();
    const toast = useToast();
    const router = useRouter();
    const emitter = inject('emitter');
    const detailTable = ref(null);
    const route = useRoute();
    const { saveRow } = PrefabMixin();
    const { exportItems } = CardEditMixin();
    const state = reactive({
      tableData: [],
      loadTable: 0,
      orders: [],
      data: {},
      excelModal: false,
      dates: [],
      isLoading: false,
      unscheduledRow: {},
      fetchUnderDelivered: {},
      activityModalOpen: false,
      activityDetails: {
        id: '',
        projectId: '',
        orderName: '',
      },
      mL: {
        items: [],
      }, // for masterLabel shipping order
      updatedMasterShipment: {},
      rerenderMfTable: 0,
      userOptions: [],
      categories: [],
      allVendors: [],
      currentRowVendors: [],
      itemQtyUpdated: false,
      rowDeleted: false,
      catCollection: props.catalogCollection,
    });
    const { addFromCatalog, mItemSubstitute } = AddFromCatalogMixin();
    const {
      addItemFromInv,
      fetchUnscheduledRow,
      fetchUnderDelivered,
      removePartialShipment,
      scheduleDelivery,
      markOrderFinal,
      releaseToInv,
      updateShipment,
    } = GeneralShippingMixin();
    const row = store.state.activeScreen === 'schedule' ? mm : rowData;
    const isLocallyFiltered = computed(() => [
      'prefab-edit',
      'material-edit-preparation',
      'material-edit-sourcing',
      'material-edit-ordering'].includes(store.state.activeScreen));
    const itemLimit = computed(() => {
      let key = 'prefab';
      if (props.rowData?.instanceOf === 'BaseOrder') {
        if (row.isMM()) key = 'mm';
        if (row.isPM()) key = 'pm';
        if (row.isPO() || row.isProductionTemplate()) key = 'po';
      }

      return store.state.itemLimits[key];
    });

    const importableLimit = computed(() => itemLimit.value - rowData?.items?.length);

    const maxAddable = computed(() => itemLimit.value - state.tableData.length);

    const getRequiredDates = computed(() => {
      let dates = [
        {
          kind: 'coordDate',
          label: 'Coordination',
        },
        {
          kind: 'deliverDate',
          label: 'Onsite',
        },
      ];
      if (rowData instanceof BaseOrder && rowData.isMM()) {
        dates = [
          {
            kind: 'orderBy',
            label: 'orderBy',
          },
          {
            kind: 'shipBy',
            label: 'shipBy',
          },
        ];
      }
      if (rowData instanceof BaseOrder && rowData.isPM()) {
        dates = [
          {
            kind: 'startDate',
            label: 'Start Date',
          },
          {
            kind: 'finishDate',
            label: 'Finish Date',
          },
        ];
      }
      if (rowData instanceof BaseOrder && (rowData.isPO()
        || rowData.isProductionTemplate())) {
        dates = [
          {
            kind: 'coordDate',
            label: 'Coordination',
          },
          {
            kind: 'deliverDate',
            label: 'Onsite',
          },
        ];
      }

      return dates;
    });

    const deleteRow = (opts) => {
      opts.data.delete();
    };
    const getApiMode = () => {
      if (store.state.activeScreen === 'shipping-edit') { return true; }
      return !tab?.tableProps?.isCardView;
    };

    const loadData = () => {
      if (store.state.activeScreen === 'schedule') {
        const items = get(mm, 'items', []);
        return items;
      }
      let items = get(rowData, tab.accessor || 'items', []);
      if (['prefab-edit', 'material-edit-preparation', 'material-edit-sourcing'].includes(store.state.activeScreen)) {
        items = items.filter((i) => i.archived.value === false);
        return LocalSort.filter(items, detailTable?.value?.sort);
      }
      state.currentRowVendors = items;
      return items;
    };

    const itemQtyChanged = async (newQty, item) => {
      // update item quantity
      item.selectedQty = newQty;
      rowData.items.map((rowItem) => {
        if (rowItem.uid === item.uid) {
          rowItem.available = item.initialAvailable || item.actualQty;
          rowItem.quantity = item.quantity;
          rowItem.selectedQty = item.selectedQty;
        }
        return rowItem;
      });
    };

    const setPartQuantity = () => {
      state.partQtyUpdated = true;
    };

    const updatedQty = computed(() => {
      if (state.partQtyUpdated || state.rowDeleted) return true;
      return false;
    });

    const loadAsyncData = async () => {
      if (store.state.activeScreen === 'schedule' && rowData?.isDetailRowEnabled) {
        const items = get(props.mm, 'items', []);
        return { data: items, total: items.length };
      }
      if (['shipping-order-view'].includes(store.state.activeScreen)) {
        rowData = await Shipping.getPartialShipments({ shippingLabelId: rowData._id });
      }
      // sort the accessor based on name and asc order
      if (!isEmpty(state.updatedMasterShipment) && tab.id === 'deliveries') {
        rowData = state.updatedMasterShipment;
      }
      state.tableData = LocalSort.filter(
        get(rowData, tab.accessor || 'items' || 'partialShipments'),
        {
          sortField: 'name',
          direction: 'asc',
        },
        true,
      );
      if (store.state.activeScreen === 'assemblies' && rowData?.assemblies) {
        state.tableData = concat(state.tableData, rowData.assemblies);
        state.tableData = LocalSort.filter(state.tableData,
          {
            sortField: 'name',
            direction: 'asc',
          },
          true);
      }

      if (['items', 'partialShipments'].includes(tab.accessor)) {
        state.tableData.map((doc) => {
          doc.instanceOf = rowData.__t ? 'BaseOrder' : 'ShippingLabel';
          doc.__t = rowData.__t;
          doc._customStage = rowData._customStage;
          return doc;
        });
      }

      /*
      * add unscheduled row for deliveries tab (shipping-edit)
      * if unscheduled items exist
      */
      if (['shipping-edit'].includes(store.state.activeScreen) && route.params.cardId !== 'add' && tab.accessor === 'partialShipments') {
        state.mL = rowData; // masterShippingLabel
        state.unscheduledRow = fetchUnscheduledRow(rowData, state.tableData);
        state.fetchUnderDelivered = fetchUnderDelivered(rowData);
        if (!isEmpty(state.fetchUnderDelivered)) {
          state.tableData.unshift(state.fetchUnderDelivered);
          const tableRow = find(state.tableData, { _id: 'underDelivered' });
          set(tableRow, 'underDeliverRowActive', true);
        }
        if (!isEmpty(state.unscheduledRow)) {
          state.tableData.unshift(state.unscheduledRow);
          const tableRow = find(state.tableData, { _id: 'unscheduled' });
          set(tableRow, 'unscheduledRowActive', true);
        }
      }

      // set a unique id for each item
      if (tab.id === 'items') {
        for (const item of state.tableData) {
          if (item.source && item.source.from === 'inventory') {
            set(item, 'isInventoryItem', true);
          }
          if (['shipping-edit'].includes(store.state.activeScreen) && route.params.cardId === 'add') {
            set(item, 'quantity', item.available || 0);
            set(item, 'selectedQty', item.available || 0);
          }
          if (!item.uid) {
            set(item, 'uid', uuid());
          }
        }
      } else if (tab.accessor === 'partialShipments') {
        state.tableData = _.filter(state.tableData, (delivery) => !_.isEmpty(delivery.items))
        for (let i = 0; i < state.tableData.length; i++) {
          state.tableData[i].items.forEach((item) => {
            set(item, 'uid', uuid());
          });
        }
      }

      // filter non-archived data
      state.tableData = state.tableData.filter((item) => !item.archived?.value);
      return { data: state.tableData, total: state.tableData.length };
    };

    const getLoadData = () => {
      if (getApiMode()) return loadAsyncData;
      return loadData;
    };

    const addItem = (item) => {
      item = rowData.items[rowData.addItem(item)];
      const index = rowData.items.indexOf(item);
      rowData.items[index].newFTD('memos');
      item.isEditing = true;
    };

    const refreshTable = async () => {
      const { activeScreen } = store.state;
      if (!['schedule', 'assemblies', 'parts'].includes(activeScreen)) {
        // This condition should only work for Material cards
        if (find(props.tab.tableProps.fields, (field) => field.id === 'catId')
          && ((has(props, 'rowData.isMM') && props.rowData.isMM())
            || (has(props.catalogCollection, 'rowData.isMaterialTemplate') && props.rowData.isMaterialTemplate()))) {
          const diffCatId = differenceBy(props.rowData.items, state.catCollection, 'catId');
          const catIds = diffCatId.length > 0 ? compact(map(diffCatId, 'catId')) : [];
          const excelCatIds = catIds.length > 0 ? await Catalogs.getAssemblyParts({
            catId: catIds,
            type: 'Parts',
            limit: 1000,
          }) : catIds;
          if (excelCatIds.data) {
            state.catCollection.push(...excelCatIds.data);
          }
        }
      }
      state.loadTable++;
      await detailTable.value.refreshTable();
    };

    const setDefaultVendor = (isChecked, vendor) => {
      if (state.currentRowVendors.length) {
        for (let i = 0; i < state.currentRowVendors.length; i++) {
          if (state.currentRowVendors[i]._id === vendor._id && isChecked) {
            state.currentRowVendors[i].isDefault = true;
          } else {
            state.currentRowVendors[i].isDefault = false;
          }
        }
      }
    };

    const setVendor = async (emptyRow, selectedVendor) => {
      assign(selectedVendor, emptyRow);
    };

    const cancelDelivery = (async (shipment) => {
      const confirmParams = {
        title: 'Are you sure you want to cancel the Shipment ?',
        message: 'This shipment will return to Schedule State',
        okButton: 'Ok',
        cancelButton: 'Cancel',
        type: 'danger',
        onCancel: () => {},
        rootClass: 'danger-dialog is-header-danger',
        onConfirm: async () => {
          try {
            state.isLoading = true;
            const order = await shipment.cancelDelivery();
            if (order) {
              const getIdx = state.tableData.findIndex((data) => data.id === order.id);
              remove(state.tableData, { id: order.id });
              state.tableData.splice(getIdx, 0, order);
              state.isLoading = false;
              toast.success('Shipment cancelled successfully');
              emit('refreshCardViewOnKey', true);
            }
          } catch (e) {
            console.log('error occured while cancelling shipment', e);
            toast.error('Failed to cancel shipment !');
            state.isLoading = false;
          }
        },
      };
      DialogProgrammatic.confirm(confirmParams);
    });

    const validateCostCode = (assemblyParts) => {
      if (!isEmpty(assemblyParts.costCode)) {
        forEach(assemblyParts.costCode, (costCode) => {
          if (!isEmpty(costCode.code)) {
            costCode.code = costCode.code.trim();
            costCode.code = costCode.code.replace(/ /g, '.');
          }
        });
      }
    };

    const validationChecks = (assemblyParts) => {
      let errMsg = null;
      const keyedParts = countBy(concat(assemblyParts.parts, assemblyParts.assemblies), 'catId');
      let duplicatePart;
      forEach(keyedParts, (value, key) => { if (value === 2) duplicatePart = key; });
      if (isEmpty(assemblyParts.name) || assemblyParts.name.trim().length < 3) {
        errMsg = 'Assembly Name should be atleast 3 characters long!';
      } else if (!isEmpty(assemblyParts.catId) && !inRange(assemblyParts.catId.length, 4, 33)) {
        errMsg = 'CatId should be 4 to 32 character long';
      } else if (!isEmpty(assemblyParts.catId) && /^[A-Za-z0-9._-]+$/.test(assemblyParts.catId) === false) {
        errMsg = 'CatId can only contain alpha-numeric characters, periods, dashes and underscores';
      } else if (!isUndefined(duplicatePart)) {
        errMsg = `Contains Duplicate Part/Assembly with Name ${duplicatePart}`;
      }
      // Todo: validate for parts
      if (errMsg) {
        toast.error(errMsg);
        return false;
      }
      return true;
    };

    const validateVendorsinParts = (partRow) => {
      let errMsg = null;
      const keyedVendors = countBy(partRow.vendor, 'name');
      let duplicateVendor;
      forEach(keyedVendors, (value, key) => { if (value >= 2) duplicateVendor = key; });
      if (!isUndefined(duplicateVendor)) {
        errMsg = `${duplicateVendor} Vendor is already present in part`;
        remove(partRow.vendor, (vRow) => vRow.newRow && vRow.name === duplicateVendor);
      }
      if (partRow.vendor.length) {
        const newAddedVendors = partRow.vendor.filter((vRow) => vRow.newRow);
        newAddedVendors.forEach((ven) => {
          ven.vendorName = ven.name;
        });
        // check if any of the vendor has empty name
        if (some(newAddedVendors, (vendor) => isEmpty(vendor.name))) {
          errMsg = 'Vendor Name can not be empty';
        }
        // pref vendors should not be more than 3;
        let totalPrefVendor = 0;
        for (const vendor of partRow.vendor) {
          totalPrefVendor += (vendor.isPreferred) ? 1 : 0;
          if (totalPrefVendor > 3) {
            errMsg = 'More than Three Preferred Vendor selected. Please select maximum 3 as Preferred';
            break;
          }
        }
      }

      if (errMsg) {
        toast.error(errMsg);
        return false;
      }
      return true;
    };

    const updateRow = async (assemblyParts) => {
      if (assemblyParts.__t === 'AssemblyItems') {
        for (const part of assemblyParts.parts) {
          if (!parseFloat(part.quantity)) part.quantity = 1;
        }
      }
      state.isLoading = true;
      try {
        // validate cost code
        validateCostCode(assemblyParts);

        if (!isEmpty(assemblyParts.catId)) {
          assemblyParts.catId = Validation.getValidCatId(assemblyParts.catId);
        }

        if (validationChecks(assemblyParts)) {
          const newAssemblyParts = remove(assemblyParts.parts, (part) => part?.newRow && part?.__t === 'AssemblyItems');
          assemblyParts.assemblies = concat(assemblyParts.assemblies, newAssemblyParts);
          assemblyParts = await Catalogs.updateAssemblyParts(assemblyParts);
          if (!isEmpty(assemblyParts.failedData)) {
            const errMessage = get(assemblyParts.failedData[0], '_errorMsg', 'Please Contact ManufactOn Support');
            toast.error(errMessage);
          }
          if (!isEmpty(assemblyParts.data)) {
            let toastMsg = 'Updated.';
            if (store.state.activeScreen === 'assemblies') {
              toastMsg = 'Updated Assembly.';
            } else if (store.state.activeScreen === 'parts') {
              toastMsg = 'Updated Parts.';
            }
            toast.success(toastMsg);
            state.rerenderMfTable++;
          }
        }
      } catch (err) {
        console.log('err', err);
        toast.error(`${err.data.message || err.data.msg || err.data || 'There was some error creating assembly'}`);
      }
      state.isLoading = false;
    };

    // TODO: to create different Util file for cell Clicked.
    const onTableCellClicked = async (params) => {
      const { data, type, event } = params;
      if (type === 'three-dot') {
        return;
      } else if (type === 'quantityUpdate') {
        state.itemQtyUpdated = true;
        emit('updateMatItemQty', state.itemQtyUpdated);
        if (props.rowData.productionOrder) {
          ModalProgrammatic.open({
            component: UpdateBomQty,
            trapFocus: true,
            canCancel: false,
            rootClass: 'modal-xs',
            events: {
              'refresh-table': async () => {
              },
              'save-matCard': async () => {
                await props.rowData.save();
                await props.refreshCard();
              },
              'refresh-cancel': async () => {
                await props.refreshCard();
              },
            },
          });
        }
        return;
      } else if (type === 'scheduleDelivery') {
        state.isLoading = true;
        data.name = `Revived of ${data.name}`;
        await scheduleDelivery(data, refreshTable);
        state.isLoading = false;
      } else if (type === 'activity') {
        state.activityModalOpen = true;
        state.activityDetails = {
          id: data._id,
          projectId: data.project._id,
          orderName: data.name,
        };
      } else if (type === 'printQr') {
        const reqObj = {
          shippingId: data._id,
          projectId: data.project._id,
          tzinfo: new Date().getTimezoneOffset(),
          printSerial: false,
          type: 'shipping',
        };
        const qrLabel = await Shipping.getShippingLabel(reqObj);
        await store.getters.userPromise;
        const user = store.state.userData;
        Print.printQrLabel(qrLabel, user);
      } else if (event === 'addNew') {
        if (store.state.activeScreen === 'shipping-edit') {
          ModalProgrammatic.open({
            component: AddFromInventory,
            props: {
              forShipping: true,
              card: rowData,
            },
            trapFocus: true,
            canCancel: false,
            rootClass: 'modal-sm',
            events: {
              addItem: async (itemsToAdd) => {
                state.isLoading = true;
                const itemsData = Object.values(itemsToAdd);
                if (!isEmpty(itemsData)) {
                  await addItemFromInv(rowData, itemsData);
                }
                state.isLoading = false;
                await refreshCard();
              },
            },
          });
        } else {
          const baseItem = { isEditing: true };
          addItem(baseItem);
        }
      } else if (event === 'excelImport') {
        state.excelModal = true;
      } else if (type === 'delete') {
        if (!['planning', 'coordination'].includes(data.stage) && store.state.activeScreen === 'prefab-edit') {
          DialogProgrammatic.confirm({
            title: 'Error',
            message: 'This prefab item is already part of an order that is past coordination stage and hence cannot be deleted.',
            okButton: 'OK',
            type: 'danger',
            onCancel: () => {},
            rootClass: 'danger-dialog is-header-danger',
            onConfirm: () => {
              state.isLoading = false;
            },
          });
          return;
        }
        if (rowData?.shipType === 'm' || rowData?.shipType === 's-m') {
          state.isLoading = true;
          const key = data.catId !== '' ? 'catId' : '_id';
          for await (const sl of rowData.partialShipments) {
            const removeFrom = sl.shipType === 's-m' ? 'fromSelf' : 'fromPartial';
            for await (const item of sl.items) {
              if (data[key]?.toString() === item[key]?.toString() && item.quantity > 0) {
                sl.removeItem(item, removeFrom, 0);
                await sl.save()
                break;
              }
            }
            // New shipping label generated after removing a item.
            if(store.state.activeScreen === 'shipping-edit' && removeFrom === 'fromSelf') {
              await router.push({
                path: '/logistics/shipping/order-view',
              });
            }
          }
          await refreshCard();
          state.isLoading = false;
        } else if (store.state.activeScreen === 'assemblies') {
          // for assemblies
          remove(data.__t === 'UpcItems' ? rowData.parts : rowData.assemblies, (part) => data?._id === part?._id || data.catId === part.catId);
          /* below code for enabling save button after a part is deleted in assemblies flyout */
          state.rowDeleted = true;
        } else if (store.state.activeScreen === 'parts') {
          // for parts
          remove(rowData.vendor, (vendor) => {
            if (vendor.uid) {
              return data.uid === vendor.uid;
            }
            return data._id === vendor._id;
          });
        } else {
          deleteRow(params);
        }
      } else if (type === 'cancelShip') {
        await cancelDelivery(data);
      } else if (type === 'save') {
        let order = cloneDeep(rowData);
        state.isLoading = true;
        set(data, 'isEditing', !data.isEditing);
        const itemIdx = findIndex(order.items, (item) => item._id === data._id);
        if (itemIdx !== -1) {
          order.items[itemIdx] = data;
          order = await saveRow(order);
          Object.assign(rowData, order);
        } else if (itemIdx === -1 && !isEmpty(data.parentShipment)) {
          // for delivery row save
          const partialIdx = findIndex(order.partialShipments,
            (partial) => partial._id === data._id);
          order.partialShipments[partialIdx] = data;
          order = await Shipping.update(data);
          Object.assign(rowData, order);
        } else if (order.shipType === 's-m') {
          order = await Shipping.update(data);
        }
        state.isLoading = false;
        if (!isUndefined(refreshCard)) {
          await refreshCard();
        }
      } else if (type === 'split') {
        // split delivery
        ModalProgrammatic.open({
          component: SplitDeliveries,
          props: {
            card: data,
            masterShipment: state.mL,
          },
          trapFocus: true,
          canCancel: false,
          rootClass: 'modal-sm',
          events: {
            refreshTab: async (mS) => {
              state.isLoading = true;
              state.updatedMasterShipment = mS;
              // set updated masterShipment id
              if (mS.shipType === 'm') {
                localStorage.setItem('shipmentId', mS._id);
              }
              state.isLoading = false;
              await refreshTable();
              await refreshCard();
            },
            close: async () => {
              forEach(get(data, 'items', []), (item) => {
                item.actualQty = item.quantity;
                item.selectedQty = 0;
              });
            },
          },
        });
      } else if (type === 'receive') {
        // Receive delivery
        ModalProgrammatic.open({
          component: ShippingReceive,
          props: {
            shippingLabel: data,
          },
          trapFocus: true,
          canCancel: false,
          rootClass: 'modal-sm',
        });
      } else if (type === 'markFinal') {
        try {
          state.isLoading = true;
          await markOrderFinal(data, () => {
            state.isLoading = true;
            emit('refreshCardViewOnKey', true);
          }, state);
        } catch (e) {
          console.log('error', e)
        } finally {
          state.isLoading = false;
        }
      } else if (type === 'releaseToInv') {
        state.isLoading = true;
        const deliveryLoc = get(data.delivery, 'currentLocation', {});
        await releaseToInv(deliveryLoc, data, refreshTable, state, () => {
          state.isLoading = true;
          emit('refreshCardViewOnKey', true);
        });
        state.isLoading = false;
      } else if (type === 'createDelivery') {
        // Create delivery
        ModalProgrammatic.open({
          component: CreateDeliveries,
          props: {
            card: data,
            masterShipment: state.mL,
          },
          trapFocus: true,
          canCancel: false,
          rootClass: 'modal-sm',
          events: {},
        });
      } else if (type === 'deleteDelivery') {
        if (['fulfilled', 'zombie', 'in-transit', 'not-started'].includes(data.status)) {
          let msgToDisplay = '';
          if (['fulfilled', 'zombie'].includes(data.status) && data.shipType === 's') {
            msgToDisplay = 'All items will be returned to the status \'Not Scheduled\'. The Shipping Order  will be removed. Continue?';
          } else {
            msgToDisplay = 'All items will be returned to their source locations. The Shipping Order will be removed. Continue?';
          }

          if (data.itemKind === 'Sourcing' && data.shipType === 's-m' && every(data.items, (item) => item.source.from === 'card'
            && !['fulfilled', 'zombie'].includes(data.status))) {
            if (!data.delivery.deliveryStart) {
              toast.error('Please enter Ship Date');
              return;
            }
          }
          msgToDisplay = 'All items will be moved to Unscheduled, and the Delivery will be deleted.';

          DialogProgrammatic.confirm({
            title: 'DELETE DELIVERY',
            message: msgToDisplay,
            okButton: 'Delete',
            cancelButton: 'Cancel',
            type: 'danger',
            onCancel: () => {},
            rootClass: 'danger-dialog is-header-danger',
            onConfirm: async () => {
              state.isLoading = true;
              await removePartialShipment(rowData, data).then(async () => {
                await refreshCard();
              });
              state.isLoading = false;
            },
          });
        }
      } else if (event === 'addFromCatalog') {
        ModalProgrammatic.open({
          component: AddFromCatalog,
          props: {
            module: row.__t,
            projectId: row.project._id,
            maxAddable: maxAddable.value,
            rowData,
            row,
            title: 'Select Items From Catalog',
          },
          trapFocus: true,
          canCancel: false,
          rootClass: 'modal-sm',
          events: {
            close: async (itemsToAdd) => {
              state.isLoading = true;
              if (!isEmpty(itemsToAdd)) {
                await addFromCatalog({
                  itemsToAdd,
                  rowData: row,
                  data: rowData,
                  route,
                  refreshTable,
                  activeScreen: store.state.activeScreen,
                });
              }
              state.isLoading = false;
            },
          },
        });
      } else if (type === 'substitute') {
        ModalProgrammatic.open({
          component: AddFromCatalog,
          props: {
            module: rowData.__t,
            projectId: rowData.project._id,
            maxAddable: maxAddable.value,
            isSubstitute: true,
            substituteItem: params.data.name,
            title: 'Select An Item Substitute',
            substitutedItem: cloneDeep(params.data),
          },
          trapFocus: true,
          canCancel: false,
          rootClass: 'modal-sm',
          events: {
            close: async ({ selectedRow: newItem, substitutedItem }) => {
              await mItemSubstitute(newItem, params, rowData, refreshTable, substitutedItem);
            },
          },
        });
      } else if (event === 'excel-export') {
        await exportItems(params, rowData, route);
        if (!isUndefined(refreshCard)) await refreshCard();
      } else if (event === 'addPart') {
        ModalProgrammatic.open({
          component: CreatePartInAssembly,
          trapFocus: true,
          canCancel: false,
          rootClass: 'modal-sm',
          props: {
            rowData,
          },
          events: {
            close: async ({ cancel }) => {
              // after close of create part modal
              if (!cancel) {
                await refreshTable();
              }
            },
          },
        });
      } else if (event === 'savePart') {
        await updateRow(rowData);
        await refreshTable();
      } else if (event === 'addVendor') {
        const emptyRow = AddRow.createEmptyRow(tab.tableProps.fields);
        emptyRow.uid = uuid();
        state.vendorUID = emptyRow.uid;
        rowData.vendor.unshift(emptyRow);
      } else if (event === 'saveVendor') {
        if (!validateVendorsinParts(rowData)) return;
        await updateRow(rowData);
        await refreshTable();
        // emit('cell-clicked', params);
      } else if (type === 'cancel' && store.state.activeScreen === 'assemblies') {
        remove(rowData.parts, (part) => !part._id && (part.uid === params?.data?.uid));
      } else if (type === 'cancel' && store.state.activeScreen === 'parts' && params.data?.newRow) {
        remove(rowData.vendor, (vendor) => {
          if (vendor.uid) {
            return data.uid === vendor.uid;
          }
          return data._id === vendor._id;
        });
      }
      if (event !== 'rejectItems' && !['shipping-edit', 'kit-view-edit'].includes(store.state.activeScreen)) {
        await refreshTable();
      }
      if (['refresh-details', 'refresh-table'].includes(type) || ['kit-view-edit', 'shipping-edit'].includes(store.state.activeScreen)) {
        emit('cell-clicked', params);
      }
      if (type === 'leadTime') {
        emit('cell-clicked', params);
      }
    };

    const closeModal = () => {
      state.excelModal = false;
    };

    const fetchUsers = async (pId) => Projects.linkedProjectUsers([pId]);

    const fetchUserOptions = async () => {
      const projectId = rowData?.project?.id;
      state.userOptions = await fetchUsers(projectId);
    };

    onMounted(async () => {
      state.dates = getRequiredDates;
      await fetchUserOptions();
      if (store.state.activeScreen === 'parts') {
        const { data: vendorsData } = await Vendors.getVendors({
          sort: { sortField: 'name', direction: 'asc' },
          type: 'Parts',
          limit: 9999,
        });
        state.allVendors = vendorsData;
      }
    });

    emitter.on('refreshDelTable', async () => {
      await refreshCard();
    });

    const eventHandler = (event) => {
      emit('cell-clicked', { type: event });
    };
    watch(
      () => edit,
      () => {
        if (get(mm, 'items', []).length) {
          mm.items.forEach((item) => {
            item._isEditing = edit;
          });
        }
      },
      { immediate: true },
    );
    const isCatalogDisabled = computed(() => {
      if (['delivery', 'complete'].includes(mm?.stage)) return true;
      return false;
    });

    const setItemQtyLimit = (item) => {
      if (!rowData._id) {
        return (item.initialAvailable || item.available || item.quantity);
      }
      if (tab.id === 'items' && store.state.activeScreen === 'shipping-edit') {
        return item.actualQty;
      }
      return item.available;
    };
    const orderStage = computed(() => {
      if (includes(['shipping-edit'], store.state.activeScreen)) {
        return rowData.status;
      }
      return rowData.stage;
    });

    const updateShippingLabel = async (shippinglabel) => {
      await updateShipment(shippinglabel, false);
      await refreshCard();
    };

    const getItemDeliveries = (item) => {
      const tempArray = [];
      const res = item._id === '000000000000000000000000' ? props.deliveryDetails[item.catId]
        : props.deliveryDetails[item._id];
      if (!isEmpty(res)) {
        res.forEach((i) => {
          if (i.quantity) {
            tempArray.push({ deliveryName: i.slName, deliveryItemQty: i.quantity });
          }
        });
      }
      return tempArray;
    };

    return {
      ...toRefs(state),
      getLoadData,
      deleteRow,
      onTableCellClicked,
      detailTable,
      closeModal,
      saveRow,
      setDefaultVendor,
      getRequiredDates,
      refreshTable,
      getApiMode,
      get,
      isCatalogDisabled,
      eventHandler,
      itemQtyChanged,
      setItemQtyLimit,
      orderStage,
      updateShippingLabel,
      isLocallyFiltered,
      getItemDeliveries,
      setVendor,
      setPartQuantity,
      updatedQty,
      importableLimit,
    };
  },
});
</script>
<style scoped>
::v-deep(.is-pulled-right.buttons button:first-child:hover i) {
  color: white !important;
}
</style>
