const defaultStatus = {
  shippingStatus: [
    { name: 'Scheduled', value: 'not-started', id: 'not-started' },
    { name: 'In Transit', value: 'in-transit', id: 'in-transit' },
    { name: 'In Storage', value: 'in-storage', id: 'in-storage' },
    { name: 'Complete', value: 'fulfilled', id: 'fulfilled' },
    { name: 'Inventory', value: 'released-to-inventory', id: 'released-to-inventory' },
    { name: 'Consumed', value: 'consumed', id: 'consumed' },
    { name: 'Mixed', value: 'mixed', id: 'mixed' },
  ],
  types: [
    { name: 'Materials', value: 'materials', id: 'materials' },
    { name: 'Prefab', value: 'prefabsFilter', id: 'prefabsFilter' },
  ],
  materialStage: {
    'mm-preparation': [
      { name: 'Requesting', value: 'preparation', id: 'preparation' },
      { name: 'Sourcing', value: 'sourcing', id: 'sourcing' },
      { name: 'Ordering', value: 'ordering', id: 'ordering' },
      { name: 'Complete', value: 'complete', id: 'complete' },
      { name: 'Delivery', value: 'delivery', id: 'delivery' },
      { name: 'In-Transit', value: 'in-transit', id: 'in-transit' },
      { name: 'Mixed', value: 'mixed', id: 'mixed' },
      { name: 'Rejected', value: 'rejected', id: 'rejected' },
      { name: 'Inventory', value: 'released-to-inventory', id: 'released-to-inventory' },
    ],
    'mm-sourcing': [
      { name: 'Sourcing', value: 'sourcing', id: 'sourcing' },
    ],
    'mm-ordering': [
      { name: 'Delivery', value: 'delivery', id: 'delivery' },
      { name: 'Scheduled', value: 'not-started', id: 'not-started' },
      { name: 'In-Transit', value: 'in-transit', id: 'in-transit' },
      { name: 'Mixed', value: 'mixed', id: 'mixed' },
    ],
    all: [
      { name: 'Requesting', value: 'preparation', id: 'preparation' },
      { name: 'Sourcing', value: 'sourcing', id: 'sourcing' },
      { name: 'Ordering', value: 'ordering', id: 'ordering' },
      { name: 'Delivery', value: 'delivery', id: 'delivery' },
      { name: 'Inventory', value: 'released-to-inventory', id: 'released-to-inventory' },
      { name: 'In-Transit', value: 'in-transit', id: 'in-transit' },
      { name: 'Rejected', value: 'rejected', id: 'rejected' },
      { name: 'Complete', value: 'complete', id: 'complete' },
      { name: 'Mixed', value: 'mixed', id: 'mixed' },
    ],
  },
  orderStage: {
    planning: [
      { name: 'Planning', value: 'planning', id: 'planning' },
      { name: 'Coordination', value: 'coordination', id: 'coordination' },
      { name: 'Detailing', value: 'detailing', id: 'detailing' },
      { name: 'Manufacturing', value: 'manufacturing', id: 'manufacturing' },
      { name: 'QA/QC', value: 'qa', id: 'qa' },
      { name: 'Delivery', value: 'delivery', id: 'delivery' },
      { name: 'Complete', value: 'complete', id: 'complete' },
      { name: 'In-Transit', value: 'in-transit', id: 'in-transit' },
      { name: 'Mixed', value: 'mixed', id: 'mixed' },
      { name: 'Inventory', value: 'released-to-inventory', id: 'released-to-inventory' },
    ],
    coordination: [
      { name: 'Coordination', value: 'coordination', id: 'coordination' },
    ],
  },
  status: [
    { name: 'Not Started', value: 'not-started', id: 'not-started' },
    { name: 'In Progress', value: 'in-progress', id: 'in-progress' },
    { name: 'Paused', value: 'pause', id: 'pause' },
    { name: 'Complete', value: 'complete', id: 'complete' },
  ],
  productionStatusStages: [
    { name: 'Planning', value: 'planning', id: 'planning' },
    { name: 'Coordination', value: 'coordination', id: 'coordination' },
    { name: 'Detailing', value: 'detailing', id: 'detailing' },
    { name: 'Manufacturing', value: 'manufacturing', id: 'manufacturing' },
    { name: 'QA/QC', value: 'qa', id: 'qa' },
    { name: 'Delivery', value: 'delivery', id: 'delivery' },
    { name: 'Complete', value: 'complete', id: 'complete' },
    { name: 'In-Transit', value: 'in-transit', id: 'in-transit' },
    { name: 'Mixed', value: 'mixed', id: 'mixed' },
    { name: 'Inventory', value: 'released-to-inventory', id: 'released-to-inventory' },
  ],
  materialStatusStages: [
    { name: 'Requesting', value: 'preparation', id: 'preparation' },
    { name: 'Sourcing', value: 'sourcing', id: 'sourcing' },
    { name: 'Ordering', value: 'ordering', id: 'ordering' },
    { name: 'Complete', value: 'complete', id: 'complete' },
    { name: 'Delivery', value: 'delivery', id: 'delivery' },
    { name: 'In-Transit', value: 'in-transit', id: 'in-transit' },
    { name: 'Mixed', value: 'mixed', id: 'mixed' },
    { name: 'Rejected', value: 'rejected', id: 'rejected' },
    { name: 'Inventory', value: 'released-to-inventory', id: 'released-to-inventory' },
  ],
  requestTypes: [
    { name: 'Stock', value: 'stock', id: 'stock' },
    { name: 'Pick', value: 'pick', id: 'pick' },
  ],
  taskStatus: [
    { name: 'Not Started', value: 'not-started', id: 'not-started' },
    { name: 'In Progress', value: 'in-progress', id: 'in-progress' },
    { name: 'Complete', value: 'complete', id: 'complete' },
  ],
  lmvStage: [
    { name: 'Planning', value: 'planning', id: 'planning' },
    { name: 'Coordination', value: 'coordination', id: 'coordination' },
    { name: 'Detailing', value: 'detailing', id: 'detailing' },
    { name: 'Manufacturing', value: 'manufacturing', id: 'manufacturing' },
    { name: 'QA/QC', value: 'qa', id: 'qa' },
    { name: 'Requesting', value: 'preparation', id: 'preparation' },
    { name: 'Sourcing', value: 'sourcing', id: 'sourcing' },
    { name: 'Ordering', value: 'ordering', id: 'ordering' },
    { name: 'Scheduled', value: 'not-started', id: 'not-started' },
    { name: 'In Transit', value: 'in-transit', id: 'in-transit' },
    { name: 'In Storage', value: 'in-storage', id: 'in-storage' },
    { name: 'Complete', value: 'fulfilled', id: 'fulfilled' },
    { name: 'Inventory', value: 'released-to-inventory', id: 'released-to-inventory' },
    { name: 'Consumed', value: 'consumed', id: 'consumed' },
    { name: 'Delivery', value: 'delivery', id: 'delivery' },
    { name: 'Mixed Shipments', value: 'mixed-shipping', id: 'mixed-shipping' },
  ],
  submittalResponse: [
    { name: 'No Workflow', value: 'noWorkflow', id: 'noWorkflow' },
    { name: 'Pending', value: 'pending', id: 'pending' },
    { name: 'Submitted', value: 'submitted', id: 'submitted' },
    { name: 'Approved', value: 'approved', id: 'approved' },
    { name: 'Approved As Noted', value: 'approvedAsNoted', id: 'approvedAsNoted' },
    { name: 'For Record Only', value: 'forRecordOnly', id: 'forRecordOnly' },
    { name: 'Rejected', value: 'rejected', id: 'rejected' },
    { name: 'Revise And Resubmit', value: 'reviseAndResubmit', id: 'reviseAndResubmit' },
    { name: 'Void', value: 'void', id: 'void' },
  ],
  todoTypes: [
    { name: 'Planning', value: 'Planning', id: 'Planning' },
    { name: 'Coordination', value: 'Coordination', id: 'Coordination' },
    { name: 'Detailing', value: 'Detailing', id: 'Detailing' },
    { name: 'Manufacturing', value: 'Manufacturing', id: 'Manufacturing' },
    { name: 'QA/QC', value: 'Quality Assurance', id: 'Quality Assurance' },
    { name: 'Delivery', value: 'Delivery', id: 'Delivery' },
    { name: 'Constraint', value: 'Constraint', id: 'Constraint' },
    { name: 'Purchase', value: 'Purchase', id: 'Purchase' },
    { name: 'Paused', value: 'Pause', id: 'Pause' },
    { name: 'Material', value: 'Material', id: 'Material' },
  ],
  todoStatus: [
    { name: 'Not Started', value: 'Not Started', id: 'Not Started' },
    { name: 'In Progress', value: 'In Progress', id: 'In Progress' },
    { name: 'Inactive', value: 'Inactive', id: 'Inactive' },
    { name: 'Complete', value: 'Complete', id: 'Complete' },
    { name: 'QC Fail', value: 'QC Fail', id: 'QC Fail' },
    { name: 'QC Inspect', value: 'QC Inspect', id: 'QC Inspect' },
  ],
  module: [
    { name: 'Production Order', value: 'ProductionOrder', id: 'ProductionOrder' },
    { name: 'Material Manager', value: 'MaterialManager', id: 'MaterialManager' },
    { name: 'Prefab Package', value: 'PrefabPackage', id: 'PrefabPackage' },
  ],
  newStage: [
    { name: 'Planning', value: 'planning', id: 'planning' },
    { name: 'Coordination', value: 'coordination', id: 'coordination' },
    { name: 'Detailing', value: 'detailing', id: 'detailing' },
    { name: 'Manufacturing', value: 'manufacturing', id: 'manufacturing' },
    { name: 'QA/QC', value: 'qa', id: 'qa' },
    { name: 'Requesting', value: 'requesting', id: 'requesting' },
    { name: 'Sourcing', value: 'sourcing', id: 'sourcing' },
    { name: 'Ordering', value: 'ordering', id: 'ordering' },
    { name: 'Scheduled', value: 'not-started', id: 'not-started' },
    { name: 'In Transit', value: 'in-transit', id: 'in-transit' },
    { name: 'In Storage', value: 'in-storage', id: 'in-storage' },
    { name: 'Complete', value: 'fulfilled', id: 'fulfilled' },
    { name: 'Inventory', value: 'released-to-inventory', id: 'released-to-inventory' },
    { name: 'Consumed', value: 'consumed', id: 'consumed' },
    { name: 'Delivery', value: 'delivery', id: 'delivery' },
    { name: 'Mixed Shipments', value: 'mixed-shipping', id: 'mixed-shipping' },
  ],
  oldStage: [
    { name: 'Planning', value: 'planning', id: 'planning' },
    { name: 'Coordination', value: 'coordination', id: 'coordination' },
    { name: 'Detailing', value: 'detailing', id: 'detailing' },
    { name: 'Manufacturing', value: 'manufacturing', id: 'manufacturing' },
    { name: 'QA/QC', value: 'qa', id: 'qa' },
    { name: 'Requesting', value: 'requesting', id: 'requesting' },
    { name: 'Sourcing', value: 'sourcing', id: 'sourcing' },
    { name: 'Ordering', value: 'ordering', id: 'ordering' },
    { name: 'Scheduled', value: 'not-started', id: 'not-started' },
    { name: 'In Transit', value: 'in-transit', id: 'in-transit' },
    { name: 'In Storage', value: 'in-storage', id: 'in-storage' },
    { name: 'Complete', value: 'fulfilled', id: 'fulfilled' },
    { name: 'Inventory', value: 'released-to-inventory', id: 'released-to-inventory' },
    { name: 'Consumed', value: 'consumed', id: 'consumed' },
    { name: 'Delivery', value: 'delivery', id: 'delivery' },
    { name: 'Mixed Shipments', value: 'mixed-shipping', id: 'mixed-shipping' },
  ],
  orderStatus: [
    { name: 'Not Started', value: 'not-started', id: 'not-started' },
    { name: 'In Progress', value: 'in-progress', id: 'in-progress' },
    { name: 'Paused', value: 'pause', id: 'pause' },
    { name: 'Complete', value: 'complete', id: 'complete' }],
};

defaultStatus.stage = [...defaultStatus.lmvStage];
defaultStatus.orderStage.schedule = [...defaultStatus.materialStage.all];
defaultStatus.materialStage['material-status-item-view'] = [...defaultStatus.materialStage['mm-preparation']];
defaultStatus.orderStage['production-status-item-view'] = [...defaultStatus.orderStage.planning];
defaultStatus.status['shipping-order-view'] = [...defaultStatus.shippingStatus];

export default defaultStatus;
