/* eslint no-restricted-syntax: "off" */
import _ ,{ forEach }from 'lodash';
import uuid from 'uuid/v4';
import moment from 'moment';
import { DeepDiff } from 'deep-diff';
// import { DialogProgrammatic } from '@/components/Dialog';
import Validation from './Validations';
// import { Dialog as BDialog } from 'buefy/dist/components/dialog';
import Prefabs from './Prefabs';
import SupplyChain from './SupplyChain';
import Shipping from './Shipping';
import Catalogs from './Catalogs';
import Helper from './Helper';
import Vendors from './Vendors';
import urls from '../urls';
import Validations from './Validations';

const renameRuns = urls.version === 12;
const runLabel = {
  run: renameRuns ? 'work step' : 'run',
  Run: renameRuns ? 'Work step' : 'Run',
  runs: renameRuns ? 'work steps' : 'runs',
  Runs: renameRuns ? 'Work Steps' : 'Runs',
};
const ftds = ['todos', 'files', 'dates', 'memos'];
// these classes supposed to attach functions to save, add any ftd, and index ftd in a
// manner that they become easier to work with in UI
function modelMap() {
  return {
    /* eslint-disable global-require */
    Prefabs: require('./Prefabs').default,
    ProductionOrder: require('./Orders').default,
    manager: require('./ProductionManager').default,
    Materials: require('./MaterialManager').default,
    Sourcing: require('./MaterialManager').default,
    ProdTemplates: require('./ProductionTemplates').default,
    MatTemplates: require('./MaterialTemplates').default,
  };
}

const Material = modelMap().Materials;

const stageMap = {
  'not-started': 'scheduled', // Doing this since its a requirement from US team
  'released-to-inventory': 'inventory',
  fulfilled: 'complete',
};
function getStageMap(stage) {
  return stageMap[stage] ? stageMap[stage] : stage;
}

const Dialog = {
  alert(...all) {
    /* eslint no-unused-vars: ["error", { "args": "none" }] */
    // BDialog.alert(all);
  },
};
class FtdIndexed {
  _place;

  _accessor = null;

  order;

  _id;

  archived = { value: false };

  name = '';

  uuid = null;

  simpleDates = {};

  simpleTodos = [];

  simpleFiles = [];

  constructor(place, order, itemObj) {
    delete itemObj.order; // so that it is not overwritten when assigning
    delete itemObj._place;
    Object.assign(this, itemObj);
    this.order = order;
    this._place = place;
    this.uuid = this.uuid || uuid();
    ftds.forEach((ftd) => {
      const defaultVal = ftd === 'dates' ? {} : [];
      if (order.indices && order.indices[ftd] && !_.isUndefined(this._id)) {
        this[`simple${_.capitalize(ftd)}`] = order.indices[ftd][`${place}.${this._id}`] || defaultVal;
      } else {
        this[`simple${_.capitalize(ftd)}`] = defaultVal;
      }
    });
  }

  newFTD(type, kind = null) {
    // below code is to find an existing memo and return instead of creating new all time 
    // when saving the order
    if (type === 'memos' && this._accessor === 'items') {
      const memo = _.find(this.order.memos, (m) => {
        return _.some(m.sources, { _id: this._id || this.uuid })
      })
      if (!_.isEmpty(memo)) { return memo };
    }
    return this.order.newFTD(type, { [this._place]: this }, kind);
  }

  newDate(kind) {
    return this.newFTD('dates', kind);
  }

  newFile() {
    return this.newFTD('files');
  }

  newTodo() {
    return this.newFTD('todos');
  }

  addOrUpdateDate(kind, value) {
    const item = this;
    if (!item.simpleDates[kind]) item.newDate(kind);
    item.simpleDates[kind].value = value;
  }

  getDate(kind) {
    const item = this;
    if (!item.simpleDates[kind]) item.newDate(kind);
    return item.simpleDates[kind].value;
  }

  delete({ force } = { force: false }) {
    if (this._id && !force) {
      // removing the saved item forms before archiving.
      _.forEach(this.simpleFiles, (removingFile) => {
        removingFile.archived.value = true;
        if (removingFile.type === 'form') {
          this.order.deleteForm({ doc: removingFile });
        }
      });
      // archiving qa/qc from for run.
      if (this.form && this.form.id) {
        const taskId = this.form.id;
        const task = _.find(
          this.order.simpleTodos,
          simpleTodo => {
            return !_.isEmpty(simpleTodo.form) && simpleTodo.form.id === taskId;
          }
        );
        if (task) {
          task.archived.value = true;
        }
      }
      this.archived.value = true;
      return;
    }
    // this is N^2 right now, please improve this if possible.
    // We are removing FTDs of an new item, which was removed and not saved even once.
    ftds.forEach((ftd) => {
      const uuids = _.map(this[`simple${_.capitalize(ftd)}`], 'uuid');
      _.forEachRight(this.order[ftd], (f, index) => {
        if (uuids.includes(f.uuid)) this.order[ftd].splice(index, 1);
      });
    });
    const inOrder = _.get(this.order, this._accessor);
    const idx = _.findIndex(inOrder, { uuid: this.uuid });
    if (idx >= 0) inOrder.splice(idx, 1);
  }
}

class Item extends FtdIndexed {
  _accessor = 'items';

  combinedQuantity = false;

  archived = { value: false };

  quantity = 1;

  qa = {
    assignedTo: null,
  };

  costCode = '';

  measure = null;

  measureUnits = '';

  unitCost = 0;

  totalCost = 0;

  totalItemsCost = 0;

  runCount = 0;

  inRunCount = 0;

  leadTime = 0;

  installLocs = [];

  constructor(order, itemObj) {
    super('item', order, itemObj);
    Object.assign(this, itemObj);
    // will try to move this call inside leadTimeCal()
    if (this.order.isMM() || this.order.isSourcing()) {
      const orderByDate = this.calculateOrderBy(this);
      if (_.isEmpty(this.order.simpleDates.orderBy)) {
        this.order.addOrUpdateDate('orderBy', orderByDate);
      } else this.calculateEarliestOrderDate();
      this.addOrUpdateDate('orderBy', orderByDate);
      if (this.order.simpleDates.deliver) {
        const onsiteByDate = this.order.simpleDates.deliver;
        if (onsiteByDate && onsiteByDate.value) {
          this.addOrUpdateDate('deliver', onsiteByDate.value);
        }
      }
      const deliverDate = this.order.simpleDates.deliver;
      if (deliverDate && deliverDate.value) {
        this.addOrUpdateDate('deliver', deliverDate.value);
      }
    }
  }

  // eslint-disable-next-line consistent-return
  calculateOrderBy(item = {}) {
    const leadTime = !_.isEmpty(item) ? item.leadTime : (_.max(_.compact(_.map(this.order.items, 'leadTime'))) || 0);
    if (this.order.simpleDates && this.order.simpleDates.deliver) {
      const orderOnSiteDate = this.order.simpleDates.deliver.value;
      const orderByDate = this.order.getDateDiff(orderOnSiteDate, leadTime);
      return orderByDate;
    }
  }

  calculateEarliestOrderByDate() {
    const orderByDate = this.calculateOrderBy();
    this.order.addOrUpdateDate('orderBy', orderByDate);
    for (const item of this.order.items) {
      // eslint-disable-next-line max-len
      const itemOrderByDate = this.order.getDateDiff(this.order.simpleDates.deliver.value, item.leadTime);
      item.addOrUpdateDate('orderBy', itemOrderByDate);
    }
  }

  calculateEarliestOrderDate() {
    const orderByDate = this.calculateOrderBy();
    this.order.addOrUpdateDate('orderBy', orderByDate);
  }

  get quantityNeeded() {
    const val = (this.qtyToConsume + this.qtyToShip) || this.quantity;
    return _.round(val, 2);
  }

  set quantityNeeded(val) {
    this.quantity = _.round(val, 4);
  }

  get calcUnitCost() {
    return this.unitCost;
  }

  set calcUnitCost(val) {
    this.unitCost = _.round(val, 2) || 0;
    this.calcItemTotalCost();
    this.order.calcTotalCost();
  }

  calcItemTotalCost() {
    this.totalCost = parseFloat(this.unitCost) * parseFloat(this.quantity);
    this.totalCost = _.round(this.totalCost, 2) || 0;
  }

  get runs() {
    const runRiMap = this.order.manager._runItemsIndexMap;
    return _.filter(this.order.manager.runs, run => runRiMap[run.uuid][this.uuid] >= 0);
  }

  get ris() {
    const runRiMap = this.order.manager._runItemsIndexMap;
    return this.runs.map((run) => {
      const riIdx = runRiMap[run.uuid][this.uuid];
      const ri = run.runItemsCount[riIdx];
      ri._runName = run.name;
      return ri;
    });
  }

  get stageToShow() {
    return getStageMap(this.stage);
  }

  get isCompleted() {
    if (!['detailing', 'manufacturing', 'qa'].includes(this.stage)) {
      return true;
    }
    if (this.ris && this.ris.length > 0) {
      return _.every(this.ris, ri => ri.riCompleted);
    }
    return false;
  }

  set isCompleted(val) {
    return this.ris.forEach((ri) => { ri.riCompleted = val; });
  }

  get autoHoursonQty() {
    return this.quantity;
  }

  set autoHoursonQty(value) {
    this.quantity = value;
    for (const run of this.runs) {
      for (const rnItem of run.runItemsCount) {
        // Needed this since run.runItem.quantity.thisRun is not updated..
        rnItem.quantity.thisRun = rnItem._item.quantity;
      }
      run.setTotalPlannedHrs();
    }
  }

  getItemLeadTime(params) {
    const {
      type, fromExcel, item, selectedVendor,
    } = params;
    const existingCatalogs = _.keyBy(params.existingCatalogs, 'catId');
    if (type === 'leadTime' && fromExcel && _.isFinite(parseFloat(item.leadTime))) {
      return;
    }
    if (_.keys(existingCatalogs).includes(item.catId) && selectedVendor) {
      const matchedVendor = _.find(
        existingCatalogs[item.catId].vendor,
        v => v._id === selectedVendor._id,
      );
      if (type === 'unitCost') {
        if (!_.isEmpty(matchedVendor)) {
          item.calcUnitCost = matchedVendor[type] > 0 ? matchedVendor[type] : null;
          item.totalCost = item.quantity * parseFloat(item.unitCost);
        } else {
          item.calcUnitCost = null;
        }
      } else if (type === 'leadTime') {
        item.leadTime = (!_.isEmpty(matchedVendor)
          && matchedVendor[type]) ? matchedVendor[type] : 0;
      }
    } else if (_.isEmpty(selectedVendor) && _.keys(existingCatalogs).includes(item.catId)) {
      if (existingCatalogs[item.catId] && existingCatalogs[item.catId].vendor) {
        const defaultVendor = _.find(existingCatalogs[item.catId].vendor, { isDefault: true });
        item.leadTime = !_.isEmpty(defaultVendor)
          && defaultVendor.leadTime ? defaultVendor.leadTime : 0;
      } else {
        item.leadTime = 0;
      }
    }
  }
}

class RunItemsCount {
  static fieldsToClone = ['_id', 'runId', 'plannedHrs', 'actualHrs', 'percComplete', 'pf',
    'riCompleted', 'quantity', 'serials', 'status', 'revision', 'ReturnHistory'];

  constructor(riObj) {
    Object.assign(this, riObj);
    if (!_.isEmpty(this.run)) {
      const totalPlannedHrs = this.run.hours * this.quantity.thisRun;
      this.run.stats.plannedHrs += totalPlannedHrs;
    }
  }

  get uuid() {
    return this._item.uuid;
  }

  get run() {
    if (this._item.order._indexedRuns) {
      return this._item.order._indexedRuns[this.runId];
    }
    return {};
  }

  get runItemQty() {
    return this.quantity.thisRun;
  }

  set runItemQty(value) {
    this.quantity.thisRun = value;
    this.run.setTotalPlannedHrs();
  }
}

class Run extends FtdIndexed {
  _accessor = 'manager.runs';

  archived = { value: false };

  runItemsCount = [];

  copiedFromId;

  hours = 0;

  stats = {
    plannedHrs: null,
    actualHrs: null,
    pf: null,
  }

  completed = false;

  viewIndex = 0;

  isTracked = false;

  constructor(order, runObj) {
    super('run', order, runObj);
    Object.assign(this, runObj);
    const indexedItems = this.order._indexedItems;
    // filter out RIs for whom item doesn't exist in the order.
    // for whatever reason
    this.runItemsCount = _.filter(this.runItemsCount, ri => !!indexedItems[ri._id])
      .map((ri) => {
        ri._item = indexedItems[ri._id];
        indexedItems[ri._id]._runs.push(runObj);
        return new RunItemsCount(ri);
      });
    const run = this;
    this.runItemsCount.push = ele => Array.prototype.push.call(run.runItemsCount, new RunItemsCount(ele));
  }

  get isCompleted() {
    // if (typeof this._completed === 'undefined') {
    if (this.form && this.form._id && this.formStatus !== 'complete' && _.every(this.runItemsCount, { riCompleted: true })) {
      return this.completed === false;
    }
    this.completed = _.every(this.runItemsCount, { riCompleted: true });
    return this.completed;
  }

  set isCompleted(val) {
    if (val && _.some(this.runItemsCount, ri => (ri._item.stage === 'detailing'
      && ri._item.purpose === 'general'))) {
      Dialog.alert(`Unable to mark ${runLabel.run} as complete because one of `
        + 'the items in this run is in detailing stage.');
      // DialogProgrammatic.confirm({
      //   title: 'Warning',
      //   message: `Unable to mark ${runLabel.run} as complete because one of `
      //   + 'the items in this run is in detailing stage.',
      //   okButton: 'Ok',
      //   type: 'danger',
      // });
      return;
    }
    for (const ri of this.runItemsCount) {
      ri.riCompleted = ri._item.stage === 'qa' ? ri.riCompleted : val;
    }
    this.completed = val;
    if (val) this.stats.percComplete = 100;
    if (!val && this.stats.percComplete === 100) {
      this.stats.percComplete = 95;
      _.forEach(this.runItemsCount, (ri) => { ri.percComplete = 95; });
    }
  }

  get autoHours() {
    return this.hours;
  }

  set autoHours(value) {
    this.hours = value;
    this.stats.plannedHrs = 0;
    this.runItemsCount = _.uniqBy(this.runItemsCount, 'uuid');
    this.setTotalPlannedHrs();
  }
  
  get autoActualHours() {
    return this.stats.actualHrs || 0;
  }

  set autoActualHours(value) {
    this.stats.actualHrs = value;
    const splitActualHrs= (value / this.runItemsCount.length);
    forEach(this.runItemsCount, (runItem) => runItem.actualHrs = splitActualHrs);
  }

  get calcPf() {
    let pf = 'N/A';
    if (this.stats && this.stats.plannedHrs && this.stats.percComplete) {
      const res = (this.stats.actualHrs * 100) / (this.stats.plannedHrs * this.stats.percComplete);
      if (res >= 0) pf = res;
    }
    return { pf };
  }

  setTotalPlannedHrs() {
    let totalPlannedHrs = 0;
    this.stats.plannedHrs = 0;
    for (const ri of this.runItemsCount) {
      totalPlannedHrs = this.hours * ri.quantity.thisRun;
      this.stats.plannedHrs += totalPlannedHrs;
    }
  }

  async save() {
    this.order.manager.runs = [this];
    return this.order.save();
  }

  addQaQcForm(form) {
    this.form = { _id: form._id };
    this.hasNewForm = true;
    this.formStatus = form.status;
  }

  changeQaQcFormStatus(status) {
    this.formStatus = status;
  }
}

class Manager extends FtdIndexed {
  _runItemsIndexMap = {};

  runs = [];

  notes = '';

  qaNotes = '';

  tags = [];

  constructor(order, itemObj) {
    super('manager', order, itemObj);
    Object.assign(this, itemObj);
  }

  addRun() {
    if (!this.runs) this.runs = [];
    const run = {
      uuid: uuid(),
      owner: JSON.parse(JSON.stringify(this.owner)),
      location: this.order && this.order.__t === 'ProdTemplates' ? {} : JSON.parse(JSON.stringify(this.location)),
    };
    return this.runs[this.runs.push(new Run(this.order, run)) - 1];
  }

  runItemsIndexMap() {
    const map = {};
    let totalPlannedHrs = 0;
    this.runs.forEach((run) => {
      run.stats.plannedHrs = 0;
      map[run.uuid] = {};
      run.runItemsCount.forEach((ri, index) => {
        map[run.uuid][ri.uuid] = index;
        totalPlannedHrs = run.hours * ri.quantity.thisRun;
        run.stats.plannedHrs += totalPlannedHrs;
      });
    });
    this._runItemsIndexMap = map;
  }

  getDefaultRi({ run, item }) {
    return new RunItemsCount({
      _item: item,
      _id: item._id,
      runId: run._id,
      riCompleted: false,
      quantity: {
        total: item.quantity,
        thisRun: item.quantity,
      },
      actualHrs: 0,
      plannedHrs: (_.clone(run.hours) || 0),
      percComplete: 0,
    });
  }
}

class BaseOrder {
  static runItemLimit = 200;

  name = '';

  idsMap = [];

  customId = '';

  purpose = 'general';

  stageUsed = 'all';

  isLocked = false;

  templateName = '';

  templateId = '';

  isDefault = false;

  estHrs = 0;

  dates = [];

  files = [];

  tags = [];

  items = [];

  memos = [];

  __t = 'Prefabs';

  multiTrade = { companies: [], value: false };

  owner = {
    company: { _id: '' },
    user: { _id: '' },
  };

  project = { _id: '', name: '' };

  stage = 'planning';

  todos = [];

  manager = {};

  extraCost = 0;

  totalCost = null;

  baseDelivery = {};

  isManager = false;

  indices = {
    dates: {},
    files: {},
    todos: {},
    memos: {},
  };

  leadDates = {
    coordination: null,
    detailing: null,
    manufacturing: null,
    partsManufacturing: null,
    qa: null,
    fab: null,
    available: null,
    ship: null,
  }

  leastStage = '';

  currentNote = {
    text: '',
  };

  createdFrom = {
    type: 'normal',
    deviceId: '',
  }

  _model = Prefabs;

  _customStage;

  _excludedFields = [];

  _jsonString;

  constructor(obj = {}) {
    Object.assign(this, obj);
    this.initFTDs();
    ftds.forEach((ftd) => {
      const defaultVal = ftd === 'dates' ? {} : [];
      this[`simple${_.capitalize(ftd)}`] = this.indices[ftd][`order.${this._id}`] || defaultVal;
    });

    this._model = modelMap()[this.isManager && !['Materials', 'ProdTemplates', 'MatTemplates'].includes(this.__t) ? 'manager' : this.__t];
    this.items = (this.items || [])
      .filter(i => !_.get(i, 'archived.value', false))
      .map((i) => {
        i._runs = [];
        return new Item(this, i);
      });
    this._indexedItems = _.keyBy(this.items, '_id');
    this.leastStage = this.getCardStage();
    if (this.manager) {
      this.manager = new Manager(this, this.manager);
      this.manager.runs = this.manager.runs
        .filter(i => !_.get(i, 'archived.value', false))
        .map(i => new Run(this, i))
        .sort((run1, run2) => run1.viewIndex - run2.viewIndex);
      this._indexedRuns = _.keyBy(this.manager.runs, '_id');
    }

    this._jsonString = JSON.stringify(this.toJSON());
    if (this.isSourcing()) {
      this.totalItemsCost = _.round(_.sumBy(
        this.items,
        item => item.quantity * parseFloat(item.unitCost),
      ) || 0, 2);
      this.totalCost = _.round(this.totalItemsCost + this.extraCost, 2);
    }
  }

  static getShipStageMap() {
    return {
      delivery: [
        'not-started',
        'in-transit',
        'mixed-shipping',
      ],
      complete: [
        'fulfilled',
        'released-to-inventory',
        'in-storage',
        'consumed',
      ],
    };
  }

  get stageToShow() {
    return getStageMap(this.stage);
  }

  get totalPlannedHrs() {
    let plannedHrs = 0;
    const runs = _.get(this, 'manager.runs', []);
    if (!_.isEmpty(runs)) {
      for (const run of runs) {
        for (const ri of run.runItemsCount) {
          plannedHrs += ri.plannedHrs;
        }
      }
    }
    return this.clockDisplay(plannedHrs, true);
  }

  get calcExtraCost() {
    return this.extraCost;
  }

  set calcExtraCost(val) {
    this.extraCost = _.round(val, 2);
    this.calcTotalCost();
  }

  calcTotalCost() {
    let total = 0;
    if (this.extraCost && parseFloat(this.extraCost) > 0) total += parseFloat(this.extraCost);
    if (!_.isEmpty(this.items)) {
      for (const item of this.items) {
        if (item.totalCost && parseFloat(item.totalCost) > 0) total += parseFloat(item.totalCost);
      }
    }
    this.totalCost = _.round(total, 2) || 0;
    this.totalItemsCost = _.round(this.totalCost - this.extraCost, 2) || 0;
  }

  get getTotalCost() {
    return this.totalCost;
  }

  get totalConsumedHrs() {
    let actualHrs = 0;
    const runs = _.get(this, 'manager.runs', []);
    if (!_.isEmpty(runs)) {
      for (const run of runs) {
        actualHrs += run.stats.actualHrs;
      }
    }
    return this.clockDisplay(actualHrs, true);
  }

  get totalItems() {
    let quantity = 0;
    const items = _.get(this, 'items', []);
    if (!_.isEmpty(items)) {
      for (const item of items) {
        quantity += item.quantity;
      }
    }
    return quantity;
  }

  addLeadingZero(val) {
    if (val < 10) return `0${val}`;
    return val;
  }

  clockDisplay(val, showSec = false) {
    let hrs = parseInt((val / 3600), 10);
    let mins = parseInt(((val % 3600) / 60), 10);
    const secs = parseInt(((val % 60)), 10);
    if (val < 3600 && !showSec) { hrs = 0; mins = 0; }
    let res = `${this.addLeadingZero(hrs)}:${this.addLeadingZero(mins)}`;
    if (showSec) res += `.${this.addLeadingZero(secs)}`;
    return res;
  }

  static appendShippingStages(stages) {
    stages = _.castArray(stages);
    const shippingStageMap = this.getShipStageMap();
    if (stages.includes('delivery')) {
      stages.push(...shippingStageMap.delivery);
    }
    if (stages.includes('complete')) {
      stages.push(...shippingStageMap.complete);
    }
    return stages;
  }

  // calling this function will only push the changes in FTDs, items, runs to server.
  // Unchanged entities will not be sent.
  async slimSave(showValidation = true) {
    this._slimSave = true;
    try {
      const savedCard = await this.save(showValidation);
      return savedCard;
    } catch (err) {
      throw err;
    }
  }

  // ************note: do not use this other than list view mass update************
  // use this only for mass date update in list view
  async saveNaked() {
    try {
      this.checkDateValidation();
      this._saveNaked = true;
      return this._update();
    } catch (err) {
      throw err;
    }
  }

  // this is one function which is to be called when saving a BO. It will decide if one api call
  // is to be made or two. two are required in case where a new FTD is added to a new Item or new
  // Run. Since the item/run won't have any _id, the FTD can't refer it. Hence, in first api call
  // the items/runs are assigned _id by server and then its attached to the FTD and sent with
  // another call
  async save(showValidation = true) {
    const validationObj = await this.basicValidation(showValidation);
    if (validationObj !== true) {
      if (showValidation) Dialog.alert(validationObj.msg);
      throw new Error(validationObj.msg || validationObj.title);
    }
    this.checkDateValidation();
    const _newFTDs = {};
    ftds.forEach((ftd) => {
      _newFTDs[ftd] = [];
      _.remove(this[ftd] || [], (f) => {
        if (!f.value && ftd === 'dates') return true;
        if (f.needsToAttachId) {
          _newFTDs[ftd].push(f);
          return true;
        }
        return false;
      });
    });
    try {
      // set the viewIndex = the real index for all runs. Better done here once before save
      if (this.isPM() || this.__t === 'ProdTemplates') {
        this.manager.runs.forEach((run, index) => { run.viewIndex = index; });
      }

      if (this.stage === 'manufacturing' && _.every(this.items, item => item.quantity === _.get(item, 'serials.unavailable', 0))) {
        Dialog.alert('No more items in Manufacturing - all items have been shipped. Order will be moved to shipping.');
      }

      const isCreate = (!this._id);
      let createdBo;
      try {
        createdBo = await (isCreate ? this._create() : this._update());
      } catch (e) {
        throw e;
      }
      if (!(createdBo instanceof BaseOrder)) {
        createdBo = new BaseOrder(createdBo);
      }

      // if at least one ftd kind needs re-assigned run second update
      if (isCreate || _.some(_newFTDs, ftdArray => ftdArray.length > 0)) {
        // map items, and runs as per their uuids. makes later lookup as O(1)
        if (isCreate) {
          Object.assign(createdBo, this, _.pick(createdBo, [
            'name', '_id', 'tags', 'project',
            'submittal', 'owner', 'items', 'lastModified', 'manager', 'templateId', 'uniqueOrderId'
          ]));
        }
        const holderWithId = {
          prefabs: { order: { _id: createdBo._id } },
          order: { order: { _id: createdBo._id } },
          material: { order: { _id: createdBo._id } },
          sourcing: { order: { _id: createdBo._id } },
          item: _.keyBy(createdBo.items || [], 'uuid'),
          run: _.keyBy(_.get(createdBo, 'manager.runs', []), 'uuid'),
          manager: { [this.manager.uuid]: { _id: createdBo.manager._id } },
        };

        ftds.forEach((ftd) => {
          createdBo[ftd] = (_newFTDs[ftd] || []).map((f) => {
            f.sources.forEach((src) => {
              if (!src._id || src._id.length !== 24) { // mongoid is 24 char string
                src._id = _.get(holderWithId[src.type][src._id], '_id');
              }
            });
            return f;
          });
        });
        createdBo.items = [];
        if (createdBo.isManager) createdBo.manager.runs = [];
        createdBo = await createdBo._update();
      }
      if (!(createdBo instanceof BaseOrder)) {
        createdBo = new BaseOrder(createdBo);
      }
      if (this._customStage) createdBo._customStage = this._customStage;
      delete createdBo._slimSave;
      this.lastModified = createdBo.lastModified;
      return createdBo;
    } catch (e) {
      if (!this._id) {
        // create call failed, re-attaching ftds
        ftds.forEach((ftd) => {
          if (!this[ftd]) this[ftd] = [];
          _newFTDs[ftd].forEach((elem) => {
            this[ftd].push(elem);
          });
        });
      }
      let errMsg = 'Error saving: please contact ManufactOn support';
      if (e.message) errMsg = e.message;
      else if (_.get(e, 'data.msg', '')) errMsg = e.data.msg;
      if (showValidation) Dialog.alert(errMsg);
      console.log('Error occured', e);
      throw e;
    }
  }

  async saveAndApply() {
    const card = await this.save();
    Object.assign(this, card);
  }

  async BOMQuantityCheck() {
    const card = this;
    let qtyBOMCheck = false;
    let partQty = 1;
    let materialCatIds = [];
    let materialOrder = [];
    const assmsIds = [];
    let indexedItems = [];
    let totalQuantity = 1;
    for (const item of card.items) {
      if (item.catId) assmsIds.push(item.catId);
    }
    if (assmsIds.length < 0) return false;
    if (card.isPrefab()) {
      indexedItems = _.filter(card.items, item => !_.isEmpty(item.catId));
      indexedItems = _.keyBy(indexedItems, 'catId');
    } else {
      const materialIds = _.map(card.materials, '_id');
      const updatedItem = _.find(card.items, item => item.qtyUpdated === true);
      let updatedItemId = _.findIndex(card.materials, material => updatedItem && material.card.linkedAssembly === updatedItem._id);
      updatedItemId = updatedItemId === -1 ? 0 : updatedItemId;
      materialOrder = await Material.getOne({
        cardId: materialIds[updatedItemId],
        projectId: card.project._id,
      });
      if (materialOrder.stage === 'sourcing') return 'sourcing';
      if (['delivery', 'complete', 'ordering'].includes(materialOrder.stage)) return 'ordering';
      if (_.isEmpty(materialOrder)) return false;
      indexedItems = _.keyBy(card.items, 'catId');
      if (materialOrder.items && materialOrder.items.length > 0) materialCatIds = _.map(materialOrder.items, 'catId');
    }
    const { data: assms } = await Catalogs.getAssemblyParts({
      catId: assmsIds,
    });
    if (!assms) return false;
    for (const assm of assms) {
      if (assm.parts.length > 0) {
        for (const p of assm.parts) {
          if ((card.isPrefab() && card.isKit() && (_.keys(indexedItems)).includes(assm.catId))
            || (materialCatIds.includes(p.catId))) {
            partQty = _.isNull(p.quantity) ? 1 : p.quantity;
            partQty = _.chain(partQty).toNumber().value();
            totalQuantity = partQty * indexedItems[assm.catId].quantity;
            if (totalQuantity > 9999) {
              qtyBOMCheck = true;
              break;
            }
          }
          // code to reset the materials if assembly quantity is changed and catQtyMap
          if (!_.isEmpty(card.catQtyMaps) && !_.isEmpty(card._beforeEdit)) {
            const oldItem = _.find(card._beforeEdit.items, { _id: indexedItems[assm.catId]._id });
            const catQtyPart = _.find(card.catQtyMaps, { catId: p.catId });
            if (oldItem.quantity !== indexedItems[assm.catId].quantity) {
              catQtyPart.qtyToConsume = (indexedItems[assm.catId].quantity * p.quantity);
              catQtyPart.qtyToShip = 0;
            }
          }
        }
        if (qtyBOMCheck) {
          // Dialog.alert('<strong>linked BOM item qunatity exceeded Max item Quantity limit please decrease the Quantity</strong>');
          throw new Error('linked BOM item qunatity exceeded max item Quantity limit please decrease the Quantity');
        }
      }
      if (assm.assemblies && assm.assemblies.length > 0) {
        for (const p of assm.assemblies) {
          if ((card.isPrefab() && card.isKit() && (_.keys(indexedItems)).includes(assm.catId))
            || (materialCatIds.includes(p.catId))) {
            partQty = _.isNull(p.quantity) ? 1 : p.quantity;
            partQty = _.chain(partQty).toNumber().value();
            totalQuantity = partQty * indexedItems[assm.catId].quantity;
            if (totalQuantity > 9999) {
              qtyBOMCheck = true;
              break;
            }
          }
          // code to reset the materials if assembly quantity is changed and catQtyMap
          if (!_.isEmpty(card.catQtyMaps) && !_.isEmpty(card._beforeEdit)) {
            const oldItem = _.find(card._beforeEdit.items, { _id: indexedItems[assm.catId]._id });
            const catQtyPart = _.find(card.catQtyMaps, { catId: p.catId });
            if (oldItem.quantity !== indexedItems[assm.catId].quantity) {
              catQtyPart.qtyToConsume = (indexedItems[assm.catId].quantity * p.quantity);
              catQtyPart.qtyToShip = 0;
            }
          }
        }
        if (qtyBOMCheck) {
          // Dialog.alert('<strong>linked BOM item qunatity exceeded Max item Quantity limit please decrease the Quantity</strong>');
          throw new Error('linked BOM item qunatity exceeded max item Quantity limit please decrease the Quantity');
        }
      }
    }
    return true;
  }

  resetOrderByDates() {
    const card = this;
    if (!card.isMM()) {
      return;
    }
    const items = _.filter(card.items, item => item.archived.value === false);
    const maxLeadTime = _.max(_.compact(_.map(items, 'leadTime'))) || 0;
    const onSiteDate = card.simpleDates.deliver;
    if (!_.isEmpty(onSiteDate) && !_.isEmpty(onSiteDate.value)) {
      const orderByDate = card.getDateDiff(onSiteDate.value, maxLeadTime);
      card.addOrUpdateDate('orderBy', orderByDate);
    }
  }

  async calcItemCostAndDate(parts = [], selectedVendor, type = 'unitCost', fromExcel = false) {
    const card = this;
    if (_.isEmpty(card.items) || card.items.length === 0) { return; }
    if (_.isEmpty(selectedVendor) && type === 'unitCost') {
      return;
    } if (_.isEmpty(selectedVendor)) {
      selectedVendor = card.baseDelivery.vendor;
    }
    let existingCatalogs = [];
    const catIds = _.compact(_.map(card.items, 'catId'));
    if (_.isEmpty(catIds)) return;
    if (_.isEmpty(parts)) {
      existingCatalogs = (await Catalogs.getAssemblyParts({
        catId: _.compact(catIds),
        type: 'UpcItems',
      })).data;
    } else {
      existingCatalogs = parts;
    }
    const params = {
      existingCatalogs,
      type,
      fromExcel,
      selectedVendor,
    };
    for (const item of card.items) {
      params.item = item;
      item.getItemLeadTime(params);
      if (type === 'leadTime') {
        const onSiteDate = card.simpleDates.deliver;
        if (onSiteDate && onSiteDate.value) {
          const itemOrderByDate = card.getDateDiff(onSiteDate.value, item.leadTime);
          item.addOrUpdateDate('orderBy', itemOrderByDate);
          item.addOrUpdateDate('deliver', onSiteDate.value);
        }
      }
    }
    if (type === 'leadTime') {
      const maxLeadTime = _.max(_.compact(_.map(card.items, 'leadTime'))) || 0;
      const orderDate = card.simpleDates.deliver;
      if (!_.isEmpty(orderDate)) {
        card.addOrUpdateDate('orderBy', card.getDateDiff(orderDate.value, maxLeadTime));
      }
    }
  }

  async attachVendors() {
    let indexedCategories = {};
    let existingCatalogs = [];
    const card = this;
    const categoryIds = _.map(card.items, 'category._id');
    const catIds = _.compact(_.map(card.items, 'catId'));
    if (_.isEmpty(categoryIds) && _.isEmpty(catIds)) return card;
    if (!_.isEmpty(catIds)) {
      existingCatalogs = (await Catalogs.getAssemblyParts({
        catId: _.compact(catIds),
        type: 'UpcItems',
      })).data;
    }
    existingCatalogs = _.keyBy(existingCatalogs, 'catId');
    if (!_.isEmpty(categoryIds)) {
      const { data: vendors } = await Vendors.getVendors({
        category: categoryIds,
      });
      if (vendors && vendors.length > 0) {
        indexedCategories = Helper.getCategoriesFromVendors(vendors);
      }
    }
    // console.log('indexedCategories', indexedCategories);
    for (const item of card.items) {
      if (!_.isEmpty(item.catId) && _.isEmpty(item.category)) {
        item.category = existingCatalogs[item.catId] ? existingCatalogs[item.catId].category : '';
      }
      item.vendors = item.vendors ? item.vendors : [];
      // const categoryVendorsToPush = indexedCategories[item.category._id];
      // if (categoryVendorsToPush) {
      //   categoryVendorsToPush.forEach((vendor) => {
      //     item.vendors.push(vendor);
      //   });
      // }
      if (item.catId && !_.isEmpty(existingCatalogs[item.catId])) {
        const vendorsToPush = existingCatalogs[item.catId].vendor;
        vendorsToPush.forEach((ven) => {
          if (!(item.vendors.some(v => v._id === ven._id))) {
            item.vendors.push(ven);
          }
        });
      }
      const defVendor = _.filter(item.vendors, { isDefault: true });
      if (defVendor) {
        if (defVendor.length > 1) {
          item.defVendor = _.find(defVendor, { name: item.activeSupplier });
        } else {
          item.defVendor = _.find(defVendor, { isDefault: true });
        }
      }
      item.vendors = _.uniqBy(item.vendors, '_id');
    }
    return card;
  }

  _create() {
    return this._model.create(this._slimSave ? this.toSlimJson() : this.toJSON());
  }

  _update() {
    return this._model.update(this._slimSave ? this.toSlimJson() : this.toJSON());
  }

  newFTD(ftdKind, level, kind, dateName) {
    let ftd = {
      value: '',
      sources: [],
      needsToAttachId: false,
      archived: { value: false },
      assignedTo: {},
      isDirty: true,
    };
    const stage = this._customStage || this.stage;
    if (ftdKind === 'dates') {
      ftd.kind = kind;
      ftd.stage = stage;
      if (kind === 'additional' && dateName) ftd.name = dateName;
    }
    const sourceType = stage === 'planning' ? 'prefabs' : 'order';
    const typeSource = stage === 'preparation' ? 'material' : 'sourcing';
    ftd.sources.push({
      _id: this._id || 'order',
      type: this.isMM() || this.isSourcing() ? typeSource : sourceType,
      stage,
    });

    if (!_.isString(level)) {
      _.forOwn(level, (val, key) => {
        ftd.sources.push({ _id: val._id || val.uuid, type: key, stage });
        if (!val._id) ftd.needsToAttachId = true;
      });
    }

    // this _id means that a second update call will be needed
    if (!this._id) ftd.needsToAttachId = true;

    const newLen = this[ftdKind].push(ftd);
    ftd = this[ftdKind][newLen - 1];

    const simpleAccessor = `simple${_.capitalize(ftdKind)}`;

    function addInSimpleIndex(place) {
      if (ftdKind === 'dates') {
        place[simpleAccessor][kind] = ftd;
      } else {
        place[simpleAccessor].push(ftd);
      }
    }

    if (level === 'order') { addInSimpleIndex(this); }
    if (level.item) { addInSimpleIndex(level.item); }
    if (level.run) { addInSimpleIndex(level.run); }
    if (level.manager) { addInSimpleIndex(level.manager); }

    return ftd;
  }

  addItem(itemObj = {}) {
    if (_.isUndefined(this.items)) {
      this.items = [];
    }
    const item = new Item(this, {
      uuid: uuid(),
      isNew: true,
      stage: this._customStage || this.stage,
      project: this.project,
      ...itemObj,
    });
    this.items.push(item);
    return this.items.length - 1;
  }

  async basicValidation() {
    this.customId = this.customId.trim();
    if (this.__t !== 'ProdTemplates' && this.isKit() && this.customId.length < 3) {
      Dialog.alert('Kit ID must contain at least 3 characters!');
      throw new Error('Kit ID Validation failed');
    }
    this.name = this.name.trim();
    if (this.name.length < 3) {
      return ({
        title: 'Name Validation failed',
        msg: 'Card name must contain at least 3 characters!',
      });
    } if (this.multiTrade.value) {
      if (_.findIndex(this.multiTrade.companies, ['_id', this.owner.company._id]) === -1) {
        return ({
          title: 'Company Validation failed',
          msg: "Cannot remove owner's company!",
        });
      }
    }
    if (['sourcing', 'ordering'].includes(this._customStage) && (_.isEmpty(this.items) || !_.some(this.items, r => !r.archived.value))) {
      return ({
        title: 'Item Validation failed',
        msg: 'Please add a Item to save the Order!',
      });
    }
    if ((['ordering'].includes(this._customStage) || (_.some(this.items, it => it.isMaterialItem && it.isNew)))
      && !_.isEmpty(this.items) && _.some(this.items, i => _.isEmpty(i.catId))) {
      return ({
        title: 'Item Validation failed',
        msg: 'Please add catId to Item!',
      });
    }
    if (this._beforeEdit && this._beforeEdit.financialId !== this.financialId && this.financialId !== '') {
      this.financialId = this.financialId.toString().trim().replace(/ /g, '.').toUpperCase();
      if (!Validations.validateOrderId(this.financialId)) {
        return ({
          title: 'OrderId Validation failed',
          msg: 'Description length must be 4 to 24 characters long and cannot start or end with special characters',
        });
      }
    }
    if (['ordering'].includes(this._customStage)) {
      // if catId, then must exist in catId Manager
      const existingCatalogs = await Catalogs.getAssemblyParts({
        catId: _.map(this.items, 'catId'),
        type: 'parts',
        limit: 9999,
      });
      const existingCatIds = _.map(_.get(existingCatalogs, 'data', []), 'catId');
      if (_.some(this.items, item => !existingCatIds.includes(item.catId))) {
        return ({
          title: 'Item Validation failed',
          msg: 'Catalog IDs present in 1 or more items do not exist in catalog ID manager.',
        });
      }
    }

    if (this.level) this.level = _.trim(this.level);
    else if (_.get(this, 'baseDelivery.level')) this.baseDelivery.level = _.trim(this.baseDelivery.level);

    if (this.zone) this.zone = _.trim(this.zone);
    else if (_.get(this, 'baseDelivery.zone')) this.baseDelivery.zone = _.trim(this.baseDelivery.zone);

    for (const item of this.items) {
      const regex = '^[A-Za-z0-9][A-Za-z0-9-._]*?[A-Za-z0-9]$';
      if (!item.archived.value) {
        if (_.isFinite(parseFloat(item.measure)) && _.isEmpty(item.measureUnits)) {
          return ({
            title: 'Item Validation failed',
            msg: `Please select measure unit for item ${item.name}`,
          });
        }
        if (item.name.length < 3) {
          return ({
            title: 'Item Validation failed',
            msg: 'Item name must be at least 3 characters.',
          });
        }
        if (!_.isEmpty(item.costCode)) {
          item.costCode = item.costCode.trim();
          item.costCode = item.costCode.replace(/ /g, '.');
          if (!_.inRange(item.costCode.length, 4, 25) || !RegExp(regex).test(item.costCode)) {
            return ({
              title: 'Item Validation failed',
              msg: 'Item cost code should be 4 to 24 character long and cannot start with special characters.',
            });
          }
        }
        if (item.quantity < 0.01) {
          return ({
            title: 'Item Validation failed',
            msg: 'Please enter item quantity',
          });
        }
        if ((this.__t === 'Materials' || this.isMaterialTemplate() || this.isKit() || item.isMaterialItem) && !_.isEmpty(item.catId)) {
          item.catId = item.catId.trim();
          item.catId = item.catId.replace(/ /g, '.');
          if (!_.inRange(item.catId.length, 4, 33) || !RegExp(regex).test(item.catId)) {
            return ({
              title: 'Item Validation failed',
              msg: 'Catalog ID should be 4 to 32 character long and cannot start with special characters',
            });
          }
        }
        if (!_.isEmpty(item.measureUnits) && !_.isFinite(parseFloat(item.measure))) {
          return ({
            title: 'Item Validation failed',
            msg: `Please select measure for item ${item.name}`,
          });
        }
        if (item.level) item.level = _.trim(item.level);
        if (item.zone) item.zone = _.trim(item.zone);
      }
    }
    for (const todo of this.simpleTodos) {
      if (!todo.archived.value && !todo.text) {
        return ({
          title: 'Checklist Validation failed',
          msg: 'Todo Description can not be empty',
        });
      }
      if (!todo.archived.value && todo.assignedTo.user === null) {
        return ({
          title: 'Checklist Validation failed',
          msg: `Please select an assignee for '${todo.text}'`,
        });
      }
      if (!['ProdTemplates', 'MatTemplates'].includes(this.__t) && !todo.archived.value && (!todo.dueDate || !moment(todo.dueDate).isValid())) {
        return ({
          title: 'Checklist Validation failed',
          msg: `Please specify a due date for '${todo.text}' Checklist`,
        });
      }
    }
    if (this.isPM() && !this._excludedFields.includes('runs')) {
      const filterItems = _.filter(this.items, i => !i.archived.value);
      if (filterItems.length > 0) {
        filterItems.forEach((i) => {
          i.inRunCount = 0;
          i.runCount = 0;
        });
        for (const run of this.manager.runs) {
          if (run.archived.value) continue;
          if (run.name.length < 1) {
            return ({
              title: `${runLabel.Run} Validation failed`,
              msg: `${runLabel.Run} '${run.name}' name should be at least 1 char`,
            });
          }
          if (run.runItemsCount.length === 0) {
            return ({
              title: `${runLabel.Run} Validation failed`,
              msg: `${runLabel.Run} '${run.name}' should contain at least one item`,
            });
          }
          run.runItemsCount.forEach((ri) => {
            ri._item.inRunCount = (ri._item.inRunCount || 0) + ri.quantity.thisRun;
            const idx = _.findIndex(this.items, { _id: ri._item._id });
            if (idx !== -1) this.items[idx].inRunCount = ri._item.inRunCount;
            if (!ri._item.runCount) ri._item.runCount = 0;
            ri._item.runCount++;
          });
        }
      }
      for (const item of this.items) {
        if (item.archived.value) continue;
        const spreadOver = (item.inRunCount || 0) / item.quantity;
        if (item.inRunCount === 0) {
          return ({
            title: 'Item Validation failed',
            msg: `Item '${item.name}' must be part of at least one run`,
          });
        }
        if ((spreadOver > 1 && spreadOver < item.runCount) || (item.inRunCount < item.quantity)) {
          return ({
            title: 'Quantity Validation failed',
            msg: `<p ><b class="is-size-4">Item quantity for '${item.name}' is not correctly distributed</b></p>
                  <p class="is-size-4"> In the Quantities tab, either set quantity to ${item.quantity} for all runs</p>
                  <center class="is-size-4">or</center>
                  <p class="is-size-4"> Make sure all quantities add up to ${item.quantity}</p>`,
          });
        }
        if (spreadOver === 0) {
          return ({
            title: 'Item Validation failed',
            msg: `Item '${item.name}' must be part of at least one ${runLabel.run}>`,
          });
        }
      }
    }
    return true;
  }

  checkDateValidation(source = 'orderSave', datesOrderParam) {
    const datesOrder = datesOrderParam || ['coord', 'orderBy', 'detailBy',
      'manufactureBy', 'qaBy', 'deliver'];
    const datesVal = datesOrder.map((date) => {
      const dateField = ['detailBy', 'manufactureBy', 'qaBy'].includes(date)
        ? this.manager.simpleDates : this.simpleDates;
      return _.get(dateField, `${date}.value`, -100) || -100;
    });
    if (_.some(datesVal, val => val === -100) && this.purpose === 'kit' && source !== 'massUpdate') {
      return { isValid: false };
    }
    let index = datesVal.length - 1;
    let lowIndex = index;
    while (index > 0 && lowIndex > 0) {
      lowIndex -= 1;
      if (datesVal[index] === -100) continue;
      if (datesVal[lowIndex] === -100) continue;
      if (moment(datesVal[lowIndex]).isAfter(datesVal[index], 'd')) {
        if (source === 'massUpdate') {
          const failedDateKinds = [datesOrder[lowIndex], datesOrder[index]];
          return { isValid: false, failedDateKinds };
        }
        Dialog.alert(`${datesOrder[lowIndex]} date should not
        be greater than ${datesOrder[index]} date!`);
        throw new Error('Date Validation Failed');
      }
      index = lowIndex;
    }
    return { isValid: true };
  }

  async materialsReadyToShip() {
    if (this._customStage !== 'manufacturing') return true;
    const { data } = await Helper.getMaterialItems(this);
    const readyToShip = !_.some(
      data,
      dt => (parseFloat(dt.qtyToConsume) + parseFloat(dt.qtyToShip)) > parseFloat(_.get(dt, 'reserved', 0)),
    );
    this.materialsReserved = readyToShip;
    return readyToShip;
  }

  readyForShipment() {
    // if (!this.isMM() && !this.isSourcing()) return true;
    const card = this;
    // removed orderBy, available and shipBy date validation as per #10642
    const dateKeys = ['deliver'];
    let errMsg;
    dateKeys.forEach((key) => {
      if (!moment(card.simpleDates[key].value).isValid()) {
        errMsg = `Please set the ${key} date first`;
        Dialog.alert(errMsg);
        throw new Error(errMsg);
      }
    });
    if (card.isMM()) {
      if (!_.get(card, 'baseDelivery.location', false)) {
        errMsg = 'Please add Delivery location before Shipping';
        Dialog.alert(errMsg);
        throw new Error(errMsg);
      }
      if (!_.get(card, 'baseDelivery.recipient', false)) {
        errMsg = 'Please add a Recipient before Shipping';
        Dialog.alert(errMsg);
        throw new Error(errMsg);
      }
      if (_.some(card.items, item => !Validation.validateCatalogId(item.catId))) {
        errMsg = 'Catalog ID should be 4 to 32 character long';
        Dialog.alert(errMsg);
        throw new Error(errMsg);
      }
      if (_.isEmpty(card.baseDelivery.vendor)) {
        errMsg = 'Vendor cannot be Empty. Please select a vendor';
        Dialog.alert(errMsg);
        throw new Error(errMsg);
      }
      if (card.items && card.items.length > 200) {
        errMsg = `Too many items to move this order.
            Please break it down into orders of 200 items or less, and then move.`;
        Dialog.alert(errMsg);
        throw new Error(errMsg);
      }
    }
    // return true;
  }

  isMM() {
    return this.__t === 'Materials';
  }

  isSourcing() {
    return this.__t === 'Sourcing';
  }

  isMaterialTemplate() {
    return this.__t === 'MatTemplates';
  }

  isProductionTemplate() {
    return this.__t === 'ProdTemplates';
  }

  isPrefab() {
    return this.__t === 'Prefabs';
  }

  isPO() {
    return this.__t === 'ProductionOrder' && !this.isManager;
  }

  isKit() {
    return this.purpose === 'kit';
  }

  isAssembly() {
    return this.purpose === 'assembly';
  }

  isPM() {
    return this.__t === 'ProductionOrder' && this.isManager === true;
  }

  managerDates() {
    return ['detailBy', 'manufactureBy', 'qaBy'];
  }

  datesOrder() {
    if (this.isKit()) {
      if (this.isPM()) {
        return ['coord', 'detailBy', 'partsManufactureBy', 'manufactureBy', 'qaBy', 'deliver'];
      }
      return ['coord', 'poDetailBy', 'partsManufactureBy', 'poManufactureBy', 'poQaBy', 'deliver'];
    }
    if (this.isPrefab() || this.isPM()) return ['coord', 'detailBy', 'manufactureBy', 'qaBy', 'deliver'];
    if (this.isPO()) return ['coord', 'poDetailBy', 'poManufactureBy', 'poQaBy', 'deliver'];
    if (this.isMM() || this.isSourcing()) return ['coord', 'orderBy', 'available', 'shipBy', 'deliver'];
    return ['coord', 'deliver'];
  }

  newDate(kind) {
    return this.newFTD('dates', 'order', kind);
  }

  addOrUpdateDate(kind, value) {
    let item = this;

    // code to put dates in PO outside of manger starts
    if (!item.simpleDates[kind]) item.newDate(kind);
    item.simpleDates[kind].value = value;
    // end here
    if (this.managerDates().includes(kind)) item = this.manager;
    if (!item.simpleDates[kind]) item.newDate(kind);
    item.simpleDates[kind].value = value;
  }

  getDate(kind) {
    let item = this;
    if (this.managerDates().includes(kind)) item = this.manager;
    if (!item.simpleDates[kind]) {
      item.newDate(kind);
    }
    return item.simpleDates[kind].value;
  }

  newFile(files) {
    if (!files) return this.newFTD('files', 'order');
    files = _.castArray(files);
    return files.map((f) => {
      const newFile = this.newFTD('files', 'order');
      _.assign(
        newFile,
        _.pick(f, ['url', 'name', 'visible', 'type']),
      );
      return newFile;
    });
  }

  newTodo() {
    this.newFTD('todos', 'order');
    return this.todos.length - 1;
  }

  fillBasicTemplateData(templateOrder, itemTemplateApplied = false) {
    const card = this;
    if (_.isEmpty(templateOrder)) return card;
    const templateNote = templateOrder.currentNote.text;
    card.templateOrder = templateOrder._id;
    card.templateLeadTime = templateOrder.leadTime;
    const toPick = ['activity', 'level', 'zone', 'notes', 'leadDates', 'tags'];
    _.assign(card, _.pick(templateOrder, toPick));
    if (_.isEmpty(card.name)) card.name = templateOrder.name;
    if (!_.isEmpty(templateNote) && itemTemplateApplied) {
      card.currentNote.text = ` ${templateNote}`;
    } else {
      card.currentNote.text += ` ${templateNote}`;
    }
    if (!card.financialId || card.financialId === '') {
      card.financialId = templateOrder.financialId;
    }
    card.notes = templateOrder.manager.notes;
    card.files = _.filter(templateOrder.files, file => file.type !== 'form');
    _.assign(card.manager, _.pick(templateOrder.manager, ['name', 'notes', 'qaNotes']));
    if (templateOrder.__t === 'ProdTemplates') {
      if (!_.get(templateOrder, 'orderCreator', false)) {
        card.owner = templateOrder.owner;
      }
      card.location = _.get(templateOrder, 'manager.location', {});
    } else if (templateOrder.__t === 'MatTemplates') {
      card.baseDelivery = { location: _.get(templateOrder, 'baseDelivery.location', {}) };
    }
    return card;
  }

  async canAddTemplateItems(templateOrder) {
    const card = this;
    const projectSetting = _.find(
      card.project.projectSettings,
      { companyId: _.get(card.owner, 'user.company', '') },
    );
    if (['Materials', 'Sourcing'].includes(card.__t) && projectSetting
      && projectSetting.isCatIdRequired) {
      // catIds can't be empty
      if (_.some(templateOrder.items, item => !item.catId)) {
        return `Can't add items of '${templateOrder.name}' template, as 1 or more items
          have empty Catalog ID.`;
      }
      // if catId, then must exist in catId Manager
      const existingCatalogs = await Catalogs.getAssemblyParts({
        catId: _.map(templateOrder.items, 'catId'),
        type: 'UpcItems',
      });
      const existingCatIds = _.map(existingCatalogs.data, 'catId');
      if (_.some(templateOrder.items, item => !existingCatIds.includes(item.catId))) {
        return `Can't add items of '${templateOrder.name}' template, as Catalog IDs present in 1 or
          more items do not exist in catalog ID manager.`;
      }
    }
    return null;
  }

  getDateDiff(originalDate, diff) {
    const tempDate = new Date(originalDate);
    return moment.utc(tempDate.setDate(tempDate.getDate() - diff)).hours(12).format();
  }

  addTemplateRuns(runs = [], selectedOrder) {
    const card = this;
    let startIndex = 0;
    let endIndex = 0;
    const totalRuns = runs.length;
    // eslint-disable-next-line
    for (const runIndex in runs) {
      const run = runs[runIndex];
      if (_.isEmpty(run.archived) || !run.archived.value) {
        const newRun = card.manager.addRun();
        _.assign(newRun, _.pick(run, ['name', 'hours', 'copiedFromId', 'owner', 'form', 'hasNewForm', 'formStatus', 'addTemplateOwner']));
        if (!_.isEmpty(newRun.form) && _.get(newRun, 'form._id', _.get(newRun, 'form.id', false)) && _.isUndefined(newRun.formStatus)) newRun.formStatus = 'notStarted';
        if (run && run.simpleFiles) {
          for (const file of run.simpleFiles) {
            const newFile = newRun.newFile();
            _.assign(newFile, _.pick(file, ['url', 'name', 'visible', 'copiedFrom', 'type']));
          }
        }
        this.addTemplateFormTodo(newRun, run, selectedOrder);
        if (card.items.length < 200) {
          card.addTemplateRi(newRun, card.items);
        } else {
          const itemsParRun = _.floor((card.items.length) / (runs.length));
          if (itemsParRun > 200) {
            Dialog.alert('Please  add more runs To template!');
            throw new Error('More then 200 Items par Run');
          } else {
            endIndex = startIndex + itemsParRun + 1;
            if (runIndex === (totalRuns - 1)) {
              card.addTemplateRi(newRun, _.slice(card.items, startIndex, card.items.length));
            } else {
              card.addTemplateRi(newRun, _.slice(card.items, startIndex, endIndex));
              startIndex = endIndex;
            }
          }
        }
        card.manager.runItemsIndexMap();
      }
    }
    return card;
  }

  getFormOrder(templateForm) {
    if (!_.isEmpty(templateForm.formData)) {
      return templateForm;
    }
    const card = this;
    let fb = {};
    const linkedTodo = _.find(card.todos, todo => (_.map(todo.files, 'url')).includes(templateForm.url));
    if (!_.isEmpty(linkedTodo)) {
      fb = _.find(linkedTodo.files, { url: templateForm.url });
    }
    return fb;
  }

  addTemplateFormTodo(newObj, oldObj, selectedOrder) {
    const owner = oldObj.owner || selectedOrder.owner;
    const filteredTodo = _.filter(_.get(selectedOrder, 'todos', []), todo => todo.sources.length > 1
      && todo.sources[1]._id.toString() === oldObj._id.toString());
    this.createTodos(filteredTodo, owner, newObj);
  }

  updateChecklistStatus(templateForm) {
    const linkedTodo = _.find(this.todos, todo => _.map(todo.files, 'url').includes(templateForm.url));
    if (!_.isEmpty(linkedTodo)) {
      const allTodoFormCompleted = !_.some(linkedTodo.files, file => file.type === 'form' && !file.completed) || false;
      if (linkedTodo.status === 'Complete' && !templateForm.completed) {
        linkedTodo.status = 'In Progress';
      } else if (allTodoFormCompleted) linkedTodo.status = 'Complete';
    }
  }

  deleteForm(param) {
    const card = this;
    const { doc } = param;
    let updateData = {};
    const _id = doc.sources.length === 1 ? doc.sources[0]._id : doc.sources[1]._id;
    if (doc.sources.length === 1) {
      updateData = card;
    } else {
      const runOrItem = doc.sources[1].type === 'run' ? card.manager.runs : card.items;
      updateData = _.find(
        runOrItem,
        dt => dt._id === _id || dt.uuid === _id,
      );
    }
    let removeTodo;
    if (['ProdTemplates', 'MatTemplates'].includes(card.__t) && doc.sources.length > 1) {
      removeTodo = _.find(updateData.simpleTodos, simpleTodo => simpleTodo.sources[1]._id.toString() === _id.toString()
        && simpleTodo.files[0].url.toString() === doc.url.toString()
        && !simpleTodo.archived.value);
    } else {
      removeTodo = _.find(card.todos, (todo) => {
        const fileUuids = _.map(todo.files, 'url');
        return fileUuids.includes(doc.url) && !todo.archived.value;
      });
    }
    if (!_.isEmpty(removeTodo)) {
      _.assign(removeTodo.archived, { value: true });
    }
  }

  addTemplateTodos(todos = [], user, runLevel) {
    const card = runLevel || this;
    const userData = user || card.owner;
    const orderTodos = _.filter(todos, todo => todo.sources.length === 1 && !_.some(todo.files, { type: 'form' }));
    this.createTodos(orderTodos, userData, card);
  }

  createTodos(todos, userData, dataObj) {
    const user = {
      _id: _.get(userData, '_id', false) || userData.user._id,
      name: _.get(userData, 'name', false) || userData.user.name,
    };
    let company = {
      _id: _.get(userData.company, '_id', false) || userData.company,
      name: _.get(userData.company, 'name', false) || userData.companyName,
    };
    for (const todo of todos) {
      if (!todo.archived.value) {
        const newTodoIndex = dataObj.newTodo();
        let newTodo = {};
        if (typeof newTodoIndex === 'object') {
          newTodo = newTodoIndex;
        } else {
          newTodo = dataObj.todos[newTodoIndex];
        }
        newTodo.assignedTo = {};
        if (todo.runCreator && !_.isEmpty(dataObj.owner)) {
          newTodo.assignedTo = _.cloneDeep(dataObj.owner);
        } else if (todo.orderCreator) {
          newTodo.assignedTo = { user, company };
        } else {
          if (!_.isEmpty(todo.assignedTo.company)) {
            company = { _id: todo.assignedTo.company._id, name: todo.assignedTo.company.name };
          }
          newTodo.assignedTo = {
            user: { _id: todo.assignedTo.user._id, name: todo.assignedTo.user.name },
            company,
          };
        }
        _.assign(newTodo, _.pick(todo, ['status', 'text', 'private', 'type', 'visible', 'createdVia']));
        newTodo.dueDate = moment().format();
        newTodo.files = todo.files.map(file => _.omit(file, '_id'));
      }
    }
  }

  addCheckListToForm(param, userData) {
    const card = this;
    const { doc, rowData } = param;
    const { _accessor } = rowData;
    const user = { _id: userData._id, name: userData.name };
    const company = { _id: userData.company, name: userData.companyName };
    let data = {};
    if (!_accessor) data = card;
    else {
      const runItemArr = _accessor === 'manager.runs' ? card.manager.runs : card.items;
      data = _.find(runItemArr, dt => ((!_.isEmpty(dt._id)
        && dt._id === rowData._id)
        || dt.uuid === rowData.uuid));
    }
    if (!_.isEmpty(data)) {
      const checklist = data.newTodo();
      let newChecklist = {};
      if (typeof (checklist) !== 'object') {
        newChecklist = card.todos[checklist];
      } else newChecklist = checklist;
      _.assign(newChecklist, {
        text: doc.name,
        assignedTo: { user, company },
        dueDate: moment().format(),
        status: 'Not Started',
        type: ['Materials', 'MatTemplates'].includes(card.__t) ? 'Material' : 'Detailing',
        stage: card.stage,
        private: true,
        files: [],
        createdVia: 'form',
        uuid: uuid(),
        isEditing: true,
      });
      const file = _.pick(doc, ['name', 'type', 'visible', 'url', 'copiedFrom', 'uuid', 'formData']);
      file.archived = {
        value: false,
      };
      newChecklist.files.push(file);
      Object.assign(file, doc);
    }
  }

  addTemplateRi(run, items) {
    const card = this;
    for (const item of items) {
      run.runItemsCount.push(card.manager.getDefaultRi({ item, run }));
      if (run.runItemsCount.length === BaseOrder.runItemLimit) break;
    }
    run.isEditing = true;
  }

  fillTemplateDates(templateOrder) {
    const card = this;
    const { leadDates } = templateOrder;
    card.addOrUpdateDate('poQaBy', this.getDateDiff(card.simpleDates.deliver.value, leadDates.qa));
    card.addOrUpdateDate('poManufactureBy', this.getDateDiff(card.simpleDates.poQaBy.value, leadDates.manufacturing));
    card.addOrUpdateDate('poDetailBy', this.getDateDiff(card.simpleDates.poManufactureBy.value, leadDates.detailing));
    card.addOrUpdateDate('coord', this.getDateDiff(card.simpleDates.poDetailBy.value, leadDates.coordination));
    if (card.isKit() && !_.isEmpty(card.templateOrder) && leadDates.partsManufacturing) {
      card.addOrUpdateDate('partsManufactureBy', this.getDateDiff(card.simpleDates.poManufactureBy.value, leadDates.partsManufacturing));
      if (moment(_.get(card.simpleDates, 'partsManufactureBy.value')).isAfter(_.get(card.simpleDates, 'poManufactureBy.value'), 'd')
        || moment(_.get(card.simpleDates, 'poDetailBy.value')).isAfter(_.get(card.simpleDates, 'partsManufactureBy.value'), 'd')) {
        card.simpleDates.partsManufactureBy.value = card.simpleDates.poManufactureBy.value;
      }
    }
  }

  fillDetailedTemplateData(templateOrder, firstDateKey, addItem = true, excelImport = false) {
    const card = this;
    if (_.isEmpty(templateOrder)) return card;
    const dateTypes = ['detailBy', 'manufactureBy', 'qaBy'];
    if (_.isEmpty(card.templateOrder)) {
      card.addOrUpdateDate('coord', card.getDate(firstDateKey));
      dateTypes.forEach((type) => {
        card.addOrUpdateDate(type, card.getDate(firstDateKey));
      });
    } else {
      if (_.isUndefined(templateOrder.items)) templateOrder.items = [];
      if (templateOrder.leadDates.qa !== null && !templateOrder.p_dates) {
        card.fillTemplateDates(templateOrder);
      }
      if (addItem) {
        for (const item of templateOrder.items) {
          const itemToAdd = _.pick(item, [
            'name', 'customId', 'catId', 'costCode', 'customId', 'inventoryNotes', 'level',
            'measure', 'measureUnits', 'quantity', 'zone',
          ]);
          if (templateOrder.preName) {
            itemToAdd.name = `${templateOrder.preName} ${itemToAdd.name}`;
          }
          if (templateOrder.postName) itemToAdd.name += ` ${templateOrder.postName}`;
          const itemIdx = card.addItem(itemToAdd);
          const newItem = card.items[itemIdx];
          this.idsMap.push({
            newUuid: newItem.uuid,
            oldItem: item,
          });
          for (const file of item.simpleFiles) {
            if (file.type !== 'form' || excelImport) {
              const newFile = newItem.newFile();
              _.assign(newFile, _.pick(file, ['url', 'name', 'copiedFrom', 'visible', 'type']));
            }
          }
          if (!_.isEmpty(item.simpleMemos)) {
            const memo = newItem.newFTD('memos');
            memo.text = item.simpleMemos[0].text;
          }
        }
      }
    }
    if (_.isUndefined(card.owner.company)) {
      card.owner.company = {
        _id: card.owner.user.company,
        name: card.owner.user.companyName,
      };
    }
    return card;
  }

  toSlimJson() {
    if (_.isEmpty(this._jsonString)) return this.toJSON();

    const oldObj = JSON.parse(this._jsonString);
    const newObj = this.toJSON();
    const have = ['items', 'dates', 'files', 'todos', 'memos'];
    if (this.isPM()) have.push('runs');
    const ignore = ['isEditing', 'dirty', '_runItemsIndexMap', 'copiedFromId',
      'simpleDates', 'simpleFiles', 'simpleTodos', 'simpleMemos',
      'inRunCount', 'runCount'];

    have.forEach((key) => {
      const oldObjInKey = key === 'runs' ? oldObj.manager.runs : oldObj[key];
      const newObjInKey = key === 'runs' ? newObj.manager.runs : newObj[key];
      const newSlimObj = [];
      const keyedDiff = {};
      const difference = DeepDiff.diff(
        oldObjInKey, newObjInKey,
        (path, field) => ignore.includes(field),
      );
      _.forEach(difference, (d) => {
        const { index, path, item } = d;
        if (item && item.kind === 'D' && (!path || path.length === 1)) {
          // if item deleted in sub fields, take it, do not skip
          return;
        }

        if (path && _.isFinite(path[0])) keyedDiff[path[0]] = d;
        else if (_.isFinite(index)) keyedDiff[index] = d;
      });
      const sortedKeys = Object.keys(keyedDiff).map(Number).sort((a, b) => a - b);
      sortedKeys.forEach((diffIndex) => {
        newSlimObj.push(newObjInKey[diffIndex]);
      });
      (key === 'runs' ? newObj.manager : newObj)[key] = newSlimObj;
    });
    return newObj;
  }

  async splitFromInventory(invItems, currentLocationId, reserveForId) {
    const fromShipments = [];
    let sls = [];
    if (invItems.length > 0) {
      // get all the shipping orders where these items may be present.
      // one item here is one CatID - which may be spread across multiple SLs
      // and over multiple ItemIds
      const itemChunks = _.chunk(invItems, 80);
      for (const chunk of itemChunks) {
        const { data: slsTemp } = await Shipping.get({
          projectId: _.uniq(_.map(chunk, 'project._id')),
          itemIds: _.uniq(_.flatMap(
            chunk,
            item => (_.get(item, 'itemIds.length', false) ? item.itemIds : item._id),
          )),
          status: ['released-to-inventory'],
          shipType: ['m', 's-m', 's'],
          limit: 100,
          showExcelOrders: true,
          showInvOrders: true,
          currentLocation: currentLocationId,
        });
        sls.push(...slsTemp);
      }
      sls = _.uniqBy(sls, '_id');
      if (sls.length) {
        // create a map here to quickly iterate over each item's itemIds later on
        // although it will still be a O(n3) loop later on, we reduce the number of lookups
        // with the map we are creating below.
        const shipMap = {};
        const itemShipMap = {};
        for (const sl of sls) {
          shipMap[sl._id] = sl;
          for (const item of sl.items) {
            if (!itemShipMap[item._id]) itemShipMap[item._id] = [];
            itemShipMap[item._id].push(sl._id);
          }
        }
        // for each item - unwrap the itemIds
        // for each itemId - find the SLs
        // from each SL - peel off until desired quantity reached
        for (const item of invItems) {
          for (const itemId of item.itemIds) {
            if (item.quantity <= 0) break;
            if (itemShipMap[itemId]) {
              for (const slId of itemShipMap[itemId]) {
                if (item.quantity <= 0) break;
                const sl = shipMap[slId];
                const slItem = _.find(sl.items, { _id: itemId });
                const qtyToRemove = Math.min(item.quantity, slItem.quantity);
                if (!qtyToRemove) continue;
                // we need item._id for addItemFromShipment
                fromShipments.push({
                  moveQuantity: qtyToRemove,
                  // name: item.name,
                  cardId: reserveForId,
                  fromShippingId: sl._id,
                  _id: itemId,
                  // viewIndex: item.viewIndex,
                });
                item.quantity -= qtyToRemove;
              }
            }
          }
        }
      } else throw new Error('No Shipments found!');
    }
    return { fromShipments, invItems };
  }

  chunkArray(res, cSize) {
    return _.reduce(res, (result, value) => {
      const lastChunk = result[result.length - 1];
      if (lastChunk.length < cSize) lastChunk.push(value);
      else result.push([value]);
      return result;
    }, [[]]);
  }

  reqForReserveQty(materials) {
    const reqCatIds = [];
    _.forEach(materials, (item) => {
      const needed = _.get(item, 'quantity', 0);
      const reserved = _.get(item, 'reserved', 0);
      const requiredQty = (needed - reserved);
      if (item.available > 0 && requiredQty > 0) {
        reqCatIds.push({
          catId: item.catId,
          requiredQty,
        });
      }
    });
    return reqCatIds;
  }

  async reserveCatalog(reqCatIds, user, isInternal = true) {
    const opts = {
      cardId: this._id,
    };
    const createdShipments = [];
    try {
      createdShipments.push(await Shipping.reserveCatalog(opts));
    } catch (e) {
      console.log('Error', e); console.log('Error', e);
    }
    return createdShipments;
  }

  toJSON() {
    const card = _.cloneDeep(this);
    card.items.forEach((i) => {
      delete i.order;
      delete i._runs;
      delete i._beforeEdit;
    });

    if (card.manager) {
      delete card.manager.order;
      delete card.manager._runItemsIndexMap;
      delete card.manager.itemStatus;
      _.get(card.manager, 'runs', []).forEach((run) => {
        delete run.order;
        delete run._beforeEdit;
        run.runItemsCount.forEach(ri => delete ri._item);
      });
      if (card.manager.templateRuns) delete card.manager.templateRuns;
    }

    delete card._model;
    delete card.indices;
    delete card._indexedItems;
    delete card._indexedRuns;
    delete card._jsonString;
    return card;
  }

  initFTDs() {
    function saveFTD(ftdIndex, ftdType, ftd, id2) {
      if (ftdType === 'dates') {
        if (!ftdIndex[id2]) ftdIndex[id2] = {};
        ftdIndex[id2][ftd.kind] = ftd;
      } else {
        if (!ftdIndex[id2]) ftdIndex[id2] = [];
        ftdIndex[id2].push(ftd);
      }
    }
    for (const ftdType of ftds) {
      for (const ftd of this[ftdType]) {
        if (_.get(ftd, 'archived.value', false)) continue;
        const ftdIndex = this.indices[ftdType];
        if (ftd.sources.length === 1) {
          const id2 = `order.${this._id}`;
          saveFTD(ftdIndex, ftdType, ftd, id2);
        } else if (ftd.sources.length > 1) {
          for (const source of ftd.sources) {
            if (['item', 'run', 'manager'].indexOf(source.type) > -1) {
              const id2 = `${source.type}.${source._id}`;
              saveFTD(ftdIndex, ftdType, ftd, id2);
            }
          }
        }
      }
    }
  }

  async submitMaterials(obj) {
    const ids = [];
    for (const i of obj) {
      if (i.stage === 'preparation' || i.stage === 'mixed') {
        ids.push(i._id);
      }
    }
    const MaterialMap = (modelMap()).Materials;
    await MaterialMap.pullToSourcing(ids);
  }

  async getSubmitMaterials() {
    const obj = [];
    const materialLength = _.get(this.materials, 'length', 0);
    if (materialLength > 0) {
      const params = {
        projectId: this.project._id,
        orderId: _.map(this.materials, '_id'),
        filterNoItemOrders: false,
        showAllCompanyOrders: false,
        limit: materialLength,
      };
      const { data } = await SupplyChain.supplyChain(params);
      for (const bom of data) {
        const item = _.find(this.materials, ['_id', bom._id]);
        item.stage = bom.stage;
        const firstObj = {};
        firstObj._id = bom._id;
        firstObj.stage = bom.stage;
        firstObj.linkedAssembly = bom.linkedAssembly || '';
        firstObj.hasNotPurchasedItems = false;
        if (!_.every(bom.items, matItem => !_.has(matItem, 'purchase') || matItem.purchase)) firstObj.hasNotPurchasedItems = true;
        obj.push(firstObj);
      }
    }
    return obj;
  }

  calculateItemLimits() {
    const maximumItemLimit = (this.__t === 'Materials') ? 500 : 200;
    return {
      max: maximumItemLimit,
      count: this.items.length,
      more: maximumItemLimit - this.items.length > 0 ? maximumItemLimit - this.items.length : 0,
    };
  }

  getCardStage() {
    const stageOrder = ['planning', 'coordination', 'detailing',
      'manufacturing', 'preparation', 'sourcing', 'qa', 'ordering', 'delivery',
      'in-transit', 'complete'];
    for (const stage of stageOrder) {
      if (_.some(this.items, { stage })) return stage;
    }
    if (this.isMM() && this.stage !== 'rejected' && _.some(this.items, { stage: 'rejected' })) return 'preparation';
    return this.isMM() ? 'ordering' : 'delivery';
  }

  getInvolvedCompanies() {
    if (this.multiTrade.value) {
      return _.map(this.multiTrade.companies, '_id');
    } if (this.isManager) {
      return [this.manager.owner.company._id];
    }
    return [this.owner.company._id];
  }

  getInvolvedUserKey() {
    if (this.isManager) {
      return 'manager.owner.user';
    }
    return 'owner.user';
  }

  getItem = id => this._indexedItems[id]
}

export { RunItemsCount, BaseOrder, Dialog };
export default BaseOrder;
