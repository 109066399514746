<template>
  <div class="modal-card">
    <header>
      <div class="modal-card-head is-borderless">
        <div class="card-header-title is-justify-content-left has-text-black-bis pl-0">
            Order More Inventory
        </div>
        <div class="is-divider-vertical"></div>
        <i class="icon-close is-clickable" @click="cancel()"></i>
      </div>
    </header>
    <section class="modal-card-body">
      <div class="columns move-order-table">
        <div class="column">
          <h4 class="title is-size-3 has-text-weight-bold has-text-black-bis">
            Ordering Details *
          </h4>
          <div class="field">
            <div class="field-label">
              <label class="label is-size-3"> Project</label>
            </div>
            <div class="field-body">
              <mf-multi-select v-model="selectedProject"
                :options="allProjects"
                :closeOnSelect="true"
                label="name"
                track-by="name"
                :multiple="false"
                :hideSelected="false"
                openDirection="below"
                :allow-empty="false"
                :disabled="false"
                class="pendo-current-project-select"
                @update:modelValue="setProjectAndLocation">
              </mf-multi-select>
            </div>
          </div>
        </div>
        <div class="column">
          <h4 class="title is-size-3 has-text-weight-bold has-text-black-bis">
            Delivery Details *
          </h4>
          <div class="field">
            <div class="field-label">
              <label class="label is-size-3"> Onsite</label>
            </div>
            <div class="field-body">
              <mf-date :item="dates" :input-props="{kind: 'deliver'}"
                      :is-edit="true">
              </mf-date>
            </div>
          </div>
          <div class="field">
            <div class="field-label">
              <label class="label is-size-3"> Delivery Location</label>
            </div>
            <div class="field-body">
              <mf-multi-select v-model="selectedLoc"
                  ref="location"
                  :options="projectLocations"
                  group-values="val" group-label="key"
                  :closeOnSelect="true"
                  label="name"
                  track-by="name"
                  :multiple="false"
                  :hideSelected="false"
                  openDirection="below"
                  :allow-empty="false"
                  :disabled="false"
                  class="pendo-current-location-select">
                  </mf-multi-select>
            </div>
          </div>
          <button
            class="button has-background-black-bis"
            @click="addFromCatalog(true)"
          >Add New Item</button>
          <add-from-catalog-popover
            :isVisible="isPopoverVisible"
            :maxAddable="50"
            title="Add Items From Catalog"
            @close="addFromCatalog"
            @selected-rows="addNewItems"
          />
        </div>
      </div>
      <div class="table-container">
        <mf-table
          :tableProps="tableProps"
          :apiMode="false"
          :loadData="loadData"
          :hideGutter="true"
          ref="itemTable"
        >
          <template v-slot:qtyToOrder="{ rowData }">
            <qty-input v-model="rowData.qtyToOrder"
                      :rowData="rowData"
                      :value="rowData.qtyToOrder"
                      :key="rowData.uid"
                      :max="rowData.maxQuantity || 9999"
                      :roundTo="2"
                      >
            </qty-input>
          </template>
        </mf-table>
      </div>
    </section>
    <footer class="modal-card-foot is-justify-content-flex-end is-align-items-center">
      <button class="button is-outlined" @click="cancel()">Cancel</button>
      <button
        class="button has-background-black-bis"
        @click="createMaterial('edit')"
        :disabled="disableOrderBtn"
      >Edit Material Order</button>
      <button
        class="button has-background-black-bis"
        @click="createMaterial()"
        :disabled="disableOrderBtn"
      >Place Order</button>
    </footer>
  </div>
  <o-loading :full-page="true" :active="isLoading" :can-cancel="false"></o-loading>
</template>

<script>
import {
  defineComponent, onMounted, reactive, toRefs, ref, defineAsyncComponent, computed,
} from 'vue';
import { useStore } from 'vuex';
import Locations from '@/models/Locations';
import _ from 'lodash';
import MfMultiSelect from '@/components/abstract/MfMultiSelect.vue';
import MfDate from '@/components/abstract/MfDate.vue';
import tableDefinition from '@/components/table-cols/CreateMaterialFromInvCols';
import LocalSort from '@/components/utils/LocalSort';
import QtyInput from '@/components/fields/QtyInput.vue';
import { useToast } from 'vue-toastification';
import moment from 'moment';
import { BaseOrder } from '@/models/BaseOrder';
import MaterialTemplates from '@/models/MaterialTemplates';
import MaterialManager from '@/models/MaterialManager';
import Project from '@/models/Projects';
import { useRouter } from 'vue-router';
import AddRow from '@/components/utils/AddRow';
import uuid from 'uuid/v4';
import CatalogPopover from '@/components/modals/AddFromCatalogPopover.vue';
import UtilityMixin from '../mixins/UtilityMixin';

const MfTable = defineAsyncComponent(() => import('@/components/table-fields/MfTable.vue'));

export default defineComponent({
  name: 'CreateMaterialFromInv',
  props: {
    itemsData: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['close'],
  components: {
    MfTable,
    'mf-date': MfDate,
    'mf-multi-select': MfMultiSelect,
    QtyInput,
    'add-from-catalog-popover': CatalogPopover,
  },
  setup(props, { emit }) {
    const { itemsData } = props;
    const store = useStore();
    const tableProps = tableDefinition;
    const itemTable = ref(null);
    const toast = useToast();
    const router = useRouter();
    const materialDates = ['orderBy', 'available', 'shipBy', 'deliver'];
    const { getAllLocations } = UtilityMixin();
    const state = reactive({
      allProjects: [],
      selectedProject: {},
      selectedLoc: {},
      projectLocations: [],
      tableData: itemsData,
      isLoading: false,
      userObject: {},
      dates: {},
      isPopoverVisible: false,
    });
    onMounted(async () => {
      state.userObject = await store.getters.userPromise;
      const loc = (await Locations.allCompanyLocations({
        companyId: state.userObject.company,
        type: 'company_location',
        limit: 9999,
        showNestedLocs: true,
      })).data;
      state.projectLocations = Locations.groupLocations(loc, store.state.companyData);
      state.projectLocations[0].val = state.projectLocations[0].val.filter((location) => !location.nestedLocation);
      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      state.dates.deliver = tomorrow;
      state.allProjects = store.state.queryParams._projects;
    });
    const addFromCatalog = (val) => {
      state.isPopoverVisible = val;
    };
    const addNewItems = async (selectedRows) => {
      _.forEach(selectedRows, (row) => {
        const existingItem = _.find(state.tableData, (i) => i.catId === row.catId);
        if (existingItem) {
          toast.error('Item with same catalog id already exists');
        } else {
          const filledRow = AddRow.createFilledRow(tableProps.fields, row);
          filledRow.uid = uuid();
          state.tableData.unshift(filledRow);
        }
      });
      itemTable.value.refreshTable();
    };
    const disableOrderBtn = computed(() => (_.isEmpty(state.selectedProject)
        || _.isEmpty(state.selectedLoc)));
    function calculateQtyToOrder(rowData) {
      let qtyToOrderVal = (rowData.minQuantity - rowData.available);
      if (_.get(rowData, 'qtyToOrder', false)) {
        qtyToOrderVal = rowData.qtyToOrder;
      }
      return (qtyToOrderVal > 0) ? qtyToOrderVal : 1;
    }
    const loadData = () => {
      if (state.tableData.length) {
        for (const item of state.tableData) {
          if (!item.qtyToOrder) item.qtyToOrder = calculateQtyToOrder(item);
        }
        state.tableData = LocalSort.filter(state.tableData);
      }
      return state.tableData;
    };
    const cancel = () => {
      emit('close');
    };
    const setProjectAndLocation = _.debounce(async () => {
      try {
        state.selectedLoc = {};
        if (!_.isEmpty(state.selectedProject)) {
          const locs = await getAllLocations(state.selectedProject._id);
          state.projectLocations = await Locations.groupLocations(locs, store.state.companyData);
          state.projectLocations = _.filter(
            state.projectLocations,
            (loc) => loc.type !== 'gi_location',
          );
        }
      } catch (e) {
        console.log('Error', e);
      }
    }, 500);
    function basicValidation() {
      let msg = '';
      if (_.some(state.tableData, (item) => item.selected && (!item.name || !item.catId))) {
        msg = 'Item name and catId are mandatory, Please select from Master catalog';
      } else if (state.tableData.length > 500) {
        msg = 'Cannot add more than 500 items';
      }
      return msg;
    }
    const createMaterialOrder = async (mmArgs = {}, fromInv = false) => {
      try {
        const notFromRequisition = _.get(mmArgs, 'notFromRequisition', true);
        let bo = new BaseOrder({ __t: 'Materials', stage: 'preparation', notFromRequisition });
        bo.name = mmArgs.orderName || `Excel Import -${moment().format('MMM Do YYYY, h:mm:ss a')}`;
        const itemProject = mmArgs.fromExcelImport ? state.selectedProject : mmArgs.project;
        await store.getters.userPromise;
        const user = store.state.userData;
        bo.project = _.pick(itemProject, ['_id', 'name']);
        bo.owner.user = _.pick(user, ['_id', 'name']);
        bo.owner.company = _.pick(user, ['company', 'companyName']);
        if (mmArgs.fromExcelImport) {
          mmArgs.locIdKeyedItems[mmArgs.locationId].forEach((item) => {
            delete item.location;
            item.activeSupplier = _.get(item, 'activeSupplier', '').substring(0, 64);
            bo.addItem(item);
          });
        } else if (mmArgs.addMultipleItems) {
          mmArgs.items.map((item) => bo.addItem(item));
        } else {
          mmArgs.locationId = _.get(mmArgs, '_id.locId', false) || _.get(mmArgs, 'rootLoc._id', false);
          const item = _.pick(mmArgs, ['name', 'fromExcel', 'catId', 'available']);
          item.quantity = mmArgs.incBy;
          bo.addItem(item);
        }
        const validationObj = await bo.basicValidation(true);
        if (validationObj !== true) {
          toast.error(validationObj.msg);
          throw new Error(validationObj.title);
        }
        bo.fromInv = fromInv;
        let defaultTemplate = {};
        defaultTemplate = await MaterialTemplates.getDefaultTemplate({
          skipCheck: true,
        });
        if (!_.isEmpty(defaultTemplate)) {
          const clonedCard = _.cloneDeep(bo);
          bo = bo.fillBasicTemplateData(defaultTemplate);
          bo.owner = defaultTemplate.owner;
          bo.name = clonedCard.name;
          bo.applyTemplate = true;
        } else {
          bo.baseDelivery = {
            locationId: mmArgs.locationId,
            location: { _id: mmArgs.locationId },
          };
        }
        const mmObj = new BaseOrder(await MaterialManager.create(bo));
        const updateObj = new BaseOrder({ __t: 'Materials', stage: 'preparation' });
        const currProject = store.getters.findProject({ _id: mmObj.project._id });
        const stage = notFromRequisition ? 'sourcing' : 'preparation';
        let locId = _.get(mmObj, 'baseDelivery.location._id', '');
        /* while inv inc/dec,currProject is empty as commonstock project
        won't be present in store project list */
        if (currProject) {
          const orderRouteVal = currProject.getDefaults(updateObj, stage);
          const allUsers = await Project.linkedProjectUsers([mmObj.project._id]);
          if (_.isEmpty(defaultTemplate)) {
            if (orderRouteVal && orderRouteVal.newOwner) {
              const owner = _.find(allUsers, (usr) => usr._id === orderRouteVal.newOwner._id);
              mmObj.owner = {
                user: { _id: owner._id, name: owner.name },
                company: { _id: owner.company, name: owner.companyName },
              };
            }
            locId = _.get(orderRouteVal, 'newLocation._id', '') || mmArgs.locationId;
          }
        }
        updateObj._id = mmObj._id;
        updateObj.project = _.cloneDeep(mmObj.project);
        updateObj.owner = _.cloneDeep(mmObj.owner);
        updateObj.name = mmObj.name;
        updateObj.items = mmObj.items;
        updateObj.baseDelivery = {
          locationId: locId,
          location: { _id: locId },
          supplier: user._id,
          recipientId: user._id,
        };
        const applyDate = _.get(mmArgs, 'applyDate', true);
        if (materialDates && applyDate) {
          for (const kind in materialDates) {
            if (_.isUndefined(updateObj.simpleDates[materialDates[kind]])) {
              updateObj.newDate(materialDates[kind]);
              updateObj.simpleDates[materialDates[kind]].value = moment.utc();
            }
          }
        }
        return updateObj;
      } catch (e) {
        console.log('Error while creating material', e);
        throw new Error(e);
      }
    };
    const createMaterial = _.debounce(async (Edit = '') => {
      const errMsg = await basicValidation();
      if (errMsg) {
        return toast.error(errMsg);
      }
      let msg = 'Material Order Created and Moved to Sourcing';
      let mmCard = {};
      state.isLoading = true;
      const allMatItems = state.tableData;
      try {
        for (const item of allMatItems) {
          item.quantity = item.qtyToOrder;
          // new inv get api has a qtyLocations property, while creating material from inventory
          // qtyLocations also sent so the item qty is getting increament in inventory.
          // but old api didnt have qtyLoction property sent by inventory get Api call
          // so the qty was not getting updated. due to changes in the new api deleting the
          // qtyLocation while creating material from inventory
          delete item.qtyLocations;
          delete item._id;
          delete item.inventoryNotes;
          item.measure = null;
          item.measureUnits = null;
          if (item.maxQuantity <= 0) item.maxQuantity = 9999;
        }
        const args = {
          project: state.selectedProject,
          locationId: _.get(state.selectedLoc, '_id', null),
          addMultipleItems: true,
          items: allMatItems,
          applyDate: false,
          orderName: `Inventory Reorder - ${state.userObject.name} - ${moment().format('MMM Do YYYY, h:mm:ss a')}`,
        };
        if (Edit) args.notFromRequisition = false;
        const dummyMaterial = await createMaterialOrder(args);
        dummyMaterial.addOrUpdateDate('deliver', state.dates.deliver);
        mmCard = await MaterialManager.update(dummyMaterial);
        if (!Edit) {
          await MaterialManager.pullToSourcing([mmCard._id], mmCard);
        }
        cancel();
      } catch (e) {
        msg = e || _.get(e, 'data.msg', false)
          || 'Error creating material: please contact ManufactOn support';
      }
      state.isLoading = false;
      if (!Edit) toast.success(msg);
      if (Edit) {
        const projectId = _.get(mmCard, 'project._id', '');
        const cardId = _.get(mmCard, '_id', '');
        const stage = _.get(mmCard, 'stage', 'preparation');
        router.push({
          name: 'material-edit',
          params: { projectId, cardId, stage },
        });
      }
      return null;
    }, 100);
    return {
      ...toRefs(state),
      setProjectAndLocation,
      loadData,
      tableProps,
      itemTable,
      cancel,
      createMaterial,
      disableOrderBtn,
      addFromCatalog,
      addNewItems,
    };
  },
});
</script>
<style scoped>
::v-deep(.o-table tbody) {
  min-height: 250px !important;
  max-height: 250px !important;
}
</style>
