import PermissionMixin from '@/components/mixins/PermissionMixin';

const { getPermissionValues } = PermissionMixin();
function extraTableParams() {
  return {
    defaultSortField: 'name',
    defaultSortOrder: 'asc',
    isDetailed: false,
    rearrangeCols: false,
    toolBar: false,
  };
}

const tableOpts = {
  toolBar: {
    hideSearchBar: true, // by default let the search bar be available for the table
    buttons: [
      {
        event: 'addPart',
        icon: 'icon-addnew',
        isLine: true,
        tooltip: 'Add From Catalog',
        permissionType: 'assembly_parts',
        acl: {
          permissionModule: 'prefabs',
          type: 'edit',
        },
      },
      {
        event: 'savePart',
        icon: 'icon-checkmark',
        isLine: true,
        tooltip: 'Save',
        permissionType: 'assembly_parts',
        acl: {
          permissionModule: 'prefabs',
          type: 'edit',
        },
        isDisabled: (isPartQtyUpdated) => !isPartQtyUpdated,
      },
    ],
  },
};

export default {
  'parts-info': {
    ...extraTableParams(),
    ...tableOpts,
    fields: [
      {
        name: 'field-generic-input',
        width: '400px',
        title: 'Name',
        prop: 'name',
        id: 'name',
      },
      {
        name: 'field-generic-input',
        width: '112px',
        title: 'Id',
        id: 'customId',
        prop: 'customId',
      },
      {
        name: 'field-cat-id',
        width: '152px',
        title: 'Catalog ID',
        id: 'catId',
        prop: 'catId',
        allowAutoComplete: true,
        verifyCatId: true,
        inputProps: {
          type: 'text',
          disableEdit(part) {
            return !part.newRow;
          },
        },
      },
      {
        name: 'categorySelect',
        width: '152px',
        title: 'Category',
        id: 'category',
        prop: 'category.name',
      },
      {
        name: 'subCategorySelect',
        width: '152px',
        title: 'Sub Category',
        id: 'subCategory',
        prop: 'subCategory.name',
      },
      {
        name: 'field-generic-input',
        width: '400px',
        title: 'Description',
        id: 'description',
        prop: 'desc',
      },
      {
        name: 'field-generic-input',
        width: '152px',
        title: 'Meas Unit',
        id: 'measureUnits',
        prop: 'measureUnits',
      },
      {
        name: 'parts-quantity',
        title: 'QTY',
        id: 'parts-quantity',
        width: '112px',
      },
      // {
      //   name: 'field-generic-input',
      //   width: '112px',
      //   title: 'QTY',
      //   id: 'quantity',
      //   prop: 'quantity',
      //   type: 'number',
      //   inputProps: {
      //     disableEdit: false,
      //   },
      // },
      {
        name: 'field-generic-input',
        width: '196px',
        title: 'Notes',
        id: 'notes',
        prop: 'notes',
        type: 'text',
        inputProps: {
          disableEdit: false,
        },
      },
      {
        title: '',
        name: 'table-field-actions',
        id: 'action',
        hideFromChooser: true,
        alwaysVisible: false,
        width: '112px',
        searchable: false,
        clearBtn: true,
        showAllFilters: false,
        // thAttrs: () => ({ class: 'action-sticky' }),
        // tdClass: 'action-sticky',
        inputProps: {
          showEdit: false,
          showDelete: true,
          disableDelete: () => {
            const params = {
              permissionModule: 'app&projects',
              type: 'assembly_parts',
            }
            const res = getPermissionValues(params);
            return res;
          },
        },
      },
    ],
  },
  'attribute-details': {
    ...extraTableParams(),
    fields: [
      {
        name: 'field-generic-input',
        width: '400px',
        title: 'Name',
        prop: 'name',
        id: 'name',
      },
      {
        name: 'field-generic-input',
        width: '400px',
        title: 'Value',
        prop: 'value',
        id: 'value',
      },
    ],
  },
};
