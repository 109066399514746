<template>
  <h4 class="is-size-3 has-text-black-bis mb-3 is-flex is-justify-content-space-between">
    Tags ({{ getTags?.length ||0 }})
    <tag-input :card="card" :edit="edit">
    </tag-input>
  </h4>
  <div>
    <button class="button  has-text-white taghover mb-1"
      v-for="tag in getTags"
      :class="tag.scope === 'company' ? 'is-primary' : 'has-background-black-bis'"
         v-bind:key="tag._id"
         @click="removeTag(tag)">
      <span class="is-size-3">{{tag.name}}</span>
       <i v-if="edit" class="icon-removedelete has-text-white"></i>
    </button>
  </div>
</template>

<script>
import {
  defineComponent, computed, reactive, toRefs,
} from 'vue';
import TagInput from '@/components/card-edit/MfTagInput.vue';
import { CardDirtyBus } from '@/utils/CardDirtyBus';
import _ from 'lodash';

const MyComponent = defineComponent({
  name: 'mf-tags',
  props: {
    card: Object,
    edit: {
      type: Boolean,
      // keeping default true as card view are always editable
      default: true,
    },
  },
  components: {
    TagInput,
  },
  setup(props) {
    const { card } = props;
    const data = reactive({
    });
    const getTags = computed(() => {
      if (!_.isEmpty(card) && card.isPM()) {
        return card.manager.tags;
      }
      return card.tags;
    });
    const removeTag = (tag) => {
      if (!props.edit) return;
      const tagIdx = _.findIndex(getTags.value, (i) => i._id.toString() === tag._id.toString());
      getTags.value.splice(tagIdx, 1);
      CardDirtyBus.emit('setCustomDirty');
    };
    return {
      ...toRefs(data),
      removeTag,
      getTags,
    };
  },
});

export default MyComponent;
</script>
