import _ from 'lodash';
import { useToast } from 'vue-toastification';
import { useStore } from 'vuex';
import SupplyChain from '@/models/SupplyChain';
import { BaseOrder } from '@/models/BaseOrder';
import Materials from '@/models/MaterialManager';
import ProductionManager from '@/models/ProductionManager';
import Order from '@/models/Orders';
import moment from 'moment';
import PrefabsMixin from '@/components/mixins/PrefabsMixin';
import Validation from '@/utils/Validations';
import uuid from 'uuid/v4';
import Company from '@/models/Companies';
import UtilityMixin from '@/components/mixins/UtilityMixin';
import qcForm from '@/models/Form';
import CardEditMixin from './CardEditMixin';
import ExcelMixin from './ExcelMixin';

export default function () {
  const store = useStore();
  const toast = useToast();
  const { onCardLoaded } = CardEditMixin();
  const { createBomAndPR } = PrefabsMixin();
  const { getAllLocations } = UtilityMixin();
  const addPMItem = (card, item) => {
    const idx = card.addItem(item);
    item = card.items[idx];
    item.isEditing = true;
    if (item.stage !== 'manufacturing' && card.manager.pTrackEnabled) {
      // This property is to disable checkbox while tracking progress
      item.markCompleteDisabled = true;
    }
    const filteredRuns = _.filter(card.manager.runs,
      (r) => !r.archived.value);
    if (filteredRuns.length === 0) return idx;
    const runs = _.filter(
      filteredRuns,
      (r) => r.runItemsCount.length < BaseOrder.runItemLimit,
    );
    if (runs && runs.length > 0) {
      for (const run of runs) {
        run.runItemsCount.push(card.manager.getDefaultRi({
          item,
          run,
        }));
      }
      card.manager.runItemsIndexMap();
    }
    return idx;
  };

  function poAddItemRows({
    rowData: card, items, prefabList, refreshTable,
  }) {
    if (items && prefabList && prefabList.length) {
      if (_.isArray(items)) {
        items.forEach((item) => {
          const index = card.addItem({
            ...item,
            isEditing: true,
          });
          card.items[index].newFTD('memos');
        });
      }
    }
    refreshTable();
  }
  function PMAddItemRows({
    rowData: card, items, prefabList, refreshTable,
  }) {
    if (items && prefabList && prefabList.length) {
      if (_.isArray(items)) {
        items.forEach((item) => {
          addPMItem(card, item);
        });
      }
      refreshTable();
    }
    const prodOrders = {};
    if (items && prefabList && prefabList.length) {
      const keyedItems = _.keyBy(items, 'order._id');
      prefabList.forEach((data) => {
        if (data.__t === 'ProductionOrder') {
          data.items.forEach((item) => {
            if (_.map(items, '_id').includes(item._id) && item.stage === 'coordination') {
              item.archived.value = true;
            }
          });
          if (!_.isEmpty(keyedItems[data._id])) { prodOrders[data._id] = data; }
        }
      });
    }
  }
  const getAllLinkedOrderIds = (card) => {
    const linkedOrderIds = [];
    card.items.forEach((item) => {
      item.cards?.forEach((orderId) => {
        if (orderId != card._id) {
          linkedOrderIds.push(orderId);
        }
      })
    });
    return _.uniq(linkedOrderIds);
  } 
  const isUniqueValueIsUpdated = (card) => {
    const beforeValue = _.get(card, '_beforeEdit.uniqueOrderId.value', '');
    const afterValue = _.get(card, 'uniqueOrderId.value', '');
    return (beforeValue !== afterValue);
  }

  const saveCard = async (cardId, stage, card, isOrdersImport) => {
    if (isUniqueValueIsUpdated(card)) {
      _.set(card, 'uniqueOrderId.value', Validation.getValidUniqueorderId(_.get(card, 'uniqueOrderId.value', '')));
      card.uniqueOrderId.isSystemGenerated =  false;
      if (!_.isEmpty((_.get(card, 'uniqueOrderId.value', ''))) && !Validation.validateUniqueOrderId(_.get(card, 'uniqueOrderId.value', ''))) {
        toast.error('Order Id format incorrect');
        return card;
      }
    }
    // Below code is required if we introduce isConsumed check box later on for kits.
      // create items in prefab order and link the existing items for po orders
      const itemsInPrefab = [];
      try {
        const prefabItems = _.filter((card.items), (i) => !i._id);
        if (prefabItems.length > 0) {
          const isPoOrder = (card.__t == 'ProductionOrder');
  
          let queryParams;
          if (!isPoOrder) {
             queryParams = {
              projectId: card.project._id,
              limit: 1000,
              page: 1,
              module: 'Prefabs',
              excludeFields: {
                todos: 0,
                dates: 0,
                'items.serials': 0,
                'items.qtyLocations': 0,
              },
            };
          } else {
              // get the linked orders
              const linkedOrderIds = getAllLinkedOrderIds(card);
              // get all the linked baseOrders
              queryParams = {
                projectId: card.project._id,
                orderId: _.uniq(linkedOrderIds),
              }
          }
        
        const { data: prefabList } = await SupplyChain.supplyChain(queryParams);
        const groupedItems = _.groupBy(prefabItems, (i) => i.prefab._id);
        const indexedPrefabs = _.keyBy(prefabList, '_id');
        const itemUUIDmap = {};
        await Promise.all(_.map(groupedItems, async (items, prefabId) => {
          let prefab = indexedPrefabs[prefabId];
          items.forEach((i) => {
            const existingItem = _.find(prefab.items || [], (item) => {
              if (!_.isEmpty(i.catId)) return item.catId === i.catId;
              return false;
            });
            if (existingItem) {
              itemsInPrefab.push(i);
              return;
            }
            prefab.addItem(_.cloneDeep(i));
          });
          try {
            prefab = await prefab.slimSave();
          } catch (e) {
            _.remove(prefab.items, (item) => !item._id);
            throw e;
          }

          prefab.items.forEach((i) => {
            itemUUIDmap[i.uuid] = i._id;
          });

          // update prefab in prefabList
          const prefabIndex = _.findIndex(prefabList, { _id: prefabId });
          if (prefabIndex !== -1) prefabList[prefabIndex] = prefab;
          return true;
        }));

        prefabItems.forEach((i) => {
          i._id = itemUUIDmap[i.uuid];
        });
        if (card.isPM()) {
          card.manager.runs.forEach((r) => r.runItemsCount.forEach((ri) => { ri._id = ri._item._id; }));
        }
      }
      card.isOrdersImport = isOrdersImport;
      card = await card.slimSave();
      if (itemsInPrefab.length) {
        throw itemsInPrefab;
      }
      if (card && !_.isEmpty(card)) {
        toast.success('Changes saved.');
        return onCardLoaded(card, stage);
      }
    } catch (e) {
      console.log('error occured while saving card...', e);
      throw e;
    }
  };
 
  const createBOMForOrders = async (card) => {
    const isDetail = _.some(card.items, (item) => item.stage === 'detailing');
    if (isDetail) card = await createBomAndPR(card);
    let materials = [];
    for (const item of card.items) {
      const bomInfo = {};
      bomInfo.projectId = card.project._id;
      bomInfo.cardId = card._id;
      bomInfo.poManufactureBy = card.getDate('manufactureBy');
      bomInfo.itemId = _.get(item, '_id', null);
      bomInfo.bomBefore = card.dateOffset;
      bomInfo.manufactureBy = moment(bomInfo.poManufactureBy).subtract(bomInfo.bomBefore, 'days').format('MM-DD-YYYY');
      const BOM = await Materials.createBOM(bomInfo);
      if (_.isEmpty(BOM)) continue;
      materials = _.concat(materials, BOM);
    }
    if (materials.length > 0) {
      const bom = materials.filter((m) => card.items.some((itm) => itm._id === m.linkedAssembly));
      if (!_.isEmpty(bom)) {
        // retain materials already linked to the order
        const matsToLink = _.compact(_.concat(bom, card.materials));
        const materialIds = _.castArray(matsToLink).map((b) => b._id);
        const order = await Order.addMaterials(card, _.uniqBy(materialIds));
        card.materials = order.materials;
      }
    }
    return card;
  };

  const { addFiles } = ExcelMixin();
  const createOrderInDetailing = async (params) => {
    const {
      obj, allUsers, excel, selectedProject, datesMap, moveToDetailing, templateOrder, allProjectLocations,
    } = params;
    let user;
    if (obj.uniqueOrderId) {
      obj.manager = {
        uniqueOrderId: {
          value: obj.uniqueOrderId,
        },
      };
    }
    const ownerEmail = !_.isEmpty(obj.owner) ? obj.owner : '';
    obj.owner = {
      user: {},
      company: {},
    };
    let prefab = new BaseOrder(obj);
    if (!_.isEmpty(ownerEmail)) {
      user = _.find(allUsers, (usr) => usr.email === ownerEmail);
    }
    if (!user) {
      // eslint-disable-next-line
      user = store.state.userData;
    }
    if (prefab.stage === 'planning') {
      prefab.owner.user._id = user._id;
      prefab.owner.company._id = user.company;
    }
    // const { datesMap } = excel.value;
    prefab.project._id = selectedProject._id;
    if (!_.has(prefab, '_id') && (store.state.defaultItem
    || excel.createProdOrders || excel.moveToDetailing || moveToDetailing)) {
      const itemObj = { name: prefab.name, customId: prefab.financialId };
      if (prefab.purpose === 'kit') {
        itemObj.purpose = 'kit';
      }
      prefab.addItem(itemObj);
    }
    if (excel.moveToDetailing || moveToDetailing) datesMap.push('detailBy');
    _.forEach(datesMap, (dateKind) => {
      if (_.has(prefab, dateKind) && prefab[dateKind]) {
        if (!prefab._id) {
          prefab.addOrUpdateDate(dateKind, moment(_.get(prefab, dateKind)).add(0, 'days').hours(12).format());
          _.set(prefab.simpleDates, `${dateKind}.isDirty`, prefab.purpose !== 'kit');
        }
      }
    });
    if (templateOrder) {
      prefab = prefab.fillBasicTemplateData(templateOrder);
      prefab = prefab.fillDetailedTemplateData(templateOrder, 'deliver', true, true);
      // map todos files so that the formData is fetched from the todos while
      // adding form to new card using template to display form data on user trying to open
      const mappedTodosFiles = _.flatten(_.map(templateOrder.simpleTodos, 'files'));
      // while selecting template prefill files to the card...
      for (const file of templateOrder.simpleFiles) {
        const newFile = prefab.newFile();
        newFile.uuid = uuid();
        /* On deleting template file in modal, if _id is not
           picked there is no reference in backed to check
           which template must not be added to order */
        _.assign(newFile, _.pick(file, ['url', 'name', 'visible', 'type', '_id', 'copiedFrom']));
        if (file.type === 'form') {
          const resFile = _.find(mappedTodosFiles, { copiedFrom: file.copiedFrom });
          if (!_.isEmpty(resFile)) {
            newFile.formData = _.cloneDeep(resFile.formData);
            newFile.isFromTemplate = true;
          }
        }
      }
      prefab.addTemplateTodos(templateOrder.todos, store.state.userData);
      const orderLevelTodos = _.filter(templateOrder.todos, (todo) => todo.sources.length === 1 && _.some(todo.files, { type: 'form' }));
      prefab.createTodos(orderLevelTodos, prefab.owner, prefab);
      if (excel.moveToDetailing || moveToDetailing) {
        const templateTodoFiles = {};
        for (const t of templateOrder.todos) {
          for (const f of t.files) {
            if (f.url) {
              templateTodoFiles[f.url] = f;
            }
          }
        }
        for (const pItem of prefab.items) {
          for (const s of pItem.simpleFiles) {
            if (!_.isEmpty(_.find(s.sources, { type: 'item' }))) {
              // creating new todos for items
              const file = templateTodoFiles[s.url];
              if (!_.isEmpty(file)) {
                const docToSend = _.pick(file, ['url', 'copiedFrom', 'name', 'type', 'formData', 'visible']);
                docToSend.uuid = uuid();
                prefab.addCheckListToForm(
                  { doc: docToSend, rowData: pItem },
                  store.state.userData,
                );
              }
            }
          }
        }
      }
    }
    if (moveToDetailing) {
      prefab.coord = prefab.poDetailBy;
      _.forEach(datesMap, (dateKind) => {
        if (_.has(prefab, dateKind) && prefab[dateKind]) {
          if (!prefab._id) {
            prefab.addOrUpdateDate(dateKind, moment(_.get(prefab, dateKind)));
            _.set(prefab.simpleDates, `${dateKind}.isDirty`, prefab.purpose !== 'kit');
          }
        }
      });
    }
    if (_.has(prefab, 'note') && prefab.stage === 'planning') {
      // prefab.currentNote = {};
      prefab.currentNote.text += ` ${prefab.note}`;
      // [prefab.currentNote.text, prefab.note].join(' ');
    }
    if (_.has(prefab, '_isValid') && !prefab._isValid) return;
    prefab = addFiles(prefab);
    if (excel.moveToDetailing || moveToDetailing) {
      if (_.some([prefab.deliver, prefab.poDetailBy], (date) => date === undefined || !Validation.isDateValid(date))) {
        throw new Error('Onsite and DetailBy dates are mandatory');
      }
    }
    if (excel.createProdOrders) {
      if (_.some([prefab.deliver, prefab.coord], (date) => date === undefined
      || !Validation.isDateValid(date))) {
        throw new Error('Coordination and Onsite dates are mandatory');
      }
    }
    let order = await prefab.save();
    const project = store.getters.findProject({ _id: order.project._id });
    if (excel.createProdOrders || moveToDetailing) {
      order = new BaseOrder(Object.assign(order.toJSON(), {
        _id: '',
        __t: 'ProductionOrder',
      }));
      const defaults = project.getDefaults(order, 'coordination');
      if (!_.isEmpty(defaults.newOwner)) {
        const owner = _.find(allUsers, (usr) => usr._id === defaults.newOwner._id);
        order.owner.user._id = owner._id;
        order.owner.company._id = owner.company;
      }
      if (obj.poDetailBy && order?.simpleDates?.poDetailBy?.value) order.simpleDates.poDetailBy.value = moment.utc(prefab.poDetailBy).hours(12).format();
      if (obj.poManufactureBy && order?.simpleDates?.poManufactureBy?.value) order.simpleDates.poManufactureBy.value = moment.utc(prefab.poManufactureBy).hours(12).format();
      if (!obj.poManufactureBy && order?.simpleDates?.poManufactureBy?.value
        && !moment(order.simpleDates.poManufactureBy.value).isSameOrAfter(moment(order.simpleDates.poDetailBy.value))) _.set(order, 'simpleDates.poManufactureBy.value', null);
      if ((!order?.simpleDates?.poManufactureBy?.value) || (order?.simpleDates?.poQaBy?.value && order?.simpleDates?.poManufactureBy?.value
          && !moment(order.simpleDates.poQaBy.value).isSameOrAfter(moment(order.simpleDates.poManufactureBy.value)))) _.set(order, 'simpleDates.poQaBy.value', null);
      order = await order.save();
    }
    if (excel.moveToDetailing || moveToDetailing) {
      let loc;
      const isValidLoc = _.find(allProjectLocations, (loca) => loca.name === obj.location);
      if (!store.state.companyRuns.length) {
        await store.dispatch('getCompanyRuns');
      }
      if (!_.isEmpty(isValidLoc)) {
        loc = isValidLoc;
        order.location = loc;
      } else if (templateOrder?.manager?.location?.name) {
        loc = templateOrder.manager.location;
        order.location = loc;
      } else {
        [order.defaultRun] = store.state.companyRuns;
        loc = await getAllLocations(order.project._id);
        [order.location] = loc;
      }
      order.pTrackEnabled = true;
      const defaults = project.getDefaults(order, 'detailing');
      if (!_.isEmpty(defaults.newOwner)) {
        const owner = _.find(allUsers, (usr) => usr._id === defaults.newOwner._id);
        order.owner.user._id = owner._id;
        order.owner.user.name = owner.name;
        order.owner.company._id = owner.company;
        order.owner.company.name = owner.company.companyName;
      }
      order.poDetailBy = order.simpleDates.coord.value;
      order.deliverDate = order.simpleDates.deliver.value;
      order.manager.location = order.location;
      if (!_.isEmpty(defaults.newLocation)) {
        order.location = defaults.newLocation;
      }
      order.projectId = order.project._id;
      order = await ProductionManager.create(order);
      order = new BaseOrder(order);
      order.files = [];
      if (!_.isUndefined(obj.uniqueOrderId) || !_.isEmpty(obj.uniqueOrderId)) {
        order.uniqueOrderId = {
          isSystemGenerated: false,
          value: obj.uniqueOrderId,
        };
      }
      if (templateOrder?.manager?.runs?.length) {
        _.set(order, 'manager.runs[0].archived.value', true);
        const defaultRuns = store.state.companyRuns;
        ProductionManager.getTemplateRuns({
          newCard: order, templateProdOrder: templateOrder, defaultRuns,
        });
        let runs = order.manager.templateRuns;
        if (order.selectedCard && order.selectedCard.manager
          && order.selectedCard.manager.runs && order.selectedCard.manager.runs.length) {
          runs = order.selectedCard.manager.runs;
        }
        for (const run of runs) {
          if (run.form && run.form._id) {
            const newForm = await qcForm.attachForm({
              formId: run.form._id,
              projectId: order.project._id,
              orderId: order._id,
              setTemplateOwner: true,
            });
            run.form = { _id: newForm._id };
            run.hasNewForm = true;
            run.formStatus = newForm.status;
          }
        }
        order.addTemplateRuns(runs, order.selectedCard);
        const filesWithMultipleSources = _.remove(order.files, (file) => file.sources.length >= 2);
        const simpleFilesWithMultipleSources = _.remove(order.simpleFiles, (simpleFile) => simpleFile.sources.length >= 2);
        const todosWithMultipleSources = _.remove(order.todos, (todo) => todo.sources.length >= 2);
        const simpleTodosWithMultipleSources = _.remove(order.simpleTodos, (simpleTodo) => simpleTodo.sources.length >= 2);
        order = await ProductionManager.update(order);
        _.forEach(filesWithMultipleSources, (file) => {
          const savedRun = _.find(order.manager.runs, (run) => run.uuid === file.sources[1]._id);
          if (!_.isEmpty(savedRun)) {
            file.sources[1]._id = savedRun._id;
          }
        });
        _.forEach(simpleFilesWithMultipleSources, (simpleFile) => {
          const savedRun = _.find(order.manager.runs, (run) => run.uuid === simpleFile.sources[1]._id);
          if (!_.isEmpty(savedRun)) {
            simpleFile.sources[1]._id = savedRun._id;
          }
        });
        _.forEach(todosWithMultipleSources, (todo) => {
          const savedRun = _.find(order.manager.runs, (run) => run.uuid === todo.sources[1]._id);
          if (!_.isEmpty(savedRun)) {
            todo.sources[1]._id = savedRun._id;
          }
        });
        _.forEach(simpleTodosWithMultipleSources, (simpleTodo) => {
          const savedRun = _.find(order.manager.runs, (run) => run.uuid === simpleTodo.sources[1]._id);
          if (!_.isEmpty(savedRun)) {
            simpleTodo.sources[1]._id = savedRun._id;
          }
        });
        order.files = _.concat(order.files, filesWithMultipleSources);
        order.simpleFiles = _.concat(order.simpleFiles, simpleFilesWithMultipleSources);
        order.todos = _.concat(order.todos, todosWithMultipleSources);
        order.simpleTodos = _.concat(order.simpleTodos, simpleTodosWithMultipleSources);
      }
      order = await ProductionManager.update(order);
    }
  };

  const disableInput = (rowData) => {
    if (['not-started', 'in-transit', 'complete', 'delivery', 'released-to-inventory',
      'fulfilled', 'in-storage', 'mixed-shipping'].includes(rowData?.stage)) {
      return true;
    }
    return false;
  };
  return {
    saveCard,
    poAddItemRows,
    addPMItem,
    PMAddItemRows,
    createBOMForOrders,
    disableInput,
    createOrderInDetailing,
  };
}
