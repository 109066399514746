<template>
  <o-loading :full-page="true" :active="isLoading" :can-cancel="false"></o-loading>
  <div  class="list-view">
      <TableGutter
        :selectedAction="isBulkActionEnabled"
        :tools="tableProps.gutterOpts"
        @cc-modal-closed="triggerSetHeaderHeight"
        @re-render-table="rerenderMfTable += 1"
      >
      </TableGutter>
      <mf-table
        ref="plannerTable"
        :tableProps="tableProps"
        :loadData="loadData"
        :hideGutter="true"
        :tableName="stageFromUrl"
        :screenTable="true"
        @opened-row="getDetailRowOrder($event)"
        @cell-clicked="onCellClicked"
        :key="rerenderMfTable"
      >
       <template v-slot:lastModified="psData">
        <span class="is-pulled-right">
          {{$filters.usaDate(psData.rowData.lastModified.at)}}
        </span>
      </template>
      <template #lmvIcon="{rowData, rowField, isHeader, rowIndex }">
          <IconField
            :rowData="rowData"
            :rowField="rowField"
            :isHeader="isHeader"
            :rowIndex="rowIndex"
            :projects="allProjects" />
        </template>
      </mf-table>
    <create-order
      v-if="createOrderModal"
      :isActive="createOrderModal"
      @onSave="onCreate"
      @close="createOrderModal = false"
      :stage="stageFromUrl"
      :prefabPurpose="prefabPurpose"
      permissionModule="prefabs"
      :requiredCheck="getRequiredCheck"
    >
    </create-order>

  <transition name="slide">
    <notes-icon-slider
        v-if="isSlideNotesActive"
        :isActive="isSlideNotesActive"
        :rowData="selectedItem"
        @close="closeNotesSlider"
        @update:note="updateNotes($event)"
      >
    </notes-icon-slider>
  </transition>
  <transition name="slide">
    <item-document-slider
      v-if="isFileListVisible"
      :isActive="isFileListVisible"
      :rowData="selectedItem"
      :rowField="rowField"
      @close="closeDocumentSlider"
      :card="detailRowOrder"
      :isDisabled="disabledIconAdd"
      >
    </item-document-slider>
  </transition>
  <activity-log
          :isActive="isActive"
          :id="activityDetails.id"
          :projectId="activityDetails.projectId"
          :orderName="activityDetails.orderName"
          @update:isActive="isActive=false"></activity-log>
  <excel-import
      ref="excel"
      :card="loadData"
      :isExcelModalActive="excelImportModal"
      :kitsImport="kitsImport"
      :multiAssemblyImport="multiAssemblyImport"
      :dates="dates"
      :validateExcelItem="validateExcelItem"
      :saveExcelItem="saveExcelItem"
      :selectedProjectId="selectedProject"
      :maxLimit="500"
      :modalHeaderTitle='excelImportModalText'
      @close-modal="closeModal"
      @refresh-table="refreshTable"
  >
    <template #project>
      <td width="40%">Project*</td>
      <td width="60%">
        <mf-multi-select
                :options="groupedProjects"
                :multiple="false"
                :preselect-first="true"
                :allow-empty="false"
                v-model="selectedProject"
                label="name"
                track-by="_id"
                placeholder="Select option (Required)"
                @select="checkToDisableNext"
        ></mf-multi-select>
      </td>
    </template>
  </excel-import>
  </div>
</template>

<script>
import {
  reactive,
  toRefs,
  computed,
  ref,
  inject,
  onBeforeUnmount,
  onBeforeMount,
} from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';
import _ from 'lodash';
import { ModalProgrammatic } from '@oruga-ui/oruga-next';
import { useToast } from 'vue-toastification';
import SupplyChain from '@/models/SupplyChain';
import tableDefinition from '@/components/table-cols/plannerCols';
import CreateOrder from '@/components/CreateOrder.vue';
import PrefabMixin from '@/components/mixins/PrefabsMixin';
import ExcelImport from '@/components/ExcelImport.vue';
import Catalogs from '@/models/Catalogs';
import Validation from '@/utils/Validations';
import TableGutter from '@/components/TableGutter.vue';
import ExcelMixin from '@/components/mixins/ExcelMixin';
import Projects from '@/models/Projects';
import Validations from '@/models/Validations';
import { BaseOrder } from '@/models/BaseOrder';
import screens from '@/constants';
import moment from 'moment';
import Locations from '@/models/Locations';
import Company from '@/models/Companies';
import ProductionManager from '@/models/ProductionManager';
import CardEditMixin from '@/components/mixins/CardEditMixin';
import UtilityMixin from '@/components/mixins/UtilityMixin';
import ProductionManagerMixin from '@/components/mixins/ProductionManagerMixin';
import NotesIconSlider from '@/components/modals/NotesIconSlider.vue';
import ItemDocumentSlider from '@/components/modals/ItemDocumentSlider.vue';
import { DialogProgrammatic } from '@/components/Dialog';
import Prefab from '@/models/Prefabs';
import MoveOrders from '@/components/modals/MoveOrders.vue';
import MfTable from '../components/table-fields/MfTable.vue';
import CloneCard from '../components/modals/CloneCard.vue';
import SplitCard from '../components/modals/SplitCard.vue';
import ActivityLog from '../components/ActivityLog.vue';
import IconField from '../components/fields/IconField.vue';


export default {
  name: 'planner',
  components: {
    MfTable,
    CreateOrder,
    ActivityLog,
    ExcelImport,
    TableGutter,
    'notes-icon-slider': NotesIconSlider,
    'item-document-slider': ItemDocumentSlider,
    IconField,
  },
  setup() {
    const store = useStore();
    const emitter = inject('emitter');
    const router = useRouter();
    const route = useRoute();
    const toast = useToast();
    const { isValidInteger, addFiles } = ExcelMixin();
    const plannerTable = ref(null);
    const isBulkActionEnabled = computed(() => plannerTable?.value?.selectedBulkAction);
    const excel = ref(null);
    const { saveRow } = PrefabMixin();
    const { openLmvModel, archiveCard, openWithUserPref } = CardEditMixin();
    const { getNegativeList, getAllLocations } = UtilityMixin();
    const { createOrderInDetailing } = ProductionManagerMixin();
    const state = reactive({
      prefabPurpose: null,
      isLoading: false,
      isActive: false,
      isTableReady: true,
      tableProps: tableDefinition,
      createOrderModal: false,
      activityDetails: {
        id: '',
        projectId: '',
        orderName: '',
      },
      excelImportModal: false,
      batchSize: 500,
      // importLimit: this.spaceLeft,
      maxLimit: store.state.itemLimits.pm,
      dates: [],
      prefabDates: [
        {
          kind: 'coord',
          label: 'Coordination',
        },
        {
          kind: 'poDetailBy',
          label: 'Detail By',
        },
        {
          kind: 'deliver',
          label: 'Onsite',
        },
      ],
      kitsImport: false,
      multiAssemblyImport: false,
      catIdsFetched: false,
      existingCatIds: [],
      allUsers: [],
      user: {},
      selectedProject: '',
      allProjects: [],
      excelImportModalText: '',
      isSlideNotesActive: false,
      isFileListVisible: false,
      selectedItem: {},
      isItem: false,
      datesMap: ['coord', 'poDetailBy', 'partsManufactureBy', 'poManufactureBy', 'poQaBy', 'deliver'],
      requiredCheck: [
        { module: 'prefabs', key: 'data' },
        { module: 'prefabs', key: 'move' },
      ],
      rowField: {},
      detailRowOrder: {},
      rerenderMfTable: 0,
      templateProject: {},
      templateOrders: [],
      allProjects: [],
    });
    const importExcel = () => {
      state.kitsImport = false;
      state.dates = state.prefabDates;
      store.state.defaultItem = false;
      state.multiAssemblyImport = false;
    };
    const importKitsExcel = () => {
      state.kitsImport = true;
      state.dates = excel.value.datesMap;
      store.state.defaultItem = true;
      state.multiAssemblyImport = false;
    };
    const importAssembliesExcel = () => {
      state.kitsImport = false;
      state.dates = [];
      store.state.defaultItem = false;
      state.multiAssemblyImport = true;
    };

    const stageFromUrl = computed(() => route.path.split('/').pop());
    const permissionType = () => {
      const res = _.get(screens[store.state.activeScreen], 'permissionModule', '');
      if (res === 'shipping') return 'scm';
      return res;
    };
    const getAllProjects = computed(() => {
      const permModule = (permissionType() === 'scm')
        ? 'shipping' : permissionType();
      return store.getters.getProjectsOnPermission(permModule, store.state.queryParams._projects, 'data');
    });
    const groupedProjects = computed(() => {
      const projects = getAllProjects.value;
      const fetchFilteredProjects = store.state.queryParams.projects;
      const filteredProjectIds = fetchFilteredProjects.map((project) => project._id);
      const filteredProjects = _.filter(store.state.queryParams._projects,
        (project) => !project.isTemplateProject && filteredProjectIds.includes(project._id));
      if (store.state.queryParams.filteredProjects) {
        return filteredProjects;
      }
      return projects;
    });

    const refreshTable = () => {
      plannerTable.value.refreshTable();
    };

    const getRequiredCheck = computed(() => {
      if (store.state.activeScreen === 'coordination') {
        return state.requiredCheck;
      }
      return [];
    });
    const loadData = async (commonParam) => {
      if (_.isEmpty(store.state.userData?.company)) {
        await store.dispatch('getUserData');
      }
      const company = store.getters.selectedIdsForKey('companies', false) || store.state.userData.company
      const { activeScreen } = store.state;
      let params = {
        module: stageFromUrl.value === 'planning' ? 'Prefabs' : 'ProductionOrder',
        company,
        screen: activeScreen,
        isManager: false,
        stage: stageFromUrl.value === 'planning'
          ? store.getters.selectedValuesForKey('orderStage', false) : stageFromUrl.value,
        tag: store.getters.selectedIdsForKey('tags'),
        ...store.getters.selectedDatesForKeys([
          'deliverStartDate',
          'deliverEndDate',
          'coordStartDate',
          'coordEndDate',
          'modifiedStartDate',
          'modifiedEndDate',
        ]),
        ...commonParam,
      };
      params = await getNegativeList(params, {
        owner: store.getters.selectedOwners?.user?._id,
        projectId: [],
      });
      if (stageFromUrl.value === 'planning') {
        params.filterNoItemOrders = false;
      } else if (stageFromUrl.value === 'coordination') {
        _.assign(params, {
          status: store.getters.selectedValuesForKey('orderStatus'),
          levels: store.getters.selectedIdsForKey('level'),
          zones: store.getters.selectedIdsForKey('zone'),
        });
      }
      const apiResp = await SupplyChain.supplyChain(params);
      apiResp.data.forEach((card) => {
        card._customStage = stageFromUrl.value;
      });
      if (plannerTable.value?.selectedBulkAction?.event === 'combine') {
        apiResp.data = _.filter(apiResp.data, (order) => order.purpose !== 'kit');
      }
      return apiResp;
    };

    const onCreate = (card, toOpen) => {
      state.createOrderModal = false;
      if (toOpen) {
        router.push({
          name: card.__t === 'ProductionOrder' ? 'order-edit' : 'prefab-edit',
          params: {
            projectId: card.project._id,
            cardId: card._id,
            orderJSON: JSON.stringify(card),
          },
        });
      } else {
        // eslint-disable-next-line no-unused-expressions
        plannerTable.value?.refreshTable();
      }
    };
    const checkItemStage = (opts) => _.some(_.get(opts, 'data.items', []), (item) => (['planning', 'coordination'].indexOf(item.stage) === -1));
    const confirmRemoval = (event) => {
      const confirmParams = {
        title: 'DELETE PREFAB PACKAGE',
        message: `Are you sure want to delete "${event.data.name}"? This can't be undone.`,
        okButton: 'Delete',
        cancelButton: 'Cancel',
        type: 'danger',
        rootClass: 'danger-dialog',
        onConfirm: async () => {
          try {
            await Prefab.archive(event.data);
            toast.success('Order deleted');
          } catch (e) {
            toast.error(`Error archiving - ${e.data}.  Please contact ManufactOn support`);
          }
          refreshTable();
        },
      };
      DialogProgrammatic.confirm(confirmParams);
    };

    function openRow(card) {
      router.push({
        name: `${card.__t === 'ProductionOrder' ? 'order' : 'prefab'}-edit`,
        params: { projectId: card.project._id, cardId: card._id },
      });
    }

    const onCellClicked = async (event) => {
      if (event.type === 'save') {
        let order = _.cloneDeep(event.data);
        order = await saveRow(order);
        if (!order) {
          return;
        }
        _.set(event.data, 'isEditing', !event.data.isEditing);
        Object.assign(event.data, order);
      } else if (event.type === 'clone') {
        const canNotClone = _.some(_.get(event, 'data.items', []), (item) => {
          const validStages = ['planning', 'coordination', 'detailing'];
          return (validStages.indexOf(item.stage) === -1);
        });
        if (canNotClone) {
          toast.error('Some items are in production: cannot clone');
          return;
        }
        ModalProgrammatic.open({
          component: CloneCard,
          props: {
            card: event.data,
            headerTitle: 'Prefab Package',
            packageName: 'Prefab Package',
            copyOpts: [
              {
                model: 'documents',
                label: 'Order Documents',
              },
              {
                model: 'productionOrders',
                label: 'Production Orders',
              },
              {
                model: 'notes',
                label: 'Notes',
              },
              {
                model: 'productionRuns',
                label: 'Production Order Runs',
              },
            ],
          },
          trapFocus: true,
          canCancel: false,
          rootClass: 'modal-xs',
          events: {
            'refresh-table': async () => {
              // eslint-disable-next-line no-unused-expressions
              await plannerTable.value.refreshTable();
            },
          },
        });
      } else if (event.type === 'split') {
        if (event.data.items.length < 2) {
          toast.warning(
            'Since the order has less than 2 items, so we cannot split this order',
          );
        } else {
          const order = event.data;
          state.isLoading = true;
          state.isLoading = false;
          ModalProgrammatic.open({
            component: SplitCard,
            props: {
              card: order,
              isActive: true,
            },
            custom: false,
            trapFocus: true,
            canCancel: false,
            events: {
              'refresh-table': async () => {
                await plannerTable.value.refreshTable();
              },
            },
          });
        }
      } else if (event.type === 'openCard') {
        openWithUserPref(event.data);
      } else if (event.type === 'addNew') {
        state.prefabPurpose = event.optType;
        state.createOrderModal = true;
      } else if (event.type === 'activity') {
        state.isActive = true;
        state.activityDetails.id = event.data._id;
        state.activityDetails.projectId = event.data.project._id;
        state.activityDetails.orderName = event.data.name;
      } else if (event.type === 'excelImport') {
        const mappedImport = {
          importPrefab: importExcel,
          importKits: importKitsExcel,
          importAssemblies: importAssembliesExcel,
        };
        const excelModalHeaderText = {
          importPrefab: 'import prefabs from excel',
          importKits: 'import kits from excel',
          importAssemblies: 'import Assemblies from excel',
        };
        mappedImport[event.optType]();
        state.excelImportModalText = excelModalHeaderText[event.optType];
        // eslint-disable-next-line prefer-destructuring
        state.selectedProject = groupedProjects.value[0];
        state.excelImportModal = true;
      } else if (event.type === 'lmv') {
        openLmvModel(event.data);
      } else if (event.type === 'moveForward') {
        const order = event.data;
        ModalProgrammatic.open({
          component: MoveOrders,
          props: {
            type: 'prefab-edit',
            cards: [order],
            isActive: true,
            isMulti: false,
            kind: 'forward',
            templateProj: state.templateProject,
            templateOrders: state.templateOrders,
          },
          trapFocus: true,
          canCancel: false,
          events: {
            'refresh-table': async () => {
              await plannerTable.value.refreshTable();
            },
          },
          rootClass: 'modal-sm',
        });
      } else if (event.type === 'archive') {
        if (event.data._customStage !== 'coordination') {
          if (checkItemStage(event)) {
            toast.warning(`Not able to remove. One or more items
              from this order is already in Production Manager and
              hence the order cannot be deleted`);
          } else {
            confirmRemoval(event);
          }
        } else {
          await archiveCard(event.data, refreshTable);
        }
      }
    };

    const methods = {
      validateDates(obj) {
      // set date value to be sent in prop, sequence will get validated in excel import comp.
        for (const date of state.dates) {
          date.val = obj[date.kind];
        }
      },
      async validateExcelItem(obj, assmbs, kit) {
        let errMsg = null;
        if (typeof (obj.customId) === 'number') {
          obj.customId = obj.customId.toString();
        }
        if (state.multiAssemblyImport) {
          const res = _.filter(assmbs, { customId: obj.customId, catId: obj.catId });
          let count = 0;
          if (!_.isEmpty(obj.customId) && !_.isEmpty(kit)) {
            count = kit.items.length;
            assmbs.forEach((d) => {
              const result = _.find(kit.items, { catId: d.catId });
              if (_.isEmpty(result)) count++;
            });
          }
          if (!state.catIdsFetched || (state.existingCatIds.length
            && !state.existingCatIds.includes(obj.catId))) {
            let excelCatIds = _.compact(_.map(assmbs, 'catId'));
            excelCatIds = excelCatIds.map((catId) => (catId ? catId.toUpperCase() : null));
            const existingCatalogs = await Catalogs.getAssemblyParts({
              catId: _.compact(excelCatIds),
              type: 'Assembly',
              limit: 9999,
              page: 1,
            });
            if (!_.isEmpty(_.map(existingCatalogs.data, 'catId'))) {
              state.existingCatIds = _.uniq([..._.map(existingCatalogs.data, 'catId'),
                ...state.existingCatIds]);
              state.catIdsFetched = true;
            }
          }
          if (count > 500) {
            errMsg = 'KIT items limit exceeded. KIT can have 500 items';
          } else if (_.isEmpty(obj.catId)) {
            errMsg = 'Catalog ID is missing';
          } else if (obj.catId && !state.existingCatIds.includes(obj.catId.toUpperCase())) {
            errMsg = `Catalog ID ${obj.catId} is not present in assemblies, hence cannot be imported`;
          } else if (_.isEmpty(obj.customId)) {
            errMsg = 'KIT ID is missing';
          } else if (_.isEmpty(kit)) {
            errMsg = `KIT ${obj.customId} is not present in prefabs, hence cannot be imported`;
          } else if (kit.stage !== 'planning') {
            errMsg = 'KIT order stage is past planning';
          } else if (res.length > 1) {
            errMsg = 'Duplicate entries found';
          } else if (obj.quantity && !isValidInteger(obj, 'quantity')) {
            errMsg = 'Quantity should be an integer in the range 0 - 9999';
          }
          return errMsg;
        }
        if (obj.purpose === 'kit' && _.isEmpty(obj.customId)) {
          errMsg = 'Kit ID is mandatory';
        } else if (!_.isEmpty(obj.customId) && obj.customId.length < 3) {
          errMsg = 'Kit ID must contain at least 3 characters!';
        } else if (_.isEmpty(obj.name)) {
          errMsg = 'Name Field is Mandatory!';
        } else if (obj.name.length < 3) {
          errMsg = 'Name Field must contain three or more characters!';
        } else if (obj.prefabOwner && !Validation.validateEmail(obj.prefabOwner)) {
          errMsg = 'Owner Field must have a valid email!';
        } else if (excel.value?.createProdOrders && (!('coord' in obj) || !('deliver' in obj))) {
          errMsg = 'Coordination and Onsite dates are mandatory';
        } else if (excel.value?.moveToDetailing && (!('coord' in obj) || !('deliver' in obj)
        || !('poDetailBy' in obj))) {
          errMsg = 'Coordination, Onsite and detail By dates are mandatory';
        }
        if (obj.financialId && obj.financialId !== '') {
          obj.financialId = obj.financialId.toString().trim().replace(/ /g, '.').toUpperCase();
          if (!Validations.validateOrderId(obj.financialId)) {
            errMsg = 'Description length must be 4 to 24 characters long and cannot start or end with special characters';
          }
        }
        if (!errMsg && state.dates) {
          methods.validateDates(obj);
        }
        return errMsg;
      },

      async saveExcelItem(obj, __, kit) {
        // refector this code to multimove
        if (!state.excelImportModal || !obj) {
          plannerTable.value.refreshTable();
          return;
        }
        if (state.multiAssemblyImport) {
          for (const assmb of obj) {
            const existingAssmbIdx = _.findIndex(kit.items, { catId: assmb.catId });
            if (existingAssmbIdx > -1) {
              const updatingArray = ['level', 'zone', 'note', 'quantity'];
              if (['planning'].includes(kit.items[existingAssmbIdx].stage)) {
                for (const key of updatingArray) {
                  if (key === 'note') {
                    if (!_.isEmpty(kit.items[existingAssmbIdx].simpleMemos)) {
                      kit.items[existingAssmbIdx].simpleMemos[0].text = assmb.note;
                    } else {
                      const memo = kit.items[existingAssmbIdx].newFTD('memos');
                      memo.text = assmb.note;
                    }
                  } else {
                    kit.items[existingAssmbIdx][key] = assmb[key];
                  }
                }
                // archive the existing document for item on re-import
                kit.files.forEach((f) => {
                  const assemblyItem = _.find(kit.items, { catId: assmb.catId });
                  const result = _.find(f.sources, { _id: assemblyItem._id, type: 'item' });
                  if (!_.isEmpty(result)) f.archived.value = true;
                });
                // add new documents on re-import
                kit.items[existingAssmbIdx] = addFiles(assmb, kit.items[existingAssmbIdx]);
                kit.items[existingAssmbIdx].quantity = kit.items[existingAssmbIdx]?.quantity || 1;
              }
              continue;
            }
            const newAssmb = _.pick(assmb, [
              'quantity',
              'catId',
              'measure',
              'documents',
              'docNames',
              'level',
              'zone',
              'measureUnits',
            ]);
            Object.assign(newAssmb, {
              name: 'dummy name',
              purpose: 'assembly',
              stage: 'planning',
            });
            const itemIdx = kit.addItem({ isEditing: true, ...newAssmb });
            let newItem = kit.items[itemIdx];
            newItem = addFiles(assmb, newItem);
            if (!_.isEmpty(assmb.note)) {
              const memo = newItem.newFTD('memos');
              memo.text = assmb.note;
            }
            newItem.quantity = newItem?.quantity || 1;
          }
          await kit.save();
          return;
        }
        state.allUsers = await Projects.linkedProjectUsers([state.selectedProject._id]);
        const params = {
          allUsers: state.allUsers,
          obj,
          excel: excel.value,
          selectedProject: state.selectedProject,
          datesMap: state.datesMap,
        };
        await createOrderInDetailing(params);
      },
    };

    const updateNotes = async (event) => {
      if (event.type === 'save' && !event.isItem) {
        let order = _.cloneDeep(event.data);
        _.set(event.data, 'isEditing', !event.data.isEditing);
        order = await saveRow(order);
        Object.assign(event.data, order);
      }
    };
    const closeModal = () => {
      state.excelImportModal = false;
    };

    const closeNotesSlider = (() => {
      state.isSlideNotesActive = false;
      state.selectedItem = {};
    });

    const closeDocumentSlider = () => {
      state.isFileListVisible = false;
    };

    const checkToDisableNext = (project) => {
      excel.value.checkToDisableNext(project);
    };
    // triggering the function in mfTable after closing the column chooser modal.
    const triggerSetHeaderHeight = () => {
      // eslint-disable-next-line no-unused-expressions
      plannerTable?.value?.closeColumnChooserModal();
      // eslint-disable-next-line no-unused-expressions
      plannerTable?.value?.refreshFilterOpts();
    };

    const getDetailRowOrder = (e) => {
      state.detailRowOrder = e;
    };

    // trigger notes slider on emit
    emitter.on('toggle:notesSlider', (payload) => { // *Listen* for event
      state.selectedItem = payload.data;
      state.isItem = payload.isItem;
      state.isSlideNotesActive = payload.isActive;
    });

    emitter.on('toggle:itemDocsSlider', (payload) => {
      state.selectedItem = payload.data;
      state.rowField = payload.rowField;
      state.isFileListVisible = payload.isActive;
    });

    onBeforeMount(async () => {
      state.allProjects = await Projects.allProjects();
      ({ templateProject: state.templateProject } = (await Projects.haveTemplateProject()));
      state.templateOrders = await SupplyChain.supplyChain({
        projectId: state.templateProject._id,
        module: 'ProdTemplates',
        filterNoItemOrders: false,
        limit: 9999,
        page: 1,
      });
    });

    onBeforeUnmount(() => {
      // removing eventBus listener
      emitter.off('toggle:notesSlider');
      emitter.off('toggle:itemDocsSlider');
    });
    const disabledIconAdd = computed(() => {
      if (['planning', 'coordination'].includes(store.state.activeScreen)) return true;
    });
    return {
      ...toRefs(state),
      loadData,
      onCellClicked,
      plannerTable,
      onCreate,
      stageFromUrl,
      ...methods,
      closeModal,
      groupedProjects,
      refreshTable,
      isBulkActionEnabled,
      checkToDisableNext,
      excel,
      triggerSetHeaderHeight,
      updateNotes,
      closeNotesSlider,
      getRequiredCheck,
      closeDocumentSlider,
      getDetailRowOrder,
      disabledIconAdd,
    };
  },
};
</script>

<style scoped lang="sass"></style>
