<template>
  <div>
    <div class="modal-card">
      <header class="modal-card-head">
        <h4 class="modal-card-title">Create Shipping Order</h4>
        <div class="is-divider-vertical"></div>
        <i class="icon-close is-clickable" @click="$emit('close')"></i>
      </header>
      <section class="modal-card-body is-size-2 is-marginless">
        <div class="columns">
          <div class="column"> <!-- 1st col -->
            <h4 class="has-text-black-bis is-italic is-size-3 line-height">
              Order Details
            </h4>
            <div class="field">
              <div class="field-label">
                <label class="label">Name *</label>
              </div>
              <div class="field-body">
                <input v-model="shipmentCard.name" class="input" type="text"
                      placeholder="Enter name">
              </div>
            </div>
            <div class="field">
              <div class="field-label">
                <label class="label">Source Project</label>
              </div>
              <div class="field-body">
                <h1 class="line-height"> {{ shipmentCard.project.name }} </h1>
              </div>
            </div>
            <div class="field">
              <field-project-select
                titleLabel="Destination Project"
                :options="destinationProjects"
                :value="shipmentCard.delivery.deliveryProject"
                :isEditing="true"
                @update:value="(v) => destinationProject(v)"
              >
              </field-project-select>
            </div>
            <div class="field">
              <field-user-select
                titleLabel="Owner"
                :isEditing="true"
                :isShippingListView="true"
                :projectId="projectId"
                :value="shipmentCard.delivery.owner"
                @update:value="(v) => $_.set(shipmentCard, 'delivery.owner', v)"
              >
              </field-user-select>
            </div>
            <div class="field">
              <field-file-list
              :order="shipmentCard" prop="files"
              :isShipping="true"
              :refreshFunction="refreshFunction">
              </field-file-list>
            </div>
          </div>
          <!-- 2nd col -->
          <div class="column is-6">
            <h4 class="has-text-black-bis is-italic is-size-3 line-height">
              Shipping Details
            </h4>
            <div class="columns">
              <div class="column pb-0">
                <div class="field">
                  <div class="field-label">
                    <label class="label">Ship Date *</label>
                  </div>
                  <div class="field-body">
                    <div class="line-height has-text-black-bis">
                      <mf-date
                        :item="shipmentCard"
                        :input-props="{
                          kind: 'delivery.deliveryStart',
                          max: 'deliver.deliverBy',
                        }"
                        :is-edit="true"
                      ></mf-date>
                    </div>
                  </div>
                </div>
              </div> <!-- End of column -->
              <div class="column calendar-left pb-0">
                <div class="field">
                  <div class="field-label">
                    <label class="label">Deliver Date *</label>
                  </div>
                  <div class="field-body">
                    <mf-date
                      :item="shipmentCard"
                      :input-props="{
                        kind: 'delivery.deliverBy',
                        min: 'delivery.deliverStart',
                      }"
                      :is-edit="true"
                      >
                    </mf-date>
                  </div>
                </div>
              </div>
            </div>
              <div class="field">
                <field-location-select
                  titleLabel="Destination Location"
                  :isEditing="true"
                  :value="shipmentCard.delivery.deliveryLocation"
                  :projectId="shipmentCard.delivery.deliveryProject._id"
                  :currentProjectId="shipmentCard.delivery.currentProject._id"
                  :deliveryProjectId="shipmentCard.delivery.deliveryProject._id"
                  :key="shipmentCard.delivery.deliveryProject._id"
                  :companyId="user.company"
                  @update:value="(v) => $_.set(shipmentCard, 'delivery.deliveryLocation', v)"
                  ></field-location-select>
              </div>
              <div class="field">
                <field-user-select
                  titleLabel="Recipient *"
                  :isEditing="true"
                  :isMulti="false"
                  :companyId="user.company"
                  :isShippingListView="true"
                  :projectId="projectId"
                  :value="shipmentCard.delivery.recipient"
                  @update:value="(val) => $_.set(shipmentCard, 'delivery.recipient', val)">
                </field-user-select>
              </div>
              <div class="field">
                <field-user-select
                 titleLabel="Notify"
                 :isEditing="true"
                 :rowField="notifyRowField"
                 :isShippingListView="true"
                 :projectId="projectId"
                 :companyId="user.company"
                 :value="shipmentCard.alsoNotify"
                 @update:value="(v) => $_.set(shipmentCard, 'alsoNotify', v)"
                >
                </field-user-select>
              </div>
                <div class="field">
              <h4 class="is-size-3 has-text-black-bis mb-3">
                Notes 
                </h4>
              <textarea v-model="shipmentCard.delivery.notes" class="textarea" rows="4"
                      placeholder="Enter Notes"> </textarea>
                </div>
            </div> <!-- End of columns -->
        </div>
        <div class="field-body is-flex is-align-items-center is-justify-content-space-between" v-if="aggregateFlag">
          <span>
          <h3 class="has-text-weight-bold is-size-3 has-text-black-bis">
          Items
          </h3>
          </span>
          <span>
          <o-checkbox class="p-0 mr-0 "
              v-model="createDeliveries"
              @change="splitDelivery">
              <span class="has-text-black-bis is-size-3">
                  Create a separate delivery for each item
              </span>
            </o-checkbox>
          </span>
        </div>
        <search-bar
          :shouldEmit="true"
          @search="getSearchValue"
          placeholder="Search"
        >
        </search-bar>
        <div class="table-container create-shipping-modal">
          <mf-table
            v-if="isLoaded"
            :tableProps="tableProps"
            :apiMode="false"
            :loadData="loadData"
            :hideGutter="true"
            :checkedRows="selectedItems"
            @checkbox-toggled="selectedRows"
            :key="refreshKey"
            ref="shippingItemsTable"
            :isRowCheckable="isRowCheckable"
          >
            <template v-slot:qty-needed="{ rowData : item }">
              <qty-input
                :value="item.available"
                :max="item.available"
                :roundTo="2"
                :is-disabled="true"
                :key="item.uid">
              </qty-input>
            </template>
          </mf-table>
        </div>
      </section>
      <footer class="">
        <div class="is-size-5 footer-note has-text-white line-height">
          <span class="has-text-weight-bold">Note: </span>
          Only completed items can be shipped.
        </div>
        <div class="modal-card-foot is-justify-content-flex-end">
          <button class="button is-outlined" @click="cancel()">Cancel</button>
          <button
            class="button has-background-black-bis"
            @click="createShippingOrder()"
            :disabled="isCreateShipping"
          >Create</button>
        </div>
      </footer>
    </div>
    <o-loading
      :full-page="false"
      :active="isLoading"
      :can-cancel="true"
    ></o-loading>
  </div>
</template>

<script>
import {
  defineComponent, defineAsyncComponent, toRefs, ref, reactive, computed, onBeforeMount,
} from 'vue';
import {
  get, filter, isEmpty, find, some, isUndefined, flatMap, set, castArray, forEach, isNumber,
} from 'lodash';
import tableDefinition from '@/components/table-cols/createShippingCols';
import MfDate from '@/components/abstract/MfDate.vue';
import SearchBar from '@/components/SearchBar.vue';
import QtyInput from '@/components/fields/QtyInput.vue';
import GeneralShippingMixin from '@/components/mixins/GeneralShippingMixin';
import { useToast } from 'vue-toastification';
import { useStore } from 'vuex';
import uuid from 'uuid/v4';
import Locations from '@/models/Locations';
import Projects from '@/models/Projects';
import Shipping from '@/models/Shipping';
import ShippingLabel from '@/models/ShippingLabel';

import ManageRemainderMixin from '@/components/mixins/ManageRemainderMixin';

import { useRouter, useRoute } from 'vue-router';
import Users from '@/models/Users';

const MfTable = defineAsyncComponent(() => import('@/components/table-fields/MfTable.vue'));
export default defineComponent({
  name: 'CreateShipping',
	emits: ['close'],
  components: {
    MfTable,
    SearchBar,
    QtyInput,
    MfDate,
  },
  props: {
    card: Object,
	  orders: {
      type: [Array],
      default: () => [],
    },
    paramsFromManage: {
      type: Object,
      default: null,
    },
    aggregateFlag:{
      type : Boolean,
      default: false,
    }
  },
  setup(props, { emit }) {
    const tableProps = tableDefinition;
    const store = useStore();
    const toast = useToast();
    const router = useRouter();
    const route = useRoute();
    const { consumeAndUnreserve } = ManageRemainderMixin();
    const {
      generateShipPdf,
      getInventoryItems,
      setDeliveryFromMultiOrders,
      addItemsFromMaster,
    } = GeneralShippingMixin();
    const shippingItemsTable = ref(null);
    const state = reactive({
	    createDeliveries: false,
      user: {},
      isLoaded: false,
      searchText: '',
      isLoading: false,
	    projectId: {},
      shipmentCard: new ShippingLabel({
        name: '',
        reserveFor: null,
        delivery: {
          owner: {
            _id: store.state.userData._id,
            name: store.state.userData.name,
          },
          notes: '',
        },
        items: [],
        externalEmails: [],
        shipType: 's-m',
	      owner: {
          user: {
            _id: store.state.userData._id,
	          name: store.state.userData.name,
          },
	      },
      }),
      notifyRowField: {
        id: 'notifyUser',
        isMulti: true,
      },
      selectedItems: [],
      refreshKey: 0,
      itemsData: [],
      destinationProjects: [],
    });

    const loadData = () => {
      state.isLoading = true;
      let fetchedData = [];
      fetchedData = state.shipmentCard.items;
      fetchedData.forEach((item) => {
        item.uid = uuid();
        item.isEditing = false;
        item.selectedQty = item.quantity;
      });
      state.selectedItems = fetchedData;
      state.isLoading = false;
      return fetchedData;
    };

    const cancel = () => {
      emit('close');
    };
    const selectedRows = (rows) => {
      state.selectedItems = rows;
    };

    const getSearchValue = (searchText) => {
      state.searchText = searchText;
      shippingItemsTable.value.refreshTable();
    };

    const getKitMaterials = async () => {
      const { allData } = await Shipping.get({
        cardIds: props.card._id,
        limit: 50,
        status: ['not-started', 'in-transit', 'in-storage', 'fulfilled', 'mixed'],
        projectId: props.card.project._id,
      });
      const response = flatMap(allData, (shipment) => shipment.items.filter((item) => {
        if (!item.cardId || (item.cardId !== props.card._id) || !item.quantity) return false;
        item.sl = { _id: shipment._id };
        item.isKitMaterial = true;
        item.available = item.quantity;
        return item;
      }));
      return response;
    };

    const isCreateShipping = computed(() => {
      if (isEmpty(state.selectedItems)) { return true; }
      if (isEmpty(state.shipmentCard.name) || state.shipmentCard.name.length < 3) { return true; }
      const ItemExistWithNoQty = some(state.selectedItems, (selectedItem) => selectedItem.selectedQty === 0);
      if (ItemExistWithNoQty) { return true; }

      const shipBy = get(state.shipmentCard, 'delivery.deliveryStart', '');
      const deliverBy = get(state.shipmentCard, 'delivery.deliverBy', '');
      const recipient = get(state.shipmentCard, 'delivery.recipient');

      if (isEmpty(shipBy) || isEmpty(deliverBy)) { return true; }
      if (isEmpty(recipient)) { return true;  }
      return false;
    });
    const destinationProject = async (v) => {
      set(state.shipmentCard, 'delivery.deliveryProject', v);
    };

    const createShippingOrder = async () => {
      const { shipmentCard: shipment, orderCard: card } = state;
      const validate = shipment.validateLabel();
      if (validate.error) {
        toast.error(validate.errText);
        return;
      }
      shipment.items.forEach((i) => {
        i.quantity = i.selectedQty;
      });
      const selectedItems = shipment.items;
      // Todo: shipment.items should be selected Items.
      const nonCatItems = _.some(selectedItems, ({ catId }) => _.isEmpty(catId));
      let deliveryLoc = _.get(shipment, '_delivery.deliveryLocation', false);
      if (nonCatItems && deliveryLoc) {
        if (_.isUndefined(deliveryLoc.nestedLocation)) {
          [deliveryLoc] = await Locations.getOne({ id: deliveryLoc._id });
        }
        if (_.get(deliveryLoc, 'nestedLocation', false)) {
          toast.error(`Cannot ship items without catalog id to nested location '${deliveryLoc.name}'`);
          return;
        }
      }
      state.isLoading = true;
      try {
        for (const dataItem of selectedItems) {
          if (!_.isEmpty(card) || !_.isEmpty(props.orders)) {
            if (dataItem.isKitMaterial) {
              shipment.addItemFromShipment(
                dataItem, dataItem.sl,
                dataItem.quantity,
              );
            } else {
              // Todo: made Qty available when create shipping.
              dataItem.selectedQty = dataItem.quantity || dataItem.available || dataItem.total;
              const sourceItem = (card && card?._id) ?  card : dataItem.source._id
              shipment.addItemFromCard(dataItem, sourceItem);
            }
          } else if (dataItem.isInventoryItem) {
            dataItem.selectedQty = dataItem.quantity || dataItem.available || dataItem.total;
            shipment.addItemFromInventory(dataItem, dataItem.selectedQty);
          }
        }
        try {
          if (!_.isEmpty(props.orders)) {
            for( let i=0; i<props.orders.length; i++) {
              await consumeAndUnreserve({ order: props.orders[i]});
            }
          }
          let newPS = await shipment.save();
          if (state.createDeliveries) {
            newPS = await createDeliveryParItem({ msLabel: newPS, items: selectedItems });
          }
          await generateShipPdf(newPS);
        } catch (e) {
          console.log(e);
          throw e;
        } finally {
          state.isLoading = false;
          emit('close',true);
        }
      } catch (e) {
        toast.error(e.message || e.state.message || 'Error while processing shipment. Please try again later', '');
        console.log('Error while processing shipment', e);
      } finally {
        toast.warning('Shipping list being generated and will be attached in  a moment.');
        state.isLoading = false;
      }
    };
    const createDeliveryParItem = async (params) => {
      let { msLabel, items } = params
      try {
        let masterShipment = {};
        let p1;
        // if s-m type
        if (msLabel.shipType === 's-m') {
          // make a call to break it into m and s
          masterShipment = await Shipping.breakShipmentIntoPartials({
            shippingLabelId: msLabel._id,
          });
          [p1] = masterShipment.partialShipments;
        }
        for (let item of items){
          if (isNumber(item.selectedQty) && (item.selectedQty) > 0) {
            item.quantity -= item.selectedQty;
          }
        }

        for (const item of items){
          const selectedItems = [item];
          let updatedShipment;
          updatedShipment = await Shipping.removeItemsFromPartial({
            shippingLabelId: p1._id,
            items: selectedItems,
          });
          const updatedM1Id = updatedShipment.mLabel._id;
          try {
            masterShipment = await Shipping.getPartialShipments({
              shippingLabelId: updatedM1Id,
            });
            } catch (e) {
              console.log('Failed to get partial shipment', e);
          }
          const itemsToBeAdded = addItemsFromMaster({
            selectedItems,
            masterShipment,
          });
          const newDelivery = new ShippingLabel({
            name: msLabel.name + ' - '+ item.name,
            delivery: msLabel.delivery,
            status: 'not-started',
            project: msLabel.project,
            trackingId: msLabel._delivery.trackingId,
          });
          Object.assign(newDelivery.delivery, {
            deliverBy: msLabel._delivery.deliverBy,
            deliveryStart: msLabel._delivery.deliveryStart,
            trackingId: msLabel._delivery.trackingId,
          });
          for (const itemToBeAdded of itemsToBeAdded) {
            newDelivery.addItemFromShipment(itemToBeAdded, masterShipment, item.selectedQty);
          }
          const updatedP2 = await newDelivery.save();
          masterShipment.partialShipments.push(updatedP2);

        }
        let updatedMS = await masterShipment.save();
        return updatedMS;
      } catch (e) {
        toast.error('Error occured while spliting!', e);
      }
    };
		const createBasicCard = async () => {
      state.shipmentCard = await setDeliveryFromMultiOrders(props.orders, state.shipmentCard);
      state.shipmentCard.items.forEach((item) => {
        item.quantity = item.available;
      });
      for await (const order of props.orders) {
        const materialItems = await getInventoryItems(order);
        const materialItemsToLoad = materialItems.filter((mItem) => {
          if (mItem.qtyToShip === 0) { return false; }
          mItem.available = mItem.qtyToShip || mItem.available;
          mItem.availableInInv = mItem.catId ? 0 : 'N/A';
          set(mItem, 'selectedQty', mItem.available);
          set(mItem, 'initialAvailable', mItem.available);
          return mItem;
        });
        state.shipmentCard.items.push(...materialItemsToLoad);
      }
      if (props.orders.length === 1){
        state.shipmentCard.name = props.orders[0].name;
      }
      // state.selectedItems = state.shipmentCard.items;
      if (state.shipmentCard.purpose === 'kit') {
        if (!isEmpty(props.paramsFromManage) && !isEmpty(props.paramsFromManage.itemsData)) {
          const kitMaterials = await getKitMaterials();
          for (const kItem of kitMaterials) {
            const iData = find(props.paramsFromManage.itemsData, { _id: kItem._id });
            kItem.available = kItem.available - iData.qtyToReturn - iData.qtyConsumed;
            state.itemsData.push(kItem);
          }
        }
      }
      state.itemsData = state.shipmentCard.items;
      state.selectedItems = state.itemsData;
		};
    onBeforeMount(async () => {
      if (props.orders && props.orders.length){
        state.projectId = props.orders[0].project._id
      } else if (props.card) {
        state.projectId = props.card.project._id
      }
      state.isLoading = true;
      await store.getters.userPromise;
      state.user = store.state.userData;
      if (!props.orders.length) {
        props.orders.push(props.card);
      }
      await createBasicCard();
      state.destinationProjects.push(state.shipmentCard.project);
      let { commonStockProject } = store.state.queryParams;
      commonStockProject = !isEmpty(commonStockProject)
        ? commonStockProject : await Projects.getCommonStockProject();
      state.destinationProjects.push(commonStockProject);
      state.isLoading = false;
      state.isLoaded = true;
    });

    const isRowCheckable = () => {
      return !state.createDeliveries
    };

    const splitDelivery = () => {
      state.createDeliveries = !state.createDeliveries;
      state.selectedItems = state.shipmentCard.items;
      shippingItemsTable.value.selectedRows = state.selectedItems;
    };

    return {
      ...toRefs(state),
      tableProps,
      loadData,
      cancel,
      splitDelivery,
      selectedRows,
      getSearchValue,
      isCreateShipping,
      destinationProject,
      createBasicCard,
      createShippingOrder,
      createDeliveryParItem,
      isRowCheckable,
      shippingItemsTable,
    };
  },
});
</script>

<style scoped>

</style>
