<template>
  <div>
    <VDropdown
      placement="right" :shown="isOpen" :autoHide="true"
    >
      <template #popper>
        <div class="card">
          <header class="card-header has-background-grey-lighter">
            <h4
              class="card-header-title is-size-3 has-text-black-bis
                has-text-weight-bold is-paddingless"
            >
              {{ getHeaderText }}
            </h4>
            <div class="is-divider-vertical"></div>
            <span class="card-header-icon">
              <i class="icon-close is-clickable has-text-black-bis" @click="cancel()"></i>
            </span>
          </header>
          <section class="card-content is-marginless has-background-white">
            <span v-if="selectedId !== 'shipDeliveries'"
              class="is-size-4 has-text-weight-semibold has-text-black-bis line-height">
              {{ rowData.name }}
            </span>
            <div v-if="selectedId !== 'shipDeliveries'" class="is-divider"></div>
            <div
              v-if="selectedId === 'qtyCalculator'"
            >
              <div class="line-height has-text-black-bis
                is-flex is-justify-content-space-between"
                v-for="(label, key, $idx) of qtyArr" :key='$idx'
                :class="$idx+1 === Object.keys(qtyArr).length ? '' : 'mb-2'">
                <span class="has-text-weight-semibold is-italic">
                  {{ label }}
                </span>
                <span :class="key === 'inStock' ? 'has-text-weight-bold' :
                  'has-text-weight-semibold'">{{ valsToDisplay[key] }}</span>
              </div>
            </div>
            <div v-if="selectedId === 'shipSummary'">
              <div class="line-height has-text-black-bis
                is-flex is-justify-content-space-between"
                v-for="(label, key, $idx) of shipSummaryArr" :key='$idx'
                :class="$idx+1 === Object.keys(shipSummaryArr).length ? '' : 'mb-2'">
                <span class="has-text-weight-semibold is-italic">
                  {{ label }}
                </span>
                <span class="has-text-weight-semibold"> {{ summaryVals[key] }} </span>
              </div>
            </div>
            <div v-if="selectedId === 'shipDelivs'">
              <div class="line-height has-text-black-bis
                is-flex is-justify-content-space-between"
                v-for="($idx) of itemDeliveries" :key='$idx'
                :class="$idx+1 === Object.keys(itemDeliveries).length ? '' : 'mb-2'">
                <span class="has-text-weight-semibold is-italic">
                  {{$idx.deliveryName}}
                </span>
                <span class="has-text-weight-semibold">
                  {{$idx.deliveryItemQty}}
                </span>
              </div>
            </div>
            <div v-if="selectedId === 'shipDeliveries'">
              <div class="line-height has-text-black-bis
                is-flex is-justify-content-space-between"
                v-for="($idx) of shipDeliveriesArr" :key='$idx'
                :class="mb-2">
                <span class="has-text-weight-semibold is-italic">
                  {{$idx.name}}
                </span>
                <span class="has-text-weight-semibold">
                  <div class="is-flex is-align-items-center p-0 status-col">
                    <div class="image is-16x16 status-circle stage-color"
                      :class="getStaticColor($idx.statusVal)">
                    </div>
                    <span class="line-height p-2"> {{ $_.startCase($idx.status) }} </span>
                  </div>
                </span>
              </div>
            </div>
            <div v-if="selectedId === 'vendors'">
              <div v-for="(vendor, $idx) in getVendors" :key="vendor._id"
                class="has-text-black-bis is-flex"
                :class="$idx+1 === getVendors.length ? '' : 'mb-2'">
                <i class="mr-2" :class="rowData.defVendor?._id === vendor._id ?
                  'icon-default is-selected-text' : 'icon-preferred'"></i>
                <span class="line-height is-inline-block">{{ vendor.name }}</span>
              </div>
            </div>
          </section>
        </div>
      </template>
    </VDropdown>
    <div class="is-flex"
      :class="$_.get(rowField, 'id', '') !== 'defVendor' ?
        'is-justify-content-center is-align-items-center' : ''">
      <div v-if="isHeader">
        <span >
          <i :class="[$_.get(rowField, 'inputProps.icon') ?
                  $_.get(rowField, 'inputProps.icon') : 'icon-linked-model']"></i>
          {{$_.get(rowField, 'title', '')}}
        </span>
      </div>
      <div v-else-if="!isHeader">
        <button class="button mr-0"
          v-if="activeScreen"
        >
          <i class="icon-reorder"></i>
        </button>
        <button  v-if="hasGuid && $_.get(rowField, 'id', '') === 'lmvIcon'"
        @click="viewModel" class="button mr-0">
          <i class="icon-linked-model is-clickable has-text-link"></i>
        </button>
        <button class="button mr-0"
          v-else-if="showQtyCalc && $_.get(rowField, 'id', '') === 'qtyCalculator'"
          @click="openModal($_.get(rowField, 'id', ''))"
        >
          <i class="icon-inventory"></i>
        </button>
        <div class="note-count is-clickable"
         v-else-if="$_.get(rowField, 'id', '') === 'shipDelivs'"
          @click="openModal($_.get(rowField, 'id', ''))">
          <span> {{itemDeliveries.length}} </span>
          <i class="icon-attacheddeliveriesOL"></i>
        </div>
        <div class="note-count is-clickable"
         v-else-if="$_.get(rowField, 'id', '') === 'shipDeliveries'"
          @click="openModal($_.get(rowField, 'id', ''))">
          <span>{{ rowData.partialsCount }}</span>
          <i class="icon-attacheddeliveriesOL"></i>
        </div>
        <button class="button mr-0 note-count"
          v-else-if="$_.get(rowField, 'id', '') === 'shipSummary'"
          @click="openModal($_.get(rowField, 'id', ''))"
          >
          <i class="icon-shippingdetails"></i>
        </button>
        <span
          v-else-if="$_.get(rowField, 'id', '') === 'defVendor'"
        >
          {{$_.get(rowData, 'defVendor.name', '')}}
        </span>
        <button
          class="button has-background-black-bis mr-0 has-text-white has-text-weight-bold"
          v-else-if="getPreferredVendors && getPreferredVendors.length > 0
          && $_.get(rowField, 'id', '') === 'vendors'"
          @click="openModal($_.get(rowField, 'id', ''))"
        >
          <span class="is-size-3">{{getPreferredVendors.length}}</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  reactive,
  toRefs,
  onMounted,
  computed,
} from 'vue';
import Catalogs from '@/models/Catalogs';
import CardEditMixin from '@/components/mixins/CardEditMixin';
import defaultStatus from '@/components/DefaultFilterOpts';
import GeneralShippingMixin from '@/components/mixins/GeneralShippingMixin';
import { get, some, isEmpty } from 'lodash';
import { useStore } from 'vuex';

export default defineComponent({
  props: {
    isHeader: Boolean,
    rowData: {
      type: Object,
      default: () => {},
    },
    key: String,
    rowIndex: Number,
    rowField: {
      type: Object,
      default: () => {},
    },
    shipItemDelivs: {
      type: Array,
      default: () => [],
    },
    projects: {
      type: Array,
      default: () => [],
    }
  },
  setup(props) {
    const { rowData } = props;
    const { openLmvModel } = CardEditMixin();
    const { getDeliveriesOfItems, getMasterShipment, getStaticColor } = GeneralShippingMixin();
    const store = useStore();
    const state = reactive({
      isOpen: false,
      valsToDisplay: {
        inStock: 0,
        reserved: 0,
        inDelivery: 0,
      },
      summaryVals: {
        notScheduled: 0,
        scheduled: 0,
        inTransit: 0,
        delivered: 0,
      },
      hasGuid: false,
      itemDeliveries: [],
      shipDeliveriesArr: [],
      isLoading: false,
      selectedId: null,
      deliveryName: '',
      delStatus: '',
      activeScreen: ['manager-edit-manufacturing', 'manager-edit-detailing', 'manager-edit-qa'].includes(store.state.activeScreen),
    });

    const qtyArr = {
      inStock: 'In Stock (Available)',
      reserved: 'Reserved',
      inDelivery: 'Stock In Transit',
    };
    const shipSummaryArr = {
      notScheduled: 'Unscheduled',
      scheduled: 'Scheduled',
      inTransit: 'In Transit',
      delivered: 'Delivered',
    };

    const showQtyCalc = computed(() => props.rowData && props.rowData.catId);

    const getVendors = computed(() => {
      if (rowData && rowData.vendors) {
        const result = rowData.vendors.filter((vendor) => vendor.isPreferred || vendor.isDefault);
        return result;
      }
      return 0;
    });

    const getPreferredVendors = computed(() => {
      const result = rowData?.vendors?.filter((vendor) => vendor.isPreferred);
      return result;
    });

    const getHeaderText = computed(() => {
      if (state.selectedId === 'qtyCalculator') return 'Inventory';
      if (state.selectedId === 'shipSummary') return 'Item Shipping Status';
      if (state.selectedId === 'shipDelivs') return 'Item\'s Deliveries';
      if (state.selectedId === 'shipDeliveries') return 'Order Delivery Summary';
      if (state.selectedId === 'vendors') return `Preferred Vendors (${getPreferredVendors.value.length})`;
      return '';
    });
    const getCalcVals = async () => {
      state.isLoading = true;
      try {
        if (rowData.catId && rowData.catId.length >= 4) {
          const params = {
            catId: rowData.catId,
            projectId: rowData.order.project._id,
          };
          const returnedVals = await Catalogs.getQtyCalData(params);
          state.valsToDisplay = returnedVals.data;
        }
      } catch (e) {
        console.error('error', e);
      } finally {
        state.isLoading = false;
      }
    };

    const getShipSummary = async () => {
      state.isLoading = true;
      const summary = rowData.masterSummary;
      state.summaryVals = {
        delivered: summary.delivered || 0,
        notScheduled: summary.notScheduled || 0,
        inTransit: summary.inTransit || 0,
        scheduled: summary.scheduled || 0,
      };
      state.isLoading = false;
    };

    const getItemDeliveries = async () => {
      state.isLoading = true;
      try {
        const itemId = rowData._id;
        const shipmentId = localStorage.getItem('shipmentId');
        if (!isEmpty(props.shipItemDelivs)) {
          state.itemDeliveries = props.shipItemDelivs;
        } else {
          state.itemDeliveries = await getDeliveriesOfItems(itemId, shipmentId);
        }
      } catch (e) {
        console.error('Error occured while fetching item delivery summary', e);
      } finally {
        state.isLoading = false;
      }
    };

    const isLmvIconEnabled = async () => {
      state.isLoading = true;
      let isGuidItems = false;
      if (rowData.items && rowData.items.length) {
        isGuidItems = some(rowData.items, (item) => item.hasGuid);
      }
      if (isGuidItems || rowData.hasGuid) {
        const urn = await openLmvModel(rowData, false, props.projects);
        state.hasGuid = !isEmpty(urn);
      }
      state.isLoading = false;
    };

    const getShipDeliveries = async () => {
      state.isLoading = true;
      try {
        const shipmentId = rowData._id;
        state.shipDeliveriesArr = [];
        const shippingStatusVals = defaultStatus.shippingStatus;
        // pushing values for zombie
        shippingStatusVals.push({
          name: 'Complete',
          value: 'zombie',
          id: 'zombie',
        });

        // get master shipment details
        const shipDetails = await getMasterShipment(shipmentId);

        for (let i = 0; i < shipDetails.partials.length; i++) {
          const filterStatus = shippingStatusVals.filter((status) => status.value === shipDetails.partials[i].status);
          state.shipDeliveriesArr.push({
            name: shipDetails.partials[i].name,
            statusVal: filterStatus[0].value,
            status: filterStatus[0].name,
          });
        }
      } catch (e) {
        console.error('Error occured while fetching delivery details');
      } finally {
        state.isLoading = false;
      }
    };

    const openModal = async (id = '') => {
      if (id && id === 'shipDelivs') {
        await getItemDeliveries();
      }
      if (id && id === 'shipDeliveries') {
        await getShipDeliveries();
      }
      state.isOpen = !state.isOpen;
      state.selectedId = id;
      if (id && id === 'qtyCalculator') {
        await getCalcVals();
      }
      if (id && id === 'shipSummary') {
        await getShipSummary();
      }
    };
    const cancel = () => {
      state.isOpen = !state.isOpen;
    };
    const viewModel = async () => {
      await openLmvModel(rowData, true, props.projects);
    };

    onMounted(async () => {
      if (store.state.activeScreen === 'shipping-order-view') {
        if (props.rowField.id === 'shipDeliveries') {
          if (isEmpty(rowData.childShipments)) {
            rowData.partialsCount = 1;
          } else {
            rowData.partialsCount = rowData.childShipments.length;
          }
        }
      }
      if (['shipping-order-view', 'shipping-edit'].includes(store.state.activeScreen)
        && props.rowField.id === 'shipDelivs' && isEmpty(state.itemDeliveries)) {
        await getItemDeliveries();
      }
      if (rowData) {
        await isLmvIconEnabled();
      }
    });

    return {
      showQtyCalc,
      ...toRefs(state),
      openModal,
      getHeaderText,
      cancel,
      getVendors,
      qtyArr,
      shipSummaryArr,
      viewModel,
      getStaticColor,
      getPreferredVendors,
    };
  },
});
</script>
<style scoped>
.is-divider {
  margin: 8px 0 !important;
}
.mr-2 {
  margin-right: 8px !important;
}
.button.mr-0:hover i{
  color: white !important
}
</style>
