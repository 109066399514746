<template>
  <div class="modal-card">
    <header class="modal-card-head">
      <h4 class="modal-card-title">
        {{ title }}
      </h4>
      <div class="is-divider-vertical"></div>
      <i class="icon-close is-clickable" @click="cancel()"></i>
    </header>
    <section class="modal-card-body">
      <p class="has-text-black-bis mb-4 line-height">
        {{ description }}
      </p>
      <mf-multi-select
        v-model="selectedProject"
        :options="currentProjectsListGrouped"
        titleLabel="Project"
        :multiple="false"
        :searchable="true"
        label="name"
        track-by="_id"
        @update:modelValue="projectChanged(selectedProject)"
        group-label="type"
        class="mb-2"
        group-values="projects"
        :allow-empty="false">
      </mf-multi-select>
      <p class="has-text-black-bis mb-5" v-if="!isDestLocNeeded">
        Shipped items must come from same location
      </p>
      <mf-multi-select
        v-model="selectedLocation"
        :options="currentProjectLocations"
        :titleLabel="getSrcLocLabel"
        :multiple="false"
        :searchable="true"
        label="name"
        track-by="_id"
        group-values="val"
        class="mb-2"
        group-label="key"
        :allow-empty="false"
      >
      </mf-multi-select>
      <mf-multi-select
        v-if="isDestLocNeeded"
        v-model="selectedDestLocation"
        :options="currentProjectLocations"
        titleLabel="Destination Inventory Location"
        :multiple="false"
        :searchable="true"
        label="name"
        track-by="_id"
        group-values="val"
        group-label="key"
        :allow-empty="false"
      >
      </mf-multi-select>
    </section>
    <footer class="modal-card-foot is-justify-content-flex-end">
      <button class="button is-outlined" @click="cancel()">Cancel</button>
      <button
        class="button has-background-black-bis"
        @click="select()"
        :disabled="
          !(selectedLocation && (isDestLocNeeded ? selectedDestLocation : true))
        "
      >
        Select{{ isDestLocNeeded ? ' Orders' : '' }}
      </button>
    </footer>
    <o-loading
    :full-page="false"
    :active="isLoading"
    :can-cancel="true"
    ></o-loading>
  </div>
</template>
<script>
import {
  reactive, toRefs, computed, onMounted,
} from 'vue';
import { useStore } from 'vuex';
import _ from 'lodash';
import Projects from '@/models/Projects';
import Locations from '@/models/Locations';
import UtilityMixin from '../mixins/UtilityMixin';

export default {
  props: {
    title: String,
    description: String,
    eventType: String,
  },
  setup(props, { emit }) {
    const store = useStore();
    const state = reactive({
      selectedLocation: null,
      selectedProject: null,
      currentProjectsList: [],
      projectLocations: {},
      selectedDestLocation: null,
      allProjects: [],
      isLoading: false,
    });
    const { getAllLocations } = UtilityMixin();

    const select = () => {
      emit('selected-values', {
        selectedLocation: state.selectedLocation,
        selectedProject: state.selectedProject,
        selectedDestLocation: state.selectedDestLocation,
      });
      emit('close');
    };
    const cancel = () => {
      emit('cancel');
      emit('close');
    };
    const currentProjectsListGrouped = computed(() => {
      if (state.currentProjectsList.length) {
        return Projects.groupProjects(state.currentProjectsList);
      }
      return [];
    });
    const currentProjectLocations = computed(() => {
      if (state.selectedProject && state.projectLocations) {
        return state.projectLocations[state.selectedProject._id];
      }
      return [];
    });
    const fetchProjects = async () => {
      state.isLoading = true;
      // fetching and setting of projects lists happens here
      const companyId = store.state.userData.company;
      const { allProjects, allGIs } = await Projects.projectsForCompany(companyId, { sort: 'activity' });
      const projectList = store.state.queryParams?._projects.length ? store.state.queryParams._projects : allProjects;
      state.allProjects = projectList.concat(allGIs);
      if (!state.allProjects.length) {
        const projects = await Promise.all([
          Projects.allProjects(),
          Projects.allProjects({ isGI: true }),
        ]);
        state.allProjects = _.flatMap(projects);
      }
      const commonStockProject = await Projects.getCommonStockProject();
      const projects = store.getters.getProjectsOnPermission(
        'shipping',
        projectList,
      );
      const projLists = _.cloneDeep(projects);
      projLists.unshift(commonStockProject);
      state.currentProjectsList = _.cloneDeep(projLists);
      state.isLoading = false;
    };

    const fetchLocations = async (projectId, isGIProject, isCommonStockProject) => {
      let locs = await getAllLocations(projectId);
      if (isGIProject) {
        _.remove(locs, (loc) => loc.type !== 'gi_location');
      }

      if (isCommonStockProject) {
        _.remove(locs, (loc) => loc.nestedLocation !== true);
      }
      if (props.eventType !== 'moveToInventory' && !isCommonStockProject) {
        locs = _.partition(locs, (loc) => loc.nestedLocation)[
          +(projectId !== store.state.queryParams.commonStockProject._id)
        ];
      }
      const fetchedLocs = Locations.groupLocations(locs, store.state.companyData);
      return fetchedLocs;
    };
    const projectChanged = async (project) => {
      if (project._id) {
        // set locations list
        if (_.isEmpty(state.projectLocations[project._id])) {
          _.set(state.projectLocations, project._id, []);
          state.isLoading = true;
          const locs = await fetchLocations(project._id, project.isGI, project.isCommonStockProject);
          state.isLoading = false;
          if (locs.length) state.projectLocations[project._id] = locs;
        }
      }
    };

    const isDestLocNeeded = computed(() => {
      if (_.isString(props.eventType) && !_.isEmpty(props.eventType)) {
        return props.eventType === 'moveToInventory';
      }
      return false;
    });

    const getSrcLocLabel = computed(() => {
      if (isDestLocNeeded.value) return 'Source Location';
      return 'Item Location';
    });

    onMounted(async () => {
      await fetchProjects();
    });
    return {
      ...toRefs(state),
      select,
      cancel,
      currentProjectsListGrouped,
      fetchProjects,
      projectChanged,
      fetchLocations,
      currentProjectLocations,
      isDestLocNeeded,
      getSrcLocLabel,
    };
  },
};
</script>

<style scoped>
.modal-card-body {
  overflow: visible;
}
</style>
