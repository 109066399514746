<template>
<span v-if="inputDisabled || disableInput(rowData)" class="is-pulled-right">{{iValue}}</span>
  <input type="number"
    class="input"
    v-else
    step="any"
    v-model.number="iValue"
    :disabled="inputDisabled || disableInput(rowData)" :min="min"
    :max="max" @input="onChange">
</template>

<script>
import {
  clamp, round, isFunction, find, isEmpty, get, set,
} from 'lodash';
import {
  computed, toRefs, watch, reactive,
} from 'vue';
import Helper from '@/models/Helper';
import { useRoute } from 'vue-router';
import ProductionManagerMixin from '@/components/mixins/ProductionManagerMixin';

export default {
  name: 'QtyInput',
  props: {
    value: Number,
    max: { type: Number, default: 9999 },
    min: { type: Number, default: 0 },
    isDisabled: [Boolean, Function],
    roundTo: { type: Number, default: 0 },
    allowNegative: { type: Boolean, default: false },
    showFixed: { type: Boolean, default: false },
    rowField: Object,
    rowData: Object,
    hideTextBox: {
      type: Function,
      default: () => false,
    },
    selectedItems: {
      type: Array,
      default: () => [],
    },
    setMaxVal: {
      type: Boolean,
      default: false,
    }
  },
  setup(props, { emit }) {
    const route = useRoute();
    const { disableInput } = ProductionManagerMixin();
    const state = reactive({
      iValue: props.showFixed && props.value ? props.value.toFixed(2) : props.value,
    });
    const inputDisabled = computed(() => {
      const { rowData, rowField, isDisabled } = props;
      if (rowField?.isDisabled) {
        if (isFunction(rowField?.isDisabled)) {
          return rowField?.isDisabled(rowData, route.params);
        }
      }
      return isDisabled;
    });
    const inputValue = computed({
      get: () => {
        const rowValue = get(props, 'value');
        if (rowValue && Array.isArray(rowValue)) return rowValue.toString();
        return rowValue;
      },
      set: (val) => {
        state.iValue = val;
      },
    });
    const onChange = () => {
      const { max, min, allowNegative } = props;
      const { rowData } = props;
      let val = 0;
      if (allowNegative) {
        if (typeof state.iValue === 'number') {
          val = clamp(state.iValue, min, max);
          state.iValue = round(val, props.roundTo);
        }
      } else {
        val = clamp(state.iValue, min, max);
        if (props.showFixed) state.iValue = val.toFixed(props.roundTo);
        else state.iValue = round(val, props.roundTo);
      }
      emit('input', state.iValue);
      emit('update:value', state.iValue);
      Helper.reSubmitStageChange(rowData);
      if (props.rowData?.unitCost > 0 && route?.params?.stage
        && ['sourcing', 'ordering'].includes(route.params.stage)) {
        set(props.rowData, 'calcUnitCost', props.rowData.unitCost);
      }
    };
    watch(
      // iValue was'nt getting updated for item cost updation
      () => state.iValue,
      (newVal, oldVal) => {
        if (newVal !== oldVal) {
          state.iValue = props.showFixed ? newVal.toFixed(2) : newVal;
        }
      },
    );

    watch(
      () => props.selectedItems,
      (selectedItemsArray) => {
        const item = find(selectedItemsArray, { uid: props.rowData.uid });
        if (!isEmpty(item) && (!state.iValue ||  props.setMaxVal)) {
          if (props.setMaxVal) {
            state.iValue = item.quantity;
          } else {
            state.iValue = 1;
          }
          emit('update:value', state.iValue);
        } else if (isEmpty(item)) {
          state.iValue = 0;
          emit('update:value', state.iValue);
        }
      },
    );

    return {
      ...toRefs(state),
      inputDisabled,
      onChange,
      inputValue,
      disableInput,
    };
  },
};
</script>
<style scoped>
div >>> .borderless {
  border-bottom: 0 !important;
}
</style>
