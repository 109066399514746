import { createRouter, createWebHashHistory } from 'vue-router';
import { isFunction, camelCase, isEmpty } from 'lodash';
import store from '@/store';
import Planner from '@/views/Planner.vue';
import Schedule from '@/views/Schedule.vue';
import Manager from '@/views/Manager.vue';
import MaterialManager from '@/views/MaterialManager.vue';
import ShippingList from '@/views/ShippingList.vue';
import Home from '@/views/Home.vue';
import TabsWrapper from '@/components/TabsWrapper.vue';
import AssembliesParts from '@/components/AssembliesParts.vue';
import MMEdit from '@/views/card-edit/mm-edit.vue';
import PMEdit from '@/views/card-edit/pm-edit.vue';
import PrefabEdit from '@/views/card-edit/prefab-edit.vue';
import POEdit from '@/views/card-edit/po-edit.vue';
import ShippingEdit from '@/views/card-edit/shipping-edit.vue';
import Login from '@/views/Login.vue';
import LoadIFrame from '@/views/LoadIFrame.vue';
import ForgeViewer from '@/views/lmv/ForgeViewer';
import ForgeAuth from '@/views/lmv/ForgeAuth.vue';
import ProductionStatus from '@/views/scm/ProductionStatus.vue';
import MaterialDashboard from '@/views/MaterialDashboard.vue';
import PlanningDashboard from '@/views/PlanningDashboard.vue';
import ProductivityDashboard from '@/views/ProductivityDashboard.vue';
import MaterialStatus from '@/views/scm/MaterialStatus.vue';
import InventoryManager from '@/views/InventoryManager';
import TransferRequestEdit from '@/views/card-edit/transfer-request-edit.vue';
import TransferRequest from '@/views/TransferRequest.vue';
import UserActivation from '@/views/UserActivation.vue';
import ProcoreAuth from '@/views/ProcoreAuth.vue';
import RecentEvents from '@/views/RecentEvents.vue';
import { DialogProgrammatic } from '@/components/Dialog';
import Notifications from '@/views/resources/Notifications.vue';
import Tasks from '@/views/resources/Tasks.vue';
import FormsList from '@/views/resources/FormsList.vue';
import WebQr from '@/views/LoadQr.vue';
import KitView from '@/views/card-edit/Kitview.vue';
import SignUp from '@/views/SignUp.vue';
import ThankYou from '@/views/ThankYou.vue';
import ResetPassword from '@/views/ResetPassword.vue';
import QcFormBuilderTemplate from '@/views/QcFormBuilderTemplate.vue';
import ProdtemplateQcFormView from '@/views/ProdTemplateQcFormView';
import ItemQRScan from '@/components/modals/ItemQRScan';

const routes = [
  {
    path: '/home',
    name: 'home',
    component: Home,
    meta: {
      permissionKey: 'home:all_home_tabs',
    },
  },
  {
    path: '/signup',
    name: 'signup',
    component: SignUp,
    meta: {
      skipUser: true,
    },

  },
  {
    path: '/thankyou',
    name: 'thankyou',
    component: ThankYou,
    meta: {
      skipUser: true,
    },
  },
  {
    path: '/account/resetPassword',
    name: 'resetPassword',
    component: ResetPassword,
    meta: {
      skipUser: true,
    },
  },
  {
    path: '/settings',
    name: 'settings',
    component: LoadIFrame,
    props: () => ({
      src: '/settings/projects',
    }),
  },
  {
    path: '/mfPublic/submittal/:submittalId',
    name: 'mfPublic',
    component: LoadIFrame,
    meta: {
      skipUser: true,
      origin: 'Submittals',
    },
    props: true,
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      skipUser: true,
      activeViewColor: ' ',
    },
  },
  {
    path: '/account/activate',
    name: 'userActivation',
    component: UserActivation,
    meta: {
      skipUser: true,
    },
  },
  {
    path: '/prefab',
    name: 'prefab',
    component: TabsWrapper,
    children: [{
      path: '/prefab/dashboard',
      name: 'pp-dashboard',
      title: 'Dashboard',
      component: PlanningDashboard,
      meta: {
        origin: 'Prefab Planner',
        icon: 'icon icon-prefab-planner',
        activeViewColor: 'mf-pl-background',
        appendParamToName: ['stage'],
        permissionKey: 'prefabs:data',
        isBiDashBoard: true,
      },
    }, {
      path: '/prefab/planning',
      name: 'planning',
      title: 'Planning',
      component: Planner,
      meta: {
        origin: 'Prefab Planner',
        icon: 'icon icon-prefab-planner',
        activeViewColor: 'mf-pl-background',
        appendParamToName: ['stage'],
        permissionKey: 'prefabs:data',
      },
    }, {
      path: '/prefab/coordination',
      name: 'coordination',
      title: 'Coordination',
      component: Planner,
      meta: {
        origin: 'Prefab Planner',
        icon: 'icon icon-prefab-planner',
        activeViewColor: 'mf-pl-background',
        appendParamToName: ['stage'],
        permissionKey: 'production-order:data',
      },
    }, {
      path: '/prefab/schedule',
      name: 'schedule',
      title: 'Schedule',
      component: Schedule,
      meta: {
        origin: 'Prefab Planner',
        icon: 'icon icon-prefab-planner',
        activeViewColor: 'mf-pl-background',
        permissionKey: 'prefabs:data',
      },
    }],
  },
  {
    path: '/manager',
    name: 'manager',
    component: TabsWrapper,
    children: [{
      path: '/manager/dashboard',
      name: 'pm-dashboard',
      title: 'Dashboard',
      component: ProductivityDashboard,
      meta: {
        origin: 'Dashboard',
        icon: 'icon icon-productionmanager',
        activeViewColor: 'mf-pm-background',
        appendParamToName: ['stage'],
        permissionKey: 'production-manager:data',
        isBiDashBoard: true,
      },
    }, {
      path: '/manager/detailing',
      name: 'detailing',
      title: 'Detailing',
      component: Manager,
      meta: {
        origin: 'Detailing',
        icon: 'icon icon-productionmanager',
        activeViewColor: 'mf-pm-background',
        appendParamToName: ['stage'],
        permissionKey: 'production-manager:data',
      },
    }, {
      path: '/manager/manufacturing',
      name: 'manufacturing',
      title: 'Manufacturing',
      component: Manager,
      meta: {
        origin: 'Manufacturing',
        icon: 'icon icon-productionmanager',
        activeViewColor: 'mf-pm-background',
        appendParamToName: ['stage'],
        permissionKey: 'production-manager:data',
      },
    }, {
      path: '/manager/qa',
      name: 'qa',
      title: 'QA/QC',
      component: Manager,
      meta: {
        origin: 'QA',
        icon: 'icon icon-productionmanager',
        activeViewColor: 'mf-pm-background',
        appendParamToName: ['stage'],
        permissionKey: 'production-manager:data',
      },
    }],
  },
  {
    path: '/account/general-settings',
    name: 'user settings',
    component: LoadIFrame,
    props: () => ({
      src: '/account/general-settings',
    }),
  },
  {
    path: '/settings/projects',
    name: 'projects',
    component: LoadIFrame,
    props: () => ({
      src: '/settings/projects',
    }),
  },
  {
    path: '/settings/company-profile/assembly-parts/',
    name: 'assembly-parts',
    component: TabsWrapper,
    children: [
      {
        path: '/settings/company-profile/assembly-parts/assemblies',
        name: 'assemblies',
        title: 'Assemblies',
        component: AssembliesParts,
        meta: {
          showSideBar: true,
          screenName: 'assemblies',
          permissionKey: 'prefabs:data',
        },
      },
      {
        path: '/settings/company-profile/assembly-parts/parts',
        name: 'parts',
        title: 'Parts',
        component: AssembliesParts,
        meta: {
          showSideBar: true,
          screenName: 'parts',
          permissionKey: 'prefabs:data',
        },
      },
    ],
  },
  {
    path: '/prefabs/:projectId/:cardId',
    name: 'prefab-edit',
    component: PrefabEdit,
    props: (route) => ({
      ...route.params,
      stage: 'planning',
    }),
    meta: {
      showSideBar: false,
      permissionKey: 'prefabs:data',
      origin: 'Prefab Planner',
      icon: 'icon icon-prefab-planner',
      appendParamToName: ['stage'],
      screenName: 'prefab-edit',
      activeViewColor: 'mf-pl-background',
      cardEditIcon: 'projectplanner',
    },
  },
  {
    path: '/orders/:projectId/:cardId',
    name: 'order-edit',
    component: POEdit,
    props: (route) => ({
      ...route.params,
      stage: 'coordination',
    }),
    meta: {
      showSideBar: false,
      permissionKey: 'production-order:data',
      origin: 'Prefab Planner',
      icon: 'icon icon-prefab-planner',
      appendParamToName: ['stage'],
      screenName: 'po-edit',
      activeViewColor: 'mf-pl-background',
      cardEditIcon: 'projectplanner',
    },
  },
  {
    path: '/managers/:projectId/:cardId/:stage',
    name: 'manager-edit',
    component: PMEdit,
    props: true,
    meta: {
      showSideBar: false,
      origin: 'Production Manager',
      icon: 'icon icon-production-manager',
      permissionKey: 'production-manager:data',  
      appendParamToName: ['stage'],
      activeViewColor: 'mf-pm-background',
      cardEditIcon: 'productionmanager',
    },
  },
  {
    path: '/material/:projectId/:cardId/:stage',
    name: 'material-edit',
    component: MMEdit,
    props: true,
    meta: {
      showSideBar: false,
      permissionKey: 'material-manager:data',
      origin: 'Material Manager',
      icon: 'icon-materialmanager',
      permissionKey: 'material-manager:data', 
      appendParamToName: ['stage'],
      screenName: 'material-edit',
      activeViewColor: 'mf-mm-background',
      cardEditIcon: 'materialmanager',
    },
  },
  {
    path: '/sourcing/:projectId/:cardId/:stage',
    name: 'sourcing-edit',
    component: MMEdit,
    props: true,
    meta: {
      showSideBar: false,
      origin: 'Material Manager',
      icon: 'icon-materialmanager',
      permissionKey: 'material-manager:data',  
      appendParamToName: ['stage'],
      screenName: 'material-edit',
      activeViewColor: 'mf-mm-background',
      cardEditIcon: 'materialmanager',
    },
  },
  {
    path: '/shipping/:projectId/:cardId/',
    name: 'shipping-edit',
    component: ShippingEdit,
    props: true,
    meta: {
      showSideBar: false,
      origin: 'Shipping Manager',
      icon: 'fas fa-map-marker-check',
      screenName: 'shipping-edit',
      activeViewColor: 'mf-lm-background',
      cardEditIcon: 'logisticsmanager',
    },
  },
  {
    path: '/materials',
    name: 'mm',
    component: TabsWrapper,
    children: [{
      path: '/materials/dashboard',
      name: 'mm-dashboard',
      title: 'Dashboard',
      component: MaterialDashboard,
      meta: {
        permissionKey: 'material-manager:data',
        origin: 'Material Manager',
        icon: 'icon-materialmanager',
        activeViewColor: 'mf-mm-background',
        isBiDashBoard: true,
      },
    }, {
      path: '/materials/preparation',
      name: 'mm-preparation',
      title: 'Requesting',
      component: MaterialManager,
      meta: {
        permissionKey: 'material-manager:data',
        origin: 'Material Manager',
        icon: 'icon-materialmanager',
        activeViewColor: 'mf-mm-background',
      },
    }, {
      path: '/materials/sourcing',
      name: 'mm-sourcing',
      title: 'Sourcing',
      component: MaterialManager,
      meta: {
        permissionKey: 'material-manager:data',
        origin: 'Material Manager',
        icon: 'icon-materialmanager',
        activeViewColor: 'mf-mm-background',
      },
    }, {
      path: '/materials/ordering',
      name: 'mm-ordering',
      title: 'Ordering',
      component: MaterialManager,
      meta: {
        permissionKey: 'material-manager:data',
        origin: 'Material Manager',
        icon: 'icon-materialmanager',
        activeViewColor: 'mf-mm-background',
      },
    },
    ],
  },
  {
    path: '/materials/overview',
    name: 'mm-overview',
    component: Home,
    meta: {
      permissionKey: 'material-manager:overview',
      origin: 'Material Manager',
      icon: 'icon-materialmanager',
      screenName: 'mm-overview',
      activeViewColor: 'mf-mm-background',
    },
  },
  {
    path: '/materials-overview',
    redirect: '/materials/overview',
  },
  {
    path: '/logistics',
    name: 'logistics',
    component: TabsWrapper,
    children: [{
      path: '/logistics/inventory',
      component: InventoryManager,
      name: 'inventory',
      title: 'Inventory',
      meta: {
        permissionKey: 'inventory:data',
      },
    }, {
      path: '/logistics/shipping/:view',
      name: 'shipping',
      title: 'Shipping',
      component: ShippingList,
      props: true,
      meta: {
        icon: 'fa fa-link',
        activeViewColor: 'mf-lm-background',
        appendParamToName: ['view'],
        permissionKey: 'shipping:data',
      },
    }, {
      path: '/logistics/transfer-request',
      component: TransferRequest,
      name: 'transfer-request',
      title: 'Transfer Requests',
      meta: {
        permissionKey: 'inventory:data',
      },
    }],
    meta: {
      activeViewColor: 'mf-lm-background',
    },
  },
  {
    path: '/lmv/:module/:urn/:projectId',
    name: 'lmv-view',
    component: ForgeViewer,
    props: true,
    meta: {
      showSideBar: false,
      origin: 'Large Model Viewer',
      icon: 'fa fa-building',
    },
  },
  {
    path: '/lmv/:module/:urn/:projectId/:orderId',
    name: 'lmv-view-card',
    component: ForgeViewer,
    props: true,
    meta: {
      showSideBar: false,
      origin: 'Large Model Viewer',
      icon: 'fa fa-building',
    },
  },
  {
    path: '/lmv/:module/:urn/:projectId/:orderId/:itemId',
    name: 'lmv-view-item',
    component: ForgeViewer,
    props: true,
    meta: {
      showSideBar: false,
      origin: 'Large Model Viewer',
      icon: 'fa fa-building',
    },
  },
  {
    path: '/scm/production-status/:projectId/kitView/:customId',
    name: 'kitView',
    component: Home,
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: {
      name: 'home',
    },
  },
  {
    path: '/account/third-party-plugins',
    name: 'third-party-plugins',
    component: LoadIFrame,
    props: () => ({
      src: '/account/third-party-plugins',
    }),
  },
  {
    path: '/projects/:projectId/:tab',
    name: 'view-project',
    component: LoadIFrame,
    props: true,
    meta: {
      origin: 'App & Project Setup',
    },
  },
  {
    path: '/webqr',
    name: 'web-qr',
    component: WebQr,
    props: true,
    meta: {
      origin: 'Web QrCode',
      icon: 'fas fa-qrcode',
    },
  },
  {
    path: '/itemQR',
    name: 'ItemQRScan',
    component: ItemQRScan,
    props: true,
  },
  {
    path: '/forge/:oauth',
    name: 'forge-authorize',
    component: ForgeAuth,
    props: true,
  },
  {
    path: '/procore/:oauth',
    name: 'procore-authorize',
    component: ProcoreAuth,
    props: true,
  },
  {
    path: '/scm/production-status',
    name: 'scm',
    component: TabsWrapper,
    children: [{
      path: '/scm/production-status/:view',
      name: 'production-status',
      title: 'production status',
      component: ProductionStatus,
      props: true,
      meta: {
        permissionKey: 'scm:production-status',
        origin: 'Supply Chain Manager',
        icon: 'fa fa-link',
        activeViewColor: 'mf-scm-background',
        appendParamToName: ['view'],
      },
    }, {
      path: '/scm/material-status/:view',
      name: 'material-status',
      title: 'material status',
      component: MaterialStatus,
      props: true,
      meta: {
        permissionKey: 'scm:material-status',
        origin: 'Supply Chain Manager',
        icon: 'fa fa-link',
        appendParamToName: ['view'],
        activeViewColor: 'mf-scm-background',
      },
    }],
  },
  {
    path: '/kitView/:projectId/:customId',
    name: 'kitView',
    component: KitView,
    meta: {
      showSideBar: false,
      origin: 'Kit Contents',
      screenName: 'kit-view-edit',
      activeViewColor: 'mf-mm-background',
      cardEditIcon: 'kit',
    },
    props: (route) => ({
      ...route.params,
    }),
  },
  {
    path: '/transfer-request/:requestId',
    name: 'transfer-request-edit',
    component: TransferRequestEdit,
    props: true,
    meta: {
      permissionKey: 'inventory:data',
      showSideBar: false,
      origin: 'Transfer Requests',
      icon: 'icon-materialmanager',
      screenName: 'transfer-request-edit',
      activeViewColor: 'mf-lm-background',
    },
  },
  {
    path: '/resources/forms',
    name: 'resources',
    component: TabsWrapper,
    children: [{
      path: '/resources/forms',
      name: 'forms',
      title: 'forms',
      component: FormsList,
      props: true,
      meta: {
        permissionKey: 'home:all_home_tabs',
        origin: 'resources',
        icon: 'fa fa-link',
        activeViewColor: 'mf-home-background',
      },
    }, {
      path: '/resources/notifications',
      name: 'notifications',
      title: 'notifications',
      component: Notifications,
      props: true,
      meta: {
        permissionKey: 'home:all_home_tabs',
        origin: 'resources',
        icon: 'fa fa-link',
        activeViewColor: 'mf-home-background',
      },
    },
    {
      path: '/resources/recentEvents/:view',
      name: 'recentEvents',
      title: 'Recent Events',
      component: RecentEvents,
      props: true,
      meta: {
        permissionKey: 'home:all_home_tabs',
        origin: 'resources',
        icon: 'fa fa-link',
        activeViewColor: 'mf-home-background',
        appendParamToName: ['view'],
      },
    },
    {
      path: '/resources/tasks',
      name: 'tasks',
      title: 'tasks',
      component: Tasks,
      props: true,
      meta: {
        permissionKey: 'home:all_home_tabs',
        origin: 'resources',
        icon: 'fa fa-link',
        activeViewColor: 'mf-home-background',
      },
    }],
  },
  {
    path: '/qcFormTemplate/:companyId/:formId/',
    name: 'qcFormTemplate-edit',
    component: QcFormBuilderTemplate,
    props: true,
    meta: {
      origin: 'Form Builder Template',
      screenName: 'qc-formBuilder-template-edit',
      showSideBar: false,
    },
  },
  {
    path: '/qcProdFormPreview/:formId',
    name: 'qcFormProdTemplate',
    component: ProdtemplateQcFormView,
    props: true,
    meta: {
      origin: 'Form Builder Template',
      screenName: 'qc-formBuilder-template-edit',
      showSideBar: false,
    },
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

// eslint-disable-next-line consistent-return
router.beforeEach(async (to, from, next) => {
  await store.restored; // wait untill store is available
  store.commit('setMultiRemoveActive', false);
  // We are clearing workstep whenever user refresh or switch the tabs
  if (!isEmpty(store.state.queryParams.selectedWorkstep)) {
    store.commit('setSelectedWorkStep', '');
  }
  if (!to.meta.skipUser) {
    await store.getters.userPromise;
    if (to.meta.permissionKey) {
      const subTab = store.getters.getSubtabsModule(camelCase(to.params.tab));
      let key = to.meta.permissionKey;
      let hasPermission = false;
      const compCheck = ['app&projects:projects', 'app&projects:members', 'app&projects:companies',
        'scm:production-status', 'scm:material-status', 'scm:shipping', 'home:all_home_tabs'];
      if (isFunction(key)) key = key(to);
      if (subTab && store.getters.hasCompanyPermissionForModule(subTab)) {
        hasPermission = store.getters.hasCompanyPermissionForModule(key);
      } else if (to.params && to.params.view === 'third-party-plugins') {
        hasPermission = store.getters.hasCompanyPermissionForModule(key);
      } else if (key === 'material-manager:overview') {
        hasPermission = store.getters.hasCompanyPermissionForModule(key);
      } else if (store.getters.hasPermissionForModule(key) && !subTab
        && key !== 'material-manager:overview') {
        if (compCheck.includes(key)) {
          hasPermission = store.getters.hasCompanyPermissionForModule(key);
        } else hasPermission = true;
      } else if (key === 'app&projects:plugins') hasPermission = true;

      if (hasPermission) return next();

      let routePath = '/home';
      let tab = 'home';

      if (key === 'home:all_home_tabs') {
        routePath = '/scm/production-status/order-view';
        tab = 'SCM';
      }
      DialogProgrammatic.confirm({
        title: 'Error',
        message: `You do not have permission to view this module redirecting to ${tab}`,
        okButton: 'OK',
        type: 'danger',
      });
      return next({ path: routePath });// throw error and redirect to home page
    }
  }
  next();
});

router.afterEach((to) => {
  if (to.name === 'qr-edit') {
    if (['manufacturing', 'detailing'].includes(to.params.stage)) {
      to.meta.screenName = `qr-${to.params.stage}`;
    }
    if (to.params.stage === 'qa' && to.params.type === 'Materials') {
      to.meta.screenName = `qr-mm-${to.params.stage}`;
    }
  }
  let screenName = to.meta && to.meta.screenName ? to.meta.screenName : to.name;
  if (to.meta.appendParamToName) {
    to.meta.appendParamToName.forEach((propKey) => {
      if (to.params[propKey]) screenName += `-${to.params[propKey]}`;
    });
  }
  store.commit('changeScreen', { screen: screenName });
  store.commit('resetSearchText');
});
export default router;
