<template>
  <o-modal
      :active="isActive"
      :canCancel="false"
      class="modal-sm"
    >
    <div class="modal-card">
      <!-- Modal Header -->
      <header class="modal-card-head">
        <h4 class="modal-card-title" >
          {{ getTitle }}
        </h4>
        <!-- <div class="is-divider-vertical"></div> -->
        <!-- <i class="icon-close is-clickable" @click="$emit('close')"></i> -->
      </header>

      <!-- Modal Body -->

      <template-list v-if="loadTemplateOrders" :stage="stage" :purpose="card.purpose"
        @fill-template-data="fillTemplateData"
        @close-template-modal="loadTemplateOrders = false">
      </template-list>
      <div class="modal-card-body is-visible has-background-white p-0" v-else>
        <div
          v-if="stage !== 'planning'"
          class="select-template is-flex is-align-items-center is-justify-content-space-between">
          <span class="is-size-3 has-text-black-bis"> {{ getTemplateName }} </span>
          <button class="button has-background-black-bis has-text-weight-bold is-size-5"
            :disabled="!templateProject" @click="loadOrders">
            <span v-if="!selectedOrder">Select Template {{selectedOrder}}</span>
            <span v-else>Replace Template</span>
          </button>
        </div>
        <div class="line-height required-label is-size-5 is-italic">
          * required
        </div>
        <div class="column pt-0">
          <div class="columns">
          <div class="column pt-0">
            <h4
              class="is-size-3"
            >
            {{orderDetailsTitle}}
            </h4>

            <div class="field">
              <div class="field-label">
                <label class="label is-size-3"> Name </label>
              </div>
              <div class="field-body">
                <div class="control">
                    <input class="input" v-model.trim="orderName.name"
                      placeholder="Enter name (3 or more characters)">
                  </div>
              </div>
            </div>

            <div class="field" v-if="card.isKit()">
              <div class="field-label">
                <label class="label is-size-3"> Kit ID * </label>
              </div>
              <div class="field-body">
                <div class="control">
                    <input class="input" v-model.trim="card.customId"
                      placeholder="Enter Kit ID (3 or more characters)">
                  </div>
              </div>
            </div>

            <project-select
              titleLabel="Project"
              :value="card.project"
              @update:value="(v) => $_.set(card, 'project', v)"
              :isEditing="true"
              :options="allProjects"
              :selfCompany="true"
              placeholder="Project Name (Required)"
              class="field"
              @select="projectSelected($event)"
              :hasPermission="showProjects"
            >
            </project-select>

            <div class="field">
              <div class="field-label">
                <label class="label"> Owner </label>
              </div>
              <div class="field-body">
                <div class="field is-expanded">
                  <div class="control">
                    <mf-multi-select v-model="cardOwner"
                      track-by="_id" label="name" placeholder="Owner Name"
                      :preselect-first="true"
                      :disabled="allUsers.length === 0"
                      :options="allUsers" :searchable="true" :allow-empty="false"
                      class="pendo-owner-select">
                    </mf-multi-select>
                  </div>
                </div>
              </div>
            </div>

            <div class="field">
              <div class="field-label">
                <label class="label"> Stage </label>
              </div>
              <div class="field-body">
                <stage-field
                  :value="stage"
                  >
                </stage-field>
              </div>
            </div>
          </div>

          <div class="column pt-0">
            <h4
              class="is-size-3"
            >
              {{deliveryDetailsTitle}}
            </h4>
            <div class="field" v-if="!card.isMM() && !card.isKit()">
              <div class="field-body">
                <label class="label">{{$_.startCase(dateLabel)}}</label>
                <div class="field is-expanded">
                  <div class="control">
                    <mf-date :item="card" :input-props="{
                                kind: firstDateKey, max: 'deliver', preFillDate: !$_.isEmpty(selectedOrder) ? setDateTo : '',
                              }" :isTemplatedSelected='!$_.isEmpty(selectedOrder)'
                            :is-edit="true" :is-disabled="disableDate" :key="selectedOrder?._id"></mf-date>
                  </div>
                </div>
              </div>
            </div>

            <div class="field" v-if="!card.isKit()">
              <div class="field-label">
                <label class="label"> Onsite </label>
              </div>
              <div class="field-body">
                <div class="field is-expanded">
                  <div class="control">
                    <mf-date :item="card" :input-props="{kind: 'deliver',
                      pos: 'bottom-left', min: firstDateKey}"
                            :is-edit="true" @set-date="setDate"></mf-date>
                  </div>
                </div>
              </div>
            </div>

            <div class="is-flex" v-if="card.isKit()">
              <div v-for="date of kitDates.col1" :key="date.label" class="column pl-0 pt-0">
                <div class="field">
                  <div class="field-label">
                    <label class="label">{{ date.label }}</label>
                  </div>
                  <div class="field-body">
                    <mf-date :item="card" :input-props="{
                          kind: date.kind,
                          min: date.min? date.min: '',
                          max: date.max? date.max: '',
                        }"
                      :is-edit = "true" :is-disabled="disableDate">
                    </mf-date>
                  </div>
                </div>
              </div>
            </div>

            <div class="is-flex" v-if="card.isKit()">
              <div v-for="date of kitDates.col2" :key="date.label" class="column pl-0 pt-0">
                <div class="field">
                  <div class="field-label">
                    <label class="label">{{ date.label }}</label>
                  </div>
                  <div class="field-body">
                    <mf-date :item="card" :input-props="{
                          kind: date.kind,
                          min: date.min? date.min: '',
                          max: date.max? date.max: '',
                        }"
                      :is-edit = "true" :is-disabled="disableDate">
                    </mf-date>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="!['planning', 'coordination'].includes(stage)" class="field">
              <div class="field-label">
                <label class="label"> Location </label>
              </div>
              <div class="field-body">
                <div class="field is-expanded">
                  <div class="control">
                    <mf-multi-select v-model="selectedLoc"
                            track-by="_id" label="name"
                            :preselect-first="true"
                            openDirection="below"
                            :disabled="allLocations.length === 0"
                            group-values="val" group-label="key"
                            :placeholder="getPlaceHolder"
                            :sort="false"
                            :options="allLocations"
                            :searchable="true"
                            :allow-empty="false">
                    </mf-multi-select>
                  </div>
                </div>
              </div>
            </div>
            <div class="column">
              <div class="columns">
                <div class="column is-paddingless mt-4">
                  <mf-file :order="card" :createOrderModal="fromModal" @delete-file="deleteFiles"
                    :hideFormPrint="true"/>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>

      <!-- Modal Footer -->
      <footer class="modal-card-foot is-justify-content-flex-end is-align-items-center"
        v-if="!loadTemplateOrders">
          <div class="column is-narrow ">
            <div v-if="!['ordering', 'sourcing'].includes(stage)">
              <button class="button is-outlined" @click="$emit('close')">
                Cancel
              </button>
               <button
                class="button has-background-black-bis" @click="save(false)"
                        :disabled="disableSave">
                  Create {{labelType}}
              </button>
              <button class="button has-background-black-bis" @click="save(true)"
                        :disabled="disableSave">
                  Create / Edit
              </button>
            </div>
            <div v-else>
              <button class="button is-outlined" @click="$emit('close')">
                Cancel
              </button>
              <button class="button  has-background-black-bis" @click="save(true, false)"
                      :disabled="disableSave" >
                Edit
              </button>
            </div>
          </div>
      </footer>
      <o-loading
        :full-page="true"
        :active="isLoading"
        :can-cancel="true"
      ></o-loading>
    </div>
  </o-modal>
</template>
<script>
import {
  reactive, toRefs, computed, onMounted,
} from 'vue';
import { BaseOrder } from '@/models/BaseOrder';
import MfMultiSelect from '@/components/abstract/MfMultiSelect.vue';
import MfDate from '@/components/abstract/MfDate.vue';
import _ from 'lodash';
import { useStore } from 'vuex';
import Company from '@/models/Companies';
import Projects from '@/models/Projects';
import MaterialTemplates from '@/models/MaterialTemplates';
import ProductionTemplates from '@/models/ProductionTemplates';
import Locations from '@/models/Locations';
import TemplateMixin from '@/components/mixins/TemplateMixin';
import ProductionManager from '@/models/ProductionManager';
import StageField from '@/components/fields/StageField.vue';
import MfFileList from '@/components/card-edit/CardFileList.vue';
import TemplateList from '@/components/modals/SelectTemplates.vue';
import ProjectSelect from '@/components/fields/ProjectSelect.vue';
import uuid from 'uuid/v4';
import { useToast } from 'vue-toastification';
import UtilityMixin from './mixins/UtilityMixin';

export default {
  name: 'CreateOrder',
	emits: ['close'],
  props: ['stage', 'permissionModule', 'requiredCheck', 'isActive', 'prefabPurpose'],
  components: {
    'mf-multi-select': MfMultiSelect,
    'mf-date': MfDate,
    StageField,
    'mf-file': MfFileList,
    TemplateList,
    ProjectSelect,
  },
  setup(props, { emit }) {
    const store = useStore();
    const toast = useToast();
    const { setOwner } = TemplateMixin();
    const { getAllLocations } = UtilityMixin();
    const state = reactive({
      orderName: {
        name: '',
      },
      selectedOrder: null,
      card: new BaseOrder({
        name: '',
        _customStage: props.stage,
        stage: ['preparation', 'sourcing', 'ordering'].includes(props.stage) ? props.stage : 'planning',
        __t: ['preparation', 'sourcing', 'ordering'].includes(props.stage) ? 'Materials' : 'Prefabs',
        project: {},
        owner: { user: null },
        location: null,
        isTrackingEnabled: false,
        defaultRun: null,
        purpose: props.prefabPurpose === 'kit' ? 'kit' : 'general',
      }),
      allUsers: [],
      allProjects: [],
      allLocations: [],
      fromModal: true,
      isLoading: false,
      companyRuns: [],
      // user: {},
      users: [],
      templateProject: false,
      defaultTemplate: {},
      userChanged: {},
      canAddItems: true,
      loadTemplateOrders: false,
      kitDates: {
        col1: [
          {
            label: 'Coordinate',
            kind: 'coord',
            max: ['poDetailBy', 'partsManufactureBy', 'poManufactureBy', 'poQaBy', 'deliver'],
          }, {
            label: 'Detail',
            kind: 'poDetailBy',
            min: 'coord',
            max: ['partsManufactureBy', 'poManufactureBy', 'poQaBy', 'deliver'],
          }, {
            label: 'Assembly Manuf',
            kind: 'partsManufactureBy',
            min: ['poDetailBy', 'coord'],
            max: ['poManufactureBy', 'poQaBy', 'deliver'],
          },
        ],
        col2: [
          {
            label: 'Manufacture',
            kind: 'poManufactureBy',
            min: ['partsManufactureBy', 'poDetailBy', 'coord'],
            max: ['poQaBy', 'deliver'],
          }, {
            label: 'QA/QC',
            kind: 'poQaBy',
            min: ['poManufactureBy', 'partsManufactureBy', 'poDetailBy', 'coord'],
            max: 'deliver',
          }, {
            label: 'Onsite',
            kind: 'deliver',
            min: ['poQaBy', 'poManufactureBy', 'partsManufactureBy', 'poDetailBy', 'coord'],
          },
        ],
      },
      setDates: {},
      showProjects: true,
    });
    const labelType = computed(() => {
      if (props.stage === 'planning' && props.prefabPurpose === 'prefab') return 'Package';
      if (props.stage === 'planning' && props.prefabPurpose === 'kit') return 'Kit';
      return 'Order';
    });

    const firstDateKey = computed(() => {
      if (props.stage === 'planning') return 'coord';
      if (props.stage === 'detailing') return 'poDetailBy';
      if (props.stage === 'manufacturing') return 'poManufactureBy';
      if (props.stage === 'coordination') return 'coord';
      if (['sourcing', 'ordering'].includes(props.stage)) return 'orderBy';
      return null;
    });
    const dateLabel = computed(() => {
      if (firstDateKey.value === 'coord') return 'coordinate';
      if (firstDateKey.value === 'poDetailBy') return 'detailBy';
      if (firstDateKey.value === 'poManufactureBy') return 'manufactureBy';
      return firstDateKey.value;
    });
    const disableDate = computed(() => {
      if (state.selectedOrder) {
        return _.isNumber(state.selectedOrder.leadDates[['sourcing', 'ordering'].includes(props.stage) ? 'fab' : props.stage]);
      }
      return false;
    });
    const getPlaceHolder = computed(() => (store.state.activeScreen === 'mm-preparation' ? 'Locations' : 'Locations (Required)'));
    const selectedLoc = computed({
      get: () => {
        if (state.card.isMM()) return state.card.baseDelivery.location;
        return state.card.location;
      },
      set: (val) => {
        if (state.card.isMM()) state.card.baseDelivery.location = val;
        else state.card.location = val;
      },
    });
    const setDateTo = computed({
      get: () => _.get(state.setDates, `${_.camelCase(dateLabel.value)}`, null),
      set: (val) => {
        if (val) {
          if (_.get(state.selectedOrder, '__t', '') === 'ProdTemplates'
            && !Object.values(state.selectedOrder.leadDates).includes(null)) {
            state.card.fillTemplateDates(state.selectedOrder);
          }
        }
      },
    });
    const setDate = (val) => {
      setDateTo.value = _.get(val, 'val', null);
    };
    const activeScreeFilters = computed(() => {
      // eslint-disable-next-line
      const activeScreen = store.state.activeScreen;
      return store.getters.getActiveScreenFilters(activeScreen);
    });
    const cardOwner = computed({
      get: () => state.card.owner.user,
      set: (userVal) => {
        _.set(state.card, 'owner.user', { _id: userVal._id, name: userVal.name });
        _.set(state.card, 'owner.company', { _id: userVal.company, name: userVal.companyName });
      },
    });
    const fillTemplateData = async (selectedOrder, defaultTemplate = false) => {
      // fill the order with basic template data before creating a prefab/preparation order
      state.canAddItems = true;
      const warningMsg = await state.card.canAddTemplateItems(selectedOrder);
      if (warningMsg) {
        // this.$warningDialog(warningMsg);
        state.canAddItems = false;
      }
      state.card.financialId = '';
      state.selectedOrder = _.cloneDeep(selectedOrder);
      state.card = state.card.fillBasicTemplateData(selectedOrder);
      if (!defaultTemplate) {
        const result = await setOwner(state.card.project, state.card, state.selectedOrder,
          [], state.allUsers, state.userChanged, false, [], props.stage);
        state.card = result.card;
        state.users = result.users;
      }
      /* If date othar than 'Onsite' is selected in modal and then template order
      with lead dates is selected than that date must be removed from card */
      if (disableDate.value) {
        state.card.dates = _.filter(state.card.dates, (date) => date.kind !== firstDateKey.value);
        delete state.card.simpleDates[firstDateKey.value];
      }
      if (!disableDate.value) {
        setDateTo.value = '';
        state.card.simpleDates.deliver.value = '';
      }
      state.card.files = [];
      state.card.simpleFiles = [];
      state.card.addOrUpdateDate(firstDateKey.value, '');
      // map todos files so that the formData is fetched from the todos while
      // adding form to new card using template to display form data on user trying to open
      const mappedTodosFiles = _.flatten(_.map(state.selectedOrder.simpleTodos, 'files'));
      // while selecting template prefill files to the card...
      for (const file of state.selectedOrder.simpleFiles) {
        const newFile = state.card.newFile();
        newFile.uuid = uuid();
        /* On deleting template file in modal, if _id is not
           picked there is no reference in backed to check
           which template must not be added to order */
        _.assign(newFile, _.pick(file, ['url', 'name', 'visible', 'type', '_id', 'copiedFrom']));
        if (file.type === 'form') {
          const resFile = _.find(mappedTodosFiles, { copiedFrom: file.copiedFrom });
          if (!_.isEmpty(resFile)) {
            newFile.formData = _.cloneDeep(resFile.formData);
            newFile.isFromTemplate = true;
          }
        }
      }
      /* this property is to push all ids of template files,
      that are deleted in the modal */
      state.card.archivedFiles = [];
      state.card.isTrackingEnabled = true;
      state.orderName.name = selectedOrder.name;
      state.orderName.preName = '';
      state.orderName.postName = '';
      state.loadTemplateOrders = false;
    };
    const projectSelected = _.debounce(async (project) => {
      const { userData } = store.state;
      const projectSetting = _.find(
        project.projectSettings,
        { companyId: userData.company },
      );
      if (!_.isEmpty(state.card.baseDelivery.location)) state.card.baseDelivery.location = null;
      const pls = await getAllLocations(project._id, true);
      if (!_.map(pls, '_id').includes(_.get(state.card, 'location._id', ''))) {
        state.card.location = null;
      }
      const result = await setOwner(state.card.project, state.card,
        state.selectedOrder, [], state.allUsers, state.userChanged, false, pls, props.stage);
      state.card = result.card;
      state.users = result.users;
      if (projectSetting && projectSetting.isPTEnabled) { state.card.isTrackingEnabled = true; }
      [state.card.defaultRun] = state.companyRuns;
      state.allUsers = _.filter(_.cloneDeep(state.users), (usr)=> usr.company.toString() === store.state.userData.company.toString());
      state.allLocations = pls;
      state.allLocations = _.filter(state.allLocations, (loc) =>
        loc.company._id.toString() === store.state.userData.company.toString()
      )
      state.allLocations = Locations.groupLocations(
        state.allLocations,
        store.state.companyData,
      );
      if (!_.isEmpty(state.selectedOrder)) {
        const msg = await state.card.canAddTemplateItems(state.selectedOrder);
        if (msg) {
          // this.$warningDialog(msg);
          state.canAddItems = false;
        }
      }
      if (_.isEmpty(state.selectedOrder) && !_.isEmpty(state.defaultTemplate) && props.stage !== 'planning') {
        fillTemplateData(state.defaultTemplate, true);
      }
    }, 10);

    const checkItemParRun = (() => {
      const totalItems = state.selectedOrder.items.length;
      const itemLimit = 20;
      let itemParRun = 21;
      if (['detailing', 'manufacturing'].includes(props.stage)) {
        if (!_.isEmpty(state.selectedOrder.manager.runs)) {
          const runsNumber = state.selectedOrder.manager.runs.length;
          itemParRun = totalItems / runsNumber;
          if (itemParRun > itemLimit) {
            return 'Please add more runs to template order';
          }
        } else if (totalItems > itemLimit) {
          return 'Please add runs to template';
        }
      }
      return false;
    });
    const loadOrders = (() => {
      if (!state.templateProject) return;
      state.loadTemplateOrders = true;
    });
    const save = (async (toOpen) => {
      state.isLoading = true;
      const { card } = state;
      // below code will remove form template array where we used to store the formdata
      // while creating order from template
      card.simpleFiles.forEach((f) => {
        if (f.isFromTemplate) {
          f.formData = [];
        }
      });
      _.assign(card, state.orderName);
      try {
        if (state.orderName.name.trim().length < 3) {
          throw new Error('Name Validation failed');
        }
        card.name = state.orderName.name;
        let newCard = {};
        if (!_.isEmpty(card.templateOrder)) {
          // create preparation order by template
          if (['preparation', 'sourcing', 'ordering'].includes(props.stage)) {
            newCard = new BaseOrder(Object.assign(card.toJSON(), {
              _id: '',
              __t: 'Materials',
              stage: props.stage,
            }));
            if (['sourcing', 'ordering'].includes(props.stage)) newCard.notFromRequisition = true;
            const deliverDate = newCard.newDate('deliver');
            deliverDate.uuid = uuid();
            deliverDate.value = card.simpleDates.deliver.value;
            if (state.selectedOrder.leadTime && deliverDate) {
              const newDate = newCard.newDate('orderBy');
              newDate.uuid = uuid();
              newDate.value = newCard.getDateDiff(deliverDate.value, state.selectedOrder.leadTime);
            } else if (deliverDate) {
              const newDate = newCard.newDate('orderBy');
              newDate.uuid = uuid();
              newDate.value = deliverDate.value;
            }
            newCard.owner.company = {
              _id: card.owner.user.company,
              name: card.owner.user.companyName,
            };
            newCard.activity = state.selectedOrder.activity;
            newCard.currentNote = state.selectedOrder.currentNote;
            newCard.baseDelivery.supplier = state.selectedOrder.baseDelivery.supplier;
            newCard.baseDelivery.vendor = state.selectedOrder.baseDelivery.vendor;
            if (state.selectedOrder.items && state.canAddItems) {
              _.forEach(state.selectedOrder.items, (item) => {
                const matItem = {};
                _.assign(matItem, _.pick(item, ['name', 'catId', 'customId', 'quantity', 'measure',
                  'measureUnits', 'activityId', 'qa', 'costCode', 'purchase']));
                matItem.leadTime = state.selectedOrder.leadTime || 0;
                matItem.stage = props.stage;
                const itemIdx = newCard.addItem(matItem);
                const newItem = newCard.items[itemIdx];
                for (const file of item.simpleFiles) {
                  const newFile = newItem.newFile();
                  _.assign(newFile, _.pick(file, ['url', 'name', 'visible', 'type', 'copiedFrom']));
                }
                newCard.addTemplateFormTodo(newItem, item, state.selectedOrder);
                if (!_.isEmpty(item.simpleMemos)) {
                  const memo = newItem.newFTD('memos');
                  memo.text = item.simpleMemos[0].text;
                }
              });
            }
            newCard.templateOrder = state.selectedOrder._id;
            newCard.addTemplateTodos(state.selectedOrder.todos, store.state.userData);
            const orderLevelTodos = _.filter(state.selectedOrder.todos, (todo) => todo.sources.length === 1 && _.some(todo.files, { type: 'form' }));
            newCard.createTodos(orderLevelTodos, newCard.owner, newCard);
            if (['sourcing', 'ordering'].includes(newCard.stage) && !_.isEmpty(newCard.baseDelivery.vendor)) {
              newCard.calcItemCostAndDate([], newCard.baseDelivery.vendor);
            }
            if (newCard.stage === 'preparation') newCard = await newCard.save();
          } else {
            // create Prefab by template
            const errorMssg = checkItemParRun();
            if (errorMssg) {
              // Add Dialog Alert
              state.isLoading = false;
              // eslint-disable-next-line
              return
            }
            card.stage = props.stage;
            newCard = await ProductionManager.createOrderFromTemplate(card);
          }
        } else {
          // create Preparation order
          // eslint-disable-next-line
          if (['preparation', 'sourcing', 'ordering'].includes(props.stage)) {
            newCard = new BaseOrder(Object.assign(card.toJSON(), {
              _id: '',
              __t: 'Materials',
              stage: props.stage,
            }));
            newCard.owner.company = {
              _id: card.owner.company._id,
              name: card.owner.company.name,
            };
            newCard.dates = [];
            newCard.simpleDates = {};
            if (_.isEmpty(card.simpleDates.orderBy) || _.isEmpty(card.simpleDates.orderBy.value)) {
              const newDate = newCard.newDate('orderBy');
              newDate.uuid = uuid();
              newDate.value = card.simpleDates.deliver.value;
            }
            const newDeliverDate = newCard.newDate('deliver');
            newDeliverDate.uuid = uuid();
            newDeliverDate.value = card.simpleDates.deliver.value;

            if (newCard.stage !== 'preparation') {
              newCard.notFromRequisition = true;
              if (_.isEmpty(card.simpleDates.orderBy)
                  || _.isEmpty(card.simpleDates.orderBy.value)) {
                const newOrderDate = newCard.newDate('orderBy');
                newOrderDate.uuid = uuid();
                newOrderDate.value = card.simpleDates.deliver.value;
              }
            } else newCard = await newCard.save();
          } else if (props.stage === 'planning') {
            if (card.isKit() && _.isEmpty(card.items)) {
              card.addItem({
                name: 'Default Kit Item',
                purpose: 'kit',
              });
            }
            newCard = await card.save();
          } else {
            // create prefab
            const basicCard = _.cloneDeep(card);
            basicCard.stage = props.stage;
            newCard = await ProductionManager.createOrderFromTemplate(basicCard);
          }
        }
        if (newCard.stage === 'ordering') {
          emit('on-save', newCard, toOpen, false);
        } else emit('on-save', newCard, toOpen);
        state.isLoading = false;
      } catch (e) {
        let msg = e?.data?.msg;
        if (e?.message === 'Kit ID Validation failed') {
          msg = 'Kit ID must contain at least 3 characters';
        }
        if (msg) toast.error(msg);
        state.isLoading = false;
      }
    });
    const getTemplateName = computed(() => {
      if (!_.isEmpty(state.selectedOrder)) {
        return state.selectedOrder.name;
      }
      return 'No template selected';
    });
    const disableSave = computed(() => {
      // Basic set of validations.
      if (!_.get(state.card, 'project._id')
        || _.get(state.orderName, 'name', []).length < 3
        || !_.get(state.card, 'owner.user._id')
        || (!['coordination', 'preparation', 'sourcing', 'ordering', 'planning'].includes(props.stage) && _.isEmpty(_.get(state.card, 'location')))
        || (['sourcing', 'ordering'].includes(props.stage) && _.isEmpty(_.get(state.card.baseDelivery, 'location')))
        || (!['planning'].includes(props.stage) && !_.get(state.card, 'simpleDates.deliver.value'))) {
        return true;
      }
      if (state.card.isKit()) {
        if (_.isEmpty(state.card.customId)) return true;
        const datesOrder = ['coord', 'poDetailBy', 'partsManufactureBy', 'poManufactureBy', 'deliver'];
        return !state.card.checkDateValidation('orderSave', datesOrder).isValid;
      }
      const dateMap = {
        coordination: 'coord',
        detailing: 'poDetailBy',
        manufacturing: 'poManufactureBy',
      };
      // if not template check for first date to be present.
      if (!state.selectedOrder) {
        return (dateMap[props.stage] && !_.get(state.card, `simpleDates.${dateMap[props.stage]}.value`));
      }
      // validations to check if template is choosen.
      if (!_.isEmpty(state.selectedOrder.name)
          && ['detailing', 'manufacturing'].includes(props.stage)
        && _.isEmpty(state.selectedOrder.manager.runs)
        && _.isEmpty(state.companyRuns)) return true;
      if (!['preparation', 'sourcing', 'ordering'].includes(props.stage) && _.isNumber(state.selectedOrder.leadDates[props.stage])) {
        return false;
      }
      return dateMap[props.stage] && _.isEmpty(_.get(state.card, `simpleDates.${dateMap[props.stage]}.value`));
    });
    const deleteFiles = (file) => {
      const fileIndex = _.findIndex(state.card.simpleFiles, { uuid: file.uuid });
      if (file._id && _.get(state.card, 'archivedFiles', false)) {
        state.card.archivedFiles.push(file._id);
      }
      state.card.simpleFiles.splice(fileIndex, 1);
      state.card.files.splice(fileIndex, 1);
    };

    const getTitle = computed(() => {
      if (state.loadTemplateOrders) {
        return !['preparation', 'source', 'ordering'].includes(props.stage)
          ? 'Select Production Order Template'
          : 'Select Material Order Template';
      }
      if (props.stage === 'preparation') return 'Create Material Request';
      if (props.stage === 'sourcing') return 'Create Sourcing Order';
      if (props.stage === 'ordering') return 'Create Purchase Order';
      if (props.stage === 'planning') {
        return props.prefabPurpose === 'prefab' ? 'Create Prefab Package' : 'Create Kit';
      }
      return 'Create Production Order';
    });

    const orderDetailsTitle = computed(() => {
      if (props.stage === 'planning') {
        return props.prefabPurpose === 'prefab' ? 'Prefab Package Details' : 'Kit Details';
      }
      return 'Order Details *';
    });

    const deliveryDetailsTitle = computed(() => {
      let title;
      if (props.stage === 'planning' && props.prefabPurpose === 'prefab') {
        title = 'Production Details';
      } else if ((props.stage === 'planning' && props.prefabPurpose === 'kit') || props.stage === 'coordination') {
        title = 'Production Details *';
      } else {
        title = 'Delivery Details *';
      }
      return title;
    });

    onMounted(async () => {
      state.isLoading = true;
      state.allProjects = store.getters.selectedValuesForKey('filteredProjects');
      state.allProjects = store.getters
        .getProjectsOnPermission(props.permissionModule, state.allProjects);
      if (props.requiredCheck && state.allProjects.length) {
        props.requiredCheck.forEach((rc) => {
          if (state.allProjects.length) {
            state.allProjects = store.getters
              .getProjectsOnPermission(rc.module, state.allProjects, rc.key);
          }
        });
      }
      if (_.isEmpty(state.allProjects)) {
        state.showProjects = false;
      }
      state.allProjects = _.filter(state.allProjects, (project) =>{
        return project.createdCompany._id === store.state.userData.company;
      });
      await store.getters.userPromise;
      state.companyRuns = await Company.getCompanyRuns('companyRuns');
      _.set(state.card, 'owner.user', { _id: store.state.userData._id, name: store.state.userData.name });
      _.set(state.card, 'owner.company', { _id: store.state.userData.company, name: store.state.userData.companyName });
      state.templateProject = (await Projects.haveTemplateProject()).templateProject;
      if (['preparation', 'sourcing', 'ordering'].includes(props.stage)) {
        state.card.stage = props.stage;
        state.card.__t = 'Materials';
        if (!_.isEmpty(state.templateProject)) {
          state.defaultTemplate = await MaterialTemplates.getDefaultTemplate({
            projectId: state.templateProject._id,
          });
        }
      } else if (!_.isEmpty(state.templateProject)) {
        state.defaultTemplate = await ProductionTemplates.getDefaultTemplate({
          projectId: state.templateProject._id,
          purpose: 'general',
        });
      }
      const multiProjId = state.allProjects[0] ? state.allProjects[0]._id : '';
      const proId = activeScreeFilters.value.includes('singleProject')
        ? store.state.queryParams.project._id : multiProjId;
      const projectFound = _.find(state.allProjects, { _id: proId });
      if (activeScreeFilters.value.includes('singleProject') && !_.isUndefined(projectFound)) {
        state.card.project = store.state.queryParams.project;
        await projectSelected(store.state.queryParams.project);
      } else if (!_.isUndefined(projectFound)) {
        [state.card.project] = state.allProjects;
        await projectSelected(state.allProjects[0]);
      }
      state.isLoading = false;
      if (_.isEmpty(state.selectedOrder) && !_.isEmpty(state.defaultTemplate) && props.stage !== 'planning') {
        fillTemplateData(state.defaultTemplate, true);
      }
    });

    return {
      onMounted,
      ...toRefs(state),
      firstDateKey,
      dateLabel,
      disableDate,
      getPlaceHolder,
      selectedLoc,
      fillTemplateData,
      save,
      checkItemParRun,
      projectSelected,
      setOwner,
      disableSave,
      getTitle,
      loadOrders,
      getTemplateName,
      orderDetailsTitle,
      deliveryDetailsTitle,
      labelType,
      setDate,
      setDateTo,
      cardOwner,
      deleteFiles,
    };
  },
};
</script>
<style scoped>
.mt-4 {
  margin-top: 16px !important;
}
</style>
