<template>
  <o-modal :active="trackModal" class="modal-md" :canCancel='false'>
    <div class="modal-card">
      <!-- Modal Header -->
      <header class="modal-card-head">
        <h4 class="modal-card-title" >
          Productivity Overlay ( IN PROGRESS )
        </h4>
        <div class="is-divider-vertical"></div>
        <i class="icon-close is-clickable" @click="$emit('close')"></i>
      </header>

      <!-- Modal Body -->

      <section class="modal-card-body has-background-white pb-0">
        <div class="column">
          <div class="columns">
            <div class="column is-narrow">
                          <label class="is-pulled-left line-height">Work Steps</label>

            </div>
            <div class="column is-4">
              <mf-multi-select
                :options="card.manager.runs"
                :multiple="false"
                :closeOnSelect="true"
                label='name'
                trackBy='_id'
                v-model="selectedRun"
                :allow-empty="false"
                :preselect-first="true"
                openDirection="below"
                @update:modelValue="onInput($event)"
              >
              </mf-multi-select>

            </div>

          </div>
        </div>
        <div class="table-container productivity-modal">
          <mf-table
            :tableProps="tableProps"
            :apiMode="false"
            :loadData="loadData"
            :hideGutter="true"
            ref="runTable"
            :key="refreshKey"
          >
          <template v-slot:clock-hrs="{ rowData }">
            <input type="number" v-model="rowData.clockHrs" class="input timer-width-hour"
                  step="1" :disabled="disableClockFields(rowData, false, selectedRun)"
                  @input="setTime(rowData, 'clockHrs');
                    calculateTotalHrs(rowData.id, rowData, 'clockHrs')"/>
            :
            <input type="number" v-model="rowData.clockMins" class="input timer-width-hour"
                  step="1" :disabled="disableClockFields(rowData, false, selectedRun)"
                  @input="setTime(rowData, 'clockMins');
                    calculateTotalHrs(rowData.id, rowData, 'clockMins')"/>
          </template>
          <template v-slot:clock="{ rowData }">
            <span v-if="!stopWatchOn(rowData, selectedRun)" class="is-flex is-justify-content-center"
                  :class="{'cursor-disabled': disableClockFields(rowData, true, selectedRun)}">
              <i @click="startStopWatch(rowData, true)"
                :class="{'no-pointer': disableClockFields(rowData, true, selectedRun),
                  'pointer': !rowData.fieldsDirty}"
                class="icon-timer has-text-info">
              </i>
            </span>
            <span @click="startStopWatch(rowData, false)" class="is-flex is-justify-content-center"
                    v-if="stopWatchOn(rowData, selectedRun)">
              <i class="icon-timerstop has-text-black-bis"></i>
            </span>
          </template>
          <template v-slot:itemDone="{ rowData }">
              <input class="mr-3"
                    :disabled="rowData._item.markCompleteDisabled || doneDisabled"
                    type="checkbox"
                    v-model="rowData.riCompleted"
                    @change="rowChanged(rowData.id, rowData, 'riComplete')"/>
              <input class="mr-2"
                :disabled="rowData._item.markCompleteDisabled || doneDisabled"
                type="number" min="0" step="5" max="100"
                v-model="rowData.percComplete"
                @input="rowChanged(rowData.id, rowData, 'percComplete')"/>
              <label>%</label>
          </template>
          <template v-slot:workers="{ rowData }">
            <input type="number" class="input timer-width-hour"
              @input="calculateTotalHrs(rowData.id, rowData, 'workers')"
              :disabled="disableClockFields(rowData, false, selectedRun)"
              v-model="rowData.workers" step="1" min="0"/>
          </template>
          <template v-slot:action-field="{ rowData }">
            <button class="button"
              :disabled="false"
              @click="onRemove(rowData)">
                <i class="icon-removedelete has-text-blue"></i>
            </button>
          </template>
          </mf-table>
        </div>
      </section>

      <!-- Modal Footer -->
      <footer class="modal-card-foot is-justify-content-flex-end">
        <div class="column is-narrow">
          <button class="button is-outlined" @click="$emit('close')">
            Cancel
          </button>
          <button
            class="button has-background-black-bis"
            @click="saveItems()"
          >
            Done
          </button>
        </div>
      </footer>
    </div>
  </o-modal>
</template>

<script>
import {
  defineComponent, reactive, computed, onBeforeMount, toRefs, ref,
} from 'vue';
import _ from 'lodash';
import tableDefinition from '@/components/table-cols/runItemCols';
import MfTable from '@/components/table-fields/MfTable.vue';
import ProductionManager from '@/models/ProductionManager';
import moment from 'moment';
import { DialogProgrammatic } from '@/components/Dialog';
import { useToast } from 'vue-toastification';

export default defineComponent({
  name: 'TrackProductivity',
  props: {
    trackModal: Boolean,
    card: {
      type: Object,
      default: () => {},
    },
    currentRun: {
      type: Object,
      default: () => {},
    },
    runIndex: Number,
  },
  components: {
    MfTable,
  },
  setup(props, { emit }) {
    const { card, currentRun, runIndex } = props;
    const tableProps = tableDefinition;
    const runTable = ref(null);
    const toast = useToast();
    const state = reactive({
      data: [],
      setHrs: 500,
      runOptions: [],
      run: {},
      runsData: {},
      refreshKey: 0,
    });
    const getHoursMinutes = ((clockHrs) => {
      let hours = 0;
      let minutes = 0;
      if (clockHrs < 60) {
        minutes = clockHrs;
        hours = 0;
      } else {
        hours = parseInt((clockHrs / 60), 10);
        minutes = clockHrs - (hours * 60);
      }
      return { hours, minutes };
    });
    const setRunItemData = ((val) => {
      const item = _.find(card.items, { _id: val._id });
      if (_.isEmpty(item)) return null;
      const rowData = _.find(state.data, { _id: val._id });
      let clockHrs = _.get(
        card.manager,
        `pt.${currentRun.uuid}.${item._id}`, {},
      );
      if (clockHrs.stopTimer) {
        clockHrs = _.get(rowData, 'timerStopped', false)
          ? moment(clockHrs.stopTimer).diff(clockHrs.startTimer, 'minutes')
          : 0;
      }
      const { hours, minutes } = getHoursMinutes(clockHrs);
      val.percComplete = val.riCompleted ? 100 : val.percComplete;
      const newVal = _.cloneDeep(val);
      _.assign(newVal, {
        name: item.name,
        customId: item.customId,
        id: item._id,
        clockHrs: hours,
        clockMins: minutes,
        fieldsDirty: false,
        workers: 1,
        isEditing: true,
      });
      return newVal;
    });
    state.run = currentRun;
    for (const run of card.manager.runs) {
      const data = [];
      for (const item of run.runItemsCount) {
        const newVal = setRunItemData(item);
        if (newVal) data.push(newVal);
      }
      state.runsData[run._id] = data;
    }
    const loadData = (() => {
      const runSelected = !_.isEmpty(selectedRun.value) ? selectedRun.value : currentRun;
      return state.runsData[runSelected._id] || [];
    });

    const onInput = ((run) => {
      refreshTable();
    });
    const checkMaxHrs = ((rowDt) => {
      if (parseFloat(rowDt.actualHrs) <= 0) {
        rowDt.actualHrs = 0;
      }
      if (parseFloat(rowDt.actualHrs > this.setHrs)) {
        toast.error(`<strong>Hours cannot be greater than${state.setHrs}</strong>`);
        rowDt.actualHrs = state.setHrs;
      }
    });
    const rowChanged = ((id, rowDt, key) => {
      rowDt.fieldsDirty = true;
      if (key && (!rowDt[key] || rowDt[key] < 0)) {
        rowDt[key] = 0;
      }
      if (key === 'percComplete') {
        rowDt.percComplete = rowDt.percComplete >= 100 ? 100 : rowDt.percComplete;
        rowDt.riCompleted = rowDt.percComplete >= 100;
      } else if (key === 'riComplete') {
        rowDt.percComplete = rowDt.riCompleted ? 100 : 95;
      }
      if (rowDt.riCompleted
        && (Number.isNaN(parseFloat(rowDt.actualHrs)) || rowDt.actualHrs === '')) {
        rowDt.actualHrs = 0;
      }
      checkMaxHrs(rowDt);
    });
    const calculateTotalHrs = ((id, rowData) => {
      rowData.fieldsDirty = true;
      rowData.workers = parseInt(rowData.workers, 10);
    });
    const setTime = ((rowData, key) => {
      if (rowData[key] < 0) rowData[key] = 0;
      if ((key === 'clockMins') && (rowData[key] > 59)) {
        rowData[key] = 0;
        rowData.clockHrs = parseInt(rowData.clockHrs, 10);
        ++rowData.clockHrs;
      }
    });
    const stopWatchOn = ((rowData, run) => {
      const managerPT = _.get(
        card.manager,
        `pt.${run.uuid}.${rowData._id}`, {},
      );
      return _.get(managerPT, 'startTimer', false)
        && !_.get(managerPT, 'stopTimer', false);
    });

    const disableClockFields = ((rowData, clock = false, run) => {
      if (clock) {
        return rowData.fieldsDirty || rowData.riCompleted
        || rowData._item.markCompleteDisabled;
      }
      return stopWatchOn(rowData, run) || rowData._item.markCompleteDisabled;
    });
    const startStopWatch = (async (row, started) => {
      let res = {};
      const params = {
        orderId: card._id,
        runUuid: currentRun.uuid,
        itemId: row._id,
      };
      if (started) {
        res = await ProductionManager.startTimer(params);
        _.set(card.manager, 'pt', res.pt);
      } else {
        res = await ProductionManager.stopTimer(params);
        card.manager.pt = res.pt;
        const managerPT = _.get(
          card.manager,
          `pt.${currentRun.uuid}.${row._id}`, {},
        );
        const startTimer = _.get(managerPT, 'startTimer', 0);
        const stopTimer = _.get(managerPT, 'stopTimer', 0);
        const clockHrs = (moment(stopTimer).diff(startTimer, 'minutes'));
        const { hours, minutes } = getHoursMinutes(clockHrs);
        row.clockHrs = hours;
        row.clockMins = minutes;
      }
      row.fieldsDirty = !started;
      row.timerStopped = !started;
      return res;
    });
    const doneDisabled = computed(() => card._customStage !== 'manufacturing');

    const validateAndSet = ((val, showToast, run) => {
      const rowData = _.cloneDeep(val);
      let saveRun = true;
      const stopWatch = stopWatchOn(rowData, run);
      if (!stopWatch) {
        const clockTime = ((parseInt(rowData.clockHrs || 0, 10) * 3600)
          + (parseInt(rowData.clockMins || 0, 10) * 60));
        if (!rowData.workers || rowData.workers <= 0) {
          rowData.workers = 1;
        }
        const actualHrs = parseInt(rowData.actualHrs, 10)
          + parseInt((clockTime * rowData.workers), 10);
        rowData.actualHrs = actualHrs;
      }
      if ((parseInt(rowData.actualHrs / 3600, 10)) > parseFloat(state.setHrs)) {
        if (showToast) {
          toast.error(`<strong>Entered hours maximum is ${state.setHrs}</strong>`);
        }
        saveRun = false;
      }
      val.actualHrs = rowData.actualHrs;
      val.percComplete = rowData.percComplete;
      val.riCompleted = rowData.riCompleted;
      if (saveRun) {
        const timer = _.get(
          card.manager,
          `pt.${run.uuid}.${rowData._id}`, {},
        );
        if (timer && timer.startTimer && timer.stopTimer) {
          delete card.manager.pt[run.uuid][rowData._id];
        }
      }
      return saveRun;
    });

    const saveData = (async () => {
      let res = null;
      let updateStatus = false;
      _.forEach(card.manager.runs, (run) => {
        if (run.completed) {
          _.set(card.manager, `pt.${run.uuid}`, {});
        }
        if (run.stats.actualHrs) updateStatus = true;
      });
      if (updateStatus) card.status = 'in-progress';
      res = await card.save();
      card.lastModified.at = res.lastModified.at;
      if (res._id) {
        toast.success('Work data saved');
      }
      emit('save');
    });

    const saveItemStatus = (async () => {
      let saveRun = false;
      let showToast = true;
      for (const [key, value] of Object.entries(state.runsData)) {
        const idx = _.findIndex(card.manager.runs, { _id: key });
        if (idx !== -1) {
          _.forEach(value, (val) => {
            const passedValidation = validateAndSet(val, showToast, card.manager.runs[idx]);
            if (!passedValidation) {
              showToast = false;
            }
            saveRun = saveRun || passedValidation;
          });
          // update run actual hrs
          card.manager.runs[idx].runItemsCount = value;
          const totalHrs = _.sumBy(
            card.manager.runs[idx].runItemsCount,
            (ri) => ri.actualHrs,
          );
          if (_.isNumber(totalHrs) && !_.isNaN(totalHrs)) {
            card.manager.runs[idx].stats.actualHrs = totalHrs;
          }
          if (card.manager.runs[idx].stats.actualHrs > 0) {
            card.manager.runs[idx].isTracked = true;
          } else {
            card.manager.runs[idx].isTracked = false;
          }
          const done = _.sumBy(
            card.manager.runs[idx].runItemsCount,
            (ri) => parseInt(ri.percComplete, 10),
          );
          card.manager.runs[idx].stats.percComplete = (done / card.manager.runs[idx].runItemsCount.length);
          const runComplete = _.every(card.manager.runs[idx].runItemsCount, (item) => item.riCompleted);
          if (runComplete) card.manager.runs[idx].isCompleted = true;
        }
      }
      if (!saveRun) return;
      await saveData();
    });

    const saveItems = (async () => {
      let dataChanged = false;
      for (const [key, value] of Object.entries(state.runsData)) {
        if (_.some(value, (data) => data.fieldsDirty || data.timerStopped)) {
          dataChanged = true;
        }
      }
      if (!dataChanged) {
        emit('close');
        return;
      }
      const confirmParam = {
        title: 'Save Changes',
        message: 'Do you want to save your changes?',
        type: 'is-danger',
        okButton: 'Save',
        cancelButton: 'Discard',
        onConfirm: () => {
          saveItemStatus();
        },
        onCancel: async () => {
          let saveCard = false;
          const boCard = _.cloneDeep(card);
          for (const [key, value] of Object.entries(state.runsData)) {
            const timerStartUndoList = _.filter(value, { timerStopped: true });
            // eslint-disable-next-line no-loop-func
            timerStartUndoList.forEach(async (item) => {
              saveCard = true;
              const idx = _.findIndex(card.manager.runs, { _id: key });
              if (idx !== -1) {
                _.set(
                  boCard.manager,
                  `pt.${boCard.manager.runs[idx].uuid}.${item._id}.stopTimer`, null,
                );
              }
            });
          }
          if (saveCard) {
            const res = await boCard.slimSave();
            card.manager.pt = res.manager.pt;
            card.lastModified.at = res.lastModified.at;
          }
        },
      };
      DialogProgrammatic.confirm(confirmParam);
      emit('close');
    });

    const selectedRun = computed({
      get: () => state.run,
      set: (val) => {
        state.run = val;
      },
    });

    const refreshTable = () => {
      runTable.value.refreshTable();
    };

    const runItemsIndexMap = computed(() => selectedRun.value.order.manager._runItemsIndexMap);
    const riIndex = (itemId) => runItemsIndexMap.value[selectedRun.value.uuid][itemId];
    const onRemove = (op) => {
      op.fieldsDirty = true;
      if (op.$isDisabled || (op._item && op._item.$isDisabled)) {
        toast.error('Only items in this stage can be added/removed');
        return;
      }
      const idx = riIndex(op.uuid);
      if (idx > -1) selectedRun.value.runItemsCount.splice(idx, 1);
      selectedRun.value.order.manager.runItemsIndexMap();
      state.runsData[selectedRun.value._id].splice(idx, 1);
      state.refreshKey++;
      refreshTable();
    };

    return {
      tableProps,
      loadData,
      calculateTotalHrs,
      setTime,
      disableClockFields,
      startStopWatch,
      stopWatchOn,
      doneDisabled,
      saveItemStatus,
      saveItems,
      rowChanged,
      checkMaxHrs,
      ...toRefs(state),
      onInput,
      selectedRun,
      refreshTable,
      runTable,
      setRunItemData,
      onRemove,
    };
  },
});
</script>

<style>
</style>
