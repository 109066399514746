<template>
  <div>
    <o-loading :full-page="false" :active="isLoading" :can-cancel="false"></o-loading>
    <div class="modal-card">
      <header class="modal-card-head">
        <h4 class="modal-card-title">{{ getHeaderText }}</h4>
        <div class="is-divider-vertical"></div>
        <i class="icon-close is-clickable" @click="cancel()"></i>
      </header>
      <section class="modal-card-body is-size-3">
        <div class="field" v-if="['new'].includes(kind)">
          <div class="field-label">
            <label class="label is-size-3"> Add How many items?</label>
          </div>
          <div class="field-body is-flex is-align-items-center">
            <button class="button has-background-black-bis p-0"
              @click="() => { if (newItemCount > 1) newItemCount -= 1;}">
              <i class="icon-removedelete"></i>
            </button>
            <input type="number" class="input"
              v-model.number="newItemCount"
              :disabled="spaceLeft === 0"
              min="1" :max="spaceLeft" @input="setItemCountValue">
            <button class="button has-background-black-bis p-0"
              @click="addNewItem" >
                <i class="icon-addnew"></i>
            </button>
            <span class="is-italic has-text-grey-darker line-height">
              (Max: {{ spaceLeft }} more)
            </span>
          </div>
        </div>
        <div class="field" :class="kind === 'old' ? '' :'m-0'">
          <div class="field-label">
            <label class="label is-size-3">
              {{ getLabelText }}
            </label>
          </div>
          <div class="field-body">
            <o-checkbox
              class="p-0"
              v-model="isChecked"
              :class="isChecked ? '' : 'mb-2'"
              v-if="!['old'].includes(kind)"
            >
              <span class="has-text-black-bis">Use the Default Prefab Package</span>
            </o-checkbox>
            <div :class="kind === 'old' ? 'column p-0 is-half' : ''">
              <mf-multi-select
                v-if="!isChecked || ['old'].includes(kind)"
                v-model="order"
                :options="orderList"
                track-by="_id"
                openDirection="below"
                label="name"
                :allow-empty="false"
                @update:modelValue="(event) => { order = event; refreshTable();}"
              ></mf-multi-select>
            </div>
          </div>
        </div>
        <div class="table-container">
          <search-bar
            class="mt-3"
            v-if="kind === 'old' && !$_.isEmpty(order)"
            :shouldEmit="true"
            @search="getSearchValue"
            placeholder="Search Items"
          >
          </search-bar>
          <mf-table
            v-if="kind === 'old' && !$_.isEmpty(orderList) && !isLoading"
            ref="itemTable"
            :apiMode="false"
            :tableProps="tableProps"
            :loadData="loadData"
            tableName="itemTable"
            @checkbox-toggled="itemSelected"
          >
          </mf-table>
        </div>
      </section>
      <footer class="modal-card-foot is-justify-content-flex-end">
        <button class="button is-outlined"
           @click="cancel()">Cancel</button>
        <button
          class="button has-background-black-bis"
          @click="addItemRows"
          :disabled="addDisabled"
        >{{kind === 'old' ? 'Select' : 'Add Items'}}</button>
      </footer>
    </div>
  </div>
</template>

<script>
import {
  defineComponent, reactive, toRefs, onMounted, computed, ref, onBeforeMount,
} from 'vue';
import SupplyChain from '@/models/SupplyChain';
import _ from 'lodash';
import { useStore } from 'vuex';
import MfMultiSelect from '@/components/abstract/MfMultiSelect.vue';
import MfTable from '@/components/table-fields/MfTable.vue';
import SearchBar from '@/components/SearchBar.vue';
import tableDefinition from '@/components/table-cols/ItemInPrefabModalCols';
import { useToast } from 'vue-toastification';

export default defineComponent({
  name: 'ItemInPrefab',
  components: {
    'mf-multi-select': MfMultiSelect,
    MfTable,
    'search-bar': SearchBar,
  },
  props: {
    card: Object,
    kind: String,
    maxAddable: { type: Number, default: 500 },
    saveExcelItem: { type: Function, default: () => {} },
    importType: String,
    module: { type: String, default: 'Prefabs' },
    // ^ to specify module for orders to be fetched
    forShipping: { type: Boolean, default: false },
    // ^ to denote if component is being used in shipping modal
  },
  setup(props, { emit }) {
    const { card, kind, maxAddable } = props;
    const itemTable = ref(null);
    const store = useStore();
    const toast = useToast();
    const tableProps = tableDefinition;
    const state = reactive({
      newItemCount: 1,
      orderList: [],
      order: {},
      isChecked: true,
      searchedValue: '',
      selectedItems: [],
      isLoading: false,
      defaultPrefabId: '',
      linkedOrderIds: [],
    });
    const cancel = () => {
      emit('close');
    };
    const addDisabled = computed(() => !state.isChecked && _.isEmpty(state.order));
    const itemLimit = computed(() => {
      let key = 'prefab';
      if (card.isPM()) key = 'pm';
      if (card.isPO()) key = 'po';
      return store.state.itemLimits[key];
    });
    const spaceInOrder = computed(() => {
      if (_.isEmpty(state.order) || kind === 'old') return itemLimit.value;
      return itemLimit.value - card.items.length;
    });
    const getHeaderText = computed(() => {
      if (kind === 'old') return 'Select Items From Existing Prefab Packages';
      return 'Add Items';
    });
    const getLabelText = computed(() => {
      if (kind === 'old') return 'Existing Prefab Package';
      return ` Select ${module === 'Sourcing' ? 'Material' : 'Prefab'} to add new items to `;
    });
    const filteredCardItems = computed(() => _.filter(card.items, (i) => !_.get(i, 'archived.value', true)));
    const spaceLeft = computed(() => (kind === 'old' ? maxAddable : Math.min(maxAddable, spaceInOrder.value)));
    const isSourceShipment = computed(() => card.delivery
     && _.isEmpty(card.delivery));
    const sourceAccessor = computed(() => (props.forShipping ? 'order' : 'prefab'));
    const addNewItem = () => { if (spaceLeft.value > state.newItemCount) { state.newItemCount += 1; } };
    const setItemCountValue = () => { if (state.newItemCount > spaceLeft.value) state.newItemCount = spaceLeft.value; };
    const addItemRows = async () => {
      const itemNames = _.map(card.items, 'catId');
      const selectedItemNames = _.map(state.selectedItems, 'catId');
      for (const item of itemNames) {
        if (!_.isEmpty(item) && selectedItemNames.includes(item)) {
          toast.error(`Item with ${item} catalog id's already exists.`);
          return;
        }
      }
      if (card.items.length >= itemLimit.value) {
        return toast.error('Can not add more than 50 items to a Production Manager');
      }
      const stage = props.module === 'Sourcing' ? 'ordering' : card._customStage;
      if (kind === 'new') {
        state.newItemCount = _.clamp(state.newItemCount, 0, spaceLeft.value);
        emit('close', _.times(state.newItemCount).map(() => ({
          cards: [state.order._id],
          [sourceAccessor.value]: { _id: state.order._id, name: state.order.name },
          forceEditing: true,
          stage,
          status: 'NotUsed',
        })), state.orderList);
        return;
      }
      if (kind === 'old') {
        if (card.items.length + state.selectedItems.length > itemLimit.value) {
          return toast.error('Can not add more than 50 items to a Production Manager');
        }
        emit(
          'close',
          _.cloneDeep(state.selectedItems).map((i) => {
            i.stage = stage;
            i.status = 'NotUsed';
            return i;
          }),
          state.orderList,
        );
        return;
      }
      if (kind === 'excel') {
        emit('close', { _id: _.get(state, 'order._id', ''), name: _.get(state, 'order.name', '') });
        return;
      }
      emit('openCatalogList', state.order._id, state.orderList);

      cancel();
    };
    const refreshTable = () => {
      itemTable.value.refreshTable();
    };
    const getSearchValue = ((searchText) => {
      state.searchedValue = searchText;
      refreshTable();
    });
    const loadData = (() => {
      if (!state.order) return [];
      const allowedStages = ['planning', 'ordering'];
      let items = _.filter(state.order.items, (i) => allowedStages.includes(i.stage));
      items = _.differenceBy(items, filteredCardItems.value, '_id');
      if (state.searchedValue) {
        return items.filter(
          (item) => item.name.toLowerCase().includes(state.searchedValue.toLowerCase()),
        );
      }
      return items;
    });
    const itemSelected = ((selectedItems) => {
      state.selectedItems = selectedItems;
    });
    onBeforeMount(async () => {
      state.isLoading = true;
      try {
        // due to 1000 limit, we are doing this
        // fetch all the linked orders based on the items
        if (!['new','old','assembly','excel'].includes(kind)) {
            const queryParams = {
              projectId: card.project._id,
              limit: 1000,
              page: 1,
              'archived.value': false,
              excludeFields: {
                todos: 0,
                dates: 0,
                'items.serials': 0,
                'items.qtyLocations': 0,
              },
            };
            queryParams.module = props.module === 'Sourcing' ? 'Materials' : props.module;
            queryParams.stage = props.module === 'Sourcing' ? 'delivery' : '';
            const orders = await SupplyChain.supplyChain(queryParams);
            state.orderList = _.filter(orders.data, (el) => {
              if (el.multiTrade.value) { return props.card.multiTrade.value === el.multiTrade.value; }
              if (props.module === 'Sourcing' || el.purpose === 'kit') {
                return false;
              }
              return true;
            });
            if (!isSourceShipment.value && card.isPM()) {
              queryParams.module = 'ProductionOrder';
              queryParams.stage = 'coordination';
              const pos = await SupplyChain.supplyChain(queryParams);
              pos.data.forEach((order) => {
                if (order.multiTrade.value && card.multiTrade.value === order.multiTrade.value) {
                  state.orderList.push(order);
                }
              });
            }
            if (kind !== 'old') {
              state.order = _.find(
                state.orderList,
                { _id: _.get(card, `items[0].${sourceAccessor.value}._id`, '') },
              ) || state.orderList[0];
            }
        }

        // new code for showing linked orders
        if (['new','old','assembly','excel'].includes(kind)) {
          // get all the linked ids
          const linkedOrderIds = [];
          card.items.forEach((item) => {
            item.cards.forEach((orderId) => {
              if (orderId != card._id) {
                linkedOrderIds.push(orderId);
              }
            })
          });
          state.linkedOrderIds = _.uniq(linkedOrderIds);
          // get all the linked baseOrders
          const queryParams = {
            projectId: card.project._id,
            orderId: _.uniq(state.linkedOrderIds),
            'archived.value': false,
              excludeFields: {
                todos: 0,
                dates: 0,
                'items.serials': 0,
                'items.qtyLocations': 0,
              },
          }
          const orders = (await SupplyChain.supplyChain(queryParams)).data;
          // attach the orders to state.orderList
          state.orderList = _.filter(orders, (el) => {
              if (el.multiTrade.value) { return props.card.multiTrade.value === el.multiTrade.value; }
              if (props.module === 'Sourcing' || el.purpose === 'kit') {
                return false;
              }
              return true;
            });
            if (!isSourceShipment.value && card.isPM()) {
              queryParams.module = 'ProductionOrder';
              queryParams.stage = 'coordination';
              const pos = await SupplyChain.supplyChain(queryParams);
              pos.data.forEach((order) => {
                if (order.multiTrade.value && card.multiTrade.value === order.multiTrade.value) {
                  state.orderList.push(order);
                }
              });
            }
           state.order = _.find(
                state.orderList,
                { _id: _.get(card, `items[0].${sourceAccessor.value}._id`, '') },
            ) || state.orderList[0];
          }
      } catch (e) {
        console.log(e);
      } finally {
        state.isLoading = false;
      }
    });
    return {
      ...toRefs(state),
      cancel,
      spaceLeft,
      addDisabled,
      addItemRows,
      getHeaderText,
      getLabelText,
      getSearchValue,
      loadData,
      itemTable,
      tableProps,
      itemSelected,
      refreshTable,
      setItemCountValue,
      addNewItem,
    };
  },
});
</script>
<style scoped>
.field-body.is-flex > * {
  margin-right: 16px;
}
.field-body.is-flex .input {
  width: 56px;
}
::v-deep(.o-table tbody) {
  min-height: 150px !important;
  max-height: 380px !important;
}
/* move this to main scss file when starting media query */
.modal-card-body {
  overflow: visible;
}
</style>
