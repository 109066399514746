<template>
  <div class="modal-card">
    <header class="modal-card-head">
      <h4 class="modal-card-title is-capitalized">
        {{card.type === 'pick' ? 'Take Items' : `${card.type} an Items`}}</h4>
      <div class="is-divider-vertical"></div>
      <i class="icon-close is-clickable" @click="cancel"></i>
    </header>
    <section class="modal-card-body">
      <div class="columns">
        <div class="column">
          <div class="field">
            <div class="field-label">
              <label class="label is-size-3">
                Item {{card.type === 'pick' ? 'Needed' : 'To Stock'}}
              </label>
            </div>
            <div class="field-body">
              <span class="line-height is-size-3 has-text-black-bis">
                {{item.name}}
              </span>
              <!-- <span>{{item.quantityMap.needed}}</span> -->
            </div>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-6">
          <div class="field">
            <div class="field-label">
              <label class="label is-size-3">
                Qty {{card.type === 'pick' ? 'Needed' : 'To Stock'}}
              </label>
            </div>
            <div class="field-body">
              <span v-if="card.type ==='pick'"
                class="line-height is-size-3 has-text-black-bis">
                <field-generic-input v-if="$_.get(item, 'neededEmpty', false)"
                  :rowData="item"
                  type="number"
                  :value="item.quantityMap.needed || '0'"
                  :isEditing="true"
                  @update:value="updateStockValue"
                  max="9999"
                >
                </field-generic-input>
                <div v-else>
                  {{ item.quantityMap.needed }}
                </div>
              </span>
              <span v-else class="line-height is-size-3 has-text-black-bis">
                <field-generic-input
                  :rowData="item"
                  type="number"
                  :value="item.quantityMap.toStock || '0'"
                  :isEditing="true"
                  @update:value="updateStockValue"
                  v-if="isQtyNeeded"
                  max="9999"
                >
                </field-generic-input>
                <label class="label is-size-3" v-else>
                  {{ getToStockCount }}
                </label>
              </span>
            </div>
          </div>
        </div>
        <div class="column is-6">
          <div class="field">
            <div class="field-label">
              <label class="label is-size-3">
                Total Qty {{card.type === 'pick' ? 'Taken' : 'Stocked'}}
              </label>
            </div>
            <div class="field-body">
              <span v-if="card.type=='pick'" class="line-height is-size-3 has-text-black-bis">
                {{item.quantityMap.taken}}
              </span>
              <span v-else class="line-height is-size-3 has-text-black-bis">
                {{item.quantityMap.stocked}}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="is-flex is-justify-content-flex-end">
        <button v-if="card.type === 'stock'" class="button px-0">
          <i class="icon-addnew" @click="selectLocation"></i>
        </button>
      </div>
      <div class="table-container">
        <mf-table
        class="mt-0"
          v-if="isDataLoaded"
          :tableProps="tableProps[card.type]"
          :apiMode="false"
          :loadData="loadData"
          :hideGutter="true"
          @checkbox-toggled="selectedRows"
          ref="trModalTable"
          :key="refreshKey"
        >
        <template v-slot:qtyInput = "{rowData}">
          <div class="is-pulled-right">
            <input v-if="card.type === 'stock' ? !item.finished : !item.delivered" class="input-qty"
              @input="setVal(rowData)"
              v-model.number="rowData.qtyToConsume"
              :max="getStockQty"
              placeholder="0"
              type="number"
              :isDisabled="isInputDisabled"
            >
            <span v-else>{{rowData.qtyToConsume ? rowData.qtyToConsume : 0}}</span>
          </div>
        </template>
        <template v-slot:action = "{rowData}">
          <button class="button action-btn is-size-5 has-background-black-bis
            has-text-weight-bold"
            v-if="rowData.delivered || rowData.finished" @click="undoChange(rowData)"
              :disabled="isInputDisabled || item.finished || item.delivered">
              {{card.type === 'pick' ? 'Return' : 'Take Back'}}</button>
          <button class="button action-btn is-size-5 has-background-black-bis
            has-text-weight-bold"
            v-else @click="performQtyChange(rowData)"
              :disabled="isInputDisabled || item.finished || item.delivered">{{card.type === 'pick' ? 'Take' : 'Stock'}}</button>
        </template>
        </mf-table>
      </div>
    </section>
    <footer class="modal-card-foot is-justify-content-flex-end is-align-items-center">
      <button class="button is-outlined" @click="cancel">Cancel</button>
      <button
        class="button has-background-black-bis"
        @click="save()" :disabled="isDoneButtonDisabled"
      >{{card.type === 'pick' ? 'Done Taking' : 'Done Stocking'}}</button>
    </footer>
  </div>
  <o-loading :full-page="true" :active="isLoading" :can-cancel="false"></o-loading>
</template>

<script>
import {
  reactive, toRefs, onMounted, computed,
} from 'vue';
import { useToast } from 'vue-toastification';
import { clamp } from 'lodash';
import { ModalProgrammatic } from '@oruga-ui/oruga-next';
import AddLocation from '@/components/modals/AddLocation.vue';
import tableDefinition from '@/components/table-cols/transferRequestModalCols';
import MfTable from '@/components/table-fields/MfTable.vue';
import QtyInput from '@/components/fields/QtyInput.vue';

export default ({
  name: 'ItemInTransferRequest',
  components: {
    MfTable,
    'qty-input': QtyInput,
  },
  props: {
    item: {
      type: Object,
    },
    card: {
      type: Object,
    },
    isFinished: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const toast = useToast();
    const tableProps = tableDefinition;
    const state = reactive({
      isDataLoaded: false,
      totalQtyTaken: 0,
      item: _.cloneDeep(props.item),
      oldItem: _.cloneDeep(props.item),
      refreshKey: 0,
    });

    onMounted(async () => {
      state.isDataLoaded = true;
    });

    const isQtyNeeded = computed(() => (props.card?.createdFor && props.card.type === 'stock'));

    const getToStockCount = computed(() => (_.get(props.item.quantityMap, 'toStock', 0) - _.get(props.item.quantityMap, 'stocked', 0)));

    const addLocationToItem = (location) => {
      const newEntry = {
        catId: props.item.catId,
        itemId: props.item.itemId,
        location,
      };
      // eslint-disable-next-line vue/no-mutating-props
      props.item.locations.push(newEntry);
      state.refreshKey++;
    };

    const selectLocation = () => {
      ModalProgrammatic.open({
        component: AddLocation,
        props: {
          for: 'transferRequest',
          onlyNestedLocs: true,
        },
        trapFocus: true,
        canCancel: false,
        events: {
          'select-location': async (loc) => {
            addLocationToItem(loc);
          },
        },
        rootClass: 'modal-sm',
      });
    };

    const performQtyChange = (rowData) => {
      if (!props.item) return;
      const { quantityMap } = props.item;
      const taskType = props.card.type;
      if (taskType === 'stock' && !_.isNumber(rowData.qtyToConsume)) {
        toast.warning('Please enter quantity needed first', 'is-danger');
        return;
      }
      if (taskType === 'pick' && rowData.qtyToConsume > rowData.qtyAvailable) {
        toast.warning('Please enter quantity less than quantity available');
        return;
      }
      const constantVal = taskType === 'stock' ? 'toStock' : 'needed';
      const changingVal = taskType === 'stock' ? 'stocked' : 'taken';
      if (!rowData.qtyToConsume
        && parseFloat(quantityMap[constantVal]) !== parseFloat(quantityMap[changingVal])) {
        if (taskType === 'stock') {
          rowData.qtyToConsume = quantityMap.toStock - quantityMap.stocked;
        } else if (taskType === 'pick') {
          if (parseFloat(rowData.qtyAvailable) >= parseFloat(quantityMap.needed)) {
            rowData.qtyToConsume = quantityMap.needed - quantityMap.taken;
          } else if (parseFloat(rowData.qtyAvailable) <= parseFloat(quantityMap.needed)) {
            if (rowData.delivered) {
              quantityMap.taken -= _.cloneDeep(rowData.moveQuantity);
            }
            rowData.moveQuantity = 0;
            /* Above 2 lines is needed as if partial qty of an rowData is picked and remade to 0
            and again clicked on pick only availble aty must be taken and not more than that. */
            rowData.qtyToConsume = rowData.qtyAvailable;
          }
        }
      }
      if (taskType === 'stock') {
        if (parseFloat(rowData.qtyToConsume)
          > (parseFloat(quantityMap.toStock) - parseFloat(quantityMap.stocked))) {
          toast.error('Enter valid quantity');
          return;
        }
        rowData.finished = true;
        if (!rowData.qtyToStock) rowData.qtyToStock = 0;
        rowData.qtyToStock += rowData.qtyToConsume;
        rowData.qtyAvailable -= rowData.qtyToConsume;
        quantityMap.stocked = parseFloat(quantityMap.stocked) + parseFloat(rowData.qtyToConsume);
      } else {
        if (parseFloat(rowData.qtyToConsume)
          > (parseFloat(quantityMap.needed) - parseFloat(quantityMap.taken))) {
          toast.error('Enter valid quantity');
          return;
        }
        rowData.delivered = true;
        // eslint-disable-next-line vue/no-mutating-props
        if (!rowData.moveQuantity) rowData.moveQuantity = 0;
        rowData.moveQuantity += rowData.qtyToConsume;
        quantityMap.taken = parseFloat(quantityMap.taken) + parseFloat(rowData.qtyToConsume || 0);
      }
    };

    const undoChange = (rowData) => {
      const qty = rowData.qtyToConsume;
      rowData.qtyToConsume = 0;
      const { quantityMap } = props.item;
      if (props.card.type === 'pick') {
        if (rowData.moveQuantity < qty) {
          toast.warning('You can not return qty more than you have taken');
          return;
        }
        rowData.moveQuantity -= qty;
        quantityMap.taken = parseFloat(quantityMap.taken) - parseFloat(qty || 0);
        rowData.delivered = false;
        if (_.every(props.item.locations, (loc) => {
          // eslint-disable-next-line no-unused-expressions
          !loc.delivered;
        })) {
          // eslint-disable-next-line vue/no-mutating-props
          props.item.delivered = false;
        }
      } else {
        if (rowData.qtyToStock < qty) {
          toast.warning('You can not take back qty more than you have stocked');
          return;
        }
        rowData.qtyToStock -= qty;
        rowData.qtyAvailable += qty;
        quantityMap.stocked = parseFloat(quantityMap.stocked) - parseFloat(qty);
        rowData.finished = false;
        if (_.every(props.item.locations, (loc) => {
          // eslint-disable-next-line no-unused-expressions
          !loc.finished;
        })) {
          // eslint-disable-next-line vue/no-mutating-props
          props.item.finished = false;
        }
      }
    };

    const setVal = (rowData) => {
      let val = 0;
        val = clamp(Math.abs(parseInt(rowData.qtyToConsume) || 0 ), 0, 9999);
        rowData.qtyToConsume = val;
      // } 
      // if (props.card.type === 'pick' && item.delivered && parseFloat(item.moveQuantity) === parseFloat(item.qtyAvailable)) {
      //   item.qtyToConsume = item.qtyAvailable;
      // }
    };

    const loadData = () => {
      const locs = props.item.locations;
      return locs;
    };
    const cancel = () => {
      // eslint-disable-next-line vue/no-mutating-props
      props.item = state.oldItem;
      emit('close');
    };
    const save = () => {
      const taskType = props.card.type;
      if (taskType === 'stock') {
        _.set(props.item, 'finished', true);
      }
      if (taskType === 'pick') {
        _.set(props.item, 'delivered', true);
      }
      emit('close');
    };

    const updateStockValue = (val) => {
      if (props.card.type === 'pick') props.item.quantityMap.needed = Number(val);
      else props.card.quantityMap.toStock = Number(val);
    };

    const isInputDisabled = computed(() => {
      if (props.card.type === 'pick' && _.lte(props.item.quantityMap.needed, 0)) return true;
      if (props.card.type === 'stock' && _.lte(props.item.quantityMap.toStock, 0)) return true;
      return false;
    });

    const getStockQty = computed(() => {
      if (props.card.type === 'pick') return props.item.quantityMap.needed;
      return props.item.quantityMap.toStock;
    });

    const isDoneButtonDisabled = computed(() => {
      if (props.card.type === 'pick') {
        return props.item.delivered || !props.item.quantityMap.needed;
      }
      if (props.card.type === 'stock') {
        return (props.isFinished && props.item.quantityMap.stocked === props.item.quantityMap.toStock) || !props.item.quantityMap.stocked;
      }
      return false;
    });

    return {
      ...toRefs(state),
      tableProps,
      loadData,
      selectLocation,
      addLocationToItem,
      performQtyChange,
      setVal,
      undoChange,
      cancel,
      save,
      getToStockCount,
      updateStockValue,
      isInputDisabled,
      getStockQty,
      isQtyNeeded,
      isDoneButtonDisabled,
    };
  },
});
</script>

<style scoped>
::v-deep(.o-table tbody) {
  min-height: 100% !important;
  max-height: 357px !important;
}
::v-deep(.input-qty) {
  width: 72px;
}
::v-deep(.o-table__td .button.action-btn) {
  width: 104px;
}
</style>
