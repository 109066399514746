<template>
  <div>
    <div class="tab-table-gutter">
      <div class="columns is-justify-content-flex-end">
        <div v-if="showProjectChooser" class="column is-paddingless">
          <div class="columns is-paddingless">
            <div class="column is-flex">
              <span class="has-text-black-bis is-flex is-align-items-center is-size-3
                is-italic has-text-weight-semibold">
                Current Project:
              </span>
              <div class="column is-3 is-3-fullhd is-7-desktop">
                <mf-multi-select
                  v-model="selectedProject"
                  label="name"
                  :placeholder="selectedProject.name"
                  :searchable="true"
                  :allow-empty="false"
                  :options="projectList"
                  :key="selectedProject._id"
                  track-by="_id"
                  @update:modelValue="setProject($event)"
                ></mf-multi-select>
              </div>
            </div>
          </div>
        </div>
        <div v-if="showCompanyChooser" class="column is-paddingless">
          <div class="columns is-paddingless">
            <div class="column is-flex">
              <span class="has-text-black-bis is-flex is-align-items-center is-size-3
                is-italic has-text-weight-semibold">
                Current Company:
              </span>
              <div class="column  is-3">
                <mf-multi-select
                  v-model="$store.state.queryParams.companies"
                  label="name"
                  placeholder="Select one"
                  :searchable="false"
                  :allow-empty="false"
                  :options="viewMembersCompany"
                  track-by="id"
                  @update:modelValue="setCompany($event)"
                ></mf-multi-select>
              </div>
            </div>
          </div>
        </div>
        <slot name="viewModel"></slot>
        <slot name="selectView"></slot>
        <slot name="selectStock"></slot>
        <slot name="showComplete"></slot>
        <slot name="hideActivities"></slot>
        <div v-for="(button, idx) in toolButtons" :key="idx" class="toolbar-icon">
          <div class="column is-flex">
            <div class="is-divider-vertical"
            v-if="button.isLine && ['schedule', 'inventory', 'order-view', 'item-view'].includes(routeParams)"></div>
            <o-dropdown v-if="button.options && routeParams.stage === button.showOptionsForStage"
              position="bottom-left" aria-role="list">
              <template #trigger>
                <button class="button px-0 has-text-black-bis">
                  <i :class="button.icon"></i>
                </button>
              </template>
              <o-dropdown-item
                v-for="(opt, idx) in button.options"
                :key="idx"
                @click="fireEvent(button.event, opt.type)"
                aria-role="listitem"
              >
                <span>{{ opt.label }}</span>
              </o-dropdown-item>
            </o-dropdown>
            <button v-else @click="fireEvent(button.event, false)"
                    v-tooltip="button.tooltip"
                    :disabled="isButtonDisabled(button)"
                    class="button px-0 has-text-black-bis">
              <i :class="button.icon"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <column-chooser
      v-if="columnChooserModal"
      :isActive="columnChooserModal"
      @close="closeColumnChooserModal"
      @re-render-table="() => $emit('re-render-table')"
    >
    </column-chooser>
  </div>
</template>

<script>
import {
  defineComponent, reactive, toRefs, computed, onBeforeMount,
} from 'vue';
import { useStore } from 'vuex';
import ColumnChooser from '@/components/modals/ColumnChooser.vue';
import {
  isFunction, isEmpty, filter, orderBy, get, flatMap, castArray, find, isArray,
} from 'lodash';
import screens from '@/constants';
import Projects from '@/models/Projects';
import { useRouter, useRoute } from 'vue-router';
import Vendors from '@/models/Vendors';

export default defineComponent({
  name: 'TableGutter',
  components: {
    ColumnChooser,
  },
  props: {
    tools: Object,
    selectedAction: Object,
  },
  setup(props, { emit }) {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const { tools } = props;
    const { activeScreen } = store.state;
    const state = reactive({
      columnChooserModal: false,
      companyChange: false,
      routeParams: route.path.split('/').pop(),
      selectedCategories: [],
      selectedSubcategory: [],
      categories: [],
      subCategories: [],
    });
    const selectedProject = computed(() => {
      const { project } = store.state.queryParams;
      if (isEmpty(project)) {
        return store.state.queryParams.filteredProjects[0];
      }
      return isArray(project) ? project[0] : project;
    });
    const toolButtons = computed(() => {
      if (_.isFunction(tools.buttons)) {
        return tools.buttons(state.routeParams.stage);
      }
      return tools.buttons || [];
    });
    const fireEvent = (eventHandler, optType) => {
      if (eventHandler === 'columnChooser') {
        state.columnChooserModal = true;
      } else {
        emit('cellClicked', eventHandler);
      }
      emit('event', {
        event: eventHandler,
        type: eventHandler,
        optType,
      });
    };
    const isButtonDisabled = (button) => {
      if (!isEmpty(props.selectedAction)) return true;
      if (isFunction(button.isDisabled)) {
        return button.isDisabled(state.routeParams);
      }
      return button.isDisabled || false;
    };
    const closeColumnChooserModal = () => {
      state.columnChooserModal = false;
      emit('ccModalClosed');
    };
    const showProjectChooser = computed(() => (['schedule', 'forms'].includes(activeScreen)));

    const showCategoryFilter = computed(() => (['assemblies', 'parts'].includes(activeScreen)));

    const showSubCategoryFilter = computed(() => (['assemblies', 'parts'].includes(activeScreen)));

    const showCompanyChooser = computed(() => {
      if (['tasks', 'forms', 'notifications', 'assemblies', 'parts'].includes(store.state.activeScreen)) return false;
      const nonArchivedCompanies = store.getters.getViewerCompanies;
      let canShow = false;
      if (nonArchivedCompanies.length > 1) canShow = true;
      return canShow && screens[store.state.activeScreen].showComapnyChooser
        && store.state.activeScreen !== 'forms';
    });
    const projectList = computed(() => store.state.queryParams.projects);
    const viewMembersCompany = computed(() => {
      const comps = filter(
        store.state.userData.multiCompanies,
        ({ archived }) => !archived.value,
      );
      return flatMap(comps, 'company');
    });
    const setQueryParams = (key, val) => {
      store.commit('setMultiRemoveActive', false);
      store.commit('setQueryParams', { [key]: val });
    };
    const refetchProject = async (selectedCompany = []) => {
      let companyId = store.state.userData.company;
      if (!isEmpty(selectedCompany)) companyId = selectedCompany[0]._id;
      // eslint-disable-next-line prefer-const
      let { allProjects, allGIs } = await Projects.projectsForCompany(companyId);
      allProjects = filter(allProjects, { isTemplateProject: false });
      allProjects = orderBy(allProjects, [(project) => project.name.toLowerCase()], ['asc']);
      setQueryParams('project', allProjects[0]);
      setQueryParams('_projects', allProjects);
      setQueryParams('_projectsAndGI', allProjects.concat(allGIs));
      await store.dispatch('updateActiveProj', companyId);
      if (!store.state.userData.rememberLastUsedProjects) {
        ['projects', 'projectsAndGI'].forEach((key) => {
          store.dispatch('selectActiveProjects', key);
        });
      }
    };
    const setFilterData = async (selectedCompany, companyChanged = false) => {
      if (companyChanged) {
        state.companyChange = true;
        // need to clear selected filter after company change
        // this.$refs.globalFilters.clearAllFilters();
      }
      setQueryParams('companies', selectedCompany);
      if (state.companyChange) await refetchProject(selectedCompany);
    };
    const setProject = (project) => {
      // when the search is made the updateModelValue is triggered and
      // the search text is set in queryParams
      if (typeof project !== 'string') {
        store.commit('setProject', project);
        // store.commit('setQueryParams', { filteredProjects: [project] });
        emit('cellClicked', { type: 'setProject' });
      }
    };
    const setCompany = (company) => {
      const selectedComp = castArray(company);
      setFilterData(selectedComp, true);
      store.dispatch('getUserData');
      if (store.getters.isViewer()) {
        /* Deleting entry in colsMap while changing company, because
        if user is GC and added to different companies,
        while swtiching between companies colsMap must be updated with selected company */
        store.commit('updateColsMap');
        if (store.state.activeScreen === 'production-status-order-view') {
          /* Doing this beacuse if user stays in production-status page and changes the company,
          even if this.$route.push is done, navigation garuds defined in router file wont be called
          which results in retaining old companies colsMap value. This also required when
          user is GC and he is added to other companies.
          */
          store.commit('changeScreen', { screen: store.state.activeScreen });
        }
      }
      if (store.state.activeScreen !== 'production-status-order-view') {
        router.push('/scm/production-status/order-view');
      }
    };

    const emitRefreshParams = () => {
      emit('re-render-table', { categories: state.selectedCategories, subCategories: state.selectedSubcategory });
    };

    const fetchSubCategories = async () => {
      if (showCategoryFilter.value) {
        if (state.selectedCategories.length <= 1) {
          const categoryId = get(state.selectedCategories, '[0]._id', '');
          if (categoryId !== '') {
            state.subCategories = await Vendors.getSubCategories({ categoryId });
            emitRefreshParams();
            return;
          }
        }
      }
      state.subCategories = [];
      emitRefreshParams();
    };

    onBeforeMount(async () => {
      if (store.getters.isViewer()) {
        if (store.state.queryParams.companies.length === 0) {
          const nonArchivedCompanies = store.getters.getViewerCompanies;
          const companyId = get(nonArchivedCompanies[0], 'company._id', store.state.userData.company);
          const userCompany = find(viewMembersCompany.value, { _id: companyId });
          const defaultCompany = castArray(userCompany);
          await setFilterData(defaultCompany);
        } else await setFilterData(store.state.queryParams.companies);
      }
      if (showProjectChooser.value && isEmpty(store.state.queryParams.project)) {
        const defaultProject = store.state.queryParams.filteredProjects.length
          ? store.state.queryParams.filteredProjects[0] : store.state.queryParams.projects[0];
        if (typeof defaultProject === 'object') {
          setProject(defaultProject);
        } else if (!isEmpty(defaultProject)) {
          setProject(store.getters.findProject({ _id: defaultProject }));
        }
      }
      if (showCategoryFilter.value) state.categories = await Vendors.getCategories({ limit: 500 });
    });
    return {
      ...toRefs(state),
      toolButtons,
      fireEvent,
      isButtonDisabled,
      closeColumnChooserModal,
      showCompanyChooser,
      showProjectChooser,
      showCategoryFilter,
      showSubCategoryFilter,
      viewMembersCompany,
      setCompany,
      setProject,
      projectList,
      selectedProject,
      fetchSubCategories,
      emitRefreshParams,
    };
  },
});
</script>
