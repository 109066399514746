import _ from 'lodash';
import moment from 'moment-timezone';
import SupplyChain from './SupplyChain';
import Project from './Projects';
import Shipping from './Shipping';
import Catalogs from './Catalogs';

// const zeroId = '000000000000000000000000';
export default {
  async hasBomCreated(items) {
    const params = {
      sort: { name: 1 },
      catId: _.compact(_.map(items, 'catId')),
    };
    const assemblies = (await Catalogs.getAssemblyParts(params)).data;
    let assemblyHavingPartsCount = 0;
    items.forEach((item) => { // consider items count based on assembly with parts
      const assembly = _.find(assemblies, { catId: item.catId });
      if (assembly && ((assembly.parts && assembly.parts.length) || (assembly.assemblies && assembly.assemblies.length))) {
        assemblyHavingPartsCount += 1;
      }
    });
    if (assemblyHavingPartsCount > 0 && (!_.isEmpty(assemblies))) {
      let parts = _.flatMap(assemblies, asm => _.concat(_.get(asm, 'parts', []), _.get(asm, 'assemblies', [])));
      const catId = _.compact(_.map(parts, 'catId'));
      const partsParams = { catId, type: 'assemblyAndItems', showAssmbsParts: true };
      parts = (await Catalogs.getAssemblyParts(partsParams)).data;
      parts = _.filter(parts, p => !_.isEmpty(p.catId) && _.get(p, 'archived.value') === false);
      if (!_.isEmpty(parts)) return true;
    }
    return false;
  },
  async reSubmitStageChange(params) {
    const rowData = params;
    if (rowData && rowData.stage === 'rejected') {
      rowData.stage = 'preparation';
    }
  },

  getTotalSeconds(obj, temp){
    const hoursToSecondsMultiplier = 3600;
    const minutesToSecondsMultiplier = 60;
    const MultiplierTable = {
      hours: hoursToSecondsMultiplier,
      minutes: minutesToSecondsMultiplier,
      seconds: 1,
    }
    temp.hours = 0;
    for (const trackerKey of ['hours', 'minutes', 'seconds']) {
      if ((!_.isUndefined(obj[trackerKey]) || !_.isEmpty(obj[trackerKey])) && !Number.isNaN(obj[trackerKey])) {
        temp[trackerKey] = parseInt((obj[trackerKey] * MultiplierTable[trackerKey]), 10);
        if (trackerKey !== 'hours') temp.hours += temp[trackerKey];
      }
    }
    return temp.hours;
  },

  async getMaterialItems(rowData, reqPar) {
    const status = ['not-started', 'in-transit', 'in-storage', 'fulfilled', 'mixed'];
    if (['qa', 'delivery', 'complete'].includes(rowData._customStage)) status.push('consumed');
    if (['manufacturing'].includes(rowData._customStage)) {
      // For inter-project inventory movement, we create 2 shipments,
      // of which common stock project SL is fulfilled. Hence excluding that.
      status.splice(status.indexOf('fulfilled'), 1);
    }
    let reserveForCard = await Shipping.get({
      cardIds: rowData._id,
      limit: 50,
      status,
      projectId: rowData.project._id,
    });
    const relayedToShipment = _.get(reserveForCard, 'data[0].relayedToShipment', '');
    let relayedShipmentFound = false;
    if (!_.isEmpty(reserveForCard.data) && reserveForCard.data.length === 1) {
      if (!_.isEmpty(relayedToShipment)) {
        const result = await Shipping.get({
          shippingId: relayedToShipment,
          limit: 50,
          status,
          projectId: rowData.project._id,
        });
        if (result.data && result.data.length) {
          reserveForCard = result;
          relayedShipmentFound = true;
        }
      }
    }
    const reservedCatId = {};
    let catIdMergedItems = [];
    _.flatMap(reserveForCard.data, shipment => shipment.items.filter((item) => {
      if ((!item.cardId || (item.cardId !== rowData._id) || !item.quantity) && (!relayedShipmentFound)) return false;
      item._parentPurpose = shipment.purpose;
      const catId = _.get(item.detail, 'catId', '');
      if (catId !== '') { item.catId = catId; }
      if (shipment.purpose === 'assembly') item.purpose = shipment.purpose;
      const reserveQty = reservedCatId[item.catId] || 0;
      reservedCatId[item.catId] = item.quantity + reserveQty;
      if (['manufacturing', 'qa', 'delivery', 'complete'].includes(rowData._customStage) || shipment.purpose === 'assembly') {
        if (!item.stage) item.stage = shipment.statusToShow;
        item.reserved = item.quantity;
        item.forBOM = shipment.forBOM;
        // requestion card qty or reserved qty to be considered. we have taken RequstionQty
        if (shipment.forBOM) {
          item.quantity = 0;
        }
        const idToCheck = _.isEmpty(item.catId) ? item._id : item.catId;
        const sameItem = _.find(
          catIdMergedItems,
          (i) => {
            const id = _.isEmpty(i.catId) ? i._id : i.catId;
            return !_.isEmpty(id)
                && !_.isEmpty(idToCheck) && id.toString() === idToCheck.toString();
          },
        );
        if (sameItem) {
          sameItem.quantity += item.quantity;
          if (_.isEmpty(item.catId)) sameItem.reserved += item.reserved;
          sameItem.combinedQuantity = true;
        } else {
          catIdMergedItems.push(item);
        }
      }
      return item;
    }));
    let ids = _.map(rowData.materials, '_id');
    if (rowData.fromInvOrderId) {
      ids = ids.concat(rowData.fromInvOrderId);
    }
    const filteredItems = [];
    let orders = [];
    if (!_.isEmpty(ids)) {
      const reqParams = {
        limit: 100,
        page: 1,
        projectId: rowData.project._id,
        orderId: ids,
        filterNoItemOrders: false,
        showAllCompanyOrders: false,
        ...reqPar,
      };
      if (!['delivery', 'complete'].includes(rowData._customStage)) {
        const { data } = await SupplyChain.supplyChain(reqParams);
        orders = _.cloneDeep(data);
        _.forEach(orders, (order) => {
          filteredItems.push(...order.items);
        });
      }
    }
    for (const item of filteredItems) {
      const sameItem = _.find(
        catIdMergedItems,
        i => !_.isEmpty(i.catId)
              && !_.isEmpty(item.catId) && i.catId.toString() === item.catId.toString(),
      );
      if (sameItem) {
        sameItem.quantity += item.quantity;
        sameItem.combinedQuantity = true;
        if (sameItem.stage !== item.stage) sameItem.stage = 'mixed';
      } else if (item.order.createdFrom !== 'inventory' || item.catId !== '') {
        catIdMergedItems.push(item);
      }
    }
    const catIds = _.uniq(_.compact(_.map(catIdMergedItems, 'catId')));
    const project = await Project.get(rowData.project._id);
    const reqParams = {
      params: {
        baseCardType: ['Sourcing', 'Prefabs'],
        catIds,
      },
      projectId: [rowData.project._id],
      locs: [_.get(project.projectSettings[0], 'projectInventoryLocation._id', '')],
      limit: !_.isEmpty(catIds) ? catIds.length : 100,
      page: 1,
      baseCardType: ['Part', 'Assembly'],
      catId: catIds,
      // used to get data present only at the root node in manuf card.
      getOnlyRoot: rowData._customStage === 'manufacturing',
    };

    const resp = await SupplyChain.inventory(reqParams, false, true);
    const qtyNeeded = {};
    _.forEach(catIdMergedItems, (item) => {
      if (!_.isEmpty(item.catId) && item.purpose !== 'assembly') {
        const inItem = _.find(resp.data, i => _.get(i, 'catId', '')
          .toString() === _.get(item, 'catId', '')
          .toString());
        if (!_.isEmpty(inItem)) {
          item.available = inItem.available;
          item.reserved = reservedCatId[inItem.catId];
        }
        if (_.isUndefined(qtyNeeded[item.catId])) {
          qtyNeeded[item.catId] = 0;
        }
        if (item.quantity) {
          qtyNeeded[item.catId] += item.quantity;
        }
      }
    });

    let { catQtyMaps } = rowData;

    let nonCatIdItems = _.remove(catIdMergedItems, item => !item.catId);
    nonCatIdItems = _.uniqBy(nonCatIdItems, '_id');
    catIdMergedItems = _.uniqBy(catIdMergedItems, 'catId');
    const allItems = _.concat(catIdMergedItems, nonCatIdItems);
    if (_.isUndefined(catQtyMaps)) {
      catQtyMaps = [];
    }
    // if (rowData._customStage === 'delivery') allItems = resp.data;
    for (const item of allItems) {
      const predicate = {};
      if (_.isEmpty(item.catId)) {
        predicate._id = item._id;
      } else {
        predicate.catId = item.catId;
      }
      let catQtyMap = _.find(catQtyMaps, predicate);
      if (_.isUndefined(catQtyMap)) {
        if (item._parentPurpose === 'assembly' || rowData.purpose === 'kit') {
          catQtyMap = {
            ...predicate,
            qtyToConsume: 0,
            qtyToShip: item.quantity,
          };
        } else {
          catQtyMap = {
            ...predicate,
            qtyToConsume: item.quantity,
            qtyToShip: 0,
          };
        }
        catQtyMaps.push(catQtyMap);
      }
      // else if (item.combinedQuantity === true && !_.isUndefined(catQtyMap)) {
      //   _.remove(catQtyMaps, (mergedItem) => mergedItem.catId === item.catId);
      //   catQtyMap = {
      //     ...predicate,
      //     qtyToConsume: item.quantity,
      //     qtyToShip: 0,
      //   };
      //   catQtyMaps.push(catQtyMap);
      // }
      item.qtyToConsume = catQtyMap.qtyToConsume;
      item.qtyToShip = catQtyMap.qtyToShip;
      item.qtyConsumed = catQtyMap.qtyConsumed || 0;
      if (qtyNeeded[item.catId] && !_.get(item, 'combinedQuantity', false)) {
        item.quantity = qtyNeeded[item.catId];
      }
    }
    return {
      data: allItems,
      orders,
      total: resp.total,
    };
  },
  getWishListName(card, user) {
    const dateObj = moment()
      .tz('America/New_York');
    const currentTime = `${dateObj.format('MM-DD-YYYY-h:mm:ss a')} (EST)`;
    return `${_.get(card, 'name', '')}-${_.get(user, 'name', '')}-${currentTime}`;
  },
  getCategoriesFromVendors(vendors) {
    const idxCategories = {};
    for (const v of vendors) {
      for (const c of v.category) {
        if ((_.keys(idxCategories)).includes(c._id)) {
          idxCategories[c._id].push(v);
        } else {
          idxCategories[c._id] = [];
          idxCategories[c._id].push(v);
        }
      }
    }
    return idxCategories;
  },
  totalPlannedHrs(card) {
    let plannedHrs = 0;
    const runs = _.get(card, 'manager.runs', []);
    if (!_.isEmpty(runs)) {
      for (const run of runs) {
        for (const ri of run.runItemsCount) {
          plannedHrs += ri.plannedHrs;
        }
      }
    }
    return this.clockDisplay(plannedHrs);
  },
  totalConsumedHrs(card) {
    let actualHrs = 0;
    const runs = _.get(card, 'manager.runs', []);
    if (!_.isEmpty(runs)) {
      for (const run of runs) {
        actualHrs += run.stats.actualHrs;
      }
    }
    return this.clockDisplay(actualHrs);
  },
  totalItems(card) {
    let quantity = 0;
    const runs = _.get(card, 'manager.runs', []);
    if (!_.isEmpty(runs)) {
      for (const run of runs) {
        for (const ri of run.runItemsCount) {
          quantity += ri.quantity.thisRun;
        }
      }
    }
    return quantity;
  },
  clockDisplay(val) {
    let hrs = 0;
    let mins = 0;
    if (val < 3600) { hrs = 0; mins = 0; } else {
      hrs = parseInt((val / 3600), 10);
      mins = parseInt(((val % 3600) / 60), 10);
    }
    return `${hrs}:${mins}`;
  },
};
