// Table cols for Assemblies and Parts Table
export default {
  defaultSortField: 'name',
  defaultSortOrder: 'asc',
  rearrangeCols: false,
  isCheckable: true,
  stickeyHeaders: true,
  gutterOpts: {},
  toolBar: false,
  isCardView: true,
  fields: [
    {
      name: 'name',
      width: '296px',
      title: 'Item Name',
      sortField: 'name',
      sticky: true,
      id: 'name',
      defaultVisible: true,
    },
    {
      name: 'desc',
      width: '296px',
      title: 'Description',
      id: 'desc',
      defaultVisible: true,
    },
    {
      name: 'field-generic-input',
      width: '200px',
      title: 'Catalog ID',
      prop: 'catId',
      sortField: 'catId',
      id: 'catId',
      defaultVisible: true,
    },
    {
      name: 'field-generic-input',
      width: '112px',
      title: 'Qty Needed',
      id: 'quantity',
      prop: 'quantity',
      type: 'number',
      defaultVisible: true,
      inputProps: {
        disableEdit: false,
        min: 0,
        max: 9999,
      },
    },
  ],
};
