<template>
  <o-loading :full-page="true" :active="isLoading" :can-cancel="false"></o-loading>
  <div class="list-view">
    <TableGutter
        :selectedAction="isBulkActionEnabled"
        :tools="tableProps.gutterOpts"
        @cellClicked="refreshTable"
        @cc-modal-closed="triggerSetHeaderHeight"
        @re-render-table="rerenderMfTable += 1"
    >
      <template v-slot:hideActivities>
        <div class="mt-3 is-flex">
          <o-field>
            <o-switch class="is-size-3 has-text-black-bis has-text-weight-bold"
              v-model="toggleSwitch"
              @click="refreshTable('toggleMyNotify', !toggleSwitch)">
              Only show my notifications
            </o-switch>
          </o-field>
           <div class="is-divider-vertical mr-2"></div>
        </div>
      </template>
    </TableGutter>
    <mf-table
      ref="notifTable"
      :tableProps="tableProps"
      :hideGutter="true"
      :loadData="loadData"
      :screenTable="true"
      :apiMode="true"
      tableName="notifTable"
      @cell-clicked="onCellClicked"
      :key="rerenderMfTable"
    >
      <template v-slot:notif-date="{ rowData }">
        <div class="text-overflow is-pulled-right">
          {{$filters.usaDate(rowData.createdAt)}}
        </div>
      </template>
      <template v-slot:notif-toggle="{ rowData }">
        <div class="text-overflow" v-tooltip="toggleSwitch ? rowData.notification : rowData.absoluteNotification">
          {{ toggleSwitch ? rowData.notification : rowData.absoluteNotification }}
        </div>
      </template>
    </mf-table>
  </div>
</template>

<script>
import {
  defineComponent, reactive, toRefs, ref, computed,
} from 'vue';
import MfTable from '@/components/table-fields/MfTable.vue';
import notificationCols from '@/components/table-cols/notificationCols';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import {
  get, filter, uniq, forEach, isEmpty,
} from 'lodash';
import SupplyChain from '@/models/SupplyChain';
import Notifications from '@/models/Notifications';
import TableGutter from '@/components/TableGutter.vue';
import UtilityMixin from '@/components/mixins/UtilityMixin';

export default defineComponent({
  name: 'notifications',
  components: {
    MfTable,
    TableGutter,
  },
  setup() {
    const notifTable = ref(null);
    const tableProps = notificationCols;
    const store = useStore();
    const router = useRouter();
    const state = reactive({
      toggleSwitch: store.state.toggleMyNotify,
      isLoading: false,
      rerenderMfTable: 0,
    });
    const { openPreference } = UtilityMixin();

    const onCellClicked = async (event) => {
      if (event.type === 'openCard') {
        let module = '';
        let stage = '';
        const card = event.data;
        const projectId = get(card.project, '_id', '');
        const cardId = get(card.source, '_id', '');
        const moduleName = get(card.source, 'module', '');
        if (moduleName === 'baseOrder') {
          const queryParams = {
            projectId,
            orderId: cardId,
          };
          const orders = await SupplyChain.supplyChain(queryParams);
          const order = orders.data[0];
          if (order.isPrefab()) module = 'prefab';
          else if (order.isPO()) module = 'order';
          else if (order.isPM()) module = 'manager';
          else if (order.isMM() || card.__t === 'Sourcing') module = 'material';
          stage = order.getCardStage();
        } else if (moduleName === 'shippingLabel') {
          module = 'shipping';
        }
        const routeParams = { projectId, cardId };
        if (['manager', 'material', 'sourcing'].includes(module)) routeParams.stage = stage;
        const routeObj = {
          name: `${module}-edit`,
          params: routeParams,
        };
        window.open(router.resolve(routeObj)?.href, openPreference());
      }
    };

    const getCompanyIds = computed(() => {
      const { userData } = store.state;
      let companyId = userData.company;
      if (store.getters.isViewer()) {
        companyId = store.getters.selectedIdsForKey('companies', false);
      }
      return companyId;
    });

    const removeArchivedOrderSource = async (notificationData) => {
      const validCards = filter(notificationData.data, (card) => card.source !== null);
      const cardIds = [];
      const projectIds = [];
      validCards.forEach((card) => {
        cardIds.push(get(card, 'source._id'));
      });
      validCards.forEach((cardId) => {
        projectIds.push(get(cardId, 'project._id'));
      });
      const SupplyChainParams = {
        projectId: uniq(projectIds),
        orderId: uniq(cardIds),
      };
      const cardData = await SupplyChain.supplyChain(SupplyChainParams);
      const validCardIds = [];
      forEach(cardData.data, (val) => {
        validCardIds.push(get(val, '_id'));
      });
      if (cardData.total > 0) {
        for (let i = 0; i < notificationData.data.length; i++) {
          if (notificationData.data[i].source != null) {
            if (!validCardIds.includes(notificationData.data[i].source._id)) {
              notificationData.data[i].source = null;
            }
          }
        }
      }
      return notificationData;
    };

    const loadData = async (commonParams) => {
      const dates = store.getters.selectedDatesForKeys(['notifyByStartDate', 'notifyByEndDate']);
      const params = {
        projectId: store.getters.selectedIdsForKey('filteredProjects'),
        ...commonParams,
        sendUserNotifsOnly: store.state.toggleMyNotify,
        companyId: getCompanyIds.value,
      };
      if (dates) {
        if (dates.notifyByStartDate) params.startDate = dates.notifyByStartDate;
        if (dates.notifyByEndDate) params.endDate = dates.notifyByEndDate;
      }
      const notificationData = await Notifications.getNotifications({ params });
      notificationData.data = notificationData.data.filter((dt) => dt.notificationTitle.toLowerCase().includes(
        store.state.queryParams.searchText.toLowerCase(),
      )
        || dt.notification.toLowerCase().includes(
          store.state.queryParams.searchText.toLowerCase(),
        ));
      const data = await removeArchivedOrderSource(notificationData);
      return data;
    };

    const refreshTable = async (type, val) => {
      if (!isEmpty(type) && type === 'toggleMyNotify') {
        store.commit('setToggleMyNotify', val);
      }
      await notifTable.value.refreshTable();
    };
    const triggerSetHeaderHeight = () => {
      // eslint-disable-next-line no-unused-expressions
      notifTable?.value?.closeColumnChooserModal();
      // eslint-disable-next-line no-unused-expressions
      notifTable?.value?.refreshFilterOpts();
    };

    return {
      ...toRefs(state),
      notifTable,
      loadData,
      refreshTable,
      tableProps,
      onCellClicked,
      triggerSetHeaderHeight,
    };
  },
});
</script>
<style scoped>
::v-deep(.o-table__td:nth-last-child(2)::before) {
  display: none;
}
::v-deep(.o-table__th:nth-last-child(2)::before) {
  display: none;
}
</style>
