<template>
  <o-modal :active="isActive" :canCancel="false" class="modal-sm">
    <div class="modal-card">
      <header class="modal-card-head">
        <h4
          class="modal-card-title is-size-4 has-text-black-bis is-capitalized has-text-weight-bold"
        >
          {{ modalName }}
        </h4>
        <div class="is-divider-vertical"></div>
        <i class="icon-close is-clickable" @click="$emit('close')"></i>
      </header>
      <section class="modal-card-body is-marginless is-visible pr-0">
        <div class="column pl-0 py-0">
          <div class="columns">
            <div class="column">
              <div class="has-text-weight-bold has-text-black-bis is-size-3">
              Order: {{rowData.name}}
              </div>
            </div>
            <div class="column is-narrow has-text-grey-darker">
              <em>* required</em>
            </div>
          </div>
        </div>
        <div class="pause-modal">
          <div class="field">
            <label class="label">Message*</label>
            <div class="control">
              <textarea
                v-model.trim="message"
                class="textarea"
                rows="3"
              >
              </textarea>
            </div>
          </div>
          <div class="field" multiple="true">
            <div class="is-flex is-justify-content-space-between">
              <label class="label"><em>Recipients *</em></label>
              <button class="button px-0 is-pulled-right mr-0" @click="activateDropdown">
                <i class="icon-addnew"></i>
              </button>
            </div>
            <div class="control">
               <VueMultiselect
                class="recipient-field"
                v-model="notifyUsers"
                :options="groupedListUsers"
                track-by="_id"
                label="name"
                :multiple="true"
                group-values="val"
                group-label="key"
                :searchable="true"
                :allow-empty="true"
                :tags="true"
                ref="recipientField"
                :close-on-select="false"
              >
              </VueMultiselect>
            </div>
          </div>
          <div class="field">
            <h4 class="is-size-3 has-text-black-bis mb-3">
              Create Task
              <button class="button px-0 is-pulled-right mr-0 has-text-black-bis"
                @click="toggleTask">
                <i :class="[createTask ? 'icon-removedelete' : 'icon-addnew']"></i>
              </button>
            </h4>
          </div>
          <div class="field" v-if="createTask">
            <label class="label">Description *</label>
            <div class="control">
              <textarea
                v-model.trim="description"
                class="textarea"
                rows="3"
              >
              </textarea>
            </div>
          </div>
          <div class="columns" v-if="createTask">
            <div class="column">
            <label class="label">Assignee</label>
              <mf-multi-select
                openDirection="below"
                v-model="selectedUser"
                :options="groupedUsers"
                :closeOnSelect="true"
                label="name"
                track-by="_id"
                :multiple="false"
                :hideSelected="false"
                group-values="val" group-label="key"
                @update:modelValue="selectionDone('assignedTo', $event, rowData)">
              </mf-multi-select>
              <label class="label">Status</label>
              <mf-multi-select
                v-model="selectedStatus"
                :options="todoStatus"
                :multiple="false"
                label="title"
                :allow-empty="false"
                @update:modelValue="selectionDone('status', $event, rowData)">
              </mf-multi-select>
              <div class="is-flex is-justify-content-space-between">
                <field-date
                    :item="rowData.todos[clIndex]"
                    :is-edit = "true"
                    :inputProps="{pos: 'top-right', kind: 'dueDate'}"
                    label="Due *">
                </field-date>
                <div class="is-flex is-align-items-flex-end has-text-black-bis">
                  <o-checkbox
                    v-model="isPrivate"
                    @click="onPrivate(rowData)">
                    Private
                  </o-checkbox>
                </div>
              </div>
            </div>
            <div class="column">
              <mf-file
                fromCard = "false"
                :order="rowData.todos[clIndex]"
                :type="type" />
            </div>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot is-justify-content-flex-end">
        <div>
          <button
            class="button is-outlined"
            @click="$emit('close')"
          >
            Cancel
          </button>
          <button
            class="button has-background-black-bis"
            @click="submit"
            :disabled="!isDisable"
          >
            Finish
          </button>
        </div>
      </footer>
    </div>
    <o-loading
    :full-page="false"
    :active="isLoading"
    :can-cancel="false"
    ></o-loading>
  </o-modal>
</template>

<script>
import {
  reactive,
  toRefs,
  onMounted,
  computed,
  ref,
} from 'vue';
import _ from 'lodash';
import Projects from '@/models/Projects';
import { useStore } from 'vuex';
import Users from '@/models/Users';
import translator from '@/languages/translator';
// eslint-disable-next-line import/no-extraneous-dependencies
import uuid from 'uuid/v4';
import MfFileList from '@/components/card-edit/CardFileList.vue';
import VueMultiselect from 'vue-multiselect';

export default {
  name: 'PauseRestartmodal',
  components: {
    'mf-file': MfFileList,
    VueMultiselect,
  },
  props: {
    isActive: {
      type: Boolean,
    },
    rowData: {
      type: Object,
    },
  },
  setup({ rowData }, { emit }) {
    const store = useStore();
    const { t } = translator;
    const state = reactive({
      type: 'pauseRestartModal',
      message: '',
      notifyUsers: [],
      isLoading: false,
      modalName: rowData.status === 'pause' ? 'Restart Order' : 'Pause Order',
      createTask: false,
      description: '',
      selectedUser: '',
      pvtGroupedUsers: [],
      groupedUsers: [],
      groupUsersWithRun: [],
      todoStatus: [
        { title: 'Not Started', icon: 'question-circle' },
        { title: 'In Progress', icon: 'chevron-circle-right' },
        { title: 'Inactive', icon: 'exclamation-triangle' },
        { title: 'Complete', icon: 'trophy' }],
      isPrivate: rowData.private,
      assignedTo: {},
      selectedStatus: null,
      dueDate: '',
      clIndex: '',
      groupedListUsers: [],
    });
    const recipientField = ref(null);
    const user = store.state.userData;
    const isNotTemplate = computed(() => !(rowData.__t === 'MatTemplates' || rowData.__t === 'ProdTemplates'));
    const pvtGroupUsers = (users) => {
      const filteredUsers = [];
      users.forEach((usr) => {
        if (usr.company === rowData.owner.company._id) {
          filteredUsers.push(usr);
        }
      });
      let allUsers = _.groupBy(filteredUsers, 'companyName');
      allUsers = _.map(allUsers, (val, key) => ({ val, key }));
      return allUsers;
    };
    const groupUsers = (users) => {
      let gUsers = _.groupBy(users, 'companyName');
      gUsers = _.map(gUsers, (val, key) => ({ val, key }));
      return gUsers;
    };
    const getAssignee = async () => {
      let allUsers;
      let usersWithRun;
      const orderCreator = { name: '(Order Creator)', companyName: user.companyName };
      const runCreator = {
        name: t('Work Step Owner'),
        companyName: user.companyName,
      };
      if (rowData.__t === 'MatTemplates' || rowData.__t === 'ProdTemplates') {
        allUsers = (await Users.allUsers({ limit: 1000 })).data;
        allUsers = _.filter(allUsers, (u) => u.activated && !u.archived);
        allUsers.unshift(orderCreator);
        usersWithRun = [runCreator, ...allUsers];
      } else {
        allUsers = await Projects.linkedProjectUsers([rowData.project._id]);
        state.pvtGroupedUsers = pvtGroupUsers(allUsers);
      }
      state.groupedUsers = groupUsers(allUsers);
      state.groupUsersWithRun = groupUsers(usersWithRun);
      if (state.createTask) {
        const data = rowData.todos[state.clIndex];
        if (data.private && isNotTemplate.value) {
          return state.pvtGroupedUsers;
        }
        if (data.sources[1] && data.sources[1].type === 'run') {
          return state.groupUsersWithRun;
        }
      }
      return state.groupedUsers;
    };
    const onPrivate = (data) => {
      if (!data.private) data.assignedTo.user = null;
    };
    onMounted(async () => {
      state.isLoading = true;
      const project = await Projects.getOne(rowData.project._id);
      const projectCompanies = _.map(project.projectCompanies, '_id');
      projectCompanies.push(rowData.owner.company._id);
      const params = {
        companyIds: projectCompanies,
        projectId: rowData.project._id,
        ignoreCompany: true,
      };
      const userOptions = await Users.getCompanyProjectAllLinkedUsers({ params });
      userOptions.push({
        _id: rowData.owner.user._id,
        id: rowData.owner.user._id,
        name: 'Card Owner',
        type: 'notify',
        companyName: user.companyName,
      });
      state.notifyUsers = userOptions;
      const users = Users.groupUsers(userOptions);
      state.groupedListUsers = users;
      state.isLoading = false;
    });
    const submit = async () => {
      _.set(rowData, 'notifyUsers', state.notifyUsers);
      const checklist = {
        assignedTo: {
          user: { _id: state.assignedTo._id, name: state.assignedTo.name },
          company: { _id: state.assignedTo.company, name: state.assignedTo.companyName },
        },
        status: _.isNull(state.selectedStatus) ? 'Not Started' : state.selectedStatus.title,
        private: !!(['detailing', 'manufacturing', 'qa'].includes(rowData.stage)),
        type: 'Planning',
        uuid: uuid(),
        isEditing: true,
        text: state.description,
      };
      _.assign(rowData.todos[state.clIndex], checklist);
      rowData.pauseRestartMessage = state.message;
      try {
        if (rowData.status !== 'pause') {
          _.set(rowData, 'status', 'pause');
          await rowData.save();
        } else {
          _.set(rowData, 'status', 'not-started');
          await rowData.save();
        }
      } catch (e) {
        console.log('Error pausing order', e);
      }
      emit('close');
    };
    const deleteTodo = () => {
      const dltTodo = rowData.todos[state.clIndex];
      let dltData = {};
      const formFile = _.find(dltTodo.files, { type: 'form' });
      if (formFile) {
        if (dltTodo.sources.length === 1) {
          dltData = rowData;
        } else {
          const runOrItemArr = dltTodo.sources[1].type === 'run' ? rowData.manager.runs : rowData.items;
          dltData = _.find(runOrItemArr, (dt) => {
            const urls = _.map(dt.simpleFiles, 'url');
            return urls.includes(formFile.url);
          });
        }
        const simpleFileIndex = _.findIndex(dltData.simpleFiles, { url: formFile.url });
        if (simpleFileIndex > -1) {
          dltData.simpleFiles[simpleFileIndex].archived.value = true;
          dltData.simpleFiles.splice(simpleFileIndex, 1);
        }
      }
      // eslint-disable-next-line vue/no-mutating-props
      rowData.todos.splice(state.clIndex, 1);
    };
    const toggleTask = async () => {
      state.createTask = !state.createTask;
      if (state.createTask) {
        state.clIndex = rowData.newTodo();
        await getAssignee();
      } else {
        deleteTodo();
      }
    };
    const isDisable = computed(() => {
      const requiredFields = !_.isEmpty(state.message) && !_.isEmpty(state.notifyUsers);
      if (state.createTask) {
        const requiredFieldsCt = !_.isEmpty(rowData.todos[state.clIndex].dueDate)
        && !_.isEmpty(state.description) && !_.isEmpty(state.selectedUser)
        && !_.isEmpty(state.selectedStatus);
        return requiredFields && requiredFieldsCt;
      }
      return requiredFields;
    });
    const selectionDone = (selectionType, opt) => {
      if (selectionType === 'assignedTo') {
        state.assignedTo = opt;
      }
      if (selectionType === 'status') {
        state.selectedStatus = opt;
      }
    };

    const activateDropdown = () => {
      recipientField.value.activate();
    };

    return {
      ...toRefs(state),
      submit,
      toggleTask,
      isDisable,
      onPrivate,
      selectionDone,
      activateDropdown,
      recipientField,
    };
  },
};
</script>
<style scoped>
.modal-card-body {
  height: 100%;
}
::v-deep (.o-modal__content .button) {
  color: unset !important;
}
</style>
