<template>
  <div class="field">
    <div class="field-label">
      <label class="has-text-black-bis is-italic is-size-3" v-if="label"
        :class="labelPosition === 'is-left' ? 'line-height' : 'label'">
        {{ label }}
      </label>
    </div>
    <div class="field-body">
      <div class="is-flex has-text-black-bis" v-if="isBomCreated">
        <span class="icon-checkmark mr-2"></span>
        <span class="line-height">All Available BOMs Created</span>
      </div>
      <div v-else>
        <o-checkbox class="p-0"
          v-model="create">
          <span class="has-text-black-bis">
            Create Available BOMs
          </span>
        </o-checkbox>
      </div>
      <div class="is-flex has-text-black-bis" v-if="isBomSubmitted">
        <span class="icon-checkmark mr-2"></span>
        <span class="line-height">All Available BOMs Submitted</span>
      </div>
      <o-checkbox v-else class="p-0"
        v-model="submit"
        :disabled="isSubmitDisabled"
        v-tooltip="{ content: isSubmitDisabled ? 'Not all Items are Purchased' : '' }"
      >
        <span class="has-text-black-bis">
          Submit Available BOMs
        </span>
      </o-checkbox>
    </div>
  </div>
</template>
<script>
import { computed } from 'vue';

export default ({
  props: {
    value: Object,
    label: String,
    isBomCreated: Boolean,
    isBomSubmitted: Boolean,
    isSubmitDisabled: Boolean,
  },
  setup(props, { emit }) {
    const submit = computed({
      get: () => props.value.submit,
      set: (val) => {
        emit('update:value', { val, type: 'submit' });
      },
    });
    const create = computed({
      get: () => props.value.create,
      set: (val) => {
        emit('update:value', { val, type: 'create' });
      },
    });
    return {
      create,
      submit,
    };
  },
});
</script>
